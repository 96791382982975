import ReactPDF, { StyleSheet, Text, View } from '@react-pdf/renderer'
import { PrintTitle, SpaceLine } from 'components/print'
import { usePrinterStyles } from 'components/print/usePrinterStyles'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import React from 'react'
import redraft from 'redraft'

// Este componente tem por objetivo exclusivamente realizar
// a impressão de atestados anteriores ao novo atendimento

interface PrintAtestadoLegadoProps {
  align?: 'center' | 'left' | 'right' | 'justify'
  wrapperStyles?: ReactPDF.Style
  textStyles?: ReactPDF.Style
  content: string
}

const ParseAtestadoLegado = (props: PrintAtestadoLegadoProps) => {
  const { content, align, textStyles, wrapperStyles } = props
  const { config } = usePrinterStyles()

  const styles = StyleSheet.create({
    wrapper: { marginHorizontal: 15 },
    text: { ...config.main, lineHeight: 1.25, textAlign: align ?? 'justify' },
    bold: { fontFamily: 'Lato Bold' },
    italic: { fontFamily: 'Lato Italic' },
    underline: { textDecoration: 'underline' },
    strikethrough: { textDecoration: 'line-through' },
    blockquote: {
      ...config.secondary,
      borderLeft: 2,
      borderLeftColor: config.secondary.color,
      padding: 5,
      margin: 3,
      fontFamily: 'Lato Italic',
    },
  })

  const renderers = {
    inline: {
      BOLD: (children, { key }) => {
        return (
          <Text key={`bold-${key}`} style={styles.bold}>
            {children}
          </Text>
        )
      },
      ITALIC: (children, { key }) => {
        return (
          <Text key={`italic-${key}`} style={styles.italic}>
            {children}
          </Text>
        )
      },
      UNDERLINE: (children, { key }) => {
        return (
          <Text wrap key={`underline-${key}`} style={styles.underline}>
            {children}
          </Text>
        )
      },
      STRIKETHROUGH: (children, { key }) => {
        return (
          <Text wrap key={`strikethrough-${key}`} style={styles.strikethrough}>
            {children}
          </Text>
        )
      },
    },

    blocks: {
      unstyled: (children, { keys }) => {
        return children.map((child, index) => {
          if (index === 0)
            return (
              <>
                <PrintTitle title={child} level={3} />
                <SpaceLine />
              </>
            )
          return (
            <View key={keys[index]} style={[styles.wrapper, wrapperStyles]}>
              <Text style={[styles.text, textStyles]}>{child}</Text>
            </View>
          )
        })
      },
      blockquote: (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <View key={keys[index]} style={[styles.wrapper, wrapperStyles]}>
              <Text key={`blockquote-${index}`} style={[styles.blockquote, textStyles]}>
                {child}
              </Text>
            </View>
          )
        })
      },
    },
  }

  const blocksFromHTML = convertFromHTML(content)
  const initialState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)

  const editorState = EditorState.createWithContent(initialState)
  const rawContent = convertToRaw(editorState.getCurrentContent())

  return redraft(rawContent, renderers, { blockFallback: 'unstyled' })
}

export const PrintAtestadoLegado = (props: PrintAtestadoLegadoProps) => {
  return <ParseAtestadoLegado {...props} />
}
