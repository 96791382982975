import { Button, HFlow, Icon, PagedTable, Spinner, Text, Tooltip, useStyles, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useAuditoriaHistoricoTableQuery } from 'graphql/hooks.generated'
import {
  AuditoriaHistoricoQueryInput,
  AuditoriaHistoricoTableQuery,
  StatusProcessoAuditoria,
} from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import React, { useState } from 'react'

export type AuditoriaHistoricoItemDto = AuditoriaHistoricoTableQuery['auditoriaHistorico']['content'][0]

export default function AuditoriaHistoricoTable() {
  const [filter, setFilter] = useState<AuditoriaHistoricoQueryInput>({
    pageParams: {},
  })

  const {
    data: { auditoriaHistorico },
    loading,
    refetch,
  } = useAuditoriaHistoricoTableQuery({ variables: { input: filter } })

  const { data: session } = useSession()

  useAtmosphere({
    topic: `auditoria/${session.profissional.usuario.id}`,
    onMessage: refetch,
  })

  const renderPeriodoInicio = (row: AuditoriaHistoricoItemDto) => {
    return (
      <HFlow hSpacing={0.5}>
        <DateTime value={row.dataInicio} format='DD/MM/YYYY' />
      </HFlow>
    )
  }
  const renderPeriodoFim = (row: AuditoriaHistoricoItemDto) => {
    return (
      <HFlow hSpacing={0.5}>
        <DateTime value={row.dataFim} format='DD/MM/YYYY' />
      </HFlow>
    )
  }

  const renderData = (row: AuditoriaHistoricoItemDto) => {
    return (
      <HFlow hSpacing={0.5}>
        <DateTime value={row.dataSolicitacao} format='DD/MM/YYYY HH:mm' />
      </HFlow>
    )
  }

  const { classes } = useStyles((theme) => ({
    success: {
      fontSize: '1rem',
      color: theme.pallete.status.success.main,
    },
    loading: {
      fontSize: '1rem',
      color: theme.pallete.primary.main,
    },
    error: {
      fontSize: '1rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  const renderStatus = (row: AuditoriaHistoricoItemDto) => {
    if (row.status === StatusProcessoAuditoria.PROCESSADO) {
      return (
        <HFlow hSpacing={0.5}>
          <Icon style={classes.success} icon='checkCircleOutline' />
          <Text color='success'>Processado</Text>
        </HFlow>
      )
    }
    if (row.status === StatusProcessoAuditoria.PROCESSANDO) {
      return (
        <HFlow hSpacing={0.5}>
          <Spinner style={classes.loading} />
          <Text color='primary'>Processando</Text>
        </HFlow>
      )
    }

    const errors = {
      ERRO_SEM_DADOS: 'Erro - Não existem dados para gerar este relatório',
      ERRO_PDF_NAO_SUPORTADO:
        'Erro - O relatório solicitado possui mais de 5000 registros,' +
        'refaça a solicitação  utilizando o tipo de arquivo CSV',
      ERRO_INESPERADO: 'Erro - Houve um erro durante a geração do relatório, refaça a solicitação',
      ERRO_PROCESSO_EXECUTANDO: 'Erro - Já existe uma geração em andamento, tente novamente mais tarde',
    }

    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Icon style={classes.error} icon='banOutline' />
        <Text color='danger'>{errors[row.status]}</Text>
      </HFlow>
    )
  }

  const renderButtons = (row: AuditoriaHistoricoItemDto) => {
    if (row.status !== StatusProcessoAuditoria.PROCESSADO) {
      return null
    }
    const dowloadReport = () => {
      const basePath = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : window.location.origin
      window.open(`${basePath}/api/auditoria/report?id=${row.id}`)
    }

    return (
      <Tooltip text={row.csv === true ? 'Salvar CSV' : 'Salvar PDF'}>
        <Button size='small' skin='ghost' onClick={dowloadReport}>
          <Icon icon='download' />
        </Button>
      </Tooltip>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    result: auditoriaHistorico,
    onChange: setFilter,
  })

  return (
    <>
      <VFlow vSpacing={0.5}>
        <h2>Histórico de trilhas de auditoria (Últimos 7 dias)</h2>
        <TableBox>
          <PagedTable<AuditoriaHistoricoItemDto>
            {...tableProps}
            data-cy='AuditoriaHistoricoTable'
            columns={[
              { name: 'dataInicio', header: 'Período (Início)', render: renderPeriodoInicio, sortable: false },
              { name: 'dataFim', header: 'Período (Fim)', render: renderPeriodoFim, sortable: false },
              { name: 'dataSolicitacao', header: 'Data', render: renderData, sortable: false },
              { name: 'status', header: 'Status', render: renderStatus, sortable: false, style: { width: 500 } },
              { name: 'actions', render: renderButtons, style: { textAlign: 'right' } },
            ]}
          />
        </TableBox>
      </VFlow>
    </>
  )
}
