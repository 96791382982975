/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableRow, Text, Theme, useTheme } from 'bold-ui'
import { TableBox } from 'components/table'
import { useMemo } from 'react'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'

import { ProcedimentoEscutaModel } from './HistoricoEscutaInicialModel'

interface HistoricoEscutaInicialProcedimentoTableProps {
  procedimentos: ProcedimentoEscutaModel[]
}

export default function HistoricoEscutaInicialProcedimentoTable(props: HistoricoEscutaInicialProcedimentoTableProps) {
  const { procedimentos } = props
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return (
    <TableBox>
      <Table>
        <TableBody style={styles.tableBody}>
          {procedimentos.map((procedimento) => (
            <TableRow key={procedimento.id}>
              <TableCell css={styles.tableRow}>
                <Text>{`${procedimento.descricao} - ${showCodigoProcedimento(procedimento.codigo)}`}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableBox>
  )
}

const createStyles = (theme: Theme) => ({
  tableBody: css`
    & :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.divider};
    }
  `,
  tableRow: css`
    padding: 0.5rem 1rem;
    &:hover {
      background-color: ${theme.pallete.surface.background};
    }
  `,
})
