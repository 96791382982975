import { Alert, Link } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import React from 'react'

import { LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS } from '../model'

interface ExistemAtendimentosNaoFinalizadosAlertProps {
  onClickExibirSomenteNaoFinalizados(): void
  onClickManterFiltroAtual(): void
}

export const ExistemAtendimentosNaoFinalizadosAlert = (props: ExistemAtendimentosNaoFinalizadosAlertProps) => {
  const { onClickExibirSomenteNaoFinalizados, onClickManterFiltroAtual } = props
  const { analytics } = useFirebase()

  const handleOnClickExibirSomenteNaoFinalizados = () => {
    onClickExibirSomenteNaoFinalizados()
    analytics.logEvent('ver_atend_nao_finalizados_msg_LA')
  }

  const handleOnClickManterFiltroAtual = () => {
    onClickManterFiltroAtual()
    analytics.logEvent('ignorar_msg_nao_finalizados_LA')
  }

  return (
    <Alert type='warning' style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
      <strong>Atendimentos não finalizados.</strong> Existem atendimentos dos últimos{' '}
      {LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS} dias que não foram finalizados. Esses atendimentos não serão
      incluídos no histórico do cidadão ou contabilizados nos relatórios. Você pode utilizar o filtro “Ver somente
      atendimentos não finalizados” para visualizá-los e assim poder finalizar esses atendimentos.
      <Link
        tabIndex={1}
        onClick={handleOnClickExibirSomenteNaoFinalizados}
        style={{ fontSize: 'inherit', marginLeft: '0.5rem' }}
      >
        Ver somente atendimentos não finalizados
      </Link>
      <Link tabIndex={1} onClick={handleOnClickManterFiltroAtual} style={{ fontSize: 'inherit', marginLeft: '0.5rem' }}>
        Finalizar esses atendimentos depois
      </Link>
    </Alert>
  )
}
