import { resolveValue } from 'components/form/final-form/hooks/useField'
import { FlagsContextModel } from 'config/useFlagsContext'
import { Calculation } from 'final-form-calculate'
import { SexoEnum, TipoAtendimentoEnum, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { compact, inRange, isEmpty } from 'lodash'
import { ProcedimentosAutomaticosPreNatal, ProcedimentosAutomaticosPuerperio } from 'types/enums'
import { monthsToYearsFloor } from 'util/date/calculateAge'
import { Meta, MetaArray, MetaRoot } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { ProcedimentoSigtapFieldModel } from 'view/atendimentos/detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'
import { calculateProcedimentoAutomaticoIndividual } from 'view/atendimentos/utils/procedimentoAutomaticoUtils'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import {
  hasProblemaCondicaoDePreNatal,
  hasProblemaNaoEvolucaoPuerperio,
} from '../../avaliacao/components/problemas-condicoes/utils/verifications'
import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'

const createTipoAtendimentoCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  cbo2002: string,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  isGestante: boolean,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  isObservacaoAndResponsavel: boolean,
  flags: FlagsContextModel
): Calculation => ({
  field: [meta.tipoAtendimento.absolutePath(), metaProblemasCondicoes.absolutePath()],
  updates: {
    [meta.procedimentosAdministrativos.absolutePath()]: (
      _: TipoAtendimentoEnum,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      if (!isEmpty(prevValues)) {
        const isUpa = flags.UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
        const problemasCondicoes = resolveValue<ProblemaCondicaoModel[]>(allValues, metaProblemasCondicoes) || []

        const procedimentoAutomaticoPuerperio = findProcedimentosAutomaticosByCodigo(procedimentosAutomaticos, [
          ProcedimentosAutomaticosPuerperio.CONSULTA_PUERPERAL,
        ])

        const deveAdicionarProcedimentoAutomaticoPuerperio =
          !isUpa &&
          hasProblemaNaoEvolucaoPuerperio(problemasCondicoes) &&
          canAddProcedimentoAutomaticoGestacao(
            procedimentoAutomaticoPuerperio,
            hasPermissionPreNatal,
            idadeCidadaoEmAnos,
            desejaInformarIdentidadeGenero,
            sexo
          )

        if (deveAdicionarProcedimentoAutomaticoPuerperio) {
          return getProcedimentosWithNewProced(
            allValues.finalizacao.procedimentosAdministrativos,
            procedimentoAutomaticoPuerperio
          )
        }

        const procedimentoAutomaticoPreNatal = findProcedimentosAutomaticosByCodigo(procedimentosAutomaticos, [
          ProcedimentosAutomaticosPreNatal.CONSULTA_PRE_NATAL,
        ])

        const deveAdicionarProcedimentoAutomaticoPreNatal =
          !isUpa &&
          hasProblemaCondicaoDePreNatal(problemasCondicoes, isGestante) &&
          canAddProcedimentoAutomaticoGestacao(
            procedimentoAutomaticoPreNatal,
            hasPermissionPreNatal,
            idadeCidadaoEmAnos,
            desejaInformarIdentidadeGenero,
            sexo
          )

        if (deveAdicionarProcedimentoAutomaticoPreNatal) {
          return getProcedimentosWithNewProced(
            allValues.finalizacao.procedimentosAdministrativos,
            procedimentoAutomaticoPreNatal
          )
        }

        return calculateProcedimentosAutomaticosByTipoAtendimento(
          allValues,
          procedimentosAutomaticos,
          tipoEstabelecimento,
          cbo2002,
          isObservacaoAndResponsavel,
          flags
        )
      }

      return allValues.finalizacao.procedimentosAdministrativos
    },
  },
})

const canAddProcedimentoAutomaticoGestacao = (
  procedimentosGestacao: ProcedimentoAutomatico[],
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexoCidadao: SexoEnum
): boolean => {
  if (hasPermissionPreNatal) {
    const procedimentosValidos = procedimentosGestacao.filter((item) => {
      const { idadeMinima, idadeMaxima, sexo: sexoProcedimento } = item
      const idadeMinimaEmAnos = monthsToYearsFloor(idadeMinima)
      const idadeMaximaEmAnos = monthsToYearsFloor(idadeMaxima)
      const procedimentoValidoParaSexoDoCidadao = !desejaInformarIdentidadeGenero && sexoProcedimento === sexoCidadao
      return (
        inRange(idadeCidadaoEmAnos, idadeMinimaEmAnos, idadeMaximaEmAnos) &&
        (procedimentoValidoParaSexoDoCidadao || desejaInformarIdentidadeGenero)
      )
    })
    return procedimentosValidos.isNotEmpty()
  }
  return false
}

const calculateProcedimentosAutomaticosByTipoAtendimento = (
  allValues: SoapState,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  cbo2002: string,
  isProfResponsavelObservacao: boolean,
  flags: FlagsContextModel
): ProcedimentoSigtapFieldModel[] => {
  const procedimentoAutomatico = calculateProcedimentoAutomaticoIndividual(
    tipoEstabelecimento,
    allValues.finalizacao.tipoAtendimento,
    cbo2002,
    allValues.plano?.startObservacao || isProfResponsavelObservacao,
    flags
  )

  return getProcedimentosWithNewProced(
    allValues.finalizacao.procedimentosAdministrativos,
    findProcedimentosAutomaticosByCodigo(procedimentosAutomaticos, [procedimentoAutomatico])
  )
}

export const findProcedimentosAutomaticosByCodigo = (
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  codigoProcedimentos: string[]
) => procedimentosAutomaticos.filter((item) => codigoProcedimentos.includes(item.codigo))

export const getProcedimentosWithNewProced = (
  proceds: ProcedimentoSigtapFieldModel[],
  newProceds: ProcedimentoAutomatico[]
) => {
  let listProcedAdministrativos = (proceds ?? []).filter((proced) => !proced.isAutomatico)

  compact(newProceds).forEach((item) => {
    const containsProced = listProcedAdministrativos.find((proced) => proced.procedimento.codigo === item.codigo)
    if (!containsProced) {
      listProcedAdministrativos.push({
        _id: uuidv4(),
        procedimento: item,
        isAutomatico: true,
      })
    }
  })

  return listProcedAdministrativos
}

export const createFinalizacaoIndividualCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  cbo2002: string,
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  isGestante: boolean,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  isObservacaoAndResponsavel: boolean,
  flags: FlagsContextModel
): Calculation[] => [
  createTipoAtendimentoCalculations(
    meta,
    metaProblemasCondicoes,
    cbo2002,
    procedimentosAutomaticos,
    hasPermissionPreNatal,
    idadeCidadaoEmAnos,
    desejaInformarIdentidadeGenero,
    sexo,
    isGestante,
    tipoEstabelecimento,
    isObservacaoAndResponsavel,
    flags
  ),
]
