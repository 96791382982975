import { HFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { OrigemAtendimentoRecord } from 'types/enums'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { HOJE, humanizeDate } from 'util/date/humanize-date'

import { IdadeOrigemAtendimento } from '../../../model/model-historico'
import { isFichaAtendimento } from '../../../utils/historicoUtils'
import { OrigemAtendimentoObservacaoIcon } from './atendimento-observacao/OrigemAtendimentoObservacaoIcon'

interface HistoricoIdadeOrigemAtendimentoProps {
  idadeOrigemAtendimento: IdadeOrigemAtendimento
  isAtendObservacao?: boolean
  isCompartilhamentoCuidado?: boolean
  dataInicioCompartilhamento?: LocalDate
}

export default function HistoricoIdadeOrigemAtendimento(props: HistoricoIdadeOrigemAtendimentoProps) {
  const {
    idadeOrigemAtendimento,
    isAtendObservacao = false,
    isCompartilhamentoCuidado = false,
    dataInicioCompartilhamento,
  } = props

  const ocorreuEm = humanizeDate(idadeOrigemAtendimento.dataAtendimento)
  const tipoHistorico = isCompartilhamentoCuidado ? 'Conduta' : 'Atendimento'
  const titleAtendimentoOcorreu = ocorreuEm === HOJE ? `${tipoHistorico} ocorreu` : `${tipoHistorico} ocorreu há`
  const isFicha = isFichaAtendimento(idadeOrigemAtendimento.origemAtendimento)

  return (
    <HFlow hSpacing={0.25}>
      <HLabel title={`${titleAtendimentoOcorreu}:`}>{ocorreuEm}</HLabel>|
      {dataInicioCompartilhamento && (
        <>
          <HLabel title='Início do compartilhamento'>{formatDateAndHoursMinutes(dataInicioCompartilhamento)}</HLabel>|
        </>
      )}
      <HFlow hSpacing={0.25} alignItems='center'>
        <HLabel title='Registros com origem:'>
          {OrigemAtendimentoRecord[idadeOrigemAtendimento.origemAtendimento]}
        </HLabel>

        {isAtendObservacao && isFicha && (
          <OrigemAtendimentoObservacaoIcon origemAtendimento={idadeOrigemAtendimento.origemAtendimento} />
        )}
      </HFlow>
      |
      <HLabel title='Idade do paciente no dia:'>
        {humanizeAge(idadeOrigemAtendimento.dataNascimentoCidadao, idadeOrigemAtendimento.dataAtendimento)}
      </HLabel>
    </HFlow>
  )
}
