import { Theme, useStyles } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import React, { CSSProperties } from 'react'

interface PageLoadingProps {
  message: string
}

export function PageLoading(props: PageLoadingProps) {
  const { message } = props
  const { classes } = useStyles(createStyles)
  return (
    <div className={classes.root}>
      <LoadingIndicator message={message} />
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  } as CSSProperties,
})
