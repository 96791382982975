import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { CidadaoAtendimentoContextModel } from 'hooks/atendimento-context/model'
import { isEmpty } from 'lodash'
import { createValidator, ErrorObject } from 'util/validation'
import { avaliacaoValidator } from 'view/atendimentos/detail/soap/avaliacao/validator'
import { finalizacaoAtendimentoValidator } from 'view/atendimentos/detail/soap/finalizacao/validator'
import { objetivoValidator } from 'view/atendimentos/detail/soap/objetivo/validator'

import { antecedentesValidator } from '../detail/soap/antecedentes/validator'
import { Problema } from '../detail/soap/aside/types/ProblemaModel'
import { FinalizacaoAtendimentoFormModel } from '../detail/soap/finalizacao'
import { ObjetivoFormModel } from '../detail/soap/objetivo'
import { IntervencoesProcedimentosPlanoModel } from '../detail/soap/plano/components/IntervencoesProcedimentosView'
import { OdontologiaModel } from '../detail/soap/plano/evolucoes-odontologicas/EvolucoesOdontologicasView'
import { procedimentosUnicosOdontologia } from '../detail/soap/plano/evolucoes-odontologicas/util'
import { PlanoFormModel } from '../detail/soap/plano/PlanoForm'
import { planoValidator } from '../detail/soap/plano/validator'
import { preNatalValidator, validateDumPreNatal } from '../detail/soap/pre-natal/validator'
import { subjetivoValidator } from '../detail/soap/subjetivo/validator'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import { SoapState } from './model'

interface CidadaoValidator extends CidadaoAtendimento, Pick<CidadaoAtendimentoContextModel, 'isGestante'> {}

export const atendimentoIndividualValidator = (
  cidadao: CidadaoValidator,
  dataAtendimento: Instant,
  tipoAtendProf: TipoAtendimentoProfissional,
  problemasAtivosOuLatentesAntigos: Problema[],
  isDemandaEspontanea: boolean,
  hasEncaminhamentoExterno: boolean,
  hasPermissionPreNatal: boolean,
  isAtendimentoObservacao: boolean,
  podeFinalizarAtendimentoObservacao: boolean,
  getServerTimeNow: () => Date,
  qtdTotalProcedimentos: number
) =>
  createValidator<SoapState>(
    {
      antecedentes: antecedentesValidator,
      subjetivo: subjetivoValidator,
      objetivo: objetivoValidator(cidadao, dataAtendimento),
      avaliacao: avaliacaoValidator(
        tipoAtendProf,
        cidadao.dataNascimento,
        dataAtendimento,
        problemasAtivosOuLatentesAntigos,
        hasPermissionPreNatal
      ),
      plano: planoValidator(tipoAtendProf, isAtendimentoObservacao),
      preNatal: preNatalValidator,
    },
    (values: SoapState, errors: ErrorObject<SoapState>): ErrorObject<SoapState> => {
      const isStartObservacao = values.plano?.startObservacao

      errors.finalizacao = finalizacaoAtendimentoValidator(
        tipoAtendProf,
        isDemandaEspontanea,
        hasEncaminhamentoExterno,
        isStartObservacao,
        isAtendimentoObservacao,
        podeFinalizarAtendimentoObservacao,
        getServerTimeNow
      )?.(values.finalizacao)

      const errorsObjetivo = validateDumPreNatal(
        hasPermissionPreNatal,
        cidadao.isGestante,
        cidadao.ultimaDum?.dum,
        values,
        dataAtendimento,
        errors?.objetivo as ErrorObject<ObjetivoFormModel>
      )

      const [errorsPlano, errorsDesfecho] = validateQuantidadeProcedimentos(
        qtdTotalProcedimentos,
        values,
        errors.plano as ErrorObject<PlanoFormModel>,
        errors.finalizacao as ErrorObject<FinalizacaoAtendimentoFormModel>
      )

      return { ...errors, objetivo: errorsObjetivo, plano: errorsPlano, finalizacao: errorsDesfecho }
    }
  )

const validateQuantidadeProcedimentos = (
  qtdTotalProceds: number,
  value: SoapState,
  errorsPlano: ErrorObject<PlanoFormModel>,
  errorsDesfecho: ErrorObject<FinalizacaoAtendimentoFormModel>
): [ErrorObject<PlanoFormModel>, ErrorObject<FinalizacaoAtendimentoFormModel>] => {
  const qtMaxProcedimentos = 20
  const msgErrorMaxProced = `O número máximo de procedimentos em um atendimento é ${qtMaxProcedimentos}.`

  const validateDesfecho = () =>
    !isEmpty(value?.finalizacao?.procedimentosAdministrativos)
      ? { ...errorsDesfecho, procedimentosAdministrativos: msgErrorMaxProced }
      : errorsDesfecho

  const validateIntervencoesProcedimentos = () =>
    !isEmpty(value?.plano?.intervencoesProcedimentos?.procedimentos)
      ? {
          ...(errorsPlano?.intervencoesProcedimentos as IntervencoesProcedimentosPlanoModel),
          procedimentos: msgErrorMaxProced,
        }
      : errorsPlano?.intervencoesProcedimentos

  const validateOdontologia = () =>
    value?.plano?.odontologia && !isEmpty(procedimentosUnicosOdontologia(value?.plano?.odontologia))
      ? { ...(errorsPlano?.odontologia as OdontologiaModel), limiteProcedimentos: msgErrorMaxProced }
      : errorsPlano?.odontologia

  if (qtdTotalProceds > qtMaxProcedimentos) {
    errorsDesfecho = validateDesfecho()
    errorsPlano = {
      ...errorsPlano,
      intervencoesProcedimentos: validateIntervencoesProcedimentos(),
      odontologia: validateOdontologia(),
    }
  }

  return [errorsPlano, errorsDesfecho]
}
