/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, PagedTable, VFlow } from 'bold-ui'
import { createRender } from 'components/table/render'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useEquipesVinculadasTableQuery } from 'graphql/hooks.generated'
import { EquipesConnectionInput, EquipesVinculadasTableQuery } from 'graphql/types.generated'
import { useState } from 'react'

import { EquipesVinculadasTableFilter } from './EquipesVinculadasTableFilter'

type EquipesVinculadasModel = EquipesVinculadasTableQuery['equipesVinculadas']['content'][0]

interface EquipesVinculadasTableProps {
  equipePrincipalId: ID
}

const renderNome = (row: EquipesVinculadasModel) => {
  return createRender(row, 'nome', (value) => value.capitalize())
}

const renderIne = (row: EquipesVinculadasModel) => {
  return createRender(row, 'ine', (value) => value)
}

const renderArea = (row: EquipesVinculadasModel) => {
  return createRender(row, 'area', (value) => value)
}

const renderTipoEquipe = (row: EquipesVinculadasModel) => {
  return createRender(row, 'tipoEquipe', (value) => value.sigla + ' - ' + value.nome.capitalize())
}

export function EquipesVinculadasTable(props: EquipesVinculadasTableProps) {
  const { equipePrincipalId } = props

  const [filter, setFilter] = useState<EquipesConnectionInput>({})

  const {
    data: { equipesVinculadas },
    loading,
  } = useEquipesVinculadasTableQuery({ variables: { equipePrincipalId, input: filter } })

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: equipesVinculadas,
  })

  return (
    <VFlow>
      <TableBox header={<EquipesVinculadasTableFilter initialFilter={filter} onChange={setFilter} />}>
        <PagedTable<EquipesVinculadasModel>
          {...tableProps}
          columns={[
            { name: 'nome', header: 'Nome', render: renderNome },
            { name: 'ine', header: 'INE', render: renderIne },
            { name: 'area', header: 'Área', render: renderArea },
            { name: 'tipoEquipe', header: 'Tipo de equipe', render: renderTipoEquipe },
          ]}
        />
      </TableBox>
      {equipesVinculadas?.content.isEmpty() && (
        <Heading style={styles.headingStyle} level={2}>
          Nenhum resultado encontrado para os filtros aplicados. Busque novamente.
        </Heading>
      )}
    </VFlow>
  )
}

const styles = {
  headingStyle: css`
    text-align: center;
  `,
}
