import { Grid, useStyles } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { FilterItem } from 'components/filteritem'
import { useFlags } from 'config/useFlagsContext'
import { isSameDay } from 'date-fns'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { CSSProperties, useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { listaAtendimentoStylesRecord } from 'view/atendimentos/list/listaAtendimentoStyles'

import { ListaAtendimentoFilterModel } from './model'

export interface ListaAtendimentoFilterTagsProps {
  filtros: ListaAtendimentoFilterModel
}

export function ListaAtendimentoFilterTags(props: ListaAtendimentoFilterTagsProps) {
  const { filtros } = props
  const { classes } = useStyles(createStyles)
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const { UPA_ENABLED } = useFlags()
  const { tipoEstabelecimento } = useSession()
  const isUpa = UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

  const startDate = filtros.periodo.startDate ? new Date(filtros.periodo.startDate) : null
  const endDate = filtros.periodo.endDate ? new Date(filtros.periodo.endDate) : null

  const statusAtendimentoText =
    filtros.statusAtendimento?.length > 0 &&
    filtros.statusAtendimento.map((item) => listaAtendimentoStylesRecord[item].descricao).join(', ')

  const statusAtendimento = filtros.statusAtendimento?.length > 0 && (
    <FilterItem label='Status do atendimento:' value={statusAtendimentoText} />
  )

  const isPeriodoFilled =
    !isUndefinedOrNull(filtros?.periodo?.startDate) && !isUndefinedOrNull(filtros?.periodo?.endDate)

  const isPeriodoStartAndEndDateSameDayAndAreToday = useMemo(
    () => isPeriodoFilled && isSameDay(startDate, endDate) && isSameDay(startDate, now),
    [endDate, isPeriodoFilled, now, startDate]
  )

  const startDateText = useMemo(() => (isSameDay(startDate, now) ? 'hoje' : dateAsDdMmYyyy(startDate)), [
    now,
    startDate,
  ])

  const endDateText = useMemo(() => (isSameDay(endDate, now) ? 'hoje' : dateAsDdMmYyyy(endDate)), [now, endDate])

  const periodoText = useMemo(() => {
    if (isUpa && filtros.isPeriodo24h) {
      return 'Últimas 24h'
    } else if (isPeriodoStartAndEndDateSameDayAndAreToday) {
      return 'Hoje'
    } else if (isPeriodoFilled) {
      return `${startDateText} até ${endDateText}`.capitalize()
    }
  }, [
    endDateText,
    filtros.isPeriodo24h,
    isPeriodoFilled,
    isPeriodoStartAndEndDateSameDayAndAreToday,
    isUpa,
    startDateText,
  ])

  const periodo = ((!filtros.isSomenteNaoFinalizados && isPeriodoFilled) || (isUpa && filtros.isPeriodo24h)) && (
    <FilterItem label='Período:' value={periodoText} style={classes.periodoColumn} />
  )

  const showSomenteNaoFinalizados = filtros.isSomenteNaoFinalizados && (
    <FilterItem
      value='Somente atendimentos não finalizados'
      style={classes.periodoColumn}
      textStyle={{ fontWeight: 'bold' }}
    />
  )

  const tipoServicoText =
    filtros.tiposServico?.length > 0 && filtros.tiposServico.map((item) => item.nome.capitalize()).join(', ')

  const tipoServico = filtros.tiposServico?.length > 0 && (
    <FilterItem label='Tipo de serviço:' value={tipoServicoText} />
  )

  const equipeText = filtros.equipes?.length > 0 && filtros.equipes.map((item) => item.nome).join(', ')

  const equipe = filtros.equipes?.length > 0 && <FilterItem label='Equipe:' value={equipeText} />

  const lotacoesText =
    filtros.responsaveis?.length > 0 && filtros.responsaveis.map((item) => item?.profissional.nome).join(', ')

  const lotacoes = filtros.responsaveis?.length > 0 && <FilterItem label='Profissionais:' value={lotacoesText} />

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {statusAtendimento || showSomenteNaoFinalizados || periodo || tipoServico || equipe || lotacoes ? (
        <>
          {statusAtendimento}
          {periodo}
          {showSomenteNaoFinalizados}
          {tipoServico}
          {equipe}
          {lotacoes}
        </>
      ) : (
        'Sem filtros ativos'
      )}
    </Grid>
  )
}

const createStyles = () => ({
  periodoColumn: {
    overflow: 'visible',
  } as CSSProperties,
})
