import { resolveValue } from 'components/form/final-form/hooks/useField'
import { FlagsContextModel } from 'config/useFlagsContext'
import { Calculation } from 'final-form-calculate'
import { TipoAtendimentoEnum, TipoAtendimentoProfissional, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { ProcedimentosAutomaticosAtendimentoIndividual } from 'types/enums'
import { MetaArray, MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { v4 as uuidv4 } from 'uuid'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'
import { calculateProcedimentoAutomaticoToAdd } from 'view/atendimentos/utils/procedimentoAutomaticoUtils'

import { FinalizacaoAtendimentoFormModel } from '../finalizacao'
import {
  findProcedimentosAutomaticosByCodigo,
  getProcedimentosWithNewProced,
} from '../finalizacao/calculator/calculatorIndividual'
import { ManterCidadaoLista } from '../finalizacao/components/DesfechoAtendimentoPanel'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'
import { MarcoFormSectionAccordionModel } from '../objetivo/puericultura/marcos/MarcoFormSectionAccordion'
import { AvaliacaoDesenvolvimentoModel, PuericulturaModel } from '../objetivo/puericultura/model'
import { ProcedimentoPlanoModel } from './components/ProcedimentoPlanoField'
import { isPreenchendoRPS } from './evolucoes-odontologicas/periograma/periograma-simplificado/PeriogramaSimplificadoField'
import { PlanoFormModel } from './PlanoForm'

export interface HasAccessProcedimentos {
  desenvolvimentoCrianca: boolean
  crescimentoCrianca: boolean
  glicemiaCapilar: boolean
}

export interface CreatePlanoCalculationsProps {
  plano: MetaPath<PlanoFormModel>
  formAtivoPath: MetaPath<FormAtivoObjetivoEnum>
  medicoes: MetaPath<MedicoesPanelModel>
  puericultura: MetaPath<PuericulturaModel>
  finalizacao: MetaPath<FinalizacaoAtendimentoFormModel>
  procedimentosAutomaticos: ProcedimentoAutomatico[]
  idadeCidadaoEmAnos: number
  hasAccessProcedimentos: HasAccessProcedimentos
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  tipoEstabelecimento: TipoEstabelecimentoEnum
  cbo2002: string
  isRegistroTardio: boolean
  flags: FlagsContextModel
}

export const createPlanoCalculations = (props: CreatePlanoCalculationsProps): Calculation[] => {
  const {
    plano,
    formAtivoPath,
    medicoes,
    puericultura,
    finalizacao,
    procedimentosAutomaticos,
    idadeCidadaoEmAnos,
    hasAccessProcedimentos,
    tipoAtendimentoProfissional,
    tipoEstabelecimento,
    cbo2002,
    isRegistroTardio,
    flags,
  } = props
  return [
    {
      field: [
        formAtivoPath.absolutePath(),
        medicoes.peso.absolutePath(),
        medicoes.altura.absolutePath(),
        medicoes.perimetroCefalico.absolutePath(),
      ],
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: MetaArray<ProcedimentoPlanoModel>,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const formAtivo = resolveValue(allValues, formAtivoPath)
          const procedToAdd =
            formAtivo === FormAtivoObjetivoEnum.PUERICULTURA && hasAccessProcedimentos.crescimentoCrianca
              ? procedToAddAtendimentoPuericultura(medicoes, allValues, idadeCidadaoEmAnos)
              : procedToAddAtendimentoIndividual(medicoes, allValues)

          let listaProcedimentos = resolveValue(allValues, plano.intervencoesProcedimentos.procedimentos) ?? []
          if (procedToAdd) {
            listaProcedimentos = addProcedimentoAutomatico(
              allValues,
              prevValues,
              plano.intervencoesProcedimentos.procedimentos,
              procedimentosAutomaticos,
              procedToAdd
            )
          }

          const procedsToRemove = [
            ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO,
            ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA,
            ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA,
            ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_CRESCIMENTO_PUERICULTURA,
          ].filter((proced) => proced !== procedToAdd)

          procedsToRemove.forEach((procedimento: ProcedimentosAutomaticosAtendimentoIndividual) => {
            listaProcedimentos = removeProcedimentoAutomatico(listaProcedimentos, procedimento)
          })

          return listaProcedimentos
        },
      },
    },
    {
      field: [medicoes.pressaoArterialSistolica.absolutePath(), medicoes.pressaoArterialDiastolica.absolutePath()],
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: MetaArray<ProcedimentoPlanoModel>,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const listaProcedimentos = addProcedimentoAutomatico(
            allValues,
            prevValues,
            plano.intervencoesProcedimentos.procedimentos,
            procedimentosAutomaticos,
            ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_PRESSAO_ARTERIAL
          )
          return resolveValue(allValues, medicoes.pressaoArterialSistolica) &&
            resolveValue(allValues, medicoes.pressaoArterialDiastolica)
            ? listaProcedimentos
            : removeProcedimentoAutomatico(
                listaProcedimentos,
                ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_PRESSAO_ARTERIAL
              )
        },
      },
    },
    {
      field: [puericultura.absolutePath()],
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: PuericulturaModel,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const procedToAdd =
            hasAccessProcedimentos.desenvolvimentoCrianca &&
            procedToAddDesenvolvimentoPuericultura(puericultura, allValues)

          let listaProcedimentos =
            resolveValue<ProcedimentoPlanoModel[]>(allValues, plano.intervencoesProcedimentos.procedimentos) || []
          if (procedToAdd) {
            listaProcedimentos = addProcedimentoAutomatico(
              allValues,
              prevValues,
              plano.intervencoesProcedimentos.procedimentos,
              procedimentosAutomaticos,
              procedToAdd
            )
          } else {
            listaProcedimentos = removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_DESENVOLVIMENTO_PUERICULTURA
            )
          }
          return listaProcedimentos
        },
      },
    },
    {
      field: medicoes.glicemia.absolutePath(),
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: MetaArray<ProcedimentoPlanoModel>,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const procedToAdd =
            hasAccessProcedimentos.glicemiaCapilar && resolveValue(allValues, medicoes.glicemia)
              ? ProcedimentosAutomaticosAtendimentoIndividual.GLICEMIA_CAPILAR
              : null

          let listaProcedimentos =
            resolveValue(allValues, plano.intervencoesProcedimentos.procedimentos) ?? ([] as ProcedimentoPlanoModel[])
          if (procedToAdd) {
            listaProcedimentos = addProcedimentoAutomatico(
              allValues,
              prevValues,
              plano.intervencoesProcedimentos.procedimentos,
              procedimentosAutomaticos,
              procedToAdd
            )
          } else {
            listaProcedimentos = removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.GLICEMIA_CAPILAR
            )
          }
          return listaProcedimentos
        },
      },
    },
    {
      field: medicoes.temperatura.absolutePath(),
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: MetaArray<ProcedimentoPlanoModel>,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const listaProcedimentos = addProcedimentoAutomatico(
            allValues,
            prevValues,
            plano.intervencoesProcedimentos.procedimentos,
            procedimentosAutomaticos,
            ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_DE_TEMPERATURA
          )
          return resolveValue(allValues, medicoes.temperatura)
            ? listaProcedimentos
            : removeProcedimentoAutomatico(
                listaProcedimentos,
                ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_DE_TEMPERATURA
              )
        },
      },
    },
    {
      field: plano.odontologia.periodontia.periogramaCompleto.dados.absolutePath(),
      updates: {
        [plano.odontologia.periodontia.periogramaCompleto.observacao.absolutePath()]: (
          values,
          allValues: SoapState
        ) => {
          if (!isObjectDeepEmpty(values))
            return allValues?.plano?.odontologia?.periodontia?.periogramaCompleto?.observacao
        },
      },
    },
    {
      field: [
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante1.absolutePath(),
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante2.absolutePath(),
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante3.absolutePath(),
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante4.absolutePath(),
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante5.absolutePath(),
        plano.odontologia.periodontia.periogramaSimplificado.avaliacaoSextante6.absolutePath(),
      ],
      updates: {
        [plano.odontologia.periodontia.periogramaSimplificado.observacao.absolutePath()]: (
          _: string,
          allValues: SoapState
        ) => {
          const {
            avaliacaoSextante1,
            avaliacaoSextante2,
            avaliacaoSextante3,
            avaliacaoSextante4,
            avaliacaoSextante5,
            avaliacaoSextante6,
          } = resolveValue(allValues, plano.odontologia.periodontia.periogramaSimplificado)

          if (
            isPreenchendoRPS([
              avaliacaoSextante1,
              avaliacaoSextante2,
              avaliacaoSextante3,
              avaliacaoSextante4,
              avaliacaoSextante5,
              avaliacaoSextante6,
            ])
          ) {
            return allValues.plano.odontologia.periodontia.periogramaSimplificado.observacao
          }
        },
      },
    },
    {
      field: plano.startObservacao.absolutePath(),
      updates: {
        [finalizacao.procedimentosAdministrativos.absolutePath()]: (_, allValues: SoapState) => {
          const isUpa = flags.UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

          if (isUpa) {
            const procedimentoAutomatico = calculateProcedimentoAutomaticoToAdd({
              tipoAtendimentoProfissional,
              tipoEstabelecimento,
              tipoAtendimento: allValues.finalizacao.tipoAtendimento,
              cbo2002,
              isProfResponsavelObservacao: allValues.plano.startObservacao,
              flags,
            })

            return getProcedimentosWithNewProced(
              allValues.finalizacao.procedimentosAdministrativos,
              findProcedimentosAutomaticosByCodigo(procedimentosAutomaticos, [procedimentoAutomatico])
            )
          }
          return allValues.finalizacao.procedimentosAdministrativos
        },
        [finalizacao.tipoAtendimento.absolutePath()]: (startObservacao: boolean, _, prevValues: SoapState) => {
          const isUpa = flags.UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
          if (startObservacao && isUpa) {
            return TipoAtendimentoEnum.URGENCIA
          } else {
            return prevValues?.finalizacao?.tipoAtendimento
          }
        },
        [finalizacao.desfechoAtendimento.manterCidadaoLista.absolutePath()]: (
          startObservacao: boolean,
          _,
          prevValue: SoapState
        ) => {
          if (startObservacao && !isRegistroTardio) {
            return ManterCidadaoLista.SIM
          }
          return prevValue?.finalizacao?.desfechoAtendimento?.manterCidadaoLista
        },
      },
    },
  ]
}

const procedToAddAtendimentoIndividual = (medicoes: MetaPath<MedicoesPanelModel>, allValues: SoapState) => {
  const pesoPreenchido = resolveValue(allValues, medicoes.peso)
  const alturaPreenchida = resolveValue(allValues, medicoes.altura)

  return pesoPreenchido && alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA
    : pesoPreenchido
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO
    : alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA
    : null
}

const procedToAddAtendimentoPuericultura = (
  medicoes: MetaPath<MedicoesPanelModel>,
  allValues: SoapState,
  idadeCidadao: number
) => {
  const pesoPreenchido = resolveValue(allValues, medicoes.peso)
  const alturaPreenchida = resolveValue(allValues, medicoes.altura)
  const perimetroCefPreenchido = resolveValue(allValues, medicoes.perimetroCefalico)

  return pesoPreenchido && !alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO
    : !pesoPreenchido && alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA
    : pesoPreenchido && alturaPreenchida
    ? idadeCidadao >= 2 || (idadeCidadao < 2 && perimetroCefPreenchido)
      ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_CRESCIMENTO_PUERICULTURA
      : ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA
    : null
}

const procedToAddDesenvolvimentoPuericultura = (puericultura: MetaPath<PuericulturaModel>, allValues: SoapState) => {
  const alteracoesFenotipicas = resolveValue<AvaliacaoDesenvolvimentoModel[]>(
    allValues,
    puericultura.alteracoesFenotipicas?.alteracoesFenotipicas
  )
  const fatoresRisco = resolveValue<AvaliacaoDesenvolvimentoModel[]>(allValues, puericultura.fatoresRisco?.fatoresRisco)

  const hasChangedMarcos = (): boolean => {
    const marcosDesenvolvimento = resolveValue<MarcoFormSectionAccordionModel>(
      allValues,
      puericultura.marcosDesenvolvimento
    )
    return (
      marcosDesenvolvimento &&
      Object.values(marcosDesenvolvimento.marcosRecord)?.some((marcoGrupo) => hasChanged(marcoGrupo))
    )
  }

  const hasChanged = (avaliacao: AvaliacaoDesenvolvimentoModel[]) =>
    avaliacao?.some((item) => item.statusButton !== item.status)

  return hasChanged(alteracoesFenotipicas) || hasChanged(fatoresRisco) || hasChangedMarcos()
    ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_DESENVOLVIMENTO_PUERICULTURA
    : null
}

const addProcedimentoAutomatico = (
  allValues: SoapState,
  prevValues: SoapState,
  existedProcedimentos: MetaArray<ProcedimentoPlanoModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  procedimentoAutomaticoEnum: ProcedimentosAutomaticosAtendimentoIndividual
) => {
  const procedimento: ProcedimentoPlanoModel = {
    _id: uuidv4(),
    procedimento: procedimentosAutomaticos.find((p) => p.codigo === procedimentoAutomaticoEnum),
    automatico: true,
  }
  const prevValuesNotEmpty = resolveValue(prevValues, existedProcedimentos)
  const values = resolveValue<ProcedimentoPlanoModel[]>(allValues, existedProcedimentos) ?? []

  if (Array.isArray(values) && procedimento.procedimento) {
    if (!values.find((p) => p.procedimento.id === procedimento.procedimento.id) && prevValuesNotEmpty) {
      values.push(procedimento)
    }
  }

  return values
}

const removeProcedimentoAutomatico = (
  listaProcedimentos: ProcedimentoPlanoModel | ProcedimentoPlanoModel[],
  procedimentoAutomaticoEnum: ProcedimentosAutomaticosAtendimentoIndividual
) => {
  if (Array.isArray(listaProcedimentos)) {
    const newListaProcedimentos = listaProcedimentos.filter((p) =>
      p.automatico ? p.procedimento.codigo !== procedimentoAutomaticoEnum : p
    )

    return newListaProcedimentos
  }
}
