import { AcessoCbo, Estagio, Lotacao } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

export const isPermission = (permission): permission is Permission => permission.uri

export const isPermissionArray = (permission): permission is Permission[] => permission[0]?.uri

export const isCboPermission = (permission): permission is AcessoCbo[] => !!AcessoCbo[permission[0]]

export const isLotacao = (acesso): acesso is Lotacao => acesso.__typename === 'Lotacao'

export const isEstagio = (acesso): acesso is Estagio => acesso.__typename === 'Estagio'

export const isLotacaoOrEstagio = (acesso): acesso is Estagio | Lotacao =>
  acesso.__typename === 'Estagio' || acesso.__typename === 'Lotacao'
