import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import { UnificacaoBaseView } from './UnificacaoBaseView'

export default function UnificacaoBaseRootView() {
  const { match } = useRouter()
  return (
    <>
      <Breadcrumb title='Unificação de bases' />
      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={UnificacaoBaseView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeBases}
        />
      </PecSwitch>
    </>
  )
}
