import { conditionalRequired, createValidator, maxLength, required } from 'util/validation'

import { ListaEsperaEdicaoModel, ListaEsperaFormModel } from './model'

export const listaEsperaValidator = (isCEO: boolean) =>
  createValidator<ListaEsperaFormModel>({
    cidadao: [required],
    motivo: [required, maxLength(200)],
    tipoServico: [conditionalRequired(isCEO)],
    tipoAtendimento: [conditionalRequired(!isCEO)],
  })

export const editarCidadaoListaEsperaValidator = (isCEO: boolean) =>
  createValidator<ListaEsperaEdicaoModel>({
    motivo: [required, maxLength(200)],
    tipoServico: [conditionalRequired(isCEO)],
    tipoAtendimento: [conditionalRequired(!isCEO)],
  })
