import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const {
    ENVIO_RNDS_ENABLED,
    UPA_ENABLED,
    VISUALIZACAO_TERRITORIO_ENABLED,
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED,
  } = useFlags()
  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Facilidades no agendamento de consultas de pré-natal</Text>
        <Text component='li'>Gestão da garantia do acesso</Text>
        <Text component='li'>Prescrição digital</Text>
        <Text component='li'>Teleinterconsulta</Text>
        <Text component='li'>Vínculo entre equipes do PEC</Text>
        {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && <Text component='li'>Cuidado Compartilhado</Text>}
        {ENVIO_RNDS_ENABLED && <Text component='li'>RNDS - Envio de vacinas e atendimentos individuais</Text>}
        {UPA_ENABLED && <Text component='li'>E-sus APS Ampliado</Text>}
        {VISUALIZACAO_TERRITORIO_ENABLED && <Text component='li'>Acompanhamento do Território</Text>}
      </VFlow>
    </HFlow>
  )
}
