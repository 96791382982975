import createDecorator from 'final-form-calculate'
import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { MetaPath, MetaRoot } from 'util/metaPath'

import { ListaEsperaRemocaoModel } from '../model'

export const createRemocaoCalculations = (
  name: MetaPath<ListaEsperaRemocaoModel> | MetaRoot<ListaEsperaRemocaoModel>
) =>
  createDecorator({
    field: name.motivoSaida.absolutePath(),
    updates: {
      [name.tentativasContato.absolutePath()]: (
        value: ListaEsperaMotivoSaidaEnum,
        allValues: ListaEsperaRemocaoModel
      ) => {
        if (value === ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO) return allValues.tentativasContato
      },
    },
  })
