/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import {
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  RadioGroupField,
  TextAreaField,
} from 'components/form'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR, FormApi } from 'final-form'
import { useSalvarAgendamentoReservaMutation } from 'graphql/hooks.generated'
import { MotivoAgendamentoReservaEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { convertReservaModelToInput } from '../converter'
import { AgendamentoReservaModel, LotacaoAgendaModel, motivoReservaRecord } from '../model'
import { agendamentoReservaValidator } from './validator'

export const meta = metaPath<AgendamentoReservaModel>()

export interface AgendamentoReservaFormProps {
  lotacao: LotacaoAgendaModel

  onSubmit?(): void

  onClose(): void
  getServerTimeNow(): Date
  start?: number
  end?: number

  isAtencaoDomiciliar?: boolean
}

export const AgendamentoReservaForm = (props: AgendamentoReservaFormProps) => {
  const { lotacao, onSubmit, onClose, getServerTimeNow, start, end, isAtencaoDomiciliar: isAD = false } = props
  const alert = useAlert()
  const serverTime = getServerTimeNow()

  const [salvar] = useSalvarAgendamentoReservaMutation()

  const handleCancelar = () => onClose()

  const handleFormSubmit = (values: AgendamentoReservaModel, formApi: FormApi) =>
    salvar({ variables: { input: convertReservaModelToInput(values, lotacao.id) } }).then(() => {
      alert('success', 'Agendamento de reserva cadastrado com sucesso.')
      onSubmit?.()
      onClose()
    })

  const initialValues = useMemo(
    () => ({
      horario: { inicial: new Date(start), final: new Date(end) },
      data: start ? formatISO(start) : undefined,
    }),
    [end, start]
  )

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoReservaModel>>) => {
    const { handleSubmit, values } = formProps

    return (
      <React.Fragment>
        <div
          css={css`
            min-height: 23rem;
            margin-bottom: 5px;
          `}
        >
          <Grid>
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            </Cell>
            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 12)}
                clearable
                required
              />
            </Cell>
            <Cell size={9} />

            <Cell size={6}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacao.id}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                verificarReservasAgendaOnline={false}
                atencaoDomiciliar={isAD}
                showHorarioFinal
                clearable
                required
              />
              <ErrorField name='horarioInicial' />
              <ErrorField name='horarioFinal' />
            </Cell>
            <Cell size={6} />

            <Cell flexShrink={0}>
              <RadioGroupField
                required
                name={meta.motivoReserva}
                label='Motivo'
                lines={2}
                options={[
                  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.REUNIAO),
                  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.ATENDIMENTO_EXTERNO),
                  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.ATIVIDADE_COLETIVA),
                  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.OUTRO),
                ]}
              />
            </Cell>
            <Cell flexGrow={1}>
              {values.motivoReserva === MotivoAgendamentoReservaEnum.OUTRO && (
                <TextAreaField
                  label='Especifique o motivo'
                  name={meta.especificacaoMotivoReserva}
                  maxLength={100}
                  style={css`
                    resize: none;
                  `}
                />
              )}
            </Cell>
            <Cell size={12}>
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} />
            </Cell>
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleCancelar} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium'>
            Salvar
          </Button>
        </HFlow>
      </React.Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoReservaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      suppressNotificationError //Previne mostrar alerta de validação fora do popup
      validate={agendamentoReservaValidator(getServerTimeNow())}
    />
  )
}

const motivoReservaToRadioOption = (motivo: MotivoAgendamentoReservaEnum) => ({
  value: motivo,
  label: motivoReservaRecord[motivo].nome,
})
