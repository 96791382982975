/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Cid10 } from 'graphql/types.generated'
import { porExtenso } from 'numero-por-extenso'
import { conformToMask } from 'react-text-mask'
import { formatDate } from 'util/date/formatDate'
import { masks } from 'util/mask'

import { CID10_DESCRICAO_ATESTADO } from '../atestadoUtils'
import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'

interface AtestadoPadraoTextBoxProps {
  cidadao: CidadaoAtestado
  values: AtestadoModel
  nomeUnidadeSaude: string
}

export default function AtestadoPadraoTextBox(props: AtestadoPadraoTextBoxProps) {
  return (
    <Box>
      <VFlow
        vSpacing={0.5}
        style={css`
          justify-content: center;
          align-items: center;
          margin: 1rem 6rem 2rem;
        `}
      >
        <Heading level={3}>ATESTADO</Heading>
        <Text>{getAtestadoPadraoText(props)}</Text>
      </VFlow>
    </Box>
  )
}

const renderCpfCnsSubString = (cidadao: CidadaoAtestado, cpfCns: string, naoPossui: boolean) => {
  const getCpfMasked = (cpf: string) => conformToMask(cpf, masks.cpf, { guide: false }).conformedValue

  if (cidadao.cpf) {
    return `CPF ${getCpfMasked(cidadao.cpf)}, `
  }

  if (cidadao.cns) {
    return `CNS ${cidadao.cns}, `
  }

  if (naoPossui) {
    return ''
  }

  if (!cpfCns || (cpfCns.length !== 11 && cpfCns.length !== 15)) {
    return '[CNS/CPF], '
  }

  if (cpfCns.length === 11) {
    return `CPF ${getCpfMasked(cpfCns)}, `
  }

  if (cpfCns.length === 15) {
    return `CNS ${cpfCns}, `
  }
}

const renderData = (data: Instant) => {
  return (data && formatDate(data)) || '[DATA]'
}

const renderHora = (hora: string) => {
  return hora || '[HORA]'
}

const renderCid10 = (cid10: Cid10) => (cid10 ? CID10_DESCRICAO_ATESTADO + cid10.codigo : '')

const renderDias = (dias: number) => {
  const diasLabel = dias?.toString() === '1' ? 'dia' : 'dias'
  const diasPorExtenso = dias && `${dias} (${porExtenso(dias)}) ${diasLabel}`
  return diasPorExtenso || '[DIAS]'
}

export const getAtestadoPadraoText = (props: AtestadoPadraoTextBoxProps) => {
  const { cidadao, nomeUnidadeSaude, values } = props
  const nomeCidadao = !cidadao.nomeSocial ? cidadao.nome : `${cidadao.nomeSocial} - (${cidadao.nome})`

  return `Atesto, para os devidos fins, que ${nomeCidadao.titleCase()}, ${renderCpfCnsSubString(
    cidadao,
    values.cpfCns,
    values.naoPossui
  )}recebeu atendimento na ${nomeUnidadeSaude} no dia ${renderData(values.data)} às ${renderHora(
    values.hora
  )}${renderCid10(
    values.cid10
  )}. Em decorrência, deverá permanecer em afastamento de suas atividades laborativas por um período de ${renderDias(
    values.dias
  )} a partir desta data.`
}
