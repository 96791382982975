/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Tag,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { Cep, Telefone } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import { useVisualizarCidadaosImovelQuery } from 'graphql/hooks.generated'
import { StatusFichaEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useMemo, useState } from 'react'
import { humanizeDate } from 'util/date/humanize-date'
import {
  domicilioTipoRecord,
  EditarAtualizarCidadaoCallback,
  EditarAtualizarImovelCallback,
  localizacaoTipoRecord,
  TipoDomicilioEnum,
} from 'view/visualizacaoTerritorial/model'
import { calculateInconsistencias } from 'view/visualizacaoTerritorial/utils/calculateInconsistencias'
import { getTipoImovelIcon } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { FamiliasImovelList } from '../list/FamiliasImovelList'
import { InconsistenciasImovelList } from '../list/InconsistenciasImovelList'

interface ModalImovelProps {
  imovelId: ID
  numeroCidadaosConsistentes: number
  isFichaNaoProcessadaAplicativo: boolean
  isModalOpen: boolean
  onCloseModal(): void
  onEditarAtualizarImovel: EditarAtualizarImovelCallback
  onEditarAtualizarCidadao: EditarAtualizarCidadaoCallback
}

export function ModalImovel(props: ModalImovelProps) {
  const {
    imovelId,
    numeroCidadaosConsistentes,
    isModalOpen,
    isFichaNaoProcessadaAplicativo,
    onCloseModal,
    onEditarAtualizarImovel,
    onEditarAtualizarCidadao,
  } = props

  const [renderInconsistencias, setRenderInconsistencias] = useState(false)
  const { getServerTimeNow } = useServerTime()

  const { data, loading } = useVisualizarCidadaosImovelQuery({
    variables: {
      input: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const {
    id,
    familias,
    tipoCdsImovelId,
    uuidDomicilio,
    bairro,
    cep,
    complemento,
    dataUltimaVisita,
    logradouro,
    tipoLogradouro,
    numero,
    telefoneContato,
    telefoneResidencial,
    ultimaFichaProcessada,
    ultimaFichaCadastrada,
    dataUltimaAtualizacao,
  } = data?.imovel || {}

  const tipoDomicilioId = !isFichaNaoProcessadaAplicativo
    ? ultimaFichaProcessada?.tipoDomicilioId
    : TipoDomicilioEnum.OUTRO

  const tagDomicilio = domicilioTipoRecord[tipoDomicilioId]
  const tagLocalizacao = localizacaoTipoRecord[ultimaFichaProcessada?.tipoLocalizacaoId]
  const { IconImovel, fill } = getTipoImovelIcon(tipoCdsImovelId, ultimaFichaProcessada?.tipoDomicilioId)

  const styles = createStyles(fill)

  const familiasResidentes = (familias || []).filter(
    (familia) => familia.isDomicilioAtivo && familia.isInformacaoSuficiente && familia.isFamiliaAindaReside
  )
  const inconsistencias = useMemo(() => calculateInconsistencias(familiasResidentes), [familiasResidentes])
  const isUltimaFichaCadastradaProcessada = ultimaFichaCadastrada?.isProcessado === StatusFichaEnum.ENVIADA

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Modal
      style={css`
        width: 40rem;
      `}
      closeOnBackdropClick
      onClose={onCloseModal}
      open={isModalOpen}
    >
      <ModalBody>
        <HFlow alignItems='center' hSpacing={0.5}>
          {IconImovel && <IconImovel css={styles.icon} />}
          <VFlow vSpacing={0}>
            <Heading level={5}>Domicílio</Heading>
            <Heading level={1}>
              {`${tipoLogradouro.nome} ${logradouro},`.titleCase()} nº {numero ?? 'S/N'}
              {complemento ? ` | ${complemento.titleCase()}` : ''}
            </Heading>
          </VFlow>
        </HFlow>
        <HFlow
          hSpacing={0.2}
          style={css`
            margin-left: 3rem;
            margin-top: 0.5rem;
          `}
        >
          {ultimaFichaProcessada?.tipoDomicilioId && <Tag style={styles.tag}>{tagDomicilio}</Tag>}
          {ultimaFichaProcessada?.tipoLocalizacaoId && <Tag style={styles.tag}>{tagLocalizacao}</Tag>}
          <Tag style={styles.tag}>{`Nº de moradores: ${numeroCidadaosConsistentes}`}</Tag>
          <Tooltip text='Tempo desde a última visita mais recente realizada para o imóvel em si, a familia ou qualquer um dos moradores.'>
            <Tag style={styles.tag}>
              {`Última visita: ${dataUltimaVisita ? humanizeDate(dataUltimaVisita) : 'desconhecida'}`}
            </Tag>
          </Tooltip>
        </HFlow>

        <VFlow
          style={css`
            margin-top: 1rem;
          `}
        >
          <Grid>
            <Cell size={3}>
              <InfoLabel title='CEP'>
                <Cep value={cep} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Bairro'>{bairro?.titleCase()}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Telefone'>
                <Telefone value={telefoneResidencial ?? telefoneContato} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Última atualização'>
                {dataUltimaAtualizacao ? humanizeDate(dataUltimaAtualizacao) : 'desconhecida'}
              </InfoLabel>
            </Cell>
            {!isUltimaFichaCadastradaProcessada && (
              <Cell size={9}>
                <Alert type='info'> Existem fichas ainda não processadas para este imóvel</Alert>
              </Cell>
            )}
            {inconsistencias.size > 0 && (
              <Cell size={12}>
                <Button kind='normal' size='small' onClick={() => setRenderInconsistencias(!renderInconsistencias)}>
                  {!renderInconsistencias ? 'Ver inconsistências do imóvel' : '< Retornar aos cidadãos do imóvel'}
                </Button>
              </Cell>
            )}
            <Cell size={3} />
            {familiasResidentes.length > 0 ? (
              !renderInconsistencias ? (
                <Cell size={12}>
                  <FamiliasImovelList
                    imovelId={imovelId}
                    familias={familiasResidentes}
                    onEditarAtualizarCidadaos={onEditarAtualizarCidadao}
                    getServerTimeNow={getServerTimeNow}
                  />
                </Cell>
              ) : (
                <Cell size={12}>
                  <InconsistenciasImovelList inconsistencias={inconsistencias} />
                </Cell>
              )
            ) : (
              <Cell size={12}>
                <Text
                  style={css`
                    justify-content: center;
                    display: flex;
                  `}
                  fontStyle='italic'
                  fontWeight='normal'
                  variant='h4'
                >
                  Sem registro de família cadastrada
                </Text>
              </Cell>
            )}
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Tooltip
            text={
              isFichaNaoProcessadaAplicativo
                ? 'Ação bloqueada pois existe uma ficha ainda não processada vinda do aplicativo e-SUS Território.'
                : ''
            }
            placement='bottom-end'
          >
            <Button
              kind='normal'
              disabled={isFichaNaoProcessadaAplicativo}
              onClick={() =>
                onEditarAtualizarImovel(isUltimaFichaCadastradaProcessada, uuidDomicilio, ultimaFichaCadastrada?.id, id)
              }
            >
              {isUltimaFichaCadastradaProcessada ? 'Atualizar imóvel' : 'Editar imóvel'}
            </Button>
          </Tooltip>
          <Button title='Fechar' kind='primary' onClick={onCloseModal}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const createStyles = (iconFill: string) => ({
  tag: css`
    margin-right: 0.25rem;
  `,
  icon: css`
    fill: ${iconFill};
    width: 2.5rem;
    height: 2.5rem;
  `,
})
