import {
  Alert,
  Button,
  Cell,
  FormControl,
  Grid,
  HeadingSection,
  HFlow,
  Icon,
  Text,
  TextInput,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import useFirebase from 'components/firebase/useFirebase'
import { CheckboxField, Form, NumberField, TextField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { useAlterarServidorSmtpMutation } from 'graphql/hooks.generated'
import { useDesabilitarConexaoSmtpMutation } from 'graphql/hooks.generated'
import { AlterarServidorSmtpMutation } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, email, maxLength, minLength, required } from 'util/validation'

export interface ConfiguracoesServidorSMTPBoxProps {
  initialValues: ConfiguracaoSMTPModel
  additionalInfo: ConfiguracaoSMTPInfo
  internetConnection: boolean
  refetch(): void
}

const validator = createValidator<ConfiguracaoSMTPModel>(
  {
    email: [email, maxLength(255)],
    porta: [required, maxLength(5)],
    endereco: [required, maxLength(255)],
    senha: [required, maxLength(255)],
    usuario: [required, minLength(6), maxLength(255)],
  },
  (value, errors) => {
    if (value.emailRemetente) {
      if (!errors.usuario) {
        errors.usuario = email(value.usuario)
      }
    } else {
      if (!errors.email) {
        errors.email = required(value.email)
      }
    }
    return errors
  }
)

export interface ConfiguracaoSMTPModel {
  email?: string
  porta?: string
  endereco?: string
  senha?: string
  usuario: string
  emailRemetente?: boolean
}

interface ConfiguracaoSMTPInfo {
  ativo?: boolean
  dataRegistro?: number
}

export const ConfiguracoesServidorSMTPBox = (props: ConfiguracoesServidorSMTPBoxProps) => {
  const [alterarServidorSMTP] = useAlterarServidorSmtpMutation()
  const [desabilitarConexaoSMTP] = useDesabilitarConexaoSmtpMutation()
  const alert = useAlert()
  const { analytics } = useFirebase()
  const ativo = props.additionalInfo && props.additionalInfo.ativo
  const dataRegistro = props.additionalInfo && props.additionalInfo.dataRegistro

  const handleSubmit = (formValues) => {
    const input: AlterarServidorSmtpMutation['alterarServidorSMTP'] = {
      endereco: formValues.endereco,
      porta: formValues.porta,
      usuario: formValues.usuario,
      senha: formValues.senha,
      email: formValues.email,
    }

    if (formValues.emailRemetente) {
      input.email = formValues.usuario
    }

    return alterarServidorSMTP({ variables: { input } }).then(() => {
      analytics.logEvent('habilita_smtp')
      alert('success', 'Conexão com o servidor SMTP foi habilitada com sucesso.')
      props.refetch()
    })
  }

  const handleDesabilitar = () => {
    return confirm({
      title: `Deseja desabilitar a configuração do servidor SMTP?`,
      body: `Algumas funcionalidades como a recuperação de senha via e-mail e prescrição digital ficarão indisponíveis.`,
      confirmLabel: 'Desabilitar',
      onConfirm: () =>
        desabilitarConexaoSMTP().then(() => {
          analytics.logEvent('desabilita_smtp')
          alert('success', 'Conexão com o servidor SMTP foi desabilitada com sucesso.')
          props.refetch()
        }),
    })()
  }

  const renderForm = (formProps: FormRenderProps<ConfiguracaoSMTPModel>) => {
    const { internetConnection } = props

    if (!internetConnection) {
      return (
        <Alert type='info'>
          Conexão com o servidor SMTP não pode ser habilitada em instalação sem conexão com a internet.
        </Alert>
      )
    }

    return (
      <Grid wrap gap={1}>
        <Cell size={3}>
          <TextField name='endereco' label='Endereço do servidor do e-mail (SMTP)' clearable={false} required />
        </Cell>
        <Cell size={2}>
          <NumberField name='porta' label='Porta' clearable={false} required />
        </Cell>
        <Cell size={5} />
        <Cell size={4}>
          <TextField name='usuario' label='Login' clearable={false} required />
        </Cell>
        <Cell size={4}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField name='emailRemetente' label='Usar como e-mail remetente' required />
          </FormControl>
        </Cell>
        <Cell size={3} />
        <Cell size={3}>
          <TextField name='senha' label='Senha' type='password' clearable={false} required />
        </Cell>
        {formProps.values.emailRemetente ? (
          <Cell size={4}>
            <FormControl label='E-mail remetente'>
              <TextInput value={formProps.values.usuario || ''} disabled />
            </FormControl>
          </Cell>
        ) : (
          <Cell size={4}>
            <TextField name='email' label='E-mail remetente' clearable={false} required />
          </Cell>
        )}
        <Cell size={8}>
          <HFlow alignItems='center'>
            <Tooltip text={!ativo && 'Servidor SMTP não habilitado.'}>
              <Button size='small' onClick={handleDesabilitar} disabled={!ativo}>
                Desabilitar
              </Button>
            </Tooltip>
            <Button size='small' kind='primary' onClick={formProps.handleSubmit}>
              Habilitar
            </Button>
            {ativo && (
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='checkDefault' fill='success' />
                <Text color='success'>Conexão bem sucedida.</Text>
                {dataRegistro && (
                  <Text>
                    Último teste em <DateTime value={dataRegistro} />.
                  </Text>
                )}
              </HFlow>
            )}
            {!ativo && (
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='banFilled' fill='danger' size={1} />
                <Text color='danger'>Conexão mal sucedida.</Text>
                {dataRegistro && (
                  <Text>
                    Último teste em <DateTime value={dataRegistro} />.
                  </Text>
                )}
              </HFlow>
            )}
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <Box>
      <HeadingSection title='Servidor SMTP' level={3} />
      <VFlow>
        <Form<ConfiguracaoSMTPModel>
          onSubmit={handleSubmit}
          render={renderForm}
          initialValues={props.initialValues}
          validate={validator}
        />
      </VFlow>
    </Box>
  )
}
