import { HeadingSection } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { useSalvarProfissionalMutation } from 'graphql/hooks.generated'
import qs from 'qs'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { ProfissionalDadosForm } from 'view/profissional/components/ProfissionalDadosForm'

import ProfissionalFormModel from './components/ProfissionalFormModel'
import convertModelToInput from './convertModelToInput'

export interface ProfissionalCreateViewUrlParams {
  callbackLink: string
}

export function ProfissionalCreateView() {
  const history = useHistory()
  const location = useLocation()
  const { analytics } = useFirebase()
  const alert = useAlert()
  const [save] = useSalvarProfissionalMutation()

  const handleSubmit = (values: ProfissionalFormModel) =>
    save({ variables: { input: convertModelToInput(values) } }).then((ret) => {
      setTimeout(() => {
        analytics.logEvent('add_profissional')
        alert('success', 'Profissional foi salvo com sucesso.')
        const gets = qs.parse(location.search, {
          ignoreQueryPrefix: true,
          parameterLimit: 1,
        }) as ProfissionalCreateViewUrlParams
        if (gets && gets.callbackLink) {
          const data = ret && ret.data
          const url = new URL(decodeURIComponent(gets.callbackLink), document.baseURI)
          url.searchParams.append('pId', data?.salvarProfissional?.id)
          url.searchParams.append('pNome', data?.salvarProfissional?.nome)
          history.push(`${url.pathname}${url.search}`)
          return ret
        }
        history.push('/profissional')
      })
      return ret
    })

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro' />
      <HeadingSection level={1} title='Cadastro de profissional'>
        <ProfissionalDadosForm onSubmit={handleSubmit} />
      </HeadingSection>
    </PageContent>
  )
}
