import { useCallback, useEffect, useState } from 'react'

import { ConfiguracoesVideochamadaModel } from '../model'
import { useStoredAudioDevice, useStoredVideoDevice } from './useConfiguracoesVideochamada'

interface UseMediaDevicesConfigurationProps {
  mediaDevices: ConfiguracoesVideochamadaModel
  audioEnabled: boolean
  videoEnabled: boolean
}

export function useMediaDevicesConfiguration({
  mediaDevices,
  audioEnabled,
  videoEnabled,
}: UseMediaDevicesConfigurationProps) {
  const [mediaDevicesConfiguration, setMediaDevicesConfiguration] = useState<ConfiguracoesVideochamadaModel>()
  const [storedAudioDevice, setStoredAudioDevice] = useStoredAudioDevice()
  const [storedVideoDevice, setStoredVideoDevice] = useStoredVideoDevice()

  const getMediaDevicesConfiguration = useCallback(
    ({ mediaDevices, audioEnabled, videoEnabled }: UseMediaDevicesConfigurationProps) => {
      setMediaDevicesConfiguration({
        audioInput: audioEnabled ? mediaDevices?.audioInput : storedAudioDevice,
        audioOutput: mediaDevices?.audioOutput,
        videoInput: videoEnabled ? mediaDevices?.videoInput : storedVideoDevice,
      })
    },
    [storedAudioDevice, storedVideoDevice]
  )

  useEffect(() => {
    if (!!mediaDevices?.audioInput?.id) setStoredAudioDevice(mediaDevices.audioInput)
    if (!!mediaDevices?.videoInput?.id) setStoredVideoDevice(mediaDevices.videoInput)

    getMediaDevicesConfiguration({ mediaDevices, audioEnabled, videoEnabled })
  }, [
    mediaDevices,
    audioEnabled,
    videoEnabled,
    setStoredAudioDevice,
    setStoredVideoDevice,
    getMediaDevicesConfiguration,
  ])

  return { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice }
}
