import { AcessoCbo } from 'graphql/types.generated'
import { isCboMedico } from 'util/atendimento/cboHelper'
import { isUndefinedOrNull } from 'util/checks'

import GruposCbo from './GruposCbo'

export const NIVEL_SUPERIOR: AcessoCbo[] = [
  AcessoCbo.MEDICO,
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.FARMACEUTICO,
]

export const NIVEL_TECNICO: AcessoCbo[] = [AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM, AcessoCbo.TSB]

const NIVEL_SUPERIOR_AS_STRING = NIVEL_SUPERIOR.map((item) => item.toString())
export const isCboNivelSuperior = (cbo: string) => {
  const grupoCbo = GruposCbo.find((item) => grupoCboIncludesCbo(item.cbos, cbo))
  return NIVEL_SUPERIOR_AS_STRING.includes(grupoCbo?.grupo)
}

const GRUPO_CBO_DENTISTA = GruposCbo.find((item) => item.grupo === AcessoCbo.CIRURGIAO_DENTISTA)
export const isCboDentista = (cbo: string) => {
  return grupoCboIncludesCbo(GRUPO_CBO_DENTISTA.cbos, cbo)
}

const CBO_DENTISTA_CLINICO_GERAL = '223208'
export const isCboDentistaClinicoGeral = (cbo: string) => cbo === CBO_DENTISTA_CLINICO_GERAL

export const grupoCboIncludesCbo = (grupoCbo: string[], cbo2002: string): boolean => {
  if (isUndefinedOrNull(grupoCbo) || isUndefinedOrNull(cbo2002)) return false
  return grupoCbo.some((cboListado) => cboListado === cbo2002 || cbo2002.startsWith(cboListado))
}

export const isCboMedicoOuDentista = (cbo: string) => {
  return isCboDentista(cbo) || isCboMedico(cbo)
}
