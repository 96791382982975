import { DateRange } from 'bold-ui'

export type InstantRange = {
  startDate: number
  endDate: number
}

export const convertInstantRangeToDateRange = (instantRange: InstantRange): DateRange => {
  return { startDate: new Date(instantRange.startDate), endDate: new Date(instantRange.endDate) }
}

export const convertDateRangeToInstantRange = (dateRange: DateRange): InstantRange => {
  return { startDate: dateRange.startDate?.getTime() ?? null, endDate: dateRange.endDate?.getTime() ?? null }
}
