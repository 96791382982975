import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { DropdownButton } from 'components/dropdown/DropdownButton'
import { DropdownLink } from 'components/dropdown/DropdownLink'
import { useErrorHandler } from 'components/error'
import { alterarSenhaModalForm } from 'components/form/alterar-senha/AlterarSenhaModalForm'
import { AlterarSenhaModel } from 'components/form/alterar-senha/model'
import { confirm } from 'components/modals/confirm'
import {
  useDefinirSenhaProvisoriaMutation,
  useExcluirProfissionalMutation,
  useSolicitarRedefinicaoSenhaMutation,
} from 'graphql/hooks.generated'
import React from 'react'
import Permissions from 'types/Permissions'

import { ProfissionalSolicitacaoRedefinicaoSenha } from '../components/ProfissionalSolicitacaoRedefinicaoSenha'
import { ItemType } from './ProfissionalTable'

export interface ProfissionalTableDropdownProps {
  profissional: ItemType
  refetch(): any
}

export function ProfissionalTableDropdown(props: ProfissionalTableDropdownProps) {
  const { profissional, refetch } = props

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const [excluirProfissional] = useExcluirProfissionalMutation()
  const [solicitarRedefinirSenha] = useSolicitarRedefinicaoSenhaMutation()
  const [definirSenhaProvisoria] = useDefinirSenhaProvisoriaMutation()
  const profissionalBloqueado = profissional.usuario && profissional.usuario.bloqueado

  const handleRedefinirSenha = () => {
    const appendTitulo = profissionalBloqueado ? 'Deseja desbloquear e ' : 'Deseja '

    return confirm({
      confirmLabel: profissionalBloqueado ? 'Desbloquear e redefinir' : 'Redefinir senha',
      title: `${appendTitulo} redefinir a senha do profissional ${profissional.nome}?`,
      type: 'primary',
      onConfirm: () => {
        return solicitarRedefinirSenha({
          variables: {
            input: {
              usuario: profissional.cpf,
            },
          },
        })
          .then(async ({ data: { solicitarRedefinicaoSenha } }) => {
            const confirmed = await confirm({
              icon: 'infoCircleOutline',
              title: 'Solicitação de redefinição de senha concluída',
              body: (
                <ProfissionalSolicitacaoRedefinicaoSenha
                  qrCode={{
                    base64PngImage: solicitarRedefinicaoSenha.base64PngImage,
                  }}
                  url={solicitarRedefinicaoSenha?.value}
                  nomeProfissional={profissional.nome}
                />
              ),
              confirmLabel: 'Criar senha provisória',
              cancelLabel: 'Fechar',
            })()

            confirmed &&
              (await alterarSenhaModalForm({
                title: 'Criar senha provisória',
                onSubmit: (values: AlterarSenhaModel) => {
                  return definirSenhaProvisoria({
                    variables: {
                      input: {
                        novaSenhaInput: values.novaSenhaInput,
                        usuarioId: profissional.usuario.id,
                      },
                    },
                  })
                },
                nomeProfissional: profissional.nome,
              })())

            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir o profissional?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirProfissional({ variables: { profissionalId: profissional.id } })
          .then(() => {
            alert('success', 'Profissional excluído.')
            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <CheckPermission permission={Permissions.visualizarProfissionais.redefinirSenha}>
          <Tooltip text={profissional.actions.redefinirSenha.hint}>
            <DropdownItem onClick={handleRedefinirSenha} disabled={!profissional.actions.redefinirSenha.enabled}>
              {profissionalBloqueado ? 'Desbloquear e redefinir senha' : 'Redefinir senha'}
            </DropdownItem>
          </Tooltip>
        </CheckPermission>

        <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
          <DropdownLink to={`/profissional/${profissional.id}/edit`}>Editar</DropdownLink>
        </CheckPermission>

        <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
          <DropdownItem type='danger' onClick={handleExcluir}>
            Excluir
          </DropdownItem>
        </CheckPermission>
      </DropdownButton>
    </Tooltip>
  )
}
