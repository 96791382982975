/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Grid } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { TipoVisualizacaoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'

import { BuscaAtivaVacinacaoFormModel, tipoVisualizacaoRecord } from '../model'
import { faixaEtariaFormatter } from '../utils'

export interface BuscaAtivaVacinacaoActiveFiltersProps {
  filters: BuscaAtivaVacinacaoFormModel
}

export default function BuscaAtivaVacinacaoActiveFilters({ filters }: BuscaAtivaVacinacaoActiveFiltersProps) {
  const { grupoAlvo, status, equipe, microareas, faixaEtaria, filtrosAvancados, tipoVisualizacao } = filters

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {tipoVisualizacao && (
        <FilterItem
          label={`Vacinas d${
            tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL ? 'o' : 'a'
          }`}
          value={`${tipoVisualizacaoRecord[tipoVisualizacao].toLowerCase()}`}
          textStyle={css`
            font-weight: bold;
          `}
        />
      )}
      {equipe && <FilterItem label='Equipe:' value={`${equipe.nome}`} />}
      {microareas && <FilterItem label='Microáreas:' value={microareas.join(', ')} />}
      {grupoAlvo && <FilterItem label='Grupo-alvo:' value={grupoAlvo.descricao} />}
      {(faixaEtaria.firstValue || faixaEtaria.secondValue) && (
        <FilterItem label='Faixa etária:' value={faixaEtariaFormatter(faixaEtaria)} />
      )}
      {status && <FilterItem label='Status da vacina:' value={status.descricao} />}
      {filtrosAvancados?.imunobiologicos?.isNotEmpty() && (
        <FilterItem
          label='Imunobiológico:'
          value={filtrosAvancados.imunobiologicos.map((imuno) => imuno.sigla).join(', ')}
        />
      )}
      {filtrosAvancados?.doses?.isNotEmpty() && (
        <FilterItem label='Dose:' value={filtrosAvancados.doses.map((dose) => dose.sigla).join(', ')} />
      )}
      {filtrosAvancados?.gruposAtendimento?.isNotEmpty() && (
        <FilterItem
          label='Grupo de atendimento:'
          value={filtrosAvancados.gruposAtendimento.map((grupo) => grupo.nome.capitalize()).join(', ')}
        />
      )}
      {filtrosAvancados?.bairro && <FilterItem label='Bairro:' value={filtrosAvancados.bairro.trim().capitalize()} />}
    </Grid>
  )
}
