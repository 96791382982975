/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Text, TextProps } from 'bold-ui'
import { useMemo } from 'react'

interface StatusSquareProps extends Omit<TextProps, 'color'> {
  color: string
  description: String
  squareStyle?: ExternalStyles
}

export default function StatusSquare(props: StatusSquareProps) {
  const { color, description, squareStyle, ...rest } = props
  const styles = useMemo(() => createStyles(color), [color])
  return (
    <HFlow alignItems='center' hSpacing={0.5}>
      <div css={css(styles.square, squareStyle)}></div>
      <Text {...rest}>{description}</Text>
    </HFlow>
  )
}

const createStyles = (color: string) => ({
  square: css`
    width: 1rem;
    height: 1rem;
    background-color: ${color};
    border-radius: 2px;
  `,
})
