/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { information } from 'components/modals/information'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { keyframes } from 'emotion'
import { GraphQLError } from 'graphql'
import { useRemoverParticipanteVideochamadaMutation, useVideochamadaExisteQuery } from 'graphql/hooks.generated'
import { useNotificarConexaoVideochamadaFalhouMutation } from 'graphql/hooks.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { ChatVideochamada } from './componentes/ChatVideochamada'
import { StreamPlayersVideochamadaLayout } from './componentes/StreamPlayersVideochamadaLayout'
import { VideoChamadaFooter } from './componentes/VideochamadaFooter'
import { useConfiguracoesVideochamada } from './hooks/useConfiguracoesVideochamada'
import { useMediaDevicesConfiguration } from './hooks/useMediaDevicesConfiguration'
import { useMediaSession } from './hooks/useMediaSession'
import { useScreenShare } from './hooks/useScreenShare'
import { useUserMedia } from './hooks/useUserMedia'
import { useWebRtc } from './hooks/useWebRtc'
import {
  ConfiguracoesVideochamadaModel,
  LocalVideocallParticipant,
  MotivoEncerramentoVideochamadaEnum,
  VideocallStream,
} from './model'
import { getVideocallStreams } from './utils'

const SESSION_KEEP_ALIVE_INTERVAL_USUARIO_EXTERNO = 30 * 60 //segundos

interface VideochamadaViewProps {
  selfData: LocalVideocallParticipant
  isOwner: boolean
  videochamadaId: ID
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

export function VideochamadaView(props: VideochamadaViewProps) {
  const { videochamadaId, selfData, audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled, isOwner } = props
  const { analytics } = useFirebase()

  const [chatOpen, setChatOpen] = useState(false)
  const [isChatTransitioning, setIsChatTransitioning] = useState(false)
  const [pipActive, setPipActive] = useState(false)

  useEffect(() => setIsChatTransitioning(true), [chatOpen])

  const alert = useAlert()
  const theme = useTheme()
  const styles = createStyles(theme)
  const history = useHistory()
  const match = useRouteMatch()
  const { data: session } = useSession()

  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useConfiguracoesVideochamada()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
    videoDeviceId: configuracoesVideochamada ? configuracoesVideochamada.videoInput?.id : 'default',
    audioDeviceId: configuracoesVideochamada ? configuracoesVideochamada.audioInput?.id : 'default',
  })

  const { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice } = useMediaDevicesConfiguration({
    mediaDevices,
    audioEnabled,
    videoEnabled,
  })

  const handleSetConfiguracoesVideochamada = (config: ConfiguracoesVideochamadaModel) => {
    setConfiguracoesVideochamada(config)
    setStoredAudioDevice(config.audioInput)
    setStoredVideoDevice(config.videoInput)
  }

  const handleCameraToggle = useCallback(() => setVideoEnabled(!videoEnabled), [setVideoEnabled, videoEnabled])
  const handleMicrophoneToggle = useCallback(() => setAudioEnabled(!audioEnabled), [audioEnabled, setAudioEnabled])

  useMediaSession({
    isCameraActive: videoEnabled,
    isMicrophoneActive: audioEnabled,
    onCameraToggle: handleCameraToggle,
    onMicrophoneToggle: handleMicrophoneToggle,
  })

  const { shareScreen, stopSharingScreen, sharingScreen, displayStream } = useScreenShare()

  const [removerParticipanteVideochamada] = useRemoverParticipanteVideochamadaMutation()

  const handleRemoverParticipanteError = useCallback(
    (err) => {
      if (err.graphQLErrors) {
        err.graphQLErrors?.forEach((error: GraphQLError) => {
          // erro de acesso não permitido é ignorado para não interromper a execução quando a videochamada é encerrada e o removerParticipanteVideochamada() é acionado
          error.extensions.classification !== 'HttpForbidden' && alert('danger', error.message)
        })
      } else {
        alert('danger', err)
      }
    },
    [alert]
  )

  const handlePeerDisconnected = useCallback(
    (peerId: ID) =>
      isOwner
        ? removerParticipanteVideochamada({ variables: { id: videochamadaId, participanteId: peerId } }).catch(
            handleRemoverParticipanteError
          )
        : navigator.onLine && !window.location.pathname.endsWith('/sair') && history.push('/videochamada/encerrada'),
    [isOwner, removerParticipanteVideochamada, videochamadaId, history, handleRemoverParticipanteError]
  )

  const [notificarConexaoVideochamadaFalhou] = useNotificarConexaoVideochamadaFalhouMutation()

  const handleConnectionFailModalClose = useCallback(() => history.push(`/videochamada/encerrada?owner=${isOwner}`), [
    history,
    isOwner,
  ])

  const showConnectionFailModal = useCallback(
    () =>
      information({
        title: 'Conexão falhou',
        body:
          'Não foi possível estabelecer uma conexão com o outro participante. Verifique suas configurações ou entre em contato com o administrador da rede.',
        iconColor: 'alert',
        showCloseButton: true,
        closeLabel: isOwner ? 'Encerrar chamada' : 'Voltar',
        onClose: handleConnectionFailModalClose,
      })(),
    [handleConnectionFailModalClose, isOwner]
  )

  const handleConnectionFail = useCallback(async () => {
    if (isOwner) {
      await notificarConexaoVideochamadaFalhou({ variables: { videochamadaId: videochamadaId } })
      analytics.logEvent('TELE_ERR_videochamadas_conexao_falhou')
    }
  }, [analytics, isOwner, notificarConexaoVideochamadaFalhou, videochamadaId])

  const localStreams = useMemo(() => [localStream, displayStream].filterNotNull(), [displayStream, localStream])
  const {
    remoteParticipants,
    sendMessage,
    messages,
    addPresentingStreamId,
    removePresentingStreamId,
    presentingStreamsIds,
  } = useWebRtc({
    selfData,
    roomId: videochamadaId,
    localStreams,
    onPeerDisconnected: handlePeerDisconnected,
    onConnectionFail: handleConnectionFail,
  })

  const handleVideochamadaEncerrada = useCallback(
    (response: MotivoEncerramentoVideochamadaEnum) => {
      switch (response) {
        case MotivoEncerramentoVideochamadaEnum.ENCERRADA_POR_CRIADOR:
          history.push(`/videochamada/encerrada?owner=${isOwner}`)
          break
        case MotivoEncerramentoVideochamadaEnum.TIMEOUT:
          history.push('/videochamada/timeout')
          break
        case MotivoEncerramentoVideochamadaEnum.CONEXAO_FALHOU:
          showConnectionFailModal()
          break
        default:
          history.push('/videochamada/encerrada')
          break
      }
    },
    [history, isOwner, showConnectionFailModal]
  )

  const handleConnectionChange = useCallback(
    () => !navigator.onLine && history.push(`${match.url}/perda-conexao?owner=${isOwner}`),
    [history, match.url, isOwner]
  )

  useEffect(() => {
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [handleConnectionChange])

  useAtmosphere<MotivoEncerramentoVideochamadaEnum>({
    topic: `public/videochamada/${videochamadaId}/encerrou`,
    onMessage: handleVideochamadaEncerrada,
  })

  const keepAliveIntervalSeconds = session ? session.timeout / 2 : SESSION_KEEP_ALIVE_INTERVAL_USUARIO_EXTERNO
  useVideochamadaExisteQuery({
    variables: { id: videochamadaId },
    pollInterval: keepAliveIntervalSeconds * 1000,
    onCompleted(data) {
      !data?.videochamada && handleVideochamadaEncerrada(MotivoEncerramentoVideochamadaEnum.ENCERRADA_POR_CRIADOR)
    },
  })

  useEffect(() => {
    if (displayStream) addPresentingStreamId(displayStream.id)
    return () => {
      displayStream && removePresentingStreamId(displayStream.id)
    }
  }, [addPresentingStreamId, displayStream, removePresentingStreamId])

  const videocallStreams: VideocallStream[] = useMemo(
    () =>
      getVideocallStreams(selfData.id, localStream, remoteParticipants, displayStream, isOwner, presentingStreamsIds),
    [displayStream, isOwner, localStream, presentingStreamsIds, remoteParticipants, selfData.id]
  )

  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(false)
  useEffect(() => {
    const remoteStreams = videocallStreams
      .filter((stream) => stream.remote && !!stream.stream)
      .map((stream) => stream.stream)

    const handleChangeTracks = () =>
      setRemoteVideoEnabled(remoteStreams.some((stream) => stream.getVideoTracks().some((track) => track.enabled)))

    handleChangeTracks()
    remoteStreams.forEach((stream) => {
      stream.addEventListener('addtrack', handleChangeTracks)
      stream.addEventListener('removetrack', handleChangeTracks)
    })

    return () => {
      remoteStreams.forEach((stream) => {
        stream.removeEventListener('addtrack', handleChangeTracks)
        stream.removeEventListener('removetrack', handleChangeTracks)
      })
    }
  }, [videocallStreams])

  return (
    <Fragment>
      <Global styles={styles.global} />
      <ExternalUserHeader primary />
      <PageContent style={styles.pageContent} containerStyle={styles.pageContainer} fluid>
        <Grid
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          style={css`
            flex-grow: 1;
          `}
          gapVertical={0.5}
        >
          <Cell flexGrow={1}>
            <Grid wrap={false} gap={0.5} style={styles.content}>
              <Cell size={chatOpen ? 9 : 12} style={styles.streamPlayersContainer}>
                <StreamPlayersVideochamadaLayout
                  streams={videocallStreams}
                  pipActive={pipActive}
                  setPipActive={setPipActive}
                />
              </Cell>
              {chatOpen && (
                <Cell style={styles.chatContainer}>
                  <ChatVideochamada
                    open={isChatTransitioning || chatOpen}
                    messages={messages}
                    onClose={() => setChatOpen(false)}
                    onSendMessage={sendMessage}
                    onAnimationEnd={() => setIsChatTransitioning(false)}
                    style={styles.chat}
                  />
                </Cell>
              )}
            </Grid>
          </Cell>
          <Cell>
            <VideoChamadaFooter
              isOwner={isOwner}
              audioDeviceAvailable={audioDeviceAvailable}
              audioEnabled={audioEnabled}
              setAudioEnabled={setAudioEnabled}
              videoDeviceAvailable={videoDeviceAvailable}
              videoEnabled={videoEnabled}
              setVideoEnabled={setVideoEnabled}
              videochamadaId={videochamadaId}
              onConfiguracoesChange={handleSetConfiguracoesVideochamada}
              configuracoes={mediaDevicesConfiguration}
              chatOpen={chatOpen}
              setChatOpen={setChatOpen}
              sharingScreen={sharingScreen}
              onShareScreenClick={sharingScreen ? stopSharingScreen : shareScreen}
              hasRemoteParticipant={videocallStreams.some((stream) => stream.remote)}
              hasRemoteVideo={remoteVideoEnabled}
              pipActive={pipActive}
              setPipActive={setPipActive}
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const animationConfig = '0.5s ease'
const createStyles = (theme: Theme) => ({
  global: css`
    body {
      overflow-y: auto;
    }
  `,
  pageContent: css`
    padding: 2rem 0 1.5rem;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: ${theme.pallete.gray.c10};
    overflow: hidden;
  `,
  pageContainer: css`
    display: flex;
    max-width: 75rem;
  `,
  chatContainer: css`
    overflow: hidden;
    width: 0;
    animation: ${chatContainerScrollIn} ${animationConfig} forwards;
  `,
  chat: css`
    animation: ${chatFadeIn} ${animationConfig} forwards;
  `,
  streamPlayersContainer: css`
    height: 100%;
    transition: ${animationConfig};
  `,
  content: css`
    height: 100%;
  `,
})

const chatFadeIn = keyframes`
  0% { 
    min-width: 14rem;
  }
  99% { 
    min-width: 14rem;
  }
  100% { 
    min-width: 0;
  }
`

const chatContainerScrollIn = keyframes`
  0% {
    flex-grow: 0.001;
    opacity: 0; 
  }
  100% { 
    flex-grow: 1; 
    opacity: 1; 
  }
`
