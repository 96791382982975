import { SortDirection, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { PageContent } from 'components/layout/PageContent'
import { usePagedTableProps } from 'components/table'
import { toDate } from 'date-fns'
import {
  useBuscaListagemHistoricoClinicoLazyQuery,
  useDisponibilidadeDadosClinicosHistoricoQuery,
} from 'graphql/hooks.generated'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { ExibirPorHistoricoAtendimentoFilterEnum } from 'types/enums'
import { CheckJustificativaAcessoProntuario } from 'view/prontuario/justificativa/CheckJustificativaAcessoProntuario'

import { AtendimentoFooter } from '../AtendimentoFooter'
import { convertToInput } from './components/filtro-historico/converter'
import HistoricoAtendimentoFilter from './components/filtro-historico/HistoricoAtendimentoFilter'
import HistoricoAtendimentoTable from './components/table/HistoricoAtendimentoTable'
import { useHistoricoPrint } from './hooks/useHistoricoPrint'
import { HistoricoAtendimentoFilterFormModel } from './model/historicoFilterFormModel'
import { CidadaoHistorico, HistoricoAtendimentoListagemTableModel } from './model/model-historico'
import { atendimentosHistoricoByDate } from './utils/atendimentosHistoricoByDate'
import { isHistoricoPrintEnable } from './utils/historicoUtils'

interface HistoricoAtendimentoViewProps {
  atendimentoId?: ID
  prontuarioId: ID
  cidadao: CidadaoHistorico
  unidadeSaudeCnes: string
  isAtendimentoObservacao: boolean
  isObservacaoAndResponsavel: boolean
  tipoAtendimentoProfissional?: TipoAtendimentoProfissional
  isRegistroTardio?: boolean
  isVisualizacao?: boolean
  clearCache?(updateState?: boolean): void
}

export function HistoricoAtendimentoView(props: HistoricoAtendimentoViewProps) {
  const {
    cidadao,
    prontuarioId,
    clearCache,
    unidadeSaudeCnes,
    isRegistroTardio,
    atendimentoId,
    tipoAtendimentoProfissional,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isVisualizacao = true,
  } = props
  const {
    data: {
      profissional: { cpf },
    },
  } = useSession()
  const match = useRouteMatch()
  const { getServerTimeNow } = useServerTime()
  const atendimento = useAtendimentoContext()
  const dataReferencia = useRef<Date>(atendimento?.atendimentoProfissional?.iniciadoEm ?? getServerTimeNow())

  const defaultFilter: HistoricoAtendimentoFilterFormModel = {
    cidadaoId: cidadao.id,
    cidadaoCpf: cidadao.cpf,
    profissionalCpf: cpf,
    somenteMeusAtendimentos: false,
    periodoExibicao: ExibirPorHistoricoAtendimentoFilterEnum.TODOS_ATENDIMENTOS,
    unidadeSaudeCnes,
    sortDirection: 'DESC',
    pageParams: {
      size: 10,
    },
  }

  const [filtros, setFiltros] = useState<HistoricoAtendimentoFilterFormModel>(defaultFilter)

  const [executeQuery, { data, loading }] = useBuscaListagemHistoricoClinicoLazyQuery()

  const historico: HistoricoAtendimentoListagemTableModel = data?.historico && {
    content: atendimentosHistoricoByDate(data.historico.content),
    pageInfo: data.historico.pageInfo,
  }

  const tableProps = usePagedTableProps({
    loading: loading,
    result: historico,
    onChange: setFiltros,
  })

  const countAtendimentosEnabledToPrint = historico?.content?.filter((item) => isHistoricoPrintEnable(item)).length

  const historicoPrintProps = useHistoricoPrint({
    enabledToPrintItemCount: countAtendimentosEnabledToPrint,
    page: tableProps.page,
  })

  const handleSetFiltros = useCallback(
    (newFiltro: HistoricoAtendimentoFilterFormModel) => {
      setFiltros(newFiltro)
      historicoPrintProps.reset()
    },
    [historicoPrintProps]
  )

  const setSortDirection = useCallback(
    (sortDirection: SortDirection) =>
      sortDirection !== filtros.sortDirection && handleSetFiltros({ ...filtros, sortDirection }),
    [filtros, handleSetFiltros]
  )

  const doExecuteQuery = useCallback(() => {
    executeQuery({ variables: { input: convertToInput(filtros, toDate(dataReferencia.current)) } })
  }, [executeQuery, filtros])

  useEffect(() => {
    doExecuteQuery()
  }, [dataReferencia, doExecuteQuery, executeQuery, filtros])

  const {
    data: { disponibilidadeDadosClinicosHistorico },
  } = useDisponibilidadeDadosClinicosHistoricoQuery()

  return (
    <Fragment>
      <PageContent fluid type='filled'>
        <CheckJustificativaAcessoProntuario
          cidadaoId={cidadao.id}
          prontuarioId={prontuarioId}
          basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
          disabled={!!atendimentoId || !isVisualizacao}
        >
          <VFlow>
            <HistoricoAtendimentoFilter
              filtros={filtros}
              onChangeFiltros={handleSetFiltros}
              disponibilidadeDadosClinicos={disponibilidadeDadosClinicosHistorico}
            />
            <HistoricoAtendimentoTable
              cidadao={cidadao}
              dataTable={historico}
              isLoading={loading}
              disponibilidadeDadosClinicos={disponibilidadeDadosClinicosHistorico}
              sort={filtros.sortDirection}
              onSortChange={setSortDirection}
              onChangeFilter={handleSetFiltros}
              refetchOnListagem={doExecuteQuery}
              tableProperties={tableProps}
              countAtendimentosEnabledToPrint={countAtendimentosEnabledToPrint}
              historicoPrintProps={historicoPrintProps}
            />
          </VFlow>
        </CheckJustificativaAcessoProntuario>
      </PageContent>
      {atendimentoId && (
        <AtendimentoFooter
          atendimentoId={atendimentoId}
          tipoAtendimentoProfissional={tipoAtendimentoProfissional}
          clearCache={clearCache}
          isRegistroTardio={isRegistroTardio}
          isAtendimentoObservacao={isAtendimentoObservacao}
          isObservacaoAndResponsavel={isObservacaoAndResponsavel}
        />
      )}
    </Fragment>
  )
}
