import { HFlow, Text, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import AtendimentoListingItem from 'view/atendimentos/components/AtendimentoListingItem'
import { AtendimentoTags } from 'view/atendimentos/list/AtendimentoTags'
import { ListaAtendimentoItemProps } from 'view/atendimentos/list/ListaAtendimentoItem'
import { createItemStyles } from 'view/atendimentos/list/listaUtils'

import ListaRegistroTardioItemActions from './ListaRegistroTardioItemActions'
import { listaRegistroTardioStyles } from './utils/listaRegistroTardioStyles'

type ListaRegistroTardioItemProps = Omit<ListaAtendimentoItemProps, 'isAtendimentoObservacao' | 'serverTime'>

export function ListaRegistroTardioItem(props: ListaRegistroTardioItemProps) {
  const { atend } = props
  const { classes } = useStyles(createItemStyles)
  const statusAtendimento = listaRegistroTardioStyles[atend.statusAtendimento]

  const renderTime = (
    <HFlow style={classes.hFlow}>
      <VFlow vSpacing={0}>
        <DateTime
          style={{ lineHeight: '1.35rem', fontWeight: 'bold' }}
          value={atend.iniciadoEm}
          format='DD/MM | HH:mm'
        />
        <Text>{listaRegistroTardioStyles[atend.statusAtendimento].descricao}</Text>
      </VFlow>

      <VFlow vSpacing={0}>
        <span className={classes.nomeCidadao}>{`${atend.cidadao.nomeSocial || atend.cidadao.nome} `}</span>
        <span className={classes.idadeCidadao}>{humanizeAge(atend.cidadao.dataNascimento)}</span>
      </VFlow>
    </HFlow>
  )

  const renderTags = (
    <AtendimentoTags
      horaAgendamento={atend.agendado?.horarioInicial}
      nomeProfissional={atend.agendado?.lotacaoAgendada?.profissional?.nome}
    />
  )

  const renderLotacao = (
    <>
      {atend.responsavel && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>{atend.responsavel.profissional.nome}</Text>
        </VFlow>
      )}
      {!atend.responsavel && atend.equipe && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'> {atend.equipe.nome} </Text>
          <Text>
            <b>Área </b> {atend.equipe.area}
          </Text>
        </VFlow>
      )}
    </>
  )

  const renderActions = (
    <ListaRegistroTardioItemActions
      actions={atend.actions}
      atendimentoId={atend.id}
      cidadaoId={atend.cidadao.id}
      prontuarioId={atend.prontuarioId}
      statusAtendimento={atend.statusAtendimento}
      statusRevisaoAtendimento={atend.statusRevisaoAtendimento}
      dataInicioAtendimento={atend.iniciadoEm}
      agendadoId={atend.agendado?.id}
    />
  )

  return (
    <AtendimentoListingItem
      status={statusAtendimento}
      time={renderTime}
      tags={renderTags}
      lotacao={renderLotacao}
      actions={renderActions}
    />
  )
}
