import {
  ParteBucalEnum,
  Procedimento,
  SituacaoCoroaEnum,
  SituacaoFaceEnum,
  SituacaoRaizEnum,
  TipoParteBucalEnum,
} from 'graphql/types.generated'
import { concat } from 'lodash'

import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util'
import { ArcadaFieldModel } from './components/odontograma-field/arcadas-field/ArcadasField'
import { DenteFieldModel } from './components/odontograma-field/dentes-field/DentesField'
import {
  condicoesDente,
  condicoesQueDesabilitamCoroa,
  dentesComLesaoDeFurca,
  dentesDeciduos,
  OdontogramaPosicaoProtese,
  partesBucal,
} from './components/odontograma-field/model'
import { SextanteFieldModel } from './components/odontograma-field/sextantes-field/SextantesField'
import { FillColor } from './components/odontograma/types/Coroa'
import { OdontologiaModel } from './EvolucoesOdontologicasView'

export function showDescricaoCodigoProcedimento(procedimento: Procedimento) {
  return `${procedimento.descricao.capitalize()} - ${showCodigoProcedimento(procedimento.codigo)}`
}

export function getParteBucal(parteBucalEnum: ParteBucalEnum) {
  return partesBucal[parteBucalEnum]
}

export function getCondicaoDente(condicao: SituacaoRaizEnum | SituacaoFaceEnum) {
  return condicoesDente[condicao]
}

export function getParteBucalByDenteNumber(n: number) {
  return getDentes().find((dente) => getParteBucal(dente).number === n)
}

export function getFillColorBySituacaoCoroa(
  situacaoCoroa: SituacaoCoroaEnum,
  fillColors: FillColor<SituacaoCoroaEnum>[]
) {
  return fillColors.find((fillColor) => fillColor.value === situacaoCoroa) || undefined
}

export function getArcadas() {
  return (Object.keys(partesBucal) as Array<ParteBucalEnum>)
    .filter((item) => getParteBucal(item).type === TipoParteBucalEnum.ARCADA)
    .sort((a, b) => getParteBucal(a).title.localeCompare(getParteBucal(b).title))
}

export function getSextantes() {
  return (Object.keys(partesBucal) as Array<ParteBucalEnum>)
    .filter((item) => getParteBucal(item).type === TipoParteBucalEnum.SEXTANTE)
    .sort((a, b) => getParteBucal(a).title.localeCompare(getParteBucal(b).title))
}

export function getDentes() {
  return (Object.keys(partesBucal) as Array<ParteBucalEnum>)
    .filter((item) => getParteBucal(item).type === TipoParteBucalEnum.DENTE)
    .sort((a, b) => getParteBucal(a).title.localeCompare(getParteBucal(b).title))
}

export function isDenteDeciduo(dente: ParteBucalEnum) {
  return dentesDeciduos.includes(dente)
}

export function isDentePermanente(dente: ParteBucalEnum) {
  return !isDenteDeciduo(dente)
}

export function isCoroaDisabled(dente: DenteFieldModel) {
  return dente?.condicoesDente?.some((item) => condicoesQueDesabilitamCoroa.includes(item))
}

export function possuiLesaoDeFurca(dente: ParteBucalEnum) {
  return dentesComLesaoDeFurca.includes(dente)
}

export function getDentePosicaoProtese(dente: ParteBucalEnum): OdontogramaPosicaoProtese {
  const numeroDente = getParteBucal(dente).number
  const arcadaSuperiorDenteRange = (numeroDente >= 11 && numeroDente <= 28) || (numeroDente >= 51 && numeroDente <= 65)

  return arcadaSuperiorDenteRange ? 'superior' : 'inferior'
}

export function getPartesBucalComProcedimento(
  items:
    | Record<ParteBucalEnum, DenteFieldModel>
    | Record<ParteBucalEnum, SextanteFieldModel>
    | Record<ParteBucalEnum, ArcadaFieldModel>
): ParteBucalEnum[] {
  return (
    items &&
    Object.entries(items).map(
      ([key, item]: [ParteBucalEnum, SextanteFieldModel | ArcadaFieldModel | DenteFieldModel]) =>
        item.procedimentos?.length > 0 && key
    )
  )
}

const dentes = getDentes()

export function getDentesComDados(
  items: Record<ParteBucalEnum, DenteFieldModel>,
  proteseTotalSuperior?: boolean,
  proteseTotalInferior?: boolean
): ParteBucalEnum[] {
  return dentes
    .map((dente: ParteBucalEnum) => {
      const item = items?.[dente]

      return (
        (item?.procedimentos?.length > 0 ||
          item?.condicoesDente?.length > 0 ||
          (getDentePosicaoProtese(dente) === 'superior' ? proteseTotalSuperior : proteseTotalInferior)) &&
        dente
      )
    })
    .filter((i) => !!i)
}

export const procedimentosUnicosOdontologia = (odontologia: OdontologiaModel): string[] => {
  const { odontograma, periodontia, tecidosMoles } = odontologia || {}

  const dentesProceds = odontograma?.dentes && Object.values(odontograma.dentes).flatMap((dente) => dente.procedimentos)
  const arcadaProceds =
    odontograma?.arcadas && Object.values(odontograma.arcadas).flatMap((arcada) => arcada.procedimentos)
  const outros = odontograma?.outros?.flatMap((outro) => outro.procedimentos)
  const dentesSupranumerarios = odontograma?.dentesSupranumerarios?.flatMap(
    (denteSupranumerario) => denteSupranumerario.procedimentos
  )
  const tecidosMolesProceds = tecidosMoles?.flatMap((tecidoMole) => tecidoMole.procedimentos)
  const periodontiaProceds = periodontia?.evolucoes?.flatMap((evolucao) => evolucao.procedimentos)

  const allProcedsOdonto = [
    ...new Set(
      concat(dentesProceds, arcadaProceds, outros, dentesSupranumerarios, tecidosMolesProceds, periodontiaProceds)
        .map((item) => item?.codigo)
        .filter((value) => value)
    ),
  ]
  return allProcedsOdonto
}
