import { VFlow } from 'bold-ui'
import React from 'react'

import { TransmissaoHorarioGeracaoLotesBox } from './horarioGeracaoLotes/TransmissaoHorarioGeracaoLotesBox'
import { TransmissaoLinksDestinoBox } from './instalacoesDestino/TransmissaoLinksDestinoBox'

export function TransmissaoConfiguracoesGeralView() {
  return (
    <>
      <VFlow vSpacing={2}>
        <TransmissaoLinksDestinoBox />
        <TransmissaoHorarioGeracaoLotesBox />
      </VFlow>
    </>
  )
}
