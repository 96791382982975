/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, Text, Theme, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { ChangeEvent, CSSProperties, useCallback } from 'react'

import { AgendamentoDia } from '../types/AgendamentoDia'

export interface AgendamentosDiaCheckFieldProps {
  agendamento: AgendamentoDia
  checked: boolean
  onChange(value: string): void
}

export function AgendamentosDiaCheckField(props: AgendamentosDiaCheckFieldProps) {
  const { classes } = useStyles(createStyles)
  const { agendamento, checked, onChange } = props

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Checkbox
      style={classes.checkbox}
      label={
        <AgendamentosDiaLabel
          name={agendamento.lotacaoAgendada.profissional.nome}
          time={agendamento.horarioInicial}
          nomeCbo={agendamento.lotacaoAgendada.cbo.nome}
        />
      }
      value={agendamento.id}
      onChange={handleChange}
      checked={checked}
    />
  )
}

const AgendamentosDiaLabel = (props: { name: string; time: string; nomeCbo: string }) => {
  const { time, name, nomeCbo } = props
  return (
    <VFlow
      vSpacing={0}
      style={css`
        padding-left: 0.5rem;
      `}
    >
      <Text fontWeight='bold'>
        <DateTime value={time} format='HH:mm' />
        {` -  ${name}`}
      </Text>
      <Text>{nomeCbo.titleCase()}</Text>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  checkbox: css`
    width: 100%;
    background: ${theme.pallete.surface.main};
    padding: 1.25rem 1rem 1.25rem 0.75rem;
    border-radius: 2px;
    border: 1px solid ${theme.pallete.gray.c60};
  ` as CSSProperties,
})
