import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch } from 'components/route'
import React from 'react'
import { Route, useRouteMatch } from 'react-router'

import { CuidadoCompartilhadoRootView } from './cuidado-compartilhado/CuidadoCompartilhadoRootView'
import { ListaCuidadoCompartilhadoRootView } from './lista-cuidados-compartilhados/ListaCuidadoCompartilhadoRootView'
import { VisualizarCuidadoCompartilhadoRootView } from './visualizar-cuidado-compartilhado/VisualizarCuidadoCompartilhadoRootView'

export const CuidadosCompartilhadosRootView = () => {
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title='Cuidados compartilhados' />
      <PecSwitch>
        <Route path={`${match.url}/compartilhamentos/:id`} component={CuidadoCompartilhadoRootView} />
        <Route path={`${match.url}/solicitacoes/:id`} component={CuidadoCompartilhadoRootView} />
        <Route path={`${match.url}/visualizar/:id`} component={VisualizarCuidadoCompartilhadoRootView} />
        <Route path={match.url} component={ListaCuidadoCompartilhadoRootView} />
      </PecSwitch>
    </>
  )
}
