import { Cell, Grid } from 'bold-ui'
import { ClassificacaoRisco, TipoServico } from 'graphql/types.generated'
import React from 'react'

import { AtendimentoTag, AtendimentoTagProps } from './AtendimentoTag'

export interface AtendimentoTagsProps {
  tiposServico?: TipoServico[]
  classificacaoRisco?: ClassificacaoRisco
  horaAgendamento?: Instant
  nomeProfissional?: string
}

export function AtendimentoTags(props: AtendimentoTagsProps) {
  const { tiposServico, classificacaoRisco, horaAgendamento, nomeProfissional } = props
  const tags: AtendimentoTagProps[] = []

  horaAgendamento &&
    tags.push({
      horaAgendamento,
      nomeProfissional,
    })

  classificacaoRisco &&
    classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO &&
    tags.push({
      classificacaoRisco,
    })

  tiposServico?.sort((a, b) => a.nome.localeCompare(b.nome))

  tiposServico
    ?.filter((ts) => ts.nome !== 'AGENDADO')
    .forEach((item) =>
      tags.push({
        tipoServico: item,
      })
    )

  return (
    <Grid gap={0.5} gapVertical={0.25} justifyContent='flex-start' style={{ maxWidth: '100%' }}>
      {tags?.map((item, index) => (
        <Cell key={index} style={{ maxWidth: '100%' }}>
          <AtendimentoTag
            key={index}
            classificacaoRisco={item.classificacaoRisco}
            horaAgendamento={item.horaAgendamento}
            nomeProfissional={item.nomeProfissional}
            tipoServico={item.tipoServico}
          />
        </Cell>
      ))}
    </Grid>
  )
}
