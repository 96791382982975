/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { ColorScale, turquoise } from 'bold-ui/lib/styles/colors'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'

import { situacaoAgendamentoRecord } from '../model'

interface SituacaoAgendamentoBarProps {
  situacao: SituacaoAgendadoEnum
  isForaUbs?: boolean
}

export function SituacaoAgendamentoBar(props: SituacaoAgendamentoBarProps) {
  const { situacao, isForaUbs = false } = props

  const theme = useTheme()

  const styles = useMemo(() => {
    return createStyles(theme, isForaUbs ? turquoise : situacaoAgendamentoRecord[situacao].cor)
  }, [isForaUbs, situacao, theme])

  return <div css={styles.boxStatus} />
}

const createStyles = (theme: Theme, statusColor: ColorScale) => ({
  boxStatus: css`
    min-width: 0.5rem;
    background-image: linear-gradient(
      to top,
      ${statusColor?.c50 ?? theme.pallete.surface.main},
      ${statusColor?.c70 ?? theme.pallete.surface.main}
    );
    height: 100%;
  `,
})
