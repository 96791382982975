import { HFlow, Icon, Text } from 'bold-ui'
import { DisponibilidadeHorusEnum } from 'graphql/types.generated'
import React from 'react'

import { enumToStringDisponibilidadeHorus } from '../utils/prescricaoUtils'

export interface DisponibilidadeHorusProps {
  disponibilidade: DisponibilidadeHorusEnum
  isOnModal?: boolean
}

export default function DisponibilidadeHorus({ disponibilidade, isOnModal }: DisponibilidadeHorusProps) {
  const disponibilidadeMensagem = `${enumToStringDisponibilidadeHorus[disponibilidade]}${isOnModal ? '' : ' no Hórus'}`

  switch (disponibilidade) {
    case DisponibilidadeHorusEnum.DISPONIVEL:
      return (
        <HFlow hSpacing={0.5} alignItems='center'>
          <Icon size={1} fill='success' icon='checkCircleOutline' />
          <Text color='success'>{disponibilidadeMensagem}</Text>
        </HFlow>
      )
    case DisponibilidadeHorusEnum.PARCIALMENTE_DISPONIVEL:
      return (
        <HFlow hSpacing={0.5} alignItems='center'>
          <Icon size={1} fill='alert' icon='exclamationTriangleOutline' />
          <Text color='alert'>{disponibilidadeMensagem}</Text>
        </HFlow>
      )
    case DisponibilidadeHorusEnum.INDISPONIVEL:
    case DisponibilidadeHorusEnum.SEM_CONEXAO:
      return (
        <HFlow hSpacing={0.5} alignItems='center'>
          <Icon size={1} fill='danger' icon='banOutline' />
          <Text color='danger'>{disponibilidadeMensagem}</Text>
        </HFlow>
      )
    default:
      return null
  }
}
