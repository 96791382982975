/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, Theme, useTheme } from 'bold-ui'
import { TableBox } from 'components/table'

import { HistoricoSectionStyles } from '../../model/model-historico'
import { HistoricoSectionType } from '../HistoricoSection'

export interface HistoricoDetailTableProps {
  title?: string
  sectionStyle?: HistoricoSectionType
  children: React.ReactNode
  hasLines?: boolean
}

export default function HistoricoDetailTable(props: HistoricoDetailTableProps) {
  const { title, sectionStyle, children, hasLines } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <TableBox>
      <Table>
        {title && (
          <TableHead>
            <TableRow>
              <TableHeader>
                <Text
                  style={css`
                    color: ${sectionStyle && HistoricoSectionStyles[sectionStyle].color};
                  `}
                >
                  {title}
                </Text>
              </TableHeader>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <TableRow>
            <TableCell style={hasLines ? styles.tableCell : styles.tableBox}>{children}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableBox>
  )
}

const createStyles = (theme: Theme) => ({
  tableCell: css`
    overflow-wrap: anywhere;
    & > div > :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.divider};
    }
    & > div > * {
      margin: 0 -1rem;
      padding: 0.65rem 1rem;
      &:hover {
        background-color: ${theme.pallete.surface.background};
      }
    }
  `,
  tableBox: css`
    padding: 0.5rem 1rem;
    &:hover {
      background-color: ${theme.pallete.surface.background};
    }
  `,
})
