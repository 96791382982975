import { ColorScale, green, pink } from 'bold-ui/lib/styles/colors'
import { information } from 'components/modals/information'

import { RemoteVideocallParticipant } from './model'

export const showChamadaEncerradaInformation = () =>
  information({
    title: 'Chamada encerrada',
    body: 'Não é possível ingressar em chamadas que já foram encerradas.',
    showCloseButton: true,
  })()

export const showChamadaLotadaInformation = () =>
  information({
    title: 'Número máximo de participantes excedido',
    body: 'No momento, as videochamadas e-SUS APS podem ter apenas dois participantes simultâneos.',
    showCloseButton: true,
  })()

const getUserIconColors = (isOwner: Boolean): [ColorScale, ColorScale] => (isOwner ? [pink, green] : [green, pink])

// TODO (@RNG): Implementar testes (#14543)
export function getVideocallStreams(
  selfId: ID,
  localStream: MediaStream,
  remoteParticipants: RemoteVideocallParticipant[],
  displayStream: MediaStream,
  isOwner: boolean,
  presentingStreamsIds: ReadonlySet<ID>
) {
  const [localIconColor, remoteIconColor] = getUserIconColors(isOwner)

  return [
    {
      id: selfId,
      label: 'Você',
      stream: localStream,
      remote: false,
      presenting: localStream && presentingStreamsIds.has(localStream.id),
      color: localIconColor,
    },
    displayStream && {
      id: `${selfId}|presentation`,
      label: 'Você está apresentando',
      stream: displayStream,
      remote: false,
      presenting: presentingStreamsIds.has(displayStream.id),
      color: localIconColor,
    },
    ...getRemoteVideocallStreams(remoteParticipants[0], remoteIconColor, presentingStreamsIds),
  ].filterNotFalsy()
}

function getRemoteVideocallStreams(
  remoteParticipant: RemoteVideocallParticipant,
  color: ColorScale,
  presentingStreamsIds: ReadonlySet<ID>
) {
  if (!remoteParticipant) return []

  const isPresenting = (stream: MediaStream) => stream && presentingStreamsIds.has(stream.id)

  const streams = [...remoteParticipant.streams.values()]
  const activeStreams = streams.filter(
    (stream) => isPresenting(stream) || !stream?.getTracks().every((track) => track.muted)
  )
  // Garante que será exibido ao menos um participante para indicar que o peer remoto está conectado
  if (activeStreams.every(isPresenting)) activeStreams.push(null)

  return activeStreams.map((stream) => {
    const presenting = isPresenting(stream)
    return {
      id: `${remoteParticipant.id}|${stream?.id}`,
      label: presenting ? `${remoteParticipant.nome} está apresentando` : remoteParticipant.nome,
      stream: stream,
      remote: true,
      presenting,
      color,
    }
  })
}
