/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HeadingSection, Icon, Link, Table, TableBody, TableCell, TableRow, VFlow } from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { ButtonLink } from 'components/route'
import { useAceitarTermosUsoMutation, useSobreQuery } from 'graphql/hooks.generated'

export const TermosView = () => {
  const session = useSession({ fetchPolicy: 'cache-only' })

  const faltaAceitarTermos = session.data && !session.data.profissional.usuario.aceitouTermosUso

  const styles = createStyles(faltaAceitarTermos)

  const [aceitarTermos] = useAceitarTermosUsoMutation()

  const handleAceitarTermos = () => aceitarTermos()

  const dataAtualizacao = '13/07/2023'

  const {
    data: { info },
  } = useSobreQuery()

  if (!info) {
    return null
  }

  return (
    <PageContent type='filled' style={styles.pageContentTermosUso}>
      {!session.data && (
        <ButtonLink
          to='/'
          style={css`
            padding: 0.5rem;
            margin-bottom: 1rem;
          `}
        >
          <Icon
            icon='arrowLeft'
            style={css`
              margin-right: 0.5rem;
            `}
          />
          Retornar à tela de login
        </ButtonLink>
      )}

      <Breadcrumb title='Termos de uso' />
      <VFlow vSpacing={2} style={styles.vflowPageContent}>
        <PageHeader title='Termos de Uso' subtitle={'Versão ' + info.versao} />
        <HeadingSection level={2} color='primary' title=''>
          <p>
            Este documentos busca esclarecer os direitos e obrigações do fornecedor e do usuário do Sistema e-SUS
            Atenção Primária (e-SUS APS), bem como as condições da correta utilização do referido sistema.
          </p>
          <br />
          <p>
            Ao utilizar o e-SUS APS você se torna um <b>USUÁRIO</b>, e isso implica a aceitação plena e sem reserva de
            todos os itens do presente <b>TERMO</b>.
            {faltaAceitarTermos &&
              ` Para aceitar o termo, ao final, clique em “Declaro que li e estou ciente dos termos de uso”. Caso não concorde com estes termos, clique em "Sair".`}
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='1. Aceitação do Termo de Uso'>
          <p>
            O presente Termo de Uso se refere a um contrato de adesão firmado entre o usuário e o fornecedor deste
            serviço, o Ministério da Saúde, localizado no Edifício Sede - Esplanada dos Ministérios, Bloco G, Brasília -
            DF, 70058-900.
          </p>
          <br />
          <p>
            O uso deste serviço está condicionado à ciência dos termos e das políticas associadas. O usuário deverá ler
            tais termos e políticas, certificar-se de havê-los entendido, estar consciente de todas as condições
            estabelecidas no Termo de Uso e se comprometer a cumpri-las.
          </p>
          <br />
          <p>
            Ao utilizar o serviço, o usuário manifesta estar ciente com relação ao conteúdo deste Termo de Uso e estará
            legalmente vinculado a todas as condições aqui previstas.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='2. Definições do Termo de Uso'>
          <p>Para os fins deste Termo de Uso, são aplicáveis as seguintes definições:</p>
          <br />
          <p>
            <b>Agente público:</b> Todo aquele que exerce, ainda que transitoriamente ou sem remuneração, por eleição,
            nomeação, designação, contratação ou qualquer outra forma de investidura ou vínculo, mandato, cargo, emprego
            ou função nos órgãos e entidades da Administração Pública, direta ou indireta.
          </p>
          <br />
          <p>
            <b>Agentes de Estado:</b> Inclui órgãos e entidades da Administração pública além dos seus agentes públicos.
          </p>
          <br />
          <p>
            <b>Códigos maliciosos:</b> São qualquer programa de computador, ou parte de um programa, construído com a
            intenção de provocar danos, obter informações não autorizadas ou interromper o funcionamento de sistemas
            e/ou redes de computadores.
          </p>
          <br />
          <p>
            <b>Sítios e aplicativos:</b> Sítios e aplicativos por meio dos quais o usuário acessa os serviços e
            conteúdos disponibilizados.
          </p>
          <br />
          <p>
            <b>Terceiro:</b> Pessoa ou entidade que não participa diretamente em um contrato, em um ato jurídico ou em
            um negócio, ou que, para além das partes envolvidas, pode ter interesse num processo jurídico.
          </p>
          <br />
          <p>
            <b>Internet:</b> Sistema constituído do conjunto de protocolos lógicos, estruturado em escala mundial para
            uso público e irrestrito, com a finalidade de possibilitar a comunicação de dados entre terminais por meio
            de diferentes redes.
          </p>
          <br />
          <p>
            <b>Usuários (ou "Usuário", quando individualmente considerado):</b> Todas as pessoas naturais que utilizarem
            o Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS (Atenção Primária à Saúde).
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='3. Arcabouço Legal'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 8.080, de 19 de setembro de 1990</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Dispõe sobre as condições para a promoção, proteção e recuperação da saúde, a organização e o
                      funcionamento dos serviços correspondentes e dá outras providências.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 12.737, de 30 de novembro de 2012</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Dispõe sobre a tipificação criminal de delitos informáticos.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 12.965, de 23 de abril de 2014 - Marco Civil da Internet</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 12.527, de 18 de novembro de 2011 - Lei de Acesso à Informação</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Regula o acesso a informações previsto na Constituição Federal.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 13.460, de 26 de junho de 2017</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Dispõe sobre participação, proteção e defesa dos direitos do usuário dos serviços públicos da
                      administração pública.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 13.709, de 14 de agosto de 2018</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou
                      por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos
                      fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa
                      natural.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 13.444, de 11 de maio de 2017</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Dispõe sobre a Identificação Civil Nacional (ICN).</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Lei nº 14.510, de 27 de dezembro de 2022</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Altera a Lei nº 8.080, de 19 de setembro de 1990, para autorizar e disciplinar a prática da
                      telessaúde em todo o território nacional, e a Lei nº 13.146, de 6 de julho de 2015; e revoga a Lei
                      nº 13.989, de 15 de abril de 2020.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 8.777, de 11 de maio de 2016</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Institui a Política de Dados Abertos do Poder Executivo federal.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 8.777, de 11 de maio de 2016</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Institui a Política de Dados Abertos do Poder Executivo federal.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 7.724, de 16 de maio de 2012</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Regulamenta a Lei no 12.527, de 18 de novembro de 2011 (Lei de Acesso à Informação), que dispõe
                      sobre o acesso a informações previsto na Constituição.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 7.845, de 14 de novembro de 2012</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Regulamenta procedimentos para credenciamento de segurança e tratamento de informação classificada
                      em qualquer grau de sigilo, e dispõe sobre o Núcleo de Segurança e Credenciamento.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 10.046, de 09 de outubro de 2019</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Dispõe sobre a governança no compartilhamento de dados no âmbito da administração pública federal
                      e institui o Cadastro Base do Cidadão e o Comitê Central de Governança de Dados.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Normas complementares do Gabinete de Segurança da Informação da Presidência (GSI/PR)</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Disciplinam a Gestão de Segurança da Informação e Comunicações na Administração Pública Federal,
                      direta e indireta, e dá outras providências.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Decreto nº 9.637, de 26 de dezembro de 2018</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Institui a Política Nacional de Segurança da Informação, dispõe sobre a governança da segurança da
                      informação, e altera o Decreto nº 2.295, de 4 de agosto de 1997, que regulamenta o disposto no
                      art. 24, caput, inciso IX, da Lei nº 8.666, de 21 de junho de 1993, e dispõe sobre a dispensa de
                      licitação nos casos que possam comprometer a segurança nacional.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Consolidação das normas sobre os direitos e deveres dos usuários da saúde, a organização e o
                      funcionamento do Sistema Único de Saúde.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>
                        Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, arts. 230 a 240 (Origem: PRT
                        MS/GM 2073/2011)
                      </i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Regulamenta o uso de padrões de interoperabilidade e informação em saúde para sistemas de
                      informação em saúde no âmbito do Sistema Único de Saúde, nos níveis Municipal, Distrital, Estadual
                      e Federal, e para os sistemas privados e do setor de saúde suplementar.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Portaria nº 1.434, de 28 de maio de 2020</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Institui o Programa Conecte SUS e altera a Portaria de Consolidação nº 1/GM/MS, de 28 de setembro
                      de 2017, para instituir a Rede Nacional de Dados em Saúde e dispor sobre a adoção de padrões de
                      interoperabilidade em saúde.
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>
                        Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, arts. 305 a 312 (Origem: PRT
                        MS/GM 1412/2013)
                      </i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Institui o Sistema de Informação em Saúde para a Atenção Básica (SISAB).</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>
                        Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, art. 308, §3º (Origem: PRT MS/GM
                        1113/2015)
                      </i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Altera o § 3º do art. 3º da Portaria nº 1.412/GM/MS, de 10 de julho de 2013, que institui o
                      Sistema de Informação em Saúde para a Atenção Básica (SISAB).
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Portaria nº 2.499, de 23 de setembro de 2019</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Altera a Portaria de Consolidação nº 1, de 28 de setembro de 2017.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Portaria GM/MS nº 3.632, de 21 de dezembro de 2020</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Altera a Portaria de Consolidação GM/MS nº 1, de 28 de setembro de 2017, para instituir a
                      Estratégia de Saúde Digital para o Brasil 2020-2028 (ESD28)
                    </i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>Portaria de consolidação nº 2, de 28 de setembro de 2017</i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>Consolidação das normas sobre as políticas nacionais de saúde do Sistema Único de Saúde.</i>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.col50}>
                  <p>
                    <b>
                      <i>
                        Portaria de consolidação nº 2, de 28 de setembro de 2017, ANEXO XLII - Política Nacional de
                        Informação e Informática em Saúde (PNIIS) (Redação dada pela PRT GM/MS nº 1.768 de 30.07.2021)
                      </i>
                    </b>
                  </p>
                </TableCell>
                <TableCell style={styles.col50}>
                  <p>
                    <i>
                      Altera o Anexo XLII da Portaria de Consolidação GM/MS nº 2, de 28 de setembro de 2017, para dispor
                      sobre a Política Nacional de Informação e Informática em Saúde (PNIIS).
                    </i>
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='4. Descrição do Serviço'>
          <p>
            O Prontuário Eletrônico do Cidadão (PEC) integra a estratégia e-SUS APS (Atenção Primária à Saúde): sistema
            gratuito disponibilizado pelo Ministério da Saúde, utilizado nos serviços públicos de Atenção Primária à
            Saúde (APS). A estratégia envolve o registro das informações no sistema, o qual pode ser realizado no
            Prontuário Eletrônico do Cidadão (PEC) ou pela Coleta de Dados Simplificada (CDS). O Sistema e-SUS APS
            auxilia, entre outras coisas, nas ações de gerenciamento de Unidades de Atenção Primária à Saúde,
            organização da agenda e processo de trabalho, registro e organização, em formato de prontuário eletrônico,
            para os profissionais de saúde, registro e organização, em formato de Coleta de Dados Simplificada (módulo
            CDS), por meio de fichas e processo de digitação destas, bem como monitoramento e avaliação das ações de
            saúde no território.
          </p>
          <br />
          <p>
            O PEC tem o objetivo de atender as equipes da APS lotadas unidades básicas de saúde (UBS) parcialmente ou
            totalmente informatizadas. Esse sistema é usado para registrar informações de cadastros e clínicas sobre os
            cidadãos. O prontuário funciona em cenários com ou sem conexão com a internet.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='5. Direitos do Usuário do Serviço'>
          <p>De acordo com a Lei nº 13.460, de 26 de junho de 2017, são direitos básicos do usuário:</p>
          <br />
          <ol type='I' css={styles.lista}>
            <li>Participação no acompanhamento da prestação e na avaliação dos serviços;</li>
            <li>
              Obtenção e utilização dos serviços com liberdade de escolha entre os meios oferecidos e sem discriminação;
            </li>
            <li>
              Acesso e obtenção de informações relativas à sua pessoa constantes de registros ou bancos de dados,
              observado o disposto no inciso X do caput do art. 5º da Constituição Federal e na Lei nº 12.527, de 18 de
              novembro de 2011;
            </li>
            <li>Proteção de suas informações pessoais, nos termos da Lei nº 12.527, de 18 de novembro de 2011;</li>
            <li>
              Atuação integrada e sistêmica na expedição de atestados, certidões e documentos comprobatórios de
              regularidade; e
            </li>
            <li>
              Obtenção de informações precisas e de fácil acesso nos locais de prestação do serviço, assim como sua
              disponibilização na internet, especialmente sobre:
              <ol type='a'>
                <br />
                <li>horário de funcionamento das unidades administrativas;</li>
                <li>
                  serviços prestados pelo órgão ou entidade, sua localização exata e a indicação do setor responsável
                  pelo atendimento ao público;
                </li>
                <li>acesso ao agente público ou ao órgão encarregado de receber manifestações;</li>
                <li>situação da tramitação dos processos administrativos em que figure como interessado; e</li>
                <li>
                  valor das taxas e tarifas cobradas pela prestação dos serviços, contendo informações para a
                  compreensão exata da extensão do serviço prestado.
                </li>
              </ol>
            </li>
          </ol>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='6. Responsabilidades do Usuário'>
          <p>
            <b>Quais são as obrigações dos usuários que utilizam o serviço?</b>
          </p>
          <br />
          <p>
            O usuário se responsabiliza pela precisão e pela veracidade dos dados informados e reconhece que a
            inconsistência deles poderá implicar a impossibilidade de se utilizar o Prontuário Eletrônico do Cidadão
            (PEC) do Sistema e-SUS APS.
          </p>
          <br />
          <p>
            Durante a utilização do serviço, a fim de resguardar e proteger os direitos de terceiros, o usuário se
            compromete a fornecer somente seus dados pessoais, e não os de terceiros.
          </p>
          <br />
          <p>
            O login e senha só poderão ser utilizados pelo usuário cadastrado. Ele se compromete em manter o sigilo da
            senha, que é pessoal e intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido
            após o ato de compartilhamento.
          </p>
          <br />
          <p>
            O usuário do serviço é responsável pela atualização dos seus dados pessoais e pelas consequências em caso de
            omissão ou erros nos dados fornecidos.
          </p>
          <br />
          <p>
            O Usuário é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive
            decorrentes de violação de quaisquer direitos de outros usuários; de terceiros, inclusive direitos de
            propriedade intelectual;de sigilo; e de personalidade), que sejam causados à Administração Pública, a
            qualquer outro Usuário, ou ainda a qualquer terceiro, inclusive em virtude do descumprimento do disposto
            nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso ao
            serviço.
          </p>
          <br />
          <p>O Ministério da Saúde não poderá ser responsabilizado pelos seguintes fatos:</p>
          <br />
          <ol type='a' css={styles.lista}>
            <li>Equipamento infectado ou invadido por atacantes;</li>
            <li>Equipamento avariado no momento do consumo de serviços;</li>
            <li>Proteção do computador;</li>
            <li>Proteção das informações baseadas nos computadores dos usuários;</li>
            <li>Abuso de uso dos computadores dos usuários;</li>
            <li>Monitoração clandestina do computador dos usuários;</li>
            <li>Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;</li>
            <li>Perímetro inseguro.</li>
          </ol>
          <br />
          <p>
            Em nenhuma hipótese, a Administração Pública Federal será responsável pela instalação, no equipamento do
            Usuário ou de terceiros, de códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware,
            rootkit, ou de quaisquer outros que venham a ser criados), em decorrência da navegação na Internet pelo
            Usuário
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='7. Responsabilidade da Administração Pública'>
          <p>
            <b>Quais são as responsabilidades da Administração Pública com os meus dados?</b>
          </p>
          <br />
          <p>
            A Administração Pública se compromete a cumprir todas as legislações inerentes ao uso correto dos dados
            pessoais do cidadão de forma a preservar a privacidade dos dados utilizados no serviço, bem como a garantir
            todos os direitos e garantias legais dos titulares dos dados. Ela também se obriga a promover,
            independentemente de requerimentos, a divulgação em local de fácil acesso, no âmbito de suas competências,
            de informações de interesse coletivo ou geral por eles produzidas ou custodiadas. É de responsabilidade da
            Administração Pública implementar controles de segurança para proteção dos dados pessoais dos titulares.
          </p>
          <br />
          <p>
            A Administração Pública poderá, quanto às ordens judiciais de pedido das informações, compartilhar
            informações necessárias para investigações ou tomar medidas relacionadas a atividades ilegais, suspeitas de
            fraude ou ameaças potenciais contra pessoas, bens ou sistemas que sustentam o Serviço ou de outra forma
            necessárias para cumprir com obrigações legais. Caso ocorra, a Administração Pública notificará os titulares
            dos dados, salvo quando o processo estiver em segredo de justiça.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='8. Mudanças no Termo de Uso'>
          <p>
            <b>Este Termo de Uso pode ser alterado?</b>
          </p>
          <br />
          <p>
            A presente versão {info.versao} deste Termo de Uso foi atualizada pela última vez em: {dataAtualizacao}.
          </p>
          <br />
          <p>
            O editor se reserva o direito de modificar no site, a qualquer momento, as presentes normas, especialmente
            para adaptá-las às evoluções do Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, seja pela
            disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
          </p>
          <br />
          <p>
            Qualquer alteração e/ou atualização do Termos de Uso e da Política de Privacidade passará a vigorar a partir
            da data de sua publicação no sítio do serviço e deverá ser integralmente observada pelos Usuários.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='9. Informações para contato'>
          <p>
            Em caso de dúvidas relacionadas ao Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, entre em
            contato através dos nossos canais de atendimento:
          </p>
          <br />
          <p>
            Canal de Suporte e-SUS APS:{' '}
            <Link href='https://esusaps.freshdesk.com/support/login' target='_blank'>
              https://esusaps.freshdesk.com/support/login
            </Link>
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='10. Foro'>
          <p>
            Este Termo será regido pela legislação brasileira. Qualquer reclamação ou controvérsia com base neste Termo
            será dirimida em Brasília-DF.
          </p>
          <br />
          <p>
            Sem prejuízo de qualquer outra via administrativa ou judicial disponível, todos os titulares de dados
            pessoais têm direito a apresentar reclamação à Autoridade Nacional de Proteção de Dados (ANPD).
          </p>
        </HeadingSection>
      </VFlow>
      <VFlow vSpacing={2} style={styles.vflowPageContent}>
        <PageHeader
          title='Política de Privacidade'
          subtitle={'Versão ' + info.versao}
          style={styles.pageContentePoliticaPrivacidade}
        />
        <HeadingSection level={2} color='primary' title='1. Definições'>
          <p>Para melhor compreensão deste documento, nesta Política de Privacidade, consideram-se:</p>
          <br />
          <p>
            <b>Agentes de tratamento: </b>O controlador e o operador.
          </p>
          <br />
          <p>
            <b>Anonimização: </b>Utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio
            dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
          </p>
          <br />
          <p>
            <b>Autoridade Nacional: </b>Órgão da administração pública responsável por zelar, implementar e fiscalizar o
            cumprimento desta Lei em todo o território nacional.
          </p>
          <br />
          <p>
            <b>Banco de Dados: </b>Conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em
            suporte eletrônico ou físico.
          </p>
          <br />
          <p>
            <b>Consentimento: </b>Manifestação livre, informada e inequívoca pela qual o titular concorda com o
            tratamento de seus dados pessoais para uma finalidade determinada.
          </p>
          <br />
          <p>
            <b>Controlador: </b>Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
            referentes ao tratamento de dados pessoais.
          </p>
          <br />
          <p>
            <b>Dado Anonimizado: </b>Dado relativo a um titular que não possa ser identificado, considerando a
            utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.
          </p>
          <br />
          <p>
            <b>Dado Pessoal: </b>Informação relacionada a uma pessoa natural identificada ou identificável.
          </p>
          <br />
          <p>
            <b>Dado Pessoal Sensível: </b>Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente
            à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
          </p>
          <br />
          <p>
            <b>Encarregado: </b>Pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o
            controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
          </p>
          <br />
          <p>
            <b>Operador: </b>Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de
            dados pessoais em nome do controlador.
          </p>
          <br />
          <p>
            <b>Órgão de Pesquisa: </b>Órgão ou entidade da administração pública direta ou indireta ou pessoa jurídica
            de direito privado sem fins lucrativos, legalmente constituída sob as leis brasileiras e com sede e foro no
            País, que inclua em sua missão institucional ou em seu objetivo social ou estatutário a pesquisa básica ou
            aplicada de caráter histórico, científico, tecnológico ou estatístico.
          </p>
          <br />
          <p>
            <b>Titular: </b>Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.
          </p>
          <br />
          <p>
            <b>Transferência Internacional de Dados: </b>Transferência de dados pessoais para país estrangeiro ou
            organismo internacional do qual o país seja membro.
          </p>
          <br />
          <p>
            <b>Tratamento: </b>Toda operação realizada com dados pessoais, como as que se referem a coleta, produção,
            recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
            arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
            transferência, difusão ou extração.
          </p>
          <br />
          <p>
            <b>Uso Compartilhado de Dados: </b>Comunicação, difusão, transferência internacional, interconexão de dados
            pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no
            cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização
            específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes
            privados.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='2. Base Legal para Tratamento de Dados Pessoais'>
          <p>
            Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal nº 12.965 de 23 de abril de
            2014 (Marco Civil da Internet) e com a Lei Federal nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção
            de Dados Pessoais).
          </p>
          <br />
          <p>
            O site se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados Pessoais (LGPD) e a
            respeitar os princípios dispostos no Art. 6º de tal norma:
          </p>
          <br />
          <ol type='I' css={styles.lista}>
            <li>
              Finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao
              titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;
            </li>
            <li>
              Adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o
              contexto do tratamento;
            </li>
            <li>
              Necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com
              abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento
              de dados;
            </li>
            <li>
              Livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do
              tratamento, bem como sobre a integralidade de seus dados pessoais;
            </li>
            <li>
              Qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de
              acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
            </li>
            <li>
              Transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a
              realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e
              industrial;
            </li>
            <li>
              Segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos
              não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
              difusão;
            </li>
            <li>
              Prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados
              pessoais;
            </li>
            <li>
              Não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou
              abusivos;
            </li>
            <li>
              Responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e
              capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive,
              da eficácia dessas medidas.
            </li>
          </ol>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='3. Controlador'>
          <p>
            <b>
              A quem competem as decisões referentes ao tratamento de dados pessoais realizado no Prontuário Eletrônico
              do Cidadão (PEC) do Sistema e-SUS APS?
            </b>
          </p>
          <br />
          <p>
            A Lei Geral de Proteção de Dados Pessoais define como controlador, em seu Art. 5º, inciso VI, a pessoa
            natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de
            dados pessoais.
          </p>
          <br />
          <p>
            Além disso, a Autoridade Nacional de Proteção de Dados (ANPD), estabelece que há controladoria conjunta
            quando:{' '}
            <i>
              “Nas decisões comuns, duas ou mais entidades possuem uma intenção comum sobre as finalidades e meios de
              tratamento e tomam decisões em conjunto. Em contrapartida, nas decisões convergentes existem decisões
              distintas sendo tomadas, porém elas se complementam de tal forma que o tratamento não seria possível sem a
              participação de ambos os controladores”
            </i>
            , Guia Orientativo para Definições dos Agentes de Tratamento de Dados Pessoais e do Encarregado, publicado
            pela ANPD, pg. 14.
          </p>
          <br />
          <p>
            Para o Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, as decisões referentes ao tratamento de
            dados pessoais são de responsabilidade tanto do Ministério da Saúde, quanto dos <b>municípios</b> que aderem
            à respectiva estratégia, ou mesmo, quando for o caso, dos <b>estados</b>, exercendo, dessa forma,
            controladoria conjunta, uma vez que possuem poder de decisão sobre o tratamento de dados pessoais, há
            interesse mútuo, com base em finalidades próprias, sobre um mesmo tratamento e tomam decisões comuns ou
            convergentes sobre as finalidades e elementos essenciais do tratamento.
          </p>
          <br />
          <p>
            Endereço do Ministério da Saúde: Edifício Sede - Esplanada dos Ministérios, Bloco G, Brasília - DF,
            70058-900
          </p>
          <br />
          <p>
            E-mail: <Link href='mailto:aps@saude.gov.br'>aps@saude.gov.br</Link>
          </p>
          <br />
          <p>Telefone do Ministério da Saúde: (61) 3315-2425</p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='4. Operador'>
          <p>
            <b>Quem realiza o tratamento de dados (Operador)?</b>
          </p>
          <br />
          <p>
            A Lei Geral de Proteção de Dados Pessoais define como operador, em seu Art. 5º, inciso VII, a pessoa natural
            ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do
            controlador.
          </p>
          <br />
          <p>
            Para o Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, quem realiza o tratamento de dados
            pessoais em nome do controlador é o operador,{' '}
            <b>
              <i>no que tange ao desenvolvimento do sistema</i>
            </b>
            :
          </p>
          <br />
          <p>Nome: Laboratório Bridge</p>
          <br />
          <p>Endereço: Torre Flora - R. Lauro Linhares, 2055 - Sala 902 - Trindade, Florianópolis - SC, 88036-003.</p>
          <br />
          <p>
            E-mail: <Link href='mailto:contato@bridge.ufsc.br'>contato@bridge.ufsc.br</Link>
          </p>
          <br />
          <p>Telefone: (48) 3721-6190</p>
          <br />
          <p>
            Outros operadores de dados que atuem em nome dos controladores relacionados aos estados e municípios, serão
            informados aos titulares de dados pelos respectivos entes federativos.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='5. Encarregado'>
          <p>
            <b>
              Quem é o responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a
              Autoridade Nacional de Proteção de Dados (Encarregado)?
            </b>
          </p>
          <br />
          <p>
            A Lei Geral de Proteção de Dados Pessoais define como encarregado, em seu Art. 5º, inciso VIII, a pessoa
            indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares
            dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
          </p>
          <br />
          <p>
            O encarregado de dados do Controlador Ministério da Saúde e, consequentemente, para o Prontuário Eletrônico
            do Cidadão (PEC) do Sistema e-SUS APS, o responsável por atuar como canal de comunicação entre o
            controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados é a encarregada Adriana
            Macedo Marques, conforme a Portaria GM/MS nº 953, de 11 de maio de 2023.
          </p>
          <br />
          <p>
            O usuário poderá entrar em contato por meio do e-mail
            <Link href='mailto:encarregado@saude.gov.br'> encarregado@saude.gov.br</Link> ou{' '}
            <Link href='mailto:adriana.mmarques@saude.gov.br'>adriana.mmarques@saude.gov.br</Link> do Encarregado, para
            sanar quaisquer dúvidas sobre esta Política de Privacidade ou para obter mais informações sobre o tratamento
            dos dados realizado com fundamento na LGPD.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='6. Direitos do Titular de Dados Pessoais'>
          <p>
            <b>Quais são os direitos do titular de dados pessoais?</b>
          </p>
          <br />
          <p>
            O titular de dados pessoais possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados
            Pessoais (LGPD):
          </p>
          <br />
          <ul css={styles.lista}>
            <li>
              Direito de confirmação e acesso (Art. 18, incisos I e II): é o direito do titular de dados de obter do
              serviço a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e,
              se for esse o caso, o direito de acessar os seus dados pessoais.
            </li>
            <li>
              Direito de retificação (Art. 18, inciso III): é o direito de solicitar a correção de dados incompletos,
              inexatos ou desatualizados.
            </li>
            <li>
              Direito à limitação do tratamento dos dados (Art. 18, inciso IV): é o direito do titular de dados de
              limitar o tratamento de seus dados pessoais, podendo exigir a eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com o disposto na Lei Geral de Proteção de Dados Pessoais.
            </li>
            <li>
              Direito de oposição (Art. 18, § 2º): é o direito do titular de dados de, a qualquer momento, opor-se ao
              tratamento de dados por motivos relacionados com a sua situação particular, com fundamento em uma das
              hipóteses de dispensa de consentimento ou em caso de descumprimento ao disposto na Lei Geral de Proteção
              de Dados Pessoais.
            </li>
            <li>
              Direito de portabilidade dos dados (Art. 18, inciso V): é o direito do titular de dados de realizar a
              portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo
              com a regulamentação da autoridade nacional, observados os segredos comercial e industrial.
            </li>
            <li>
              Direito de não ser submetido a decisões automatizadas (Art. 20): o titular dos dados tem direito a
              solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais
              que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional,
              de consumo e de crédito ou os aspectos de sua personalidade.{' '}
            </li>
          </ul>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='7. Quais Dados são Tratados'>
          <p>
            A utilização de determinadas funcionalidades do Serviço pelo titular de dados pessoais dependerá do
            tratamento dos seguintes dados pessoais
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DO CIDADÃO PACIENTE</p>
          <br />
          <p>
            <b>Identificação: </b>Nome completo, nome social, telefone da residência, telefone de contato, telefone,
            número do prontuário, e-mail, CPF, CNS, Nº NIS (PIS/PASEP).
          </p>
          <br />
          <p>
            <b>Composição familiar: </b>Nome da mãe, Nome do pai, desconhece o nome completo da mãe/pai, possui
            referência familiar?, relação de parentesco com o responsável familiar e ocupação do responsável familiar,
            CPF do responsável familiar.
          </p>
          <br />
          <p>
            <b>Dados financeiros: </b>Renda familiar (salário mínimo), recebe algum benefício.
          </p>
          <br />
          <p>
            <b>Dados residenciais: </b>Endereço, domicílio, área, fora da área, microárea, tipo do imóvel, Situação de
            Moradia/Posse da terra, Localização, Tipo de domicílio, Em caso de área de produção rural: condição de posse
            e uso da terra, Nº de moradores, Nº de cômodos, Tipo de acesso ao domicílio, Disponibilidade de energia
            elétrica?, Material predominante na construção das paredes externas do seu domicílio, Abastecimento de água,
            Água para consumo no domicílio, Forma de escoamento do banheiro ou sanitário, Destino do lixo, Animais no
            domicílio?, Reside desde, Mudou-se, Nome da instituição de permanência, Cidadão é responsável familiar,
            saída do cidadão do cadastro.
          </p>
          <br />
          <p>
            <b>Características pessoais: </b>Idade, sexo, raça/cor, etnia, data de nascimento, local de nascimento,
            nacionalidade, naturalizado, estrangeiro, cidadão faleceu/óbito, data de óbito/Número da DO, portaria de
            naturalização, data de naturalização, país de nascimento, data de entrada no Brasil, município e UF de
            nascimento, estado civil, orientação sexual, identidade de gênero.
          </p>
          <br />
          <p>
            <b>Hábitos pessoais: </b>Está fumante?, Faz uso de álcool?, Faz uso de drogas?, Usa plantas medicinais?, Usa
            outras práticas integrativas e complementares?, Quantas vezes se alimenta ao dia?, Qual a origem da
            alimentação?, Tem acesso a higiene pessoal? Está em situação de rua?, É acompanhado por outra instituição?
            Se sim, indique qual(is)? Visita algum familiar com frequência? Se sim, qual é o grau de parentesco?,
            Marcadores de consumo alimentar, Tabagismo: Clínico/Auto referido/Não.
          </p>
          <br />
          <p>
            <b>Dados referentes à saúde ou à vida sexual: </b>Peso, altura, IMC, unidade responsável, tipo sanguíneo,
            equipe/INE responsável pelo cidadão, antecedentes, subjetivo (descrição em texto livre), motivo da consulta,
            objetivo (descrição em texto livre), DUM, DPP, antropometria, sinais vitais, glicemia, vacinação em dia?,
            resultados de exames, Aplicação, Transcrição de caderneta de imunobiológico, Aprazamento de imunobiológico,
            Grupo-alvo, status da vacinação, imunobiológico e dose, viajante, Resultados de Exames de Síndrome
            neurológica por Zika / Microcefalia, Avaliação (descrição em texto livre), Problemas e/ou condições
            avaliados neste atendimento, Alergias e reações adversas, Plano (descrição em texto livre), Histórico do
            plano de cuidado, Intervenções e/ou procedimentos clínicos realizados, Classificação de
            risco/vulnerabilidade, Prescrição de medicamentos, Solicitação de Exames, atestados, orientações,
            encaminhamentos, tipo de atendimento, atendimento compartilhado, procedimentos administrativos, ficha de
            notificação de caso suspeito, racionalidade em saúde, conduta, desfecho do atendimento, agendamento de
            consulta, turno, data do atendimento/cadastro, local de atendimento, justificativa, profissional que
            realizou o atendimento, escuta inicial/orientação?, Realização de procedimentos/pequenas cirurgias da Ficha
            de Procedimentos, Testes rápidos da Ficha de Procedimentos, Administração de Medicamentos da Ficha de
            Procedimentos, Avaliação Antropométrica da FAI, Tipo de aleitamento materno (se criança), Gestante,
            Puérpera, Doenças transmissíveis, Rastreamento de problema/condição avaliada, Triagem neonatal, Ficou em
            observação?, Cidadão em observação, NASF/Polo, Status de desenvolvimento da criança, Pré-natal, parto e
            nascimento da criança, Alterações fenotípicas da criança, atores de risco da criança, Marcos de
            desenvolvimento da criança, Pré-natal, parto e nascimento (atendimento da gestante), Data de atendimento,
            unidade responsável e turno, Horário de inicio e fim do atendimento, Motivo da visita, Desfecho da visita
            domiciliar, Dados de saúde autorreferidos, Acompanhamento - Visita domiciliar, Controle ambiental /
            vetorial, Busca ativa - Visita domiciliar, Procedência (procedência do encaminhamento para AD),
            Condição(ões) avaliada(s), CID10 (principal), CID10 (secundário 1), CID10 (secundário 2), Conclusão
            (conclusão da avaliação de elegibilidade), Conclusão/Destino (conclusão/Destino da avaliação de
            elegibilidade), Cuidador, Modalidade Atenção Domiciliar, Especifique (Campo para especificar a procedência),
            Condição(ões) avaliada(s) - CID 10 e CIAP2, Procedimentos FAD, Conduta/Desfecho atendimento domiciliar, Data
            de admissão (data de admissão em AD), Vigilância em Saúde Bucal, Necessidade de prótese inferior/superior,
            Possui aparelho, Possui contenção, Prótese total superior, Prótese total inferior, Evoluções odontológicas,
            Fornecimento, Programa saúde na escola, Atividade, Temas para reunião, Público alvo, Temas para saúde,
            Práticas em saúde, Idade Gestacional, Lembrete, Comunicante Hanseníase, Ações (Atendimento Domiciliar),
            Observações (Escuta Inicial), Registro anterior (Antropometria, sinais vitais e glicemia capilar), Registro
            anterior (Medição anterior da criança), Subtipo de atendimento, Relatório operacional de crianças menores de
            5 anos, Data do último atendimento, Data último atendimento de Pré-Natal, Puerpério, Odontológica e Visita
            do ACS, Total de gestantes/puérperas na microárea/Total de gestantes/puérperas fora do território/Total
            geral de gestantes/puérperas, Data último Atendimento Risco cardiovascular, Data última consulta
            odontológica, Data última visita ACS, Data último rastreamento risco cardiovascular.
          </p>
          <br />
          <p>
            <b>Associações: </b>Participa de algum grupo comunitário?, É membro de povo ou comunidade tradicional?.
          </p>
          <br />
          <p>
            <b>Educação e treinamento: </b>Frequenta escola ou creche, Qual é o curso mais elevado que frequentou?,
            escolaridade.
          </p>
          <br />
          <p>
            <b>Profissão e emprego: </b>Ocupação, Situação no mercado de trabalho.
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DO CUIDADOR</p>
          <br />
          <p>
            <b>Identificação: </b>Nome completo, data de nascimento, CPF, CNS.
          </p>
          <br />
          <p>
            <b>Composição familiar: </b>Relação com o cidadão.
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DO ACOMPANHANTE</p>
          <br />
          <p>
            <b>Identificação do acompanhante no atendimento: </b>Nome completo.
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DO RESPONSÁVEL FAMILIAR</p>
          <br />
          <p>
            <b>Identificação do responsável pela residência: </b>Nome completo, e-mail, telefone, CPF, CNS.
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DO RESPONSÁVEL PELA RESIDÊNCIA</p>
          <br />
          <p>
            <b>Profissão e emprego: </b>Cargo na instituição
          </p>
          <br />
          <br />
          <p css={styles.subtituloDadosTratados}>DOS PROFISSIONAIS DE SAÚDE</p>
          <br />
          <p>
            <b>Identificação do profissional: </b>Nome completo, e-mail, telefone, CPF, CNS, No do conselho regional,
            sexo, data de nascimento, endereço.
          </p>
          <br />
          <p>
            <b>Identificação do profissional da saúde externo à UBS de instalação do PEC: </b>Nome completo, CPF.
          </p>
          <br />
          <p>
            <b>Educação e treinamento do profissional com lotação de estágio: </b>Nível do curso do estágio, Formação
            técnica, Instituição de ensino.
          </p>
          <br />
          <p>
            <b>Profissão e emprego: </b>Lotação profissional, CBO do profissional participante da atividade.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='8. Qual a Finalidade de Tratamento dos Dados'>
          <p>Execução de políticas públicas em saúde, respaldadas no item 3 dos termos de uso.</p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='9. Compartilhamento de Dados'>
          <p>
            O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS sistema faz os seguintes compartilhamentos:
          </p>
          <br />
          <ol type='I' css={styles.lista}>
            <li>Dos dados do e-SUS APS com o Centralizador Nacional, do Datasus/Ministério da Saúde;</li>
            <li>
              Dos dados de atendimento individual com a RNDS;
              <ol type='a' css={styles.lista}>
                <br />
                <li>
                  Dados do cidadão: CPF, CNS, Subjetivo (descrição em texto livre), Observação (descrição em texto
                  livre), Avaliação (descrição em texto livre), problema e condição identificada, diagnósticos CID e/ou
                  CIAP2, observações dos CID e/ou CIAP2, altura (cm), circunferência abdominal (cm), DUM, idade
                  gestacional, perímetro cefálico (cm), peso (kg), pressão arterial (mmHg), abortos, gestas prévias,
                  tipo de aleitamento, grau de certeza das alergias/reações adversas, categoria do agenda causador,
                  criticidade, agente ou substância específica, manifestações, data, observações, recomendações da
                  prescrição eletrônica, dose, intervalo, turno, frequência da dose, via de administração, forma
                  farmacêutica, quantidade, início do tratamento, conclusão do tratamento, unidade de fornecimento.{' '}
                </li>
                <li>
                  Também são enviados dados referentes à vacinação: Estratégia, Data da administração do imunobiológico,
                  Imunobiológico, Fabricante, Lote, Dose, Via de administração, Local de aplicação, Estabelecimento de
                  saúde (código CNES), Grupo de atendimento, Condição maternal, data de validade da vacina (transcrição
                  de caderneta);
                </li>
                <li>
                  Dados do profissional: CNS, CNES da lotação, CBO da lotação do profissional, Equipe da lotação.{' '}
                </li>
                <br />
              </ol>
            </li>
            <li>
              Dos seguinte dados com o paciente, via e-mail cadastrado, caso haja prescrição eletrônica: Informações do
              estabelecimento de saúde onde foi realizado o atendimento: Estado, Município, Endereço, Telefone 1,
              Telefone 2; Informações do prescritor: Nome, Conselho de classe, Estado emissor, Número de registro no
              conselho, CBO; Identificação do cidadão: Nome do cidadão, Nome social, CNS do cidadão, Endereço do
              cidadão; Medicamentos incluídos na prescrição, com as informações: Aplicação / Uso, Nome do medicamento,
              Recomendações, Posologia, Unidade, Quantidade; Data do atendimento, Data e hora da impressão do documento,
              Nome do profissional responsável pela impressão;
            </li>
            <li>
              Dos dados específicos e pertinentes os quais são definidos pelos profissionais de saúde usuários do
              Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, com outro profissional da saúde, no caso de
              teleinterconsulta.
            </li>
          </ol>
          <br />
          <p>
            Demais compartilhamentos de dados, que são feitos de outras formas, utilizando dados do Prontuário
            Eletrônico do Cidadão (PEC) do Sistema e-SUS APS - mas que não são feitos pelo próprio Sistema como
            supramencionado - e principalmente quando os estados e municípios operarem qualquer tipo de compartilhamento
            de dados com outras entidades, organizações ou instituições públicas ou privadas devem seguir as diretrizes
            da ANPD.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='10. Transferência Internacional de Dados'>
          <p>O Serviço não faz transferência internacional de dados.</p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='11. Segurança dos Dados'>
          <p>
            O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS se compromete a aplicar as medidas técnicas e
            organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição,
            perda, alteração, comunicação ou difusão de tais dados.
          </p>
          <br />
          <p>
            Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os
            custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os
            direitos e liberdades do usuário.
          </p>
          <br />
          <p>
            O serviço utiliza criptografia para que os dados sejam transmitidos de forma segura e confidencial, de
            maneira que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira
            totalmente cifrada ou encriptada.
          </p>
          <br />
          <p>
            No entanto, o serviço se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque
            de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a
            terceiro. O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS compromete- se, ainda, a comunicar o
            usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa
            lhe causar um alto risco para seus direitos e liberdades pessoais.
          </p>
          <br />
          <p>
            A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a
            destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos,
            conservados ou sujeitos a qualquer outro tipo de tratamento.
          </p>
          <br />
          <p>
            Por fim, o serviço se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos
            limites legais.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='12. Cookies'>
          <p>
            Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam
            armazenados, com informações relacionadas à navegação do site.
          </p>
          <br />
          <p>
            Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que
            o servidor do serviço possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o
            dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site.
          </p>
          <br />
          <p>
            É importante ressaltar que nem todo cookie contém dados pessoais do usuário, já que determinados tipos de
            cookies podem ser utilizados somente para que o serviço funcione corretamente.{' '}
          </p>
          <br />
          <p>
            As informações eventualmente armazenadas em cookies também são consideradas dados pessoais. Todas as regras
            previstas nesta Política de Privacidade também são aplicáveis aos referidos cookies.
          </p>
          <br />
          <p>O Prontuário Eletrônico do Cidadão utiliza os seguintes cookies:</p>
          <br />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell css={[styles.col25, styles.boldTableTitle]}>
                  <p>Nome do Cookie</p>
                </TableCell>
                <TableCell css={[styles.col50, styles.boldTableTitle]}>
                  <p>Finalidade da utilização</p>
                </TableCell>
                <TableCell css={[styles.col25, styles.boldTableTitle]}>
                  <p>Duração</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell css={styles.col25}>
                  <p>JSESSIONID</p>
                </TableCell>
                <TableCell css={styles.col50}>
                  <p>Manter a sessão do usuário do PEC durante a utilização do sistema.</p>
                </TableCell>
                <TableCell css={styles.col25}>
                  <p>30 minutos</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell css={styles.col25}>
                  <p>XSRF-TOKEN</p>
                </TableCell>
                <TableCell css={styles.col50}>
                  <p>Proteção contra Cross-site Request Forgery (CSRF). </p>
                </TableCell>
                <TableCell css={styles.col25}>
                  <p>30 minutos</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='13. Mudanças na Política de Privacidade'>
          <p>
            A presente versão {info.versao} desta Política de Privacidade foi atualizada pela última vez em:{' '}
            {dataAtualizacao}.
          </p>
          <br />
          <p>
            O editor se reserva o direito de modificar, a qualquer momento, o site as presentes normas, especialmente
            para adaptá-las às evoluções do serviço inserir o Nome do Serviço, seja pela disponibilização de novas
            funcionalidades, seja pela supressão ou modificação daquelas já existentes.
          </p>
          <br />
          <p>
            Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão
            pela qual se convida o usuário a consultar periodicamente esta seção.
          </p>
        </HeadingSection>
        {faltaAceitarTermos && (
          <Button kind='primary' onClick={handleAceitarTermos}>
            Declaro que li e estou ciente dos termos de uso
          </Button>
        )}
      </VFlow>
      {faltaAceitarTermos && (
        <FormFooter>
          <LogoutLinkWrapper>
            <Button>Sair</Button>
          </LogoutLinkWrapper>
        </FormFooter>
      )}
      {!session.data && (
        <FormFooter>
          <ButtonLink
            style={css`
              padding-left: 2rem;
              padding-right: 2rem;
            `}
            to='/'
          >
            Voltar
          </ButtonLink>
        </FormFooter>
      )}
    </PageContent>
  )
}

const createStyles = (faltaAceitarTermos: boolean) => ({
  subtituloDadosTratados: css`
    font-weight: bold;
    color: #0069d0;
  `,
  pageContentTermosUso: css`
    margin-top: 2rem;
    margin-bottom: ${!faltaAceitarTermos && '2rem'};
  `,
  vflowPageContent: css`
    width: 640px;
    text-align: left;
    font-size: 0.875rem;
  `,
  col25: css`
    width: 25%;
    padding: 0.5rem 1rem;
  `,
  col33: css`
    width: 33.33%;
    padding: 0.5rem 1rem;
    text-align: center;
  `,
  col50: css`
    width: 50%;
    padding: 0.5rem 1rem;
  `,
  lista: css`
    margin: 0 !important;
    line-height: 1.5;
  `,
  boldTableTitle: css`
    font-weight: bold;
  `,
  pageContentePoliticaPrivacidade: css`
    margin-top: 4rem;
  `,
})
