/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { vacinadoExterior } from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { HistoricoVacinacaoPecModel } from './HistoricoVacinacaoPec'

export default function HistoricoVacinacaoPecTableItem(historico: HistoricoVacinacaoPecModel) {
  const imunoEstrategiaDose = `${historico.imunobiologico.nome}${
    historico.isRegistroAnterior ? '' : `, ${historico.estrategiaVacinacao?.nome.capitalize()}`
  }, ${historico.doseImunobiologico.nome.capitalize()}`

  const loteFab =
    !historico.loteImunobiologico?.lote && !historico.loteImunobiologico?.fabricante?.nome
      ? '-'
      : `${historico.loteImunobiologico?.lote ?? '-'}/${
          historico.loteImunobiologico?.fabricante?.nome.titleCase() ?? '-'
        }`

  const isCovid = !!covidImunosRecord[historico.imunobiologico.id]
  const dataAplicacao = formatDate(historico.dataAplicacao)
  const dataValidade =
    !historico.isRegistroAnterior &&
    historico.loteImunobiologico?.validade &&
    formatDate(historico.loteImunobiologico.validade)

  return historico.isRegistroAnterior ? (
    <Grid
      style={css`
        padding: 0.5rem 0;
      `}
    >
      <Cell size={4}>
        <InfoLabel title='Imunobiológico/Dose'>{imunoEstrategiaDose}</InfoLabel>
      </Cell>
      <Cell size={4}>
        <InfoLabel title='Lote/Fabricante'>{loteFab}</InfoLabel>
      </Cell>
      <Cell size={2}>
        <InfoLabel title='Data de aplicação'>{dataAplicacao}</InfoLabel>
      </Cell>
      {isCovid && (
        <Fragment>
          <Cell size={2}>
            <InfoLabel title='Vacinado no exterior'>{vacinadoExterior(historico.isAplicadoExterior)}</InfoLabel>
          </Cell>
          {historico.estrategiaVacinacao && (
            <Cell size={12}>
              <HLabel title='Estratégia:'>{historico.estrategiaVacinacao.nome.titleCase()}</HLabel>
            </Cell>
          )}
        </Fragment>
      )}
      {historico.observacoes && (
        <Cell
          size={12}
          style={css`
            word-break: break-all;
          `}
        >
          <HLabel title='Observações:'>{historico.observacoes}</HLabel>
        </Cell>
      )}
    </Grid>
  ) : (
    <Grid
      style={css`
        padding: 0.5rem 0;
      `}
    >
      <Cell size={5}>
        <InfoLabel title='Imunobiológico/Estratégia/Dose'>{imunoEstrategiaDose}</InfoLabel>
        {!!historico.grupoAtendimento && (
          <HLabel title='Grupo de atendimento:'>{historico.grupoAtendimento.nome.capitalize()}</HLabel>
        )}
      </Cell>
      <Cell size={4}>
        <InfoLabel title='Lote/Fabricante'>{loteFab}</InfoLabel>
        {!!dataValidade && <HLabel title='Data de validade:'>{dataValidade}</HLabel>}
      </Cell>
      <Cell size={3}>
        <InfoLabel title='Data de aplicação'>{dataAplicacao}</InfoLabel>
      </Cell>
      <Cell size={5}>
        <InfoLabel title='Via de administração'>{historico.viaAdministracao.nome.capitalize()}</InfoLabel>
      </Cell>
      <Cell size={4}>
        <InfoLabel title='Local de aplicação'>{historico.localAplicacaoVacinacao.nome.capitalize()}</InfoLabel>
      </Cell>
      {historico.observacoes && (
        <Cell
          size={12}
          style={css`
            word-break: break-all;
          `}
        >
          <HLabel title='Observações:'>{historico.observacoes}</HLabel>
        </Cell>
      )}
    </Grid>
  )
}
