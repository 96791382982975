/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { RichTextField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { Fragment } from 'react'
import { Field } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import {
  DiscussaoCasoSolicitanteFieldsModel,
  DiscussaoCondutaViewEnum,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import { discussaoCasoSolicitanteValidator } from './validator-discussaocaso'

interface DiscussaoCasoSolicitanteFieldsProps {
  path: MetaPath<DiscussaoCasoSolicitanteFieldsModel>
}

export const DiscussaoCasoSolicitanteFields = (props: DiscussaoCasoSolicitanteFieldsProps) => {
  const { path } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Field<DiscussaoCasoSolicitanteFieldsModel>
      name={path.absolutePath()}
      validate={discussaoCasoSolicitanteValidator}
      render={() => {
        return (
          <Fragment>
            <RadioFieldContainer
              name={path.condutaGroup}
              value={DiscussaoCondutaViewEnum.NOVA_PERGUNTA}
              style={styles.radioFieldContainer}
            >
              <Text fontWeight='bold'>Devolutiva de discussão do caso clínico</Text>
            </RadioFieldContainer>
            <VFlow vSpacing={0.5}>
              <div css={styles.paper}>
                <VFlow>
                  <Text>
                    Insira uma observação caso ainda existam dúvidas ou questionamentos sobre a discussão de caso do
                    cidadão.
                  </Text>
                  <RichTextField name={path.pergunta} label='Observações' maxLength={1000} required />
                </VFlow>
              </div>
            </VFlow>
          </Fragment>
        )
      }}
    />
  )
}

const createStyles = (theme: Theme) => ({
  radioFieldContainer: css`
    background-color: ${theme.pallete.gray.c90};
  `,
  paper: css`
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 0 0 2px 2px;
    margin-top: -1px;
  `,
})
