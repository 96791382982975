import { DateRange } from 'bold-ui'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import {
  RELATORIOS_GERENCIAIS_DIA_OPCOES_MAPPING,
  RELATORIOS_GERENCIAIS_MES_OPCOES_MAPPING,
  RELATORIOS_GERENCIAIS_SEMANA_OPCOES_MAPPING,
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisUnidadeMedidaTempoEnum,
} from '../../common/model'
import { RelatoriosGerenciaisPeriodoFormModel } from './RelatoriosGerenciaisPeriodoForm'

export const createRelatoriosGerenciaisPeriodoFormCalculator = (
  name: MetaRoot<RelatoriosGerenciaisPeriodoFormModel>
): Calculation => ({
  field: [name.opcaoSelecionada.absolutePath(), name.unidade.absolutePath()],
  updates: {
    [name.periodo.absolutePath()]: (
      _,
      { opcaoSelecionada, unidade, periodo }: RelatoriosGerenciaisPeriodoFormModel
    ) => {
      const now = new Date()
      if (opcaoSelecionada !== RelatoriosGerenciaisOpcoesPeriodoEnum.OUTRO) {
        switch (unidade) {
          case RelatoriosGerenciaisUnidadeMedidaTempoEnum.DIA:
            return calculatePeriodForDay(now, opcaoSelecionada)

          case RelatoriosGerenciaisUnidadeMedidaTempoEnum.SEMANA:
            return calculatePeriodForWeek(now, opcaoSelecionada)

          case RelatoriosGerenciaisUnidadeMedidaTempoEnum.MES:
            return calculatePeriodForMonth(now, opcaoSelecionada)
        }
      } else return periodo
    },
  },
})

type RelatoriosGerenciaisOpcoesCalculaveis =
  | RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA
  | RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA
  | RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA

export const calculatePeriodForMonth = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastMonth = subMonths(now, 1)
  const month = subMonths(now, RELATORIOS_GERENCIAIS_MES_OPCOES_MAPPING.get(opcaoSelecionada).quantityToSubtract)

  return {
    startDate: startOfMonth(month),
    endDate: endOfMonth(lastMonth),
  }
}

export const calculatePeriodForWeek = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastWeek = subWeeks(now, 1)
  const week = subWeeks(now, RELATORIOS_GERENCIAIS_SEMANA_OPCOES_MAPPING.get(opcaoSelecionada).quantityToSubtract)

  return {
    startDate: startOfWeek(week),
    endDate: endOfWeek(lastWeek),
  }
}

export const calculatePeriodForDay = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const day = subDays(now, RELATORIOS_GERENCIAIS_DIA_OPCOES_MAPPING.get(opcaoSelecionada).quantityToSubtract)

  return {
    startDate: startOfDay(day),
    endDate: endOfDay(now),
  }
}
