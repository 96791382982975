import { DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import React from 'react'

import { CuidadoCompartilhadoPlanoTableItemModel } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoTableActionsProps {
  row: CuidadoCompartilhadoPlanoTableItemModel
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
}

export const CuidadoCompartilhadoTableActions = (props: CuidadoCompartilhadoTableActionsProps) => {
  const { row, onEdit, onDelete } = props

  const alert = useAlert()

  const handleDelete = (id: ID) =>
    confirm({
      title: 'Deseja excluir o cuidado compartilhado?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        onDelete(id)
        alert('success', 'Cuidado compartilhado excluído com sucesso')
      },
    })()

  return (
    row.isRegistradoAgora &&
    (onEdit || onDelete) && (
      <HFlow alignItems='center' justifyContent='flex-end'>
        <Tooltip text='Mais opções'>
          <DropdownButton stopPropagationOnClick>
            {onEdit && (
              <DropdownItem type='normal' onClick={() => onEdit(row.cacheId)}>
                Editar
              </DropdownItem>
            )}

            {onDelete && (
              <DropdownItem type='danger' onClick={() => handleDelete(row.cacheId)}>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Icon icon='trashOutline' />
                  Excluir
                </HFlow>
              </DropdownItem>
            )}
          </DropdownButton>
        </Tooltip>
      </HFlow>
    )
  )
}
