import { Button, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { usePrinter } from 'components/print'
import { PrinterType } from 'components/print/model'
import { useAuditaImpressaoAtestadoMutation, useLoadAtestadoByIdLazyQuery } from 'graphql/hooks.generated'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { OBSERVACAO_DISABLE_EDIT_AND_REMOVE_ATESTADOS_TOOLTIP_TEXT } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'

import { grupoCboAtestado } from '../../acessos'
import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import AtestadoEmBrancoFormModal from '../em-branco/AtestadoEmBrancoFormModal'
import { ImpressaoAtestado } from '../impressao/ImpressaoAtestado'
import LicencaMaternidadeFormModal from '../licenca-maternidade/LicencaMaternidadeFormModal'
import AtestadoPadraoFormModal from '../padrao/AtestadoPadraoFormModal'
import { RegistroLista } from './AtestadosTable'
import AtestadosVisualizacaoModal from './AtestadosVisualizacaoModal'

export interface AtestadoTableButtonsProps {
  registro: RegistroLista
  cidadao: CidadaoAtestado
  atestadoRecente?: AtestadoModel
  atendimentoId?: ID
  prontuarioId: ID
  update(index: number, value: AtestadoModel): void
  remove(index: number): void
  readOnly: boolean
}

export default function AtestadoTableButtons(props: AtestadoTableButtonsProps) {
  const { registro, cidadao, atestadoRecente, update, remove, atendimentoId, prontuarioId, readOnly } = props

  const { printPDF } = usePrinter()
  const { data: session, hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })
  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const [auditAtestadoMutation] = useAuditaImpressaoAtestadoMutation()

  const hasPermissionImprimirAtestado = hasCboAuth(grupoCboAtestado.imprimir)

  const [isModalVisualizacaoOpen, setModalVisualizacaoOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(
    atestadoRecente,
    '',
    OBSERVACAO_DISABLE_EDIT_AND_REMOVE_ATESTADOS_TOOLTIP_TEXT
  )

  const nomeCidadaoFormatted = !cidadao.nomeSocial ? cidadao.nome : `${cidadao.nomeSocial} - (${cidadao.nome})`

  const [executeQuery, { loading }] = useLoadAtestadoByIdLazyQuery({
    onCompleted: (data) => {
      const infoProfissional: PrinterType = {
        acesso: data?.atestado.atendimentoProfissional.lotacao,
        profissional: data?.atestado.atendimentoProfissional.lotacao.profissional,
      }

      const docBody = ImpressaoAtestado({
        tipoAtestado: data?.atestado.tipo,
        content: data?.atestado.descricao,
        dataAtendimento: data?.atestado.atendimentoProfissional.iniciadoEm,
        nomeCidadao: nomeCidadaoFormatted,
        session: infoProfissional,
      })

      printPDF(
        {
          docBody,
          documentTitle: 'Atestado-' + (cidadao.nomeSocial || cidadao.nome),
          session: infoProfissional,
          showSignature: false,
        },
        handleRejection
      )
      auditAtestadoMutation({ variables: { prontuarioId, atendimentoId, atestadoId: data?.atestado.id } })
    },
  })

  const handleExcluir = () => {
    const isLicencaMaternidade = TipoAtestadoEnum.LICENCA_MATERNIDADE === atestadoRecente.tipo
    const titleConfirm = isLicencaMaternidade ? 'Deseja excluir a licença maternidade?' : 'Deseja excluir o atestado?'
    const alertSucess = isLicencaMaternidade
      ? 'Licença maternidade excluída com sucesso'
      : 'Atestado excluído com sucesso'
    return confirm({
      title: titleConfirm,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        remove(registro.id)
        alert('success', alertSucess)
      },
    })()
  }

  const handlePrint = () => {
    if (!registro.recente) {
      executeQuery({
        variables: { id: registro.id },
      })
    } else {
      const docBody = ImpressaoAtestado({
        tipoAtestado: atestadoRecente.tipo,
        content: atestadoRecente.descricao,
        dataAtendimento: registro.atendimentoProfissional.iniciadoEm,
        nomeCidadao: nomeCidadaoFormatted,
        session,
      })

      printPDF(
        {
          docBody,
          documentTitle: 'Atestado-' + (cidadao.nomeSocial || cidadao.nome),
          showSignature: false,
        },
        handleRejection
      )
      auditAtestadoMutation({ variables: { prontuarioId, atendimentoId } })
    }
  }

  const onSubmitUpdate = (value: AtestadoModel) => {
    update(registro.id, value)
  }

  const EditModal = () => {
    if (atestadoRecente.tipo === TipoAtestadoEnum.EM_BRANCO) {
      return (
        <AtestadoEmBrancoFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          initialValues={atestadoRecente}
        />
      )
    }

    if (atestadoRecente.tipo === TipoAtestadoEnum.LICENCA_MATERNIDADE) {
      return (
        <LicencaMaternidadeFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          cidadao={cidadao}
          initialValues={atestadoRecente}
        />
      )
    }

    if (atestadoRecente.tipo === TipoAtestadoEnum.PADRAO) {
      return (
        <AtestadoPadraoFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          cidadao={cidadao}
          initialValues={atestadoRecente}
        />
      )
    }
  }

  return (
    <>
      <HFlow justifyContent='flex-end' hSpacing={0}>
        <Tooltip text='Visualizar'>
          <Button size='small' skin='ghost' onClick={() => setModalVisualizacaoOpen(true)} loading={loading}>
            <Icon icon='zoomOutline' />
          </Button>
        </Tooltip>
        {hasPermissionImprimirAtestado && (
          <Tooltip text='Imprimir'>
            <Button size='small' skin='ghost' onClick={handlePrint} loading={loading}>
              <Icon icon='printerOutline' />
            </Button>
          </Tooltip>
        )}

        {!readOnly && (
          <Tooltip text={tooltipText}>
            <DropdownButton disabled={!registro.recente || isEditable}>
              <DropdownItem
                onClick={() => {
                  setEditModalOpen(true)
                }}
              >
                Editar
              </DropdownItem>
              <DropdownItem onClick={handleExcluir} type='danger'>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Icon icon='trashOutline' />
                  Excluir
                </HFlow>
              </DropdownItem>
            </DropdownButton>
          </Tooltip>
        )}
      </HFlow>

      {isModalVisualizacaoOpen && (
        <AtestadosVisualizacaoModal
          registro={registro}
          descricaoAtestadoRecente={atestadoRecente.descricao}
          tipoAtestadoRecente={atestadoRecente.tipo}
          handlePrint={handlePrint}
          handleModalClose={() => setModalVisualizacaoOpen(false)}
          nomeCidadao={nomeCidadaoFormatted}
        />
      )}

      {isEditModalOpen && <EditModal />}
    </>
  )
}
