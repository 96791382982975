import {
  CiapCidModel,
  CiapCidSelectModel,
  isCiap,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'

export const convertCiapCidToBIFiltroCiapCid = (ciapsCids: CiapCidSelectModel[]): Set<string> => {
  return new Set<string>(
    ciapsCids.map((ciapOuCid) =>
      isCiap(ciapOuCid) ? convertCiapToBIFiltroCiap(ciapOuCid) : convertCidToBIFiltroCid(ciapOuCid)
    )
  )
}

export const convertGrupoCondicoesPrioritariosToCiapsCids = (
  grupos: GrupoCondicaoSelectModel[]
): CiapCidSelectModel[] => {
  const ciapsCids: CiapCidSelectModel[] = []
  const ciaps = grupos.flatMap((grupo) => grupo.ciaps)
  const cids = grupos.flatMap((grupo) => grupo.cids)
  return ciapsCids.concat(ciaps, cids)
}

const convertCiapToBIFiltroCiap = (ciap: CiapCidModel): string => `Ciap-${ciap.codigo}`
const convertCidToBIFiltroCid = (cid: CiapCidModel): string => `Cid10-${cid.codigo}`
