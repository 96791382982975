import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { emptyArray } from 'util/array'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'
import {
  CuidadoCompartilhadoPlanoQueryModel,
  CuidadoCompartilhadoPlanoTableItemModel,
} from '../model-cuidadocompartilhado'

export const joinCuidadosCompartilhado = (
  valoresAtuais: CuidadoCompartilhadoPlanoModel[],
  valoresHistorico: CuidadoCompartilhadoPlanoQueryModel[],
  lotacaoAtual: CuidadoCompartilhadoPlanoTableItemModel['lotacaoExecutante'],
  atendIniciadoEm: Instant
): CuidadoCompartilhadoPlanoTableItemModel[] => {
  return [
    ...(valoresAtuais || emptyArray).map((valorAtual) =>
      convertValorAtualToJoined(valorAtual, lotacaoAtual, atendIniciadoEm)
    ),
    ...(valoresHistorico?.map(convertValorHistoricoToJoined) ?? emptyArray),
  ]
}

const convertValorAtualToJoined = (
  valorAtual: CuidadoCompartilhadoPlanoModel,
  lotacaoAtual: CuidadoCompartilhadoPlanoTableItemModel['lotacaoExecutante'],
  atendIniciadoEm: Instant
): CuidadoCompartilhadoPlanoTableItemModel => ({
  cacheId: valorAtual._id,
  dataInicioAtendimento: atendIniciadoEm,
  lotacaoExecutante: {
    id: valorAtual.lotacao.id,
    cbo: valorAtual.lotacao.cbo,
    profissional: valorAtual.lotacao.profissional,
  },
  cidadaoAceitaAtendTic: valorAtual.cidadaoAceitaAtendTic === SimNaoEnum.SIM ? true : false,
  lotacaoSolicitante: {
    id: lotacaoAtual.id,
    cbo: lotacaoAtual.cbo,
    profissional: lotacaoAtual.profissional,
  },
  prioridade: valorAtual.prioridade,
  discussao: valorAtual.discussao,
  ciap: valorAtual.problemasECondicoes?.ciap,
  cid10: valorAtual.problemasECondicoes?.cid10,
  isRegistradoAgora: true,
})

const convertValorHistoricoToJoined = (
  valorHistorico: CuidadoCompartilhadoPlanoQueryModel
): CuidadoCompartilhadoPlanoTableItemModel => ({
  dataInicioAtendimento: valorHistorico.iniciadoEm,
  lotacaoExecutante: valorHistorico.lotacaoExecutante,
  lotacaoSolicitante: valorHistorico.lotacaoSolicitante,
  prioridade: valorHistorico.classificacaoRisco,
  discussao: valorHistorico.discussao,
  ciap: valorHistorico.ciap,
  cid10: valorHistorico.cid10,
  isRegistradoAgora: false,
  cidadaoAceitaAtendTic: valorHistorico.cidadaoAceitaAtendTic,
})
