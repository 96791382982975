import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV45() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 4.5.15'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível redefinir ou criar senha de alguns profissionais.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.13'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Alteração do nome da funcionalidade "Registro anterior" no registro de vacinação para "Transcrição de
            caderneta", essa alteração foi feita para destacar que essa funcionalidade serve apenas para atualização da
            caderneta e não gera produção como ocorre no registro tardio.
          </Text>
          <Text component='li'>
            Correção de cenário que causava erro na contagem no Relatório de produção de vacinação ao utilizar o filtro
            personalizado "Vacinação".
          </Text>
          <Text component='li'>
            Inclusão dos procedimentos SIGTAP "0202031349 - Teste de biologia molecular para a detecção de marcadores de
            Mycobacterium leprae, em amostras de biópsia de pele ou de nervos", "0202031357 - Teste qualitativo in
            vitro, para detecção de Mycobacterium leprae resistente à rifampicina e/ou dapsona e/ou ofloxacino, em
            pacientes acometidos por hanseníase e com suspeita de resistência a antimicrobianos" e "0214010171- Teste
            rápido para detecção de anticorpos IgM anti-Mycobacterium leprae".
          </Text>
          <Text component='li'>
            Alteração na Ficha e no Atendimento de vacinação, onde agora se torna obrigatório o preenchimento do campo
            "CPF/CNS do cidadão".
          </Text>
          <Text component='li'>
            Adição de alerta de vacinas não recomendadas para gestantes para as doses dos imunobiológicos contra a
            Covid-19.
          </Text>
          <Text component='li'>
            Alteração no formato de primeiro acesso ao sistema para não ser concedido automaticamente na importação do
            CNES. Para liberar acesso ao sistema, um coordenador ou outro profissional autorizado deverá utilizar a
            opção "Redefinir senha" no módulo de Profissionais.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.12'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Inclusão dos procedimentos SIGTAP "0301070210 - Reabilitação de Pacientes Pós Covid-19" e "0301070229 -
            Reabilitação Cardiorrespiratória de Pacientes Pós Covid-19".
          </Text>
          <Text component='li'>
            Ajustes na vacina de Covid-19 Sinovac - Coronavac inativada. Agora é possível realizar aplicações e
            aprazamentos com este imunobiológico.
          </Text>
          <Text component='li'>
            Inclusão do grupo de atendimento "Pessoas de 3 a 4 anos" para registros de vacinação.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.11'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde não estava sendo possível aplicar o filtro "Ver agendamentos anteriores" na tela de
            agendamentos do cidadão.
          </Text>
          <Text component='li'>
            Correção de cenário em que os registros no histórico não apresentavam o nome de alguns profissionais
            cadastrados na instalação.
          </Text>
          <Text component='li'>Ajustes de desempenho nas impressões dos Relatórios de atendimento individual.</Text>
          <Text component='li'>Inclusão do campo "Observações" nas reservas na agenda.</Text>
          <Text component='li'>
            Correção no módulo da Agenda do profissional, onde não estava sendo possível navegar no calendário caso a
            agenda do profissional fosse inativada.
          </Text>
          <Text component='li'>
            Ajustes na apresentação dos agendamentos e das reservas na agenda dos profissionais que possuem lotações
            inativas.
          </Text>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível imprimir alguns Atendimentos de pré-natal de cidadãos
            unificados.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.10'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Correção de cenário onde a conexão com o serviço do CADSUS estava inoperante.</Text>
          <Text component='li'>
            Inclusão da dose de reforço (REF) para o imunobiológico COV19 Coronavac - Sinovac/Butantan.
          </Text>
          <Text component='li'>
            Correção de erro onde não era possível finalizar alguns atendimentos de vacinação com registros anteriores
            de vacinação.
          </Text>
          <Text component='li'>Atualização da tabela INEP conforme o Censo 2021.</Text>
          <Text component='li'>
            Adicionadas as funcionalidades de visualização e paginação nos agendamentos do cidadão, além da exibição da
            justificativa e do responsável pelo cancelamento dos agendamentos.
          </Text>
          <Text component='li'>
            Ajustes na tela da agenda onde, em monitores com resoluções pequenas, o nome do cidadão não era apresentado
            corretamente.
          </Text>
          <Text component='li'>
            Inclusão dos procedimentos SIGTAP "0101040105 - Dispensação de Suplemento de Ácido Fólico" e "0101040113 -
            Dispensação de Suplemento de Ferro".
          </Text>
          <Text component='li'>
            Inativação do procedimento SIGTAP "0101040067 - Aplicação de Suplementos de Micronutrientes".
          </Text>
          <Text component='li'>
            Alteração na ficha de Marcadores de consumo alimentar, onde agora se torna obrigatório o preenchimento do
            campo "CPF/CNS do cidadão" e retirado o campo "Nome do cidadão".
          </Text>
          <Text component='li'>
            Ajustes gerais nos filtros personalizados do Relatório de produção de atividade coletiva.
          </Text>
          <Text component='li'>Atualização da tabela de referências de países do PEC.</Text>
          <Text component='li'>
            Nova opção de filtro "Incluir fichas do histórico" na Ficha de Cadastro Domiciliar para incluir o histórico
            de fichas do domicílio na busca por CPF/CNS.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.9'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Ajustes gerais visando performance.</Text>
          <Text component='li'>
            Alteração nos relatórios de produção e no histórico do cidadão para que o CNS temporário dos profissionais
            também seja considerado.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.5'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não era possível realizar um Registro anterior e uma Aplicação do mesmo
            imunobiológico em um mesmo atendimento de vacinação.
          </Text>
          <Text component='li'>
            Ajustes no módulo de Encaminhamentos onde alguns dados dos campos Motivo e Observação eram exibidos
            parcialmente na impressão.
          </Text>
          <Text component='li'>
            Correção de erro onde alguns dados não eram transmitidos corretamente ao Centralizador Nacional em
            instalações com o Administrador Municipal inativo.
          </Text>
          <Text component='li'>Melhorias no calendário e otimizações no módulo da Agenda.</Text>
          <Text component='li'>
            Correção das quantidades divergentes entre o Resumo do cadastro e a Situação sociodemográfica geral no
            Relatório consolidado da situação do território.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 4.5.4'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde alguns dados eram sincronizados de forma incorreta entre o PEC e o aplicativo
            Atenção Domiciliar.
          </Text>
          <Text component='li'>
            Correção de erro onde a data da última consulta RCV aparecia incorreta no Relatório operacional de risco
            cardiovascular.
          </Text>
          <Text component='li'>
            Correção de cenário onde, em alguns casos, não era possível atualizar o PEC para a versão 4.5.3.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
