/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Theme, useTheme } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { SIDE_MENU_WIDTH } from 'components/layout/SideMenu'
import logoImgWhite from 'images/esus_white.svg'
import logoImgBlue from 'images/esus-logo.png'

interface ExternalUserHeader {
  primary?: Boolean
}

export const ExternalUserHeader = (props: ExternalUserHeader) => {
  const { primary = false } = props
  const theme = useTheme()
  const classes = createStyles(theme, primary)

  return (
    <header css={classes.container}>
      <PageContainer fluid>
        <HFlow justifyContent='flex-start' alignItems='center'>
          <img height={35} src={primary ? logoImgWhite : logoImgBlue} alt='Logo e-SUS  Atenção Primária' />
        </HFlow>
      </PageContainer>
    </header>
  )
}

const createStyles = (theme: Theme, primary: Boolean) => ({
  container: css`
    display: flex;
    align-items: center;
    background: ${primary ? theme.pallete.primary.main : theme.pallete.gray.c100};
    border-bottom: 1px solid ${theme.pallete.divider};
    ${theme.breakpoints.up('lg')} {
      padding-left: ${SIDE_MENU_WIDTH}px;
    }
    height: 4rem;
  `,
})
