import { ListaEsperaItemDeleteInput } from 'graphql/types.generated'

import { ListaEsperaRemocaoModel } from '../model'

export const convertModelToInput = (values: ListaEsperaRemocaoModel): ListaEsperaItemDeleteInput => ({
  listaEsperaId: values.listaEsperaId,
  motivoSaida: values.motivoSaida,
  tentativasContato: values.tentativasContato,
  observacaoSaida: values.observacaoSaida,
})
