/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonProps, Tooltip } from 'bold-ui'
import { ReactComponent as SvgShareScreen } from 'images/videochamada/screen-share-filled.svg'

import { SwitchButton, SwitchButtonProps } from './SwitchButton'

type AcaoVideochamadaButtonIcon = 'mic' | 'video' | 'chat' | 'config' | 'screen'

interface AcaoVideochamadaButtonProps extends ButtonProps {
  icon: AcaoVideochamadaButtonIcon
  state: 'on' | 'off'
  unavailable?: boolean
}

interface AcaoVideochamadaTooltipProps {
  tooltipOn?: string
  tooltipOff?: string
  unavailableTooltip?: string
}

export function AcaoVideochamadaButton(props: AcaoVideochamadaButtonProps) {
  const { icon, state, unavailable = false, ...rest } = props

  const { unavailableTooltip, tooltipOn, tooltipOff, ...restButtonProps } = acaoVideochamadaButtonRecord[icon]

  const isOn = state === 'on' || unavailable

  return (
    <Tooltip text={unavailable ? unavailableTooltip : isOn ? tooltipOn : tooltipOff}>
      <SwitchButton state={isOn ? 'on' : 'off'} disabled={unavailable} {...restButtonProps} {...rest} />
    </Tooltip>
  )
}

const acaoVideochamadaButtonRecord: Record<
  AcaoVideochamadaButtonIcon,
  Omit<SwitchButtonProps, 'state'> & AcaoVideochamadaTooltipProps
> = {
  mic: {
    defaultState: 'on',
    iconOn: 'microphoneFilled',
    iconOff: 'microphoneOffFilled',
    unavailableTooltip: 'Nenhum dispositivo de entrada de áudio encontrado.',
    tooltipOn: 'Desativar microfone',
    tooltipOff: 'Ativar microfone',
  },
  video: {
    defaultState: 'on',
    iconOn: 'videoFilled',
    iconOff: 'videoOffFilled',
    unavailableTooltip: 'Nenhum dispositivo de entrada de vídeo encontrado.',
    tooltipOn: 'Desativar câmera',
    tooltipOff: 'Ativar câmera',
  },
  chat: {
    iconOn: 'chatFilled',
    tooltipOn: 'Ocultar mensagens',
    tooltipOff: 'Exibir mensagens',
  },
  config: {
    defaultState: 'on',
    iconOn: 'gearFilled',
    tooltipOn: 'Configurações',
  },
  screen: {
    iconOn: <SvgShareScreen fill='white' fontSize='1.5rem' />,
    tooltipOn: 'Encerrar compartilhamento de tela',
    tooltipOff: 'Iniciar compartilhamento de tela',
  },
}
