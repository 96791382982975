import { AcessoCbo } from 'graphql/types.generated'
import { NIVEL_SUPERIOR, NIVEL_TECNICO } from 'types/NivelCbo'

export const grupoCboPrescricao = {
  visualizar: [
    AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM,
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.TSB,
    AcessoCbo.PSICOLOGO,
  ],
  adicionar: [AcessoCbo.ENFERMEIRO, AcessoCbo.MEDICO, AcessoCbo.CIRURGIAO_DENTISTA, AcessoCbo.FARMACEUTICO],
  interromperTratamento: [AcessoCbo.ENFERMEIRO, AcessoCbo.MEDICO, AcessoCbo.CIRURGIAO_DENTISTA],
}

export const grupoCboAtestado = {
  visualizar: [
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.PSICOLOGO,
  ],
  adicionar: [AcessoCbo.MEDICO, AcessoCbo.CIRURGIAO_DENTISTA, AcessoCbo.PSICOLOGO],
  imprimir: [AcessoCbo.MEDICO, AcessoCbo.CIRURGIAO_DENTISTA, AcessoCbo.PSICOLOGO],
}

export const grupoCboOrientacoes = {
  visualizar: [...NIVEL_SUPERIOR, ...NIVEL_TECNICO],
  adicionar: [...NIVEL_SUPERIOR, AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM],
}

export const grupoCboEncaminhamentoExterno = [
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.MEDICO,
  AcessoCbo.PSICOLOGO,
]

export const grupoCboLembretes = {
  visualizar: [...NIVEL_SUPERIOR, ...NIVEL_TECNICO],
  adicionar: [...NIVEL_SUPERIOR, AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM],
}

export const grupoCboSolicitacaoExame = {
  visualizar: [
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.FARMACEUTICO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.PSICOLOGO,
    AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM,
  ],
  adicionar: [
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.FARMACEUTICO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.PSICOLOGO,
  ],
}

export const grupoCboProcedimentosAutomaticos = {
  desenvolvimentoCrianca: [AcessoCbo.MEDICO, AcessoCbo.ENFERMEIRO],
  crescimentoCrianca: [
    AcessoCbo.MEDICO,
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.FARMACEUTICO,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  ],
  glicemiaCapilar: [
    AcessoCbo.MEDICO,
    AcessoCbo.FARMACEUTICO,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM,
    AcessoCbo.NIVEL_MEDIO,
  ],
}

export const grupoCboMedicamentosFolhaRosto = [
  ...NIVEL_SUPERIOR,
  AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM,
  AcessoCbo.TSB,
]

export const grupoCboIniciarObservacao = [AcessoCbo.MEDICO, AcessoCbo.ENFERMEIRO]

export const grupoCboPlanoCuidadoObservacao = [...NIVEL_SUPERIOR, AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM]

export const grupoCboCuidadoCompartilhado = [
  AcessoCbo.MEDICO,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.CIRURGIAO_DENTISTA,
]
