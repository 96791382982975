import React from 'react'
import { MetaPath } from 'util/metaPath'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'

import { TipoPreNatal } from '../pre-natal/model'
import { SoapSection } from '../SoapSection'
import { FinalizacaoAtendimentoForm, FinalizacaoAtendimentoFormModel } from './FinalizacaoAtendimentoForm'

export interface FinalizacaoAtendimentoSectionProps {
  name: MetaPath<FinalizacaoAtendimentoFormModel>
  atendimentoId: ID
  profissionalId?: ID
  agendamentoAtendimentoId?: ID
  concluiAgendamento: boolean
  dataAtendimento: Instant
  agendamentosDia: AgendamentoDia[]
  isAtendimentoProcedimentos: boolean
  isAtendimentoOdonto: boolean
  tipoPreNatal?: TipoPreNatal
  isRegistroTardio: boolean
}

export function FinalizacaoAtendimentoSection(props: FinalizacaoAtendimentoSectionProps) {
  return (
    <SoapSection initialIcon='checkCircleFilled' title='Finalização do atendimento'>
      <FinalizacaoAtendimentoForm {...props} />
    </SoapSection>
  )
}
