// @ts-ignore

import { AcessoCbo } from 'graphql/types.generated'

/**
 * This file is automatically generated.
 *
 * To generate a new group of cbos follow this steps:
 *
 * Add a new enum inside of AcessoCbo enum, in AcessoCboAuth class.
 *
 * Still in AcessoCboAuth, map the new group enum with the list of cbos in 'mapping'
 * (you can use an initial if necessary. Ex.: all cbos related to a dental surgeon start with '2232', so you don't need to use a list with every cbos).
 *
 * Add the new group enum in AcessoCbo enum, in cbo.graphqls.
 *
 * Now, your application needs to be rebuilt and must be running on port 8080.
 *
 * Run 'yarn run gruposCbo:download' in the frontend folder.
 */

export type GrupoCbo = { grupo: AcessoCbo; cbos: string[] }
const GruposCbo = [
  {
    grupo: 'ENFERMEIRO',
    cbos: [
      '223505',
      '223565',
      '223520',
      '223525',
      '223530',
      '223535',
      '223540',
      '223545',
      '223550',
      '223555',
      '223560',
      '223510',
    ],
  },
  {
    grupo: 'MEDICO',
    cbos: [
      '2231F9',
      '225103',
      '225105',
      '225106',
      '225109',
      '225110',
      '225112',
      '225115',
      '225118',
      '225120',
      '225121',
      '225122',
      '225124',
      '225125',
      '225127',
      '225130',
      '225133',
      '225135',
      '225136',
      '225139',
      '225140',
      '225142',
      '225145',
      '225148',
      '225150',
      '225151',
      '225154',
      '225155',
      '225160',
      '225165',
      '225170',
      '225175',
      '225180',
      '225185',
      '225195',
      '225203',
      '225210',
      '225215',
      '225220',
      '225225',
      '225230',
      '225235',
      '225240',
      '225250',
      '225255',
      '225260',
      '225265',
      '225270',
      '225275',
      '225280',
      '225285',
      '225290',
      '225295',
      '225305',
      '225310',
      '225315',
      '225320',
      '225325',
      '225330',
      '225335',
      '225340',
      '225345',
      '225350',
      '2231A2',
      '2231F8',
    ],
  },
  {
    grupo: 'AUXILIAR_OU_TECNICO_ENFERMAGEM',
    cbos: [
      '322205',
      '515110',
      '322230',
      '322250',
      '322235',
      '322240',
      '324210',
      '322245',
      '322210',
      '322215',
      '322220',
    ],
  },
  {
    grupo: 'NIVEL_MEDIO',
    cbos: [
      '322405',
      '322425',
      '322205',
      '515110',
      '322230',
      '322250',
      '322235',
      '322240',
      '324210',
      '322245',
      '322210',
      '322215',
      '322220',
      '2236I1',
      '322415',
      '322430',
      '324205',
      '352210',
      '515105',
      '515120',
      '515140',
      '5151F1',
      '515310',
    ],
  },
  {
    grupo: 'CIRURGIAO_DENTISTA',
    cbos: [
      '223204',
      '223208',
      '223293',
      '223280',
      '223272',
      '223284',
      '223212',
      '223216',
      '223220',
      '223224',
      '223228',
      '223276',
      '223288',
      '223232',
      '223236',
      '223240',
      '223244',
      '223248',
      '223252',
      '223256',
      '223260',
      '223264',
      '223268',
    ],
  },
  {
    grupo: 'OUTRO_PROF_NIVEL_SUPERIOR',
    cbos: [
      '251605',
      '515305',
      '223405',
      '223415',
      '223425',
      '223410',
      '223445',
      '223430',
      '223650',
      '223605',
      '223660',
      '223655',
      '223635',
      '223630',
      '223810',
      '223840',
      '223815',
      '223830',
      '226305',
      '223710',
      '239415',
      '2241E1',
      '224140',
      '234410',
      '239425',
      '223905',
      '226110',
      '226105',
      '226315',
      '226310',
      '226320',
      '1312C1',
      '131225',
    ],
  },
  {
    grupo: 'TSB',
    cbos: ['322405', '322425'],
  },
  {
    grupo: 'PSICOLOGO',
    cbos: ['2515'],
  },
  {
    grupo: 'FARMACEUTICO',
    cbos: ['223435', '223440', '223405', '223415', '223420', '223425', '223430', '223445', '223410'],
  },
]
export default GruposCbo
