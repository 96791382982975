import { css } from '@emotion/core'
import { FormControl, HFlow, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form/final-form/CheckboxField'
import { ErrorField } from 'components/form/final-form/ErrorField'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { CondutaEnum } from 'graphql/types.generated'
import React from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { isCondutaRequired } from '../util'

export interface CondutaPanelProps {
  name: MetaPath<CondutaEnum[]>
  isObservacao: boolean
  hasNotificacaoCasoSuspeito: boolean
  hasEncaminhamentoExterno: boolean
}

export function CondutaPanel(props: CondutaPanelProps) {
  const { name, isObservacao, hasNotificacaoCasoSuspeito, hasEncaminhamentoExterno } = props

  const {
    input: { value: manterCidadaoNaLista },
  } = useField(resolveName(meta.finalizacao?.desfechoAtendimento?.manterCidadaoLista), {
    subscription: { value: true },
  })

  const isRequired = isCondutaRequired({
    isObservacao,
    hasNotificacaoCasoSuspeito,
    hasEncaminhamentoExterno,
    manterCidadaoNaLista,
  })

  return (
    <FormControl label='Conduta' required={isRequired}>
      <VFlow vSpacing={0.5}>
        <ErrorField name={name} />

        <HFlow
          hSpacing={4}
          style={css`
            margin-left: -0.25rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Retorno para consulta agendada'
              value={CondutaEnum.RETORNO_PARA_CONSULTA_AGENDADA}
            />
            <CheckboxField name={name} label='Agendamento para NASF' value={CondutaEnum.AGENDAMENTO_PARA_NASF} />
            <CheckboxField name={name} label='Agendamento para grupos' value={CondutaEnum.AGENDAMENTO_PARA_GRUPOS} />
          </VFlow>
          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Retorno para consulta programada / cuidado continuado'
              value={CondutaEnum.RETORNO_PARA_CUIDADO_CONTINUADO_PROGRAMADO}
            />
            <CheckboxField name={name} label='Alta do episódio' value={CondutaEnum.ALTA_DO_EPISODIO} />
          </VFlow>
        </HFlow>
      </VFlow>
    </FormControl>
  )
}
