/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, CellSize, ExternalStyles, Grid, HFlow, Icon, Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'

import { ACCORDION_DATA_TABLE_MAX_COLUMNS, TableAccordionColumnConfig } from './AccordionDataTable'

export interface AccordionDataTableRowDefaultProps<T> {
  row: T
  columns: TableAccordionColumnConfig<T>[]
  disabled?: boolean
  style?: ExternalStyles
}

export function AccordionDataTableRowDefault<T>(props: AccordionDataTableRowDefaultProps<T>) {
  const { row, columns, disabled, style } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const displayedColumns = columns.filter((column) => !column.hide)

  return (
    <HFlow alignItems='center' justifyContent='flex-start' style={classes.row}>
      <Icon
        name='accordionAngleIcon'
        icon={!disabled ? 'angleDown' : 'angleUp'}
        fill={!disabled ? 'secondary' : 'disabled'}
      />

      <Grid gap={0} gapVertical={0} alignItems='center' wrap={false} style={style}>
        {displayedColumns.map((column, index) => {
          const { render, size, style, sortable, hide, ...cellProps } = column

          return (
            render && (
              <Cell
                key={index}
                size={size || ((ACCORDION_DATA_TABLE_MAX_COLUMNS / displayedColumns.length) as CellSize)}
                style={[classes.rowCell, style]}
                {...cellProps}
                onFocus={(e) => e.stopPropagation()}
              >
                {render(row)}
              </Cell>
            )
          )
        })}
      </Grid>
    </HFlow>
  )
}

const styles = (theme: Theme) => ({
  row: css`
    grid-template-columns: minmax(auto, max-content) 1fr;
    padding-left: 1.5rem;
  `,
  rowCell: css`
    padding: 0.75rem 1rem;
    overflow: hidden;
    word-break: break-word;
    ${theme.breakpoints.down('lg')} {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    &:first-of-type {
      padding-left: 0;
    }
  `,
})
