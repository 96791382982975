import { AlertType } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { TipoAlerta } from 'types/enums'

export interface RemoteVideocallParticipant {
  id: ID
  streams: Map<ID, MediaStream>
  nome: string
}

export interface LocalVideocallParticipant {
  id: ID
  nome: string
  cpf: string
  termoProfissionalAceito: boolean
}

export interface VideocallStream {
  id: ID
  label: string
  color: ColorScale
  stream: MediaStream
  remote: boolean
  presenting: boolean
}

export interface ChatMessage {
  time: number
  content: string
  sender: string
  local: boolean
}

export interface VideochamadaUrlParams {
  id: string
}

export enum MotivoEncerramentoVideochamadaEnum {
  TIMEOUT = 'TIMEOUT',
  ENCERRADA_POR_CRIADOR = 'ENCERRADA_POR_CRIADOR',
  CONEXAO_FALHOU = 'CONEXAO_FALHOU',
}

export enum AtorVideochamada {
  CIDADAO = 'CIDADAO',
  PROFISSIONAL = 'PROFISSIONAL',
  PROFISSIONAL_EXTERNO = 'PROFISSIONAL_EXTERNO',
}

export interface DispositivoMidiaSelectModel {
  id: ID
  nome: string
}

export interface ConfiguracoesVideochamadaModel {
  audioInput: DispositivoMidiaSelectModel
  audioOutput: DispositivoMidiaSelectModel
  videoInput: DispositivoMidiaSelectModel
}

export interface CompartilharVideochamadaAlertData {
  tipo: AlertType
  message: string
}

export enum AlertVideochamadaType {
  LINK_COMPARTILHADO = 'LINK_COMPARTILHADO',
  ENVIO_EMAIL_SUCESSO = 'ENVIO_EMAIL_SUCESSO',
  ENVIO_EMAIL_ERRO = 'ENVIO_EMAIL_ERRO',
}

export const compartilharChamadaAlertRecord: Record<AlertVideochamadaType, CompartilharVideochamadaAlertData> = {
  LINK_COMPARTILHADO: { tipo: TipoAlerta.SUCCESS, message: 'Link da chamada copiado com sucesso.' },
  ENVIO_EMAIL_SUCESSO: { tipo: TipoAlerta.SUCCESS, message: 'Link da chamada enviado com sucesso.' },
  ENVIO_EMAIL_ERRO: { tipo: TipoAlerta.DANGER, message: 'Envio de link por e-mail falhou.' },
}
