import { endOfDay, startOfDay } from 'date-fns'
import { AtendimentosQueryInput, StatusAtendimento } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { AtendimentosRegistroTardioFilterModel } from './filter/useListaRegistroTardioFilterDefault'

export const convertListaRegistroTardioStateToInput = (
  state: AtendimentosRegistroTardioFilterModel
): AtendimentosQueryInput => {
  return {
    query: state.filter.query,
    ordem: state.filter.ordem,
    somenteMeusAtendimentos: state.filter.somenteMeusAtendimentos,
    statusAtendimento:
      !state.filter.statusAtendimento || state.filter.statusAtendimento.length === 0
        ? [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.AGUARDANDO_ATENDIMENTO]
        : state.filter.statusAtendimento || [],
    dataInicio: !isUndefinedOrNull(state.filter.periodo.startDate)
      ? startOfDay(state.filter.periodo.startDate).getTime()
      : null,
    dataFim: !isUndefinedOrNull(state.filter.periodo.endDate) ? endOfDay(state.filter.periodo.endDate).getTime() : null,
    responsaveisIds: state.filter.responsaveis?.map((value) => value.id),
    isRegistroTardio: true,
    pageParams: state.pageParams,
  }
}
