/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Icon, Tag, Theme, useTheme } from 'bold-ui'
import { turquoise, yellow } from 'bold-ui/lib/styles/colors'
import { SubtipoRecord } from 'components/form/field/select/SubtipoAtendimentoSelectField/SubtipoAtendimentoSelectField'
import { css } from 'emotion'
import { TipoAtendimentoHistoricoClinico, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { ReactComponent as Mosquito } from 'images/historico/mosquito.svg'
import { Fragment, useMemo } from 'react'

import {
  recordCuidadoCompartilhadoRiscoTagStyle,
  recordEscutaHistoricoTagStyle,
  tipoConsultaOdontoRecord,
} from '../../../../model/model-historico'
import { HistoricoAccordionHeaderProps } from '../HistoricoAccordionHeader'

interface HistoricoAccordionHeaderTagsProps
  extends Omit<HistoricoAccordionHeaderProps, 'cidadao' | 'historicoPrintProps'> {}

export default function HistoricoAccordionHeaderTitleTags({
  historicoAtendimento,
  subtipoAtendimento,
}: HistoricoAccordionHeaderTagsProps) {
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  const escutaInicialTagEnabled =
    (historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
      historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.ESCUTA_INICIAL) &&
    !!historicoAtendimento.classificacaoRisco

  const cuidadoCompartilhadoTag =
    historicoAtendimento.cuidadoCompartilhado?.classificacaoRisco &&
    recordCuidadoCompartilhadoRiscoTagStyle[historicoAtendimento.cuidadoCompartilhado.classificacaoRisco]

  return (
    <Fragment>
      {escutaInicialTagEnabled && (
        <Tag style={recordEscutaHistoricoTagStyle[historicoAtendimento.classificacaoRisco].style}>
          {recordEscutaHistoricoTagStyle[historicoAtendimento.classificacaoRisco].descricao}
        </Tag>
      )}
      {cuidadoCompartilhadoTag && <Tag style={cuidadoCompartilhadoTag.style}>{cuidadoCompartilhadoTag.descricao}</Tag>}
      {subtipoAtendimento && (
        <Tag style={classes.tags(subtipoAtendimento?.tagColor)}>{SubtipoRecord[subtipoAtendimento.nome]}</Tag>
      )}
      {historicoAtendimento.tipoConsultaOdonto && (
        <Tag type='info'>{tipoConsultaOdontoRecord[historicoAtendimento.tipoConsultaOdonto]}</Tag>
      )}
      {historicoAtendimento.idadeGestacional && (
        <Tag type='info'>
          {`${'semana'.pluralizeAndConcatValue(historicoAtendimento.idadeGestacional.semanas)}
           e ${'dia'.pluralizeAndConcatValue(historicoAtendimento.idadeGestacional.dias)}`}
        </Tag>
      )}
      {historicoAtendimento.fichasConcatenadas?.uuidProcedimento && (
        <Tag style={classes.tagFichaConcatenada(yellow.c60)}>
          <Icon icon='bandageFilled' className={classes.icon} />
          Procedimento
        </Tag>
      )}
      {historicoAtendimento.fichasConcatenadas?.uuidZika && (
        <Tag style={classes.tagFichaConcatenada(turquoise.c60)}>
          <Mosquito className={classes.icon} />
          Síndrome neurológica por Zika/Microcefalia
        </Tag>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  tags: (tagColor?: string) => css`
    color: ${theme.pallete.primary.c100};
    background-color: ${tagColor};
  `,
  tagFichaConcatenada: (tagColor?: string) => css`
    color: ${theme.pallete.primary.c100};
    background-color: ${tagColor};
  `,
  icon: css`
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
    & path {
      fill: ${theme.pallete.primary.c100};
    }
  `,
})
