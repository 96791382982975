import { useAlert } from 'components/alert'
import { GraphQLError } from 'graphql'
import { useEntrarEmVideochamadaMutation } from 'graphql/hooks.generated'
import { EntrarEmVideochamadaInput } from 'graphql/types.generated'
import { useCallback } from 'react'

import { LocalVideocallParticipant } from '../model'
import { showChamadaEncerradaInformation, showChamadaLotadaInformation } from '../utils'

interface EntrarEmVideochamadaOptions {
  videochamadaId: ID
  onEntrar?(selfData: LocalVideocallParticipant): void
}

export function useEntrarEmVideochamada(
  options: EntrarEmVideochamadaOptions
): (nome: string, cpf: string, termoProfissionalAceito: boolean) => Promise<void> {
  const { videochamadaId, onEntrar } = options

  const alert = useAlert()

  const [entrarEmVideochamada] = useEntrarEmVideochamadaMutation()

  return useCallback(
    (nome: string, cpf: string, termoProfissionalAceito: boolean) =>
      entrarEmVideochamada({
        variables: {
          input: convertToInput(videochamadaId, nome, cpf, termoProfissionalAceito),
        },
      })
        .then(
          ({
            data: {
              entrarEmVideochamada: { id, nome: nomeParticipante, cpf: cpfParticipante },
            },
          }) => {
            onEntrar?.({ id, nome: nomeParticipante, cpf: cpfParticipante, termoProfissionalAceito })
          }
        )
        .catch((err) => {
          if (!handleEntrarErrors(err.graphQLErrors)) alert('danger', 'Erro ao entrar em videochamada.')
        }),
    [entrarEmVideochamada, videochamadaId, onEntrar, alert]
  )
}

function handleEntrarErrors(errors: GraphQLError[]): boolean {
  let handled = false
  errors?.forEach((error) => {
    const exceptionType = error.extensions?.classification

    switch (exceptionType) {
      case 'VideochamadaLotadaException':
        handled = true
        showChamadaLotadaInformation()
        break

      case 'VideochamadaInexistenteException':
        handled = true
        showChamadaEncerradaInformation()

        break
    }
  })
  return handled
}

const convertToInput = (
  videochamadaId: string,
  nome: string,
  cpf: string,
  termoProfissionalAceito: boolean
): EntrarEmVideochamadaInput => ({
  videochamadaId,
  nomeParticipante: nome,
  cpfParticipante: cpf,
  termoProfissionalAceito,
})
