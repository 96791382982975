import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { metaPath } from 'util/metaPath'

import { AtencaoDomiciliarCondutaDesfechoModel } from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { AtencaoDomiciliarModalidadeModel } from '../selectFields/AtencaoDomiciliarModalidadeSelectField'
import { AtencaoDomiciliarOrigemModel } from '../selectFields/AtencaoDomiciliarOrigemSelectField'
import { CidadaoAdSelectFieldModel } from '../selectFields/CidadaoADSelectField'
import { CidadaoListaAdSelectFieldModel } from '../selectFields/CidadaoListaADSelectField'
import { CuidadorFieldGroupModel } from './CuidadorDadosFieldGroup'
import { ResponsavelFieldGroupModel } from './ResponsavelDadosFieldGroup'

export default interface CidadaoADFormModel {
  id?: ID
  cidadao: CidadaoListaAdSelectFieldModel
  dataAdmissao: string
  modalidade: AtencaoDomiciliarModalidadeModel
  cidadaoAdEmObito: CidadaoAdSelectFieldModel
  procedencia?: AtencaoDomiciliarOrigemModel
  especifique?: string
  cid10Principal: CidSelectFieldModel
  cid10Secundario1?: CidSelectFieldModel
  cid10Secundario2?: CidSelectFieldModel
  condutaDesfecho?: AtencaoDomiciliarCondutaDesfechoModel
  responsavel?: ResponsavelFieldGroupModel
  cuidador?: CuidadorFieldGroupModel
  dataSaida?: string
  dataObito?: string
  dataUltimaVisita?: string
  dataAgendado?: Instant
  numeroObito?: string
}

export const path = metaPath<CidadaoADFormModel>()
