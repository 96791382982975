/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Text } from 'bold-ui'
import { Form, FormDebouncedValueSpy, SwitchField } from 'components/form'
import { FormState } from 'final-form'
import { Fragment } from 'react'
import { metaPath } from 'util/metaPath'

export interface ListaCuidadoCompartilhadoInlineFilterModel {
  somenteOsMeus?: boolean
}

interface ListaCuidadoCompartilhadoFilterProps {
  value: ListaCuidadoCompartilhadoInlineFilterModel
  filterSomenteOsMeusLabel: string
  onChange(value: ListaCuidadoCompartilhadoInlineFilterModel): void
}

const meta = metaPath<ListaCuidadoCompartilhadoInlineFilterModel>()

export const ListaCuidadoCompartilhadoFilter = (props: ListaCuidadoCompartilhadoFilterProps) => {
  const { value, filterSomenteOsMeusLabel, onChange } = props

  const handleDebounceChange = (formState: FormState<ListaCuidadoCompartilhadoInlineFilterModel>) => {
    onChange(formState.values)
  }

  const renderFilterForm = () => {
    return (
      <Fragment>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <SwitchField style={styles.switch} name={meta.somenteOsMeus} label={filterSomenteOsMeusLabel} />
      </Fragment>
    )
  }

  return (
    <Grid gap={0.5} gapVertical={0.5}>
      <Cell size={8}>
        <HFlow>
          <Form<ListaCuidadoCompartilhadoInlineFilterModel>
            render={renderFilterForm}
            initialValues={{ somenteOsMeus: value.somenteOsMeus }}
          />
        </HFlow>
      </Cell>
      <Cell size={4}>
        <HFlow justifyContent='flex-end' alignItems='baseline' hSpacing={0.5}>
          Ordenar por: <Text fontWeight='bold'>Classificação de prioridade</Text>
        </HFlow>
      </Cell>
    </Grid>
  )
}

const styles = {
  switch: css`
  display: 'grid',
  gridAutoFlow: 'column',
  whiteSpace: 'nowrap',
  justifyContent: 'start',
  maxWidth: '16.5rem',
`,
}
