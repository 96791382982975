import { Cnes } from 'api/Cnes'
import { FichaSinan } from 'api/FichaSinan'
import { Transmissao } from 'api/Transmissao'

import { AcompanhamentoCidadaosVinculados } from './AcompanhamentoCidadaosVinculados'
import { AcompanhamentoSaudeRelatorio } from './AcompanhamentoSaudeRelatorio'
import { Arquivo } from './Arquivo'
import { AtencaoDomiciliar } from './AtencaoDomiciliar'
import { BuscaAtivaVacinacao } from './BuscaAtivaVacinacao'
import { ImportacaoImunobiologico } from './ImportacaoImunobiologico'
import { ImpressaoAcompanhamentoPreNatal } from './ImpressaoAcompanhamentoPreNatal'
import { ImpressaoAcompanhamentoPuericultura } from './impressaoAcompanhamentoPuericultura'
import { ImpressaoAgendamentosDia } from './ImpressaoAgenda'
import { ImpressaoAtendimentoIndividual } from './ImpressaoAtendimentoIndividual'
import { ImpressaoAtendimentosHistorico } from './ImpressaoAtendimentosHistorico'
import { ImpressaoAtendimentoVacinacao } from './ImpressaoAtendimentoVacinacao'
import { ImpressaoCadernetaVacinacao } from './impressaoCadernetaVacinacao'
import { ImpressaoEncaminhamentoExterno } from './ImpressaoEncaminhamentoExterno'
import { ImpressaoMedicamentos } from './ImpressaoMedicamentos'
import { ImpressaoSolicitacaoExames } from './ImpressaoSolicitacaoExames'
import { PrescricaoDigital } from './PrescricaoDigital'

export default {
  cnes: new Cnes(),
  transmissao: new Transmissao(),
  fichaSinan: new FichaSinan(),
  acompanhamentoSaude: new AcompanhamentoSaudeRelatorio(),
  acompanhamentoPreNatal: new ImpressaoAcompanhamentoPreNatal(),
  atencaoDomiciliar: new AtencaoDomiciliar(),
  atendimentoIndividual: new ImpressaoAtendimentoIndividual(),
  atendimentosHistorico: new ImpressaoAtendimentosHistorico(),
  prescricaoMedicamento: new ImpressaoMedicamentos(),
  prescricaoDigital: new PrescricaoDigital(),
  atendimentoVacinacao: new ImpressaoAtendimentoVacinacao(),
  cadernetaVacinacao: new ImpressaoCadernetaVacinacao(),
  encaminhamentoExterno: new ImpressaoEncaminhamentoExterno(),
  solicitacaoExames: new ImpressaoSolicitacaoExames(),
  impressaoAgendamento: new ImpressaoAgendamentosDia(),
  acompanhamentoPuericultura: new ImpressaoAcompanhamentoPuericultura(),
  importacaoImunobiologico: new ImportacaoImunobiologico(),
  acompanhamentoCidadaosVinculados: new AcompanhamentoCidadaosVinculados(),
  buscaAtivaVacinacao: new BuscaAtivaVacinacao(),
  arquivo: new Arquivo(),
}
