import { createValidator } from 'util/validation'

import { getDentes } from '../../util'
import { condicoesExclusivas, condicoesPermanentesOne, condicoesPermanentesTwo } from './model'
import { OdontogramaFieldModel } from './OdontogramaField'

const dentes = getDentes()

export const odontogramaValidator = createValidator<OdontogramaFieldModel>({
  dentes: createValidator<OdontogramaFieldModel['dentes']>({}, (values, errors) => {
    if (values) {
      dentes.forEach((dente) => {
        const condicoes = values[dente]?.condicoesDente

        if (condicoes?.length > 1) {
          if (condicoes.some((condicao) => condicoesExclusivas.includes(condicao))) {
            errors[dente] = 'Dente possui mais de uma condição exclusiva.'
          } else if (
            condicoes.some((condicao) => condicoesPermanentesOne.includes(condicao)) &&
            condicoes.some((condicao) => condicoesPermanentesTwo.includes(condicao))
          ) {
            errors[dente] = 'Dente possui condições de grupos diferentes.'
          }
        }
      })

      return errors
    }
  }),
})
