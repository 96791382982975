import ReactPDF, { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintLabelProps {
  value: string
  title?: string
  level?: 1 | 2 | 3 | 4 | 5 | 6
  bold?: boolean
  uppercase?: boolean
  styles?: ReactPDF.Style
  titleStyles?: ReactPDF.Style
  valueStyles?: ReactPDF.Style
}

export const PrintLabel = (props: PrintLabelProps) => {
  const { title, value, level, bold, uppercase, titleStyles, valueStyles, styles } = props
  const { config, getTitleStyle } = usePrinterStyles()
  const heading = getTitleStyle(level ?? 5)

  const classes = StyleSheet.create({
    wrapper: { marginHorizontal: 15, flexDirection: 'row', alignItems: 'baseline' },
    title: { ...heading },
    text: { ...config.main },
    bold: { fontFamily: 'Lato Bold' },
    uppercase: { textTransform: 'uppercase' },
  })

  return (
    <View style={[classes.wrapper, styles]}>
      {title && <Text style={[classes.title, titleStyles]}>{`${title}: `}</Text>}
      <Text style={[classes.text, bold && classes.bold, uppercase && classes.uppercase, valueStyles]}>{value}</Text>
    </View>
  )
}
