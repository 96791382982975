/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { ReactElement, useState } from 'react'

import {
  RelatoriosGerenciaisPeriodoForm,
  RelatoriosGerenciaisPeriodoFormProps,
} from './form/RelatoriosGerenciaisPeriodoForm'

export interface RelatoriosGerenciaisTableWrapperProps
  extends Omit<RelatoriosGerenciaisPeriodoFormProps, 'setIsFormDirty'> {
  loading: boolean
  isEmpty: boolean
  children: ReactElement
}

export function RelatoriosGerenciaisTableWrapper(props: RelatoriosGerenciaisTableWrapperProps) {
  const { loading, isEmpty, children, isRelatorioAtendimentos = false, onSubmit } = props

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const theme = useTheme()
  const { container, loadingContainer } = createStyles(theme)

  return (
    <VFlow style={container}>
      <RelatoriosGerenciaisPeriodoForm
        isRelatorioAtendimentos={isRelatorioAtendimentos}
        loading={loading}
        onSubmit={onSubmit}
        setIsFormDirty={setIsFormDirty}
      />

      {loading ? (
        <div css={loadingContainer}>
          <PageLoading message='Em processamento' />
        </div>
      ) : isEmpty ? (
        <Text>Nenhum dado para o período</Text>
      ) : (
        !isFormDirty && children
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    color: ${theme.pallete.gray.c20};
  `,
  loadingContainer: css`
    position: absolute;
    left: 50vw;
    top: 60vh;
  `,
})
