import { ParteBucalEnum, Procedimento, TipoParteBucalEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'

import { ArcadaFieldModel } from '../components/odontograma-field/arcadas-field/ArcadasField'
import { DenteFieldModel } from '../components/odontograma-field/dentes-field/DentesField'
import { EvolucaoDenteSupranumerarioModel } from '../components/odontograma-field/dentes-supranumerarios/DentesSupranumerariosField'
import { OdontogramaFieldModel } from '../components/odontograma-field/OdontogramaField'
import { OutrosFieldModel } from '../components/odontograma-field/outros-field/OutrosField'
import { HistoricoEvolucoesOdontoTableRowModel } from '../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { getParteBucal, showDescricaoCodigoProcedimento } from '../util'
import { EvolucaoOdontoTableItemModel } from './model'

export const joinEvolucoesOdonto = (
  valoresHistorico: HistoricoEvolucoesOdontoTableRowModel[],
  valoresAtuais: OdontogramaFieldModel,
  lotacaoAtual: EvolucaoOdontoTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucaoOdontoTableItemModel[] => {
  const { dentes, dentesSupranumerarios, arcadas, outros } = valoresAtuais

  return [
    ...convertDentesToJoined(dentes, lotacaoAtual, atendIniciadoEm),
    ...convertArcadasToJoined(arcadas, lotacaoAtual, atendIniciadoEm),
    ...convertDentesSupranumerariosToJoined(dentesSupranumerarios, lotacaoAtual, atendIniciadoEm),
    ...convertOutrosToJoined(outros, lotacaoAtual, atendIniciadoEm),
    ...convertHistoricoEvolucaoOdontoToJoined(valoresHistorico),
  ].sort((a, b) => b.lastUpdate - a.lastUpdate)
}

const convertDentesToJoined = (
  items: Record<ParteBucalEnum, DenteFieldModel>,
  lotacao: EvolucaoOdontoTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucaoOdontoTableItemModel[] => {
  if (isEmpty(items)) return []

  return Object.entries(items)
    .filter(([, item]) => !!item.procedimentos?.length)
    .map(([parteBucalEnum, item]: [ParteBucalEnum, DenteFieldModel]) => {
      const partesBucais = [{ parteBucalEnum, tipoParteBucal: TipoParteBucalEnum.DENTE }]

      return {
        key: parteBucalEnum,
        lotacao,
        partesBucais,
        procedimentos: item.procedimentos,
        observacoes: item.observacoes?.[0],
        searchProcedimentos: searchProcedimentos(item.procedimentos),
        searchPartesBucais: searchPartesBucais(partesBucais),
        lastUpdate: item.lastUpdate ?? atendIniciadoEm,
        isRegistradoAgora: true,
      }
    })
}

const convertArcadasToJoined = (
  items: Record<ParteBucalEnum, ArcadaFieldModel>,
  lotacao: EvolucaoOdontoTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucaoOdontoTableItemModel[] => {
  if (isEmpty(items)) return []

  return Object.entries(items)
    .filter(([, item]) => !!item.procedimentos?.length)
    .map(([parteBucalEnum, item]: [ParteBucalEnum, ArcadaFieldModel]) => {
      const partesBucais = [{ parteBucalEnum, tipoParteBucal: TipoParteBucalEnum.ARCADA }]

      return {
        key: parteBucalEnum,
        lotacao,
        partesBucais,
        procedimentos: item.procedimentos,
        observacoes: item.observacoes?.[0],
        searchProcedimentos: searchProcedimentos(item.procedimentos),
        searchPartesBucais: searchPartesBucais(partesBucais),
        lastUpdate: item.lastUpdate ?? atendIniciadoEm,
        isRegistradoAgora: true,
      }
    })
}

const convertDentesSupranumerariosToJoined = (
  items: EvolucaoDenteSupranumerarioModel[],
  lotacao: EvolucaoOdontoTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucaoOdontoTableItemModel[] => {
  if (isEmpty(items)) return []

  const partesBucais = [
    { parteBucalEnum: ParteBucalEnum.DENTE_SUPRANUMERARIO, tipoParteBucal: TipoParteBucalEnum.SUPRANUMERARIO },
  ]

  return items.map((item, index) => ({
    key: `supranumerario${index}`,
    lotacao,
    partesBucais,
    procedimentos: item.procedimentos,
    observacoes: item.observacao,
    descricaoParteBucal: item.local,
    searchProcedimentos: searchProcedimentos(item.procedimentos),
    searchPartesBucais: '',
    cacheId: items.indexOf(item),
    lastUpdate: item.lastUpdate ?? atendIniciadoEm,
    isRegistradoAgora: true,
  }))
}

const convertOutrosToJoined = (
  items: OutrosFieldModel[],
  lotacao: EvolucaoOdontoTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucaoOdontoTableItemModel[] => {
  if (isEmpty(items)) return []

  const partesBucais = [{ parteBucalEnum: ParteBucalEnum.OUTROS, tipoParteBucal: TipoParteBucalEnum.OUTRO }]

  return items.map((item, index) => ({
    key: `outro${index}`,
    lotacao,
    partesBucais,
    procedimentos: item.procedimentos,
    observacoes: item.observacao,
    descricaoParteBucal: item.local,
    searchProcedimentos: searchProcedimentos(item.procedimentos),
    searchPartesBucais: '',
    cacheId: item._id,
    lastUpdate: item.lastUpdate ?? atendIniciadoEm,
    isRegistradoAgora: true,
  }))
}

const convertHistoricoEvolucaoOdontoToJoined = (
  items: HistoricoEvolucoesOdontoTableRowModel[]
): EvolucaoOdontoTableItemModel[] =>
  items?.map((item) => ({
    key: `db${item.id}`,
    partesBucais: item.partesBucais?.map(({ parteBucalEnum, tipoParteBucal }) => ({ parteBucalEnum, tipoParteBucal })),
    procedimentos: item.procedimentos,
    descricaoParteBucal: item.descricaoParteBucal,
    observacoes: item.observacao,
    lotacao: item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao,
    searchProcedimentos: searchProcedimentos(item.procedimentos),
    searchPartesBucais: searchPartesBucais(null),
    lastUpdate: item.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm,
    isRegistradoAgora: false,
  })) ?? []

const searchProcedimentos = (procedimentos: Procedimento[]): string =>
  procedimentos.map((procedimento) => showDescricaoCodigoProcedimento(procedimento)).join(' ')

const searchPartesBucais = (partesBucais: EvolucaoOdontoTableItemModel['partesBucais']): string =>
  partesBucais
    ?.filter(
      ({ tipoParteBucal }) =>
        tipoParteBucal === TipoParteBucalEnum.DENTE || tipoParteBucal === TipoParteBucalEnum.ARCADA
    )
    .map(({ parteBucalEnum }) => getParteBucal(parteBucalEnum).title)
    .join(' ')
