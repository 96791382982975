/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DateTime } from 'components/date'
import {
  ActionEvolucaoCuidadoCompartilhadoRecord,
  CuidadoCompartilhadoEvolucaoModel,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

interface CuidadoCompartilhadoEvolucaoItemProps {
  evolucao: CuidadoCompartilhadoEvolucaoModel
  removeLastLine?: boolean
}

export const CuidadoCompartilhadoEvolucaoItem = (props: CuidadoCompartilhadoEvolucaoItemProps) => {
  const { evolucao, removeLastLine } = props

  const { acesso } = useAcessoLotacaoOrEstagio()
  const isOwner = evolucao.lotacao.id === acesso.id

  const theme = useTheme()
  const styles = createStyles(theme, isOwner, removeLastLine)

  return (
    <HFlow hSpacing={0.5}>
      <div css={styles.lineColumn}>
        <div css={styles.iconWrapper}>
          <Icon icon='quoteLeft' size={1} style={styles.icon} />
        </div>
        {!removeLastLine && <div css={styles.line} />}
      </div>
      <VFlow vSpacing={0} style={styles.item}>
        <Text>
          <Text fontWeight='bold'>
            {evolucao.lotacao.profissional.nome} {ActionEvolucaoCuidadoCompartilhadoRecord[evolucao.conduta]}{' '}
          </Text>
          |{' '}
          <Text color='secondary'>
            <DateTime value={evolucao.dataEvolucao} />
          </Text>
        </Text>
        <Text dangerouslySetInnerHTML={{ __html: evolucao.descricao }} style={styles.richText} />
      </VFlow>
    </HFlow>
  )
}

const createStyles = (theme: Theme, isOwner: boolean, removeLastLine: boolean) => ({
  lineColumn: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  item: css`
    margin-bottom: ${removeLastLine ? '0' : '0.75rem'};
  `,
  iconWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: ${isOwner ? theme.pallete.gray.c40 : theme.pallete.primary.c40};
    border-radius: 50%;
  `,
  icon: css`
    fill: ${theme.pallete.surface.main};
  `,
  line: css`
    width: 1px;
    flex: 1;
    margin-top: 0.25rem;
    background-color: ${theme.pallete.gray.c80};
  `,
  richText: css`
    color: ${theme.pallete.gray.c20};
    word-break: break-all;
  `,
})
