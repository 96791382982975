/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, PagedTable, Text, Tooltip } from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import { ImoveisQueryInput, UnidadeSaude } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'

import { AtualizacaoImoveisProgressBar } from './AtualizacaoImoveisProgressBar'
import { ImoveisTerritorioCheckAll } from './ImoveisTerritorioCheckAll'
import { ImoveisTerritorioCheckItem } from './ImoveisTerritorioCheckItem'
import { ReterritorializacaoAtualizacaoForm } from './ReterritorializacaoAtualizacaoForm'
import { ImoveisQueryResult } from './ReterritorializacaoView'
import {
  formatAddress,
  getNomesResponsaveisFamiliares,
  getTooltipResponsaveisFamiliares,
  numeroTotalCidadaosImovel,
} from './utils/reterritorializacao'

export type ImovelItemModel = ImoveisQueryResult['content'][0]

interface ImoveisTerritorioTableProps {
  pageResult: ImoveisQueryResult
  resetBuscaOnAtualizacaoImoveis(): void
  changeToProcessingState(): void
  onPageChange: (state: ImoveisQueryInput) => void
  numeroImoveis: number
  unidadeSaude: UnidadeSaude
  checkedRowIdToCidadaoCountMap: Map<string, number>
  checkedRowIdToCidadaoCountMapSetter: (state: Map<string, number>) => void
  progressToken: string
  processingState: boolean
}

const handleNullFields = (value: string): string => value ?? '-'
const handleEquipeField = (equipeNome: string, ine: string): string => (equipeNome ? `${equipeNome} - ${ine}` : '-')

export function ImoveisTerritorioTable({
  pageResult,
  resetBuscaOnAtualizacaoImoveis,
  changeToProcessingState,
  onPageChange,
  unidadeSaude,
  checkedRowIdToCidadaoCountMap,
  checkedRowIdToCidadaoCountMapSetter,
  progressToken,
  processingState,
}: ImoveisTerritorioTableProps) {
  const tableProps = usePagedTableProps({
    loading: false,
    result: pageResult,
    onChange: onPageChange,
  })
  tableProps.sizeOptions = [10, 30]

  const checkedRowItems = useMemo(() => {
    return {
      imoveisId: [...checkedRowIdToCidadaoCountMap.keys()],
      numeroCidadaos: [...checkedRowIdToCidadaoCountMap.values()].reduce((counter, current) => (counter += current), 0),
    }
  }, [checkedRowIdToCidadaoCountMap])

  return (
    <Fragment>
      <Heading level={2}>
        {imoveisEncontradosTitle(checkedRowItems.imoveisId.length, checkedRowItems.numeroCidadaos)}
      </Heading>
      <TableBox
        header={
          <Fragment>
            <ReterritorializacaoAtualizacaoForm
              changeToProcessingState={changeToProcessingState}
              unidadeSaude={unidadeSaude}
              checkedRowItems={checkedRowItems}
              disabled={processingState}
              progressToken={progressToken}
            />
            <Text>
              Preencha os campos acima com as informações que deseja atualizar e selecione os imóveis que devem ser
              atualizados com elas.
            </Text>
            <div
              css={css`
                margin-top: 1rem;
              `}
            >
              <AtualizacaoImoveisProgressBar
                topic={`reterritorializar/${progressToken}`}
                onCompleteCallBack={resetBuscaOnAtualizacaoImoveis}
                renderProgressBar={processingState}
              />
            </div>
          </Fragment>
        }
        css={css`
          margin-top: 0.5rem;
        `}
      >
        <PagedTable<ImovelItemModel>
          {...tableProps}
          columns={[
            {
              name: 'check',
              header: (
                <ImoveisTerritorioCheckAll
                  checkedItems={checkedRowIdToCidadaoCountMap}
                  setCheckedItems={checkedRowIdToCidadaoCountMapSetter}
                  pageResult={pageResult}
                  disabled={processingState}
                />
              ),
              render: (row: ImovelItemModel) => (
                <ImoveisTerritorioCheckItem
                  checkedItems={checkedRowIdToCidadaoCountMap}
                  setCheckedItems={checkedRowIdToCidadaoCountMapSetter}
                  item={row}
                  disabled={processingState}
                />
              ),
              sortable: false,
              style: { width: '1.5rem' },
            },
            {
              name: 'bairro',
              header: 'Bairro',
              render: (row: ImovelItemModel) => <Text>{handleNullFields(row.bairro).titleCase()}</Text>,
              sortable: true,
            },
            {
              name: 'logradouro',
              header: 'Logradouro',
              render: (row: ImovelItemModel) => (
                <Text>
                  {formatAddress(row.tipoLogradouro.nome, row.logradouro, row.numero, row.complemento, row.cep)}
                </Text>
              ),
              sortable: true,
            },
            {
              name: 'nomeCidadaoResponsavel',
              header: 'Responsável',
              render: (row: ImovelItemModel) => renderResponsavel(row),
              sortable: false,
            },
            {
              name: 'numeroCidadaos',
              header: 'Nº de cidadãos',
              render: (row: ImovelItemModel) => (
                <Text>{handleNullFields(numeroTotalCidadaosImovel(row.familias).toString())}</Text>
              ),
              sortable: false,
            },
            {
              name: 'cnes',
              header: 'Unidade de saúde',
              render: (row: ImovelItemModel) => <Text>{handleNullFields(row.nomeUnidadeSaude)}</Text>,
              sortable: false,
            },
            {
              name: 'ine',
              header: 'Equipe',
              render: (row: ImovelItemModel) => <Text>{handleEquipeField(row.nomeEquipe, row.ine)}</Text>,
              sortable: false,
            },
            {
              name: 'microarea',
              header: 'Microárea',
              render: (row: ImovelItemModel) => <Text>{handleNullFields(row.microarea)}</Text>,
              sortable: true,
            },
          ]}
        />
      </TableBox>
    </Fragment>
  )
}

const renderResponsavel = ({ familias, responsavelTecnico }: ImovelItemModel) =>
  (responsavelTecnico && <Text component='p'>{responsavelTecnico.nome?.titleCase()}</Text>) ?? (
    <Tooltip
      style={css`
        flex-wrap: wrap;
        width: 15rem;
      `}
      text={getTooltipResponsaveisFamiliares(familias)}
      placement='bottom-end'
    >
      <Text>
        {getNomesResponsaveisFamiliares(familias).map((responsavel) => (
          <Text component='p'>{responsavel}</Text>
        ))}
      </Text>
    </Tooltip>
  )

const imoveisEncontradosTitle = (numImoveis: number, numCidadaos: number) =>
  `${numImoveis} imóve${numImoveis === 1 ? 'l selecionado' : 'is selecionados'} | ${numCidadaos} ${
    numCidadaos === 1 ? 'cidadão selecionado' : 'cidadãos selecionados'
  }`
