/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, FormControl, Heading, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Form, FormValueSpy, SubmitButton } from 'components/form'
import { Fragment } from 'react'
import { metaPath } from 'util/metaPath'
import { DiscussaoCasoFormModel } from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import { CuidadoCompartilhadoEvolucoes } from '../components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { DiscussaoCasoExecutanteFields } from './DiscussaoCasoExecutanteFields'
import { DiscussaoCasoSolicitanteFields } from './DiscussaoCasoSolicitanteFields'

interface DiscussaoCasoFormProps {
  cuidadoCompartilhadoId: ID
  initialValues: DiscussaoCasoFormModel
  responsavelCuidado: any
  handleFormChange: (values: DiscussaoCasoFormModel) => void
  handleSubmit: (values: DiscussaoCasoFormModel) => Promise<void>
  handleCancel: () => void
}

const path = metaPath<DiscussaoCasoFormModel>()

export const DiscussaoCasoForm = (props: DiscussaoCasoFormProps) => {
  const {
    cuidadoCompartilhadoId,
    initialValues,
    responsavelCuidado,
    handleFormChange,
    handleCancel,
    handleSubmit,
  } = props

  return (
    <Form<DiscussaoCasoFormModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={(renderProps) => (
        <Fragment>
          <FormValueSpy<DiscussaoCasoFormModel> onChange={handleFormChange} />
          <VFlow>
            <VFlow vSpacing={0.5}>
              <Heading level={3}>Discussão</Heading>
              <Box>
                <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhadoId} />
                <FormControl label='Conduta' required>
                  {responsavelCuidado === 'EXECUTANTE' && (
                    <DiscussaoCasoExecutanteFields path={path.executanteFields} />
                  )}
                  {responsavelCuidado === 'SOLICITANTE' && (
                    <DiscussaoCasoSolicitanteFields path={path.solicitanteFields} />
                  )}
                </FormControl>
              </Box>
            </VFlow>
            <HFlow justifyContent='space-between'>
              <Alert inline type='success' styles={{ wrapper: styles.draftAlert }}>
                Rascunho salvo automaticamente às <DateTime format='HH:mm' value={renderProps.values.lastSaved} />
              </Alert>

              <HFlow justifyContent='flex-end'>
                <Button onClick={handleCancel}>Cancelar discussão de caso</Button>
                <SubmitButton handleSubmit={renderProps.handleSubmit}>Registrar discussão de caso</SubmitButton>
              </HFlow>
            </HFlow>
          </VFlow>
        </Fragment>
      )}
    />
  )
}

const styles = {
  draftAlert: css`
    background-color: transparent;
    border-width: 0;
    padding: 0;
  `,
}
