/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, VFlow } from 'bold-ui'
import { tipoEdemaRecord, tipoGravidezRecord } from 'types/enums'
import { isEmpty } from 'util/validation/Util'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { AtendimentoProfissionalPreNatalModel } from '../consulta/HistoricoObjetivoPecPanel'

interface HistoricoMedicoesPreNatalTableBoxProps {
  medicoes: HistoricoMedicaoModel[]
  atendProfPreNatal: AtendimentoProfissionalPreNatalModel
}

export default function HistoricoMedicoesPreNatalTableBox(props: HistoricoMedicoesPreNatalTableBoxProps) {
  const { medicoes, atendProfPreNatal } = props

  return (
    <HistoricoDetailTable title='Medições pré-natal' sectionStyle='O'>
      {medicoes.map((medicao) => (
        <VFlow key={medicao.id}>
          <Grid>
            {atendProfPreNatal.tipoEdema && (
              <Cell size={2}>
                <InfoLabel title='Edema'>{tipoEdemaRecord[atendProfPreNatal.tipoEdema]}</InfoLabel>
              </Cell>
            )}
            {medicao.valorAlturaUterina && (
              <Cell size={2}>
                <InfoLabel title='Altura uterina'>{medicao.valorAlturaUterina} cm</InfoLabel>
              </Cell>
            )}
            {!isEmpty(atendProfPreNatal.gravidezPlanejada) && (
              <Cell size={2}>
                <InfoLabel title='Gravidez planejada'>{atendProfPreNatal.gravidezPlanejada ? 'Sim' : 'Não'}</InfoLabel>
              </Cell>
            )}
            {medicao.valorBatimentoCardiacoFetal && (
              <Cell size={2}>
                <InfoLabel title='Batimento cardíaco fetal'>{medicao.valorBatimentoCardiacoFetal} bpm</InfoLabel>
              </Cell>
            )}
            {!isEmpty(atendProfPreNatal.movimentacaoFetal) && (
              <Cell size={2}>
                <InfoLabel title='Movimento fetal'>{atendProfPreNatal.movimentacaoFetal ? 'Sim' : 'Não'}</InfoLabel>
              </Cell>
            )}
            {medicao.tipoGravidez && (
              <Cell size={2}>
                <InfoLabel title='Tipo de gravidez'>{tipoGravidezRecord[medicao.tipoGravidez.tipoGravidez]}</InfoLabel>
              </Cell>
            )}
          </Grid>
        </VFlow>
      ))}
    </HistoricoDetailTable>
  )
}
