import ReactPDF, { View } from '@react-pdf/renderer'
import React from 'react'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintHFlowProps {
  children: React.ReactNode
  styles?: ReactPDF.Style
}

export const PrintHFlow = (props: PrintHFlowProps) => {
  const { children, styles } = props
  const { config } = usePrinterStyles()
  return <View style={[config.row, styles]}>{children}</View>
}
