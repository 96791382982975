import { Cell, Grid, HeadingSection, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { DefaultFormFooter, ErrorField, Form, FormPrompt, TextField, TipoAcessoSelectField } from 'components/form'
import { PerfilInput, TipoAcesso } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

import { RECURSO_REGISTRAR_ATENDIMENTO_URI, RECURSO_VISUALIZAR_PRONTUARIO_URI } from './model'
import { RecursoTreeField } from './RecursoTreeField'

export type PerfilFormModel = PerfilInput

const meta = metaPath<PerfilFormModel>()

export const validate = createValidator<PerfilFormModel>(
  {
    nome: [required, maxLength(50)],
    tipoPerfil: [required],
    recursos: [required],
  },
  (values, errors) => {
    if (
      values.recursos?.includes(RECURSO_REGISTRAR_ATENDIMENTO_URI) &&
      !values.recursos?.includes(RECURSO_VISUALIZAR_PRONTUARIO_URI)
    ) {
      errors.recursos = 'O recurso registrar atendimento depende de visualizar prontuário'
    }

    return errors
  }
)

export interface PerfilFormProps {
  initialValues?: Partial<PerfilFormModel>
  disableTipoAcesso?: boolean
  disableNome?: boolean
  onSubmit(values: PerfilFormModel): any
  onSubmitSucceeded?(): any
}

export function PerfilForm(props: PerfilFormProps) {
  const { onSubmit, onSubmitSucceeded, initialValues, disableTipoAcesso, disableNome } = props

  const { data: sessao } = useSession()

  const handleTreeChange = (formProps: FormRenderProps) => (value: string[]) => formProps.form.change('recursos', value)

  const parseUpper = (value) => {
    return value.toUpperCase()
  }

  const renderForm = (formProps: FormRenderProps) => {
    const values = formProps.values as PerfilFormModel

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <VFlow>
          <Grid wrap>
            <Cell xs={12} md={4}>
              <TextField name={meta.nome} label='Nome' disabled={disableNome} parse={parseUpper} required />
            </Cell>
            <Cell xs={12} md={4}>
              <TipoAcessoSelectField
                items={createTipoPerfilItems(sessao.acesso.tipo)}
                name={meta.tipoPerfil}
                label='Tipo de acesso'
                disabled={disableTipoAcesso}
                required
              />
            </Cell>
          </Grid>

          {values.tipoPerfil ? (
            <HeadingSection level={2} title='Recursos'>
              <VFlow>
                <RecursoTreeField
                  value={values.recursos}
                  tipoPerfil={values.tipoPerfil}
                  onChange={handleTreeChange(formProps)}
                />
                <ErrorField name={meta.recursos.absolutePath()} />
              </VFlow>
            </HeadingSection>
          ) : (
            'Selecione um tipo de acesso para mostrar a árvore de recursos.'
          )}

          <DefaultFormFooter handleSubmit={formProps.handleSubmit} />
        </VFlow>
      </form>
    )
  }
  return (
    <Form
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      render={renderForm}
      validate={validate}
      initialValues={initialValues}
    />
  )
}

const createTipoPerfilItems = (tipo: TipoAcesso) => {
  switch (tipo) {
    case TipoAcesso.ADMINISTRADOR_GERAL:
      return [TipoAcesso.ADMINISTRADOR_MUNICIPAL, TipoAcesso.GESTOR_MUNICIPAL, TipoAcesso.GESTOR_ESTADUAL]
    case TipoAcesso.GESTOR_ESTADUAL:
      return [TipoAcesso.ADMINISTRADOR_MUNICIPAL, TipoAcesso.GESTOR_MUNICIPAL]
    case TipoAcesso.ADMINISTRADOR_MUNICIPAL:
      return [TipoAcesso.GESTOR_MUNICIPAL, TipoAcesso.LOTACAO]
  }

  return []
}
