/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, TableCell, TableRow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Fragment, useMemo } from 'react'

import { HistoricoEvolucoesOdontoTableRowModel } from '../../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { getParteBucal, showDescricaoCodigoProcedimento } from '../../util'

type HistoricoEvolucoesPeriodontaisTableRowModel = HistoricoEvolucoesOdontoTableRowModel

interface HistoricoEvolucoesPeriodontaisTableRowProps {
  item: HistoricoEvolucoesPeriodontaisTableRowModel
}

export function HistoricoEvolucoesPeriodontaisTableRow(props: HistoricoEvolucoesPeriodontaisTableRowProps) {
  const { item } = props

  const classes = useMemo(() => createStyles(!!item.observacao), [item.observacao])

  return (
    <Fragment>
      <TableRow>
        <TableCell
          style={[
            classes.column,
            css`
              width: 8rem;
              max-width: 8rem;
            `,
          ]}
        >
          <DateTime value={item.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm} format='DD/MM/YYYY' />
        </TableCell>
        <TableCell
          style={[
            classes.column,
            css`
              min-width: 7rem;
            `,
          ]}
        >
          {renderLocal(item)}
        </TableCell>
        <TableCell style={classes.column}>{renderProcedimento(item)}</TableCell>
        <TableCell
          style={[
            classes.column,
            css`
              min-width: 10rem;
            `,
          ]}
        >
          {renderProfissional(item)}
        </TableCell>
      </TableRow>
      {item.observacao && (
        <TableRow>
          <TableCell
            colSpan={4}
            style={css`
              height: auto;
              border-top: 0;
              padding-bottom: 0.375rem;
            `}
          >
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Observações: </Text>
              <Text
                fontStyle='italic'
                style={css`
                  word-break: break-all;
                `}
              >
                <div dangerouslySetInnerHTML={{ __html: item.observacao }} />
              </Text>
            </HFlow>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const renderLocal = (item: HistoricoEvolucoesPeriodontaisTableRowModel) => (
  <VFlow vSpacing={0}>
    {item.partesBucais?.map((parteBucal) => (
      <Text>{getParteBucal(parteBucal.parteBucalEnum).title}</Text>
    ))}
  </VFlow>
)

const renderProcedimento = (item: HistoricoEvolucoesPeriodontaisTableRowModel) =>
  item?.procedimentos?.length > 0 && (
    <VFlow vSpacing={0}>
      {item?.procedimentos?.map((proced) => (
        <Text key={proced.id}>{showDescricaoCodigoProcedimento(proced)}</Text>
      ))}
    </VFlow>
  )

const renderProfissional = (item: HistoricoEvolucoesPeriodontaisTableRowModel) => (
  <VFlow vSpacing={0}>
    <Text>{item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.profissional.nome.titleCase()}</Text>
    <Text>{item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.cbo.nome}</Text>
  </VFlow>
)

const createStyles = (removeBorderBottom?: boolean) => ({
  column: css`
    padding: 0.375rem 1rem;
    ${removeBorderBottom && `border-bottom: 0;`}
  `,
})
