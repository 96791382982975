import { Icons } from 'bold-ui'
import { blue, ColorScale, green, orange, red, yellow } from 'bold-ui/lib/styles/colors'
import { Color } from 'csstype'
import {
  ClassificacaoRiscoCuidadoEnum,
  CondutaCuidadoCompartilhadoEnum,
  CuidadoCompartilhadoEvolucoesQuery,
  CuidadoCompartilhadoQuery,
  ListaCuidadoCompartilhadoQuery,
  PageParams,
  StatusCuidadoCompartilhadoEnum,
} from 'graphql/types.generated'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ListaCuidadoCompartilhadoInlineFilterModel } from './lista-cuidados-compartilhados/ListaCuidadoCompartilhadoFilter'

export type CuidadoCompartilhadoItem = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']['content'][0]

export type CuidadoCompartilhadoItemActions = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']['content'][0]['actions']

export type CuidadoCompartilhadoListingQueryPayload = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']

export type ListaCuidadoCompartilhadoFilterModel = ListaCuidadoCompartilhadoInlineFilterModel & {
  pageParams?: PageParams
}

export type CuidadoCompartilhadoModel = CuidadoCompartilhadoQuery['cuidadoCompartilhado']
export type CuidadoCompartilhadoEvolucaoModel = CuidadoCompartilhadoEvolucoesQuery['cuidadoCompartilhadoEvolucoes']['content'][0]

export type CidadaoCuidadoCompartilhado = (CidadaoAtendimento | CidadaoFolhaRosto) & {
  isGestante: boolean
}

export interface DiscussaoCasoFormModel {
  executanteFields?: DiscussaoCasoExecutanteFieldsModel
  solicitanteFields?: DiscussaoCasoSolicitanteFieldsModel
  lastSaved?: Instant
}

export interface DiscussaoCasoExecutanteFieldsModel {
  condutaGroup: DiscussaoCondutaViewEnum
  conduta: CondutaCuidadoCompartilhadoEnum
  resposta: string
}

export interface DiscussaoCasoSolicitanteFieldsModel {
  condutaGroup: DiscussaoCondutaViewEnum
  pergunta: string
}

export enum DiscussaoCondutaViewEnum {
  DEVOLUTIVA_DISCUSSAO_CASO = 'DEVOLUTIVA_DISCUSSAO_CASO',
  NOVA_PERGUNTA = 'NOVA_PERGUNTA',
}

export const ClassificacaoRiscoCuidadoRecord: Record<
  ClassificacaoRiscoCuidadoEnum,
  { descricao: string; cor?: Color }
> = {
  BAIXA: { descricao: 'Baixa', cor: blue.c40 },
  MEDIA: { descricao: 'Média', cor: green.c40 },
  ALTA: { descricao: 'Alta', cor: yellow.c60 },
  MUITO_ALTA: { descricao: 'Muito alta', cor: red.c40 },
}

export const StatusCuidadoRecord: Record<
  StatusCuidadoCompartilhadoEnum,
  { descricao: string; cor: ColorScale; icon: Icons }
> = {
  AGUARDANDO: { descricao: 'Aguardando', cor: orange, icon: 'exclamationTriangleFilled' },
  RESPONDIDO: { descricao: 'Respondido', cor: green, icon: 'checkCircleFilled' },
}

export const condutasResposta = [
  CondutaCuidadoCompartilhadoEnum.OPINIAO_FORMATIVA,
  CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO,
  CondutaCuidadoCompartilhadoEnum.PRESTAR_ESCLARECIMENTO,
]

export type ResponsavelCuidadoCompartilhado = 'EXECUTANTE' | 'SOLICITANTE'

export const ActionEvolucaoCuidadoCompartilhadoRecord: Record<CondutaCuidadoCompartilhadoEnum, string> = {
  NOVA_PERGUNTA: 'compartilhou cuidado',
  PRESTAR_ESCLARECIMENTO: 'respondeu',
  OPINIAO_FORMATIVA: 'respondeu',
  PARECER_TECNICO_CONCLUSIVO: 'respondeu',
}

export const LabelEvolucaoCuidadoCompartilhadoRecord: Record<CondutaCuidadoCompartilhadoEnum, string> = {
  NOVA_PERGUNTA: 'Nova pergunta',
  PRESTAR_ESCLARECIMENTO: 'Prestar esclarecimento',
  OPINIAO_FORMATIVA: 'Opinião formativa',
  PARECER_TECNICO_CONCLUSIVO: 'Parecer técnico conclusivo',
}
