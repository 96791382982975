import { SelectField, SelectFieldProps } from 'components/form'
import { TipoGravidez, TipoGravidezEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'

export interface TipoGravidezSelectFieldModel {
  value: TipoGravidezEnum
  label: string
}

export interface TipoGravidezSelectFieldProps
  extends Omit<SelectFieldProps<TipoGravidezSelectFieldModel>, 'items' | 'itemToString' | 'initialValue'> {
  showAllEnumValues?: boolean
  initialValue?: TipoGravidez
}

export default function TipoGravidezSelectField(props: TipoGravidezSelectFieldProps) {
  const { showAllEnumValues = false, initialValue, ...rest } = props
  const items = showAllEnumValues ? allItems : itemsPuericultura

  const valorInicial = useMemo(
    () =>
      initialValue
        ? {
            value: initialValue.tipoGravidez,
            label: initialValue.descricao,
          }
        : undefined,
    [initialValue]
  )

  return (
    <SelectField<TipoGravidezSelectFieldModel>
      label='Tipo de gravidez'
      items={items}
      itemToString={(i) => i?.label}
      initialValue={valorInicial}
      {...rest}
    />
  )
}

const itemsCommon: TipoGravidezSelectFieldModel[] = [
  {
    value: TipoGravidezEnum.UNICA,
    label: 'Única',
  },
  {
    value: TipoGravidezEnum.DUPLA_GEMELAR,
    label: 'Dupla / Gemelar',
  },
  {
    value: TipoGravidezEnum.TRIPLA_OU_MAIS,
    label: 'Tripla ou mais',
  },
]

export const itemsPuericultura: TipoGravidezSelectFieldModel[] = [...itemsCommon]

const allItems: TipoGravidezSelectFieldModel[] = [
  ...itemsCommon,
  { value: TipoGravidezEnum.IGNORADA, label: 'Ignorada' },
]
