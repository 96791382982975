import axios, { AxiosPromise } from 'axios'

export interface ImpressaoAgendamentoInput {
  lotacaoId: ID
  date: Date
  isAgendaAd: Boolean
  nomeProfissional: string
}

export class ImpressaoAgendamentosDia {
  gerarAgendamentos(input: ImpressaoAgendamentoInput): AxiosPromise<Blob> {
    return axios.post('/api/agendamento/gerarImpressao', input, { responseType: 'blob' })
  }
}
