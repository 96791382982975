import { FormControl, VFlow } from 'bold-ui'
import { Form, FormRenderProps, TextAreaField } from 'components/form'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { ParteBucalEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { getArcadas, getParteBucal, getPartesBucalComProcedimento } from '../../../util'
import OdontogramaPopper, { OdontogramaPopperProps } from '../components/OdontogramaPopper'
import ReplicarItemSelectField, {
  convertParteBucalEnumToReplicarItem,
  ReplicarItemSelectFieldModel,
} from '../components/ReplicarItemSelectField'
import { ArcadaFieldModel } from './ArcadasField'

export interface ArcadaFormPopperProps extends Omit<OdontogramaPopperProps, 'title' | 'onClose'> {
  parteBucalEnum: ParteBucalEnum
  initialValues?: FormRenderProps<ArcadaFormModel>['initialValues']
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  arcadasValues: Record<ParteBucalEnum, ArcadaFieldModel>
  onSubmit(values: ArcadaFieldModel, formApi: FormApi<ArcadaFieldModel>): void
}

export interface ArcadaFormModel extends ArcadaFieldModel {
  replicarItems?: ReplicarItemSelectFieldModel[]
}

const meta = metaPath<ArcadaFormModel>()

const arcadaFormDecorator = createDecorator({
  field: meta.procedimentos.absolutePath(),
  updates: {
    [meta.observacoes.absolutePath()]: (value: ProcedimentoOdontoSelectModel[], allValues: ArcadaFormModel) => {
      if (value.length > 0) return allValues.observacoes
    },
    [meta.replicarItems.absolutePath()]: (value: ProcedimentoOdontoSelectModel[], allValues: ArcadaFormModel) => {
      if (value.length > 0) return allValues.replicarItems
    },
  },
})

export default function ArcadaFormPopper(props: ArcadaFormPopperProps) {
  const {
    parteBucalEnum,
    cidadaoDataNascimento,
    dataAtendimento,
    initialValues,
    arcadasValues,
    onSubmit,
    ...popperProps
  } = props

  const [itemsDisabled, setItemsDisabled] = useState<ParteBucalEnum[]>()
  const [replicarItems, setReplicarItems] = useState<ReplicarItemSelectFieldModel[]>()
  const arcada = getParteBucal(parteBucalEnum)

  useEffect(() => {
    setReplicarItems(convertParteBucalEnumToReplicarItem(getArcadas()).filter((item) => item.value !== parteBucalEnum))
  }, [parteBucalEnum])

  useEffect(() => {
    arcadasValues && setItemsDisabled(getPartesBucalComProcedimento(arcadasValues))
  }, [arcadasValues])

  const renderForm = (formProps: FormRenderProps<ArcadaFormModel>) => {
    const disabled = !formProps?.values?.procedimentos?.length

    return (
      <OdontogramaPopper title={arcada.title} onClose={formProps.handleSubmit} {...popperProps}>
        <VFlow>
          <ProcedimentoOdontoSelectField
            name={meta.procedimentos}
            label='Procedimentos (Evolução odontológica)'
            multiple
            dataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            parteBucal={parteBucalEnum}
          />

          <FormControl label='Observações'>
            <TextAreaField
              name={meta.observacoes[0]}
              maxLength={MAX_LENGTH_OBS_ODONTO}
              placeholder='Insira informações adicionais sobre os procedimentos realizados'
              style={{ resize: 'none' }}
              disabled={disabled}
            />
          </FormControl>

          <ReplicarItemSelectField
            label='Replicar informações para'
            name={meta.replicarItems}
            items={replicarItems}
            itemsDisabled={itemsDisabled}
            disabled={disabled}
          />
        </VFlow>
      </OdontogramaPopper>
    )
  }

  return (
    <Form<ArcadaFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[arcadaFormDecorator]}
    />
  )
}
