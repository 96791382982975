import { useCallback } from 'react'

import {
  CboAndPermissionAuthorization,
  hasPermissionByTipoEstabelecimento,
  PermissionAuthorization,
  TipoEstabelecimentoAuthorization,
} from './model-authorization'
import { useCheckPermissionAndCboAuthorization } from './useCheckModuleAuthorization'
import useSession from './useSession'

type CheckPermissionProps = ((PermissionAuthorization | CboAndPermissionAuthorization) &
  TipoEstabelecimentoAuthorization) & {
  children: any
  acceptEstagio?: boolean
}

const CheckPermission = (props: CheckPermissionProps) => {
  const { acceptEstagio = true, children } = props
  const { isEstagio, hasTipoEstabelecimentoAuth } = useSession({ fetchPolicy: 'cache-only' })
  const checkPermissionAndCboAuthorization = useCheckPermissionAndCboAuthorization()

  const hasAuthorization = useCallback(
    (props: CheckPermissionProps) => {
      if (!acceptEstagio && isEstagio) {
        return false
      } else if (hasPermissionByTipoEstabelecimento(props) && !hasTipoEstabelecimentoAuth(props.tiposEstabelecimento)) {
        return false
      } else {
        return checkPermissionAndCboAuthorization(props as PermissionAuthorization | CboAndPermissionAuthorization)
      }
    },
    [acceptEstagio, checkPermissionAndCboAuthorization, hasTipoEstabelecimentoAuth, isEstagio]
  )

  return hasAuthorization(props) ? children : null
}

export default CheckPermission
