import { Atestado, AtestadoQueryInput } from 'graphql/types.generated'
import moment from 'moment'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { RegistroLista } from './AtestadosTable'

export const convertAtestadosFromDatabase = (atestado: Atestado) => {
  return {
    id: atestado.id,
    atendimentoProfissional: atestado.atendimentoProfissional,
    recente: false,
  } as RegistroLista
}
export const convertRecentes = (id: ID, dataReferencia: Instant, lotacao: LotacaoAtendimento | LotacaoFolhaRosto) => {
  return {
    id: id,
    atendimentoProfissional: { iniciadoEm: dataReferencia, lotacao },
    recente: true,
  } as RegistroLista
}

export const filterAtestados = (filter: AtestadoQueryInput, profissionalId: ID) => (value: RegistroLista) => {
  const queryNoAccents =
    filter.query !== undefined &&
    filter?.query
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

  const searchTextFilter = () =>
    !filter.query ||
    value?.atendimentoProfissional?.lotacao.profissional.nome.toLowerCase().includes(queryNoAccents) ||
    value?.atendimentoProfissional?.lotacao.unidadeSaude.nome.toLowerCase().includes(queryNoAccents)

  const checkFilter = () =>
    !filter.somenteMeusAtestados || value?.atendimentoProfissional?.lotacao.profissional.id === profissionalId

  const cboFilter = () => !filter.cboId || value?.atendimentoProfissional?.lotacao.cbo.id === filter.cboId

  const periodFilter = () =>
    (!filter.periodo?.startDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrAfter(filter.periodo?.startDate, 'date')) &&
    (!filter.periodo?.endDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrBefore(filter.periodo?.endDate, 'date'))

  return searchTextFilter() && checkFilter() && cboFilter() && periodFilter()
}
