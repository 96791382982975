import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageLoading } from 'components/loading'
import { useCuidadoCompartilhadoQuery } from 'graphql/hooks.generated'
import { StatusCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import React from 'react'
import { Redirect, useParams } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { getResponsabilidadeCuidadoCompartilhado } from '../util'
import { CuidadoCompartilhadoView } from './CuidadoCompartilhadoView'

export const CuidadoCompartilhadoRootView = () => {
  const { id } = useParams<{ id: string }>()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const {
    data: { cuidadoCompartilhado },
    loading: isLoading,
  } = useCuidadoCompartilhadoQuery({
    variables: {
      id,
    },
  })

  if (isLoading) {
    return <PageLoading message='Carregando dados do cuidado compartilhado...' />
  } else if (isUndefinedOrNull(cuidadoCompartilhado)) {
    return <Redirect to='/404' />
  }

  const responsavel = getResponsabilidadeCuidadoCompartilhado(
    acesso.id,
    cuidadoCompartilhado.lotacaoExecutante.id,
    cuidadoCompartilhado.lotacaoSolicitante.id
  )

  if (
    (responsavel === 'EXECUTANTE' && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.RESPONDIDO) ||
    (responsavel === 'SOLICITANTE' && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.AGUARDANDO)
  ) {
    return <Redirect to='/405' />
  }

  return <CuidadoCompartilhadoView cuidadoCompartilhado={cuidadoCompartilhado} />
}
