import { Cell, Grid, HFlow } from 'bold-ui'
import { RadioField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { RelatoriosGerenciaisUnidadeMedidaTempoEnum } from '../../common/model'

interface UnidadePeriodoRadioFieldProps {
  name: MetaPath<RelatoriosGerenciaisUnidadeMedidaTempoEnum>
  disabled: boolean
}

export function UnidadePeriodoRadioField(props: UnidadePeriodoRadioFieldProps) {
  const { name, disabled } = props

  return (
    <Grid wrap>
      <HFlow>
        <Cell sm={6} xs={12}>
          <RadioField
            name={name}
            value={RelatoriosGerenciaisUnidadeMedidaTempoEnum.MES}
            label='Mensal'
            disabled={disabled}
          />
        </Cell>
        <Cell sm={6} xs={12}>
          <RadioField
            name={name}
            value={RelatoriosGerenciaisUnidadeMedidaTempoEnum.SEMANA}
            label='Semanal'
            disabled={disabled}
          />
        </Cell>
        <Cell sm={12} xs={12}>
          <RadioField
            name={name}
            value={RelatoriosGerenciaisUnidadeMedidaTempoEnum.DIA}
            label='Personalizado'
            disabled={disabled}
          />
        </Cell>
      </HFlow>
    </Grid>
  )
}
