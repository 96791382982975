import { ApolloQueryResult } from 'apollo-client'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { SubmitButton } from 'components/form/final-form/SubmitButton'
import { TextField } from 'components/form/final-form/TextField'
import { FormPrompt } from 'components/form/FormPrompt'
import { useSalvarGrupoExameMutation } from 'graphql/hooks.generated'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { arrayMaxLength, createValidator, maxLength, required } from 'util/validation'

import { ProcedimentoGrupoExameField, ProcedimentoGrupoExameFieldModel } from './components/ProcedimentoGrupoExameField'

const validate = createValidator<GrupoExamesFormModel>({
  nome: [required, maxLength(30)],
  procedimentos: [required, arrayMaxLength(16)],
})

export interface GrupoExamesFormModel {
  id: ID
  nome: string
  procedimentos: Array<ProcedimentoGrupoExameFieldModel>
}

interface GrupoExamesModalFormProps {
  initialValues?: GrupoExamesFormModel
  isEdit?: boolean
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export const meta = metaPath<GrupoExamesFormModel>()

export const GrupoExamesModalForm = (props: GrupoExamesModalFormProps) => {
  const { isEdit, refetch } = props
  const history = useHistory()
  const alert = useAlert()
  const { analytics } = useFirebase()
  const [salvar] = useSalvarGrupoExameMutation()

  const goBack = () => {
    history.push('/configuracoes/gestaomunicipal/gpexames')
  }

  const handleSubmit = (values) => {
    return salvar({
      variables: {
        input: {
          id: values.id,
          nome: values.nome,
          procedimentos: values.procedimentos.map((value) => value.procedimento.id),
        },
      },
    }).then(() => {
      const mensagem = isEdit ? 'editado' : 'adicionado'
      if (!isEdit) analytics.logEvent('add_grupo_exames')
      alert('success', `Grupo de exames ${mensagem} com sucesso.`)
      return refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps<GrupoExamesFormModel>) => {
    const title = isEdit ? 'Editar grupo de exames' : 'Adicionar grupo de exames'
    const buttonLabel = isEdit ? 'Salvar' : 'Adicionar'

    return (
      <Modal open={true} onClose={goBack} size='auto' closeOnBackdropClick={false} style={{ width: '832px' }}>
        <ModalBody>
          <FormPrompt />
          <Grid alignItems='flex-start'>
            <Cell size={12}>
              <Heading level={1}>{title}</Heading>
            </Cell>
            <Cell size={6}>
              <TextField name={meta.nome} label='Nome do grupo' maxLength={30} required />
            </Cell>
            <Cell size={12}>
              <ProcedimentoGrupoExameField name={meta.procedimentos} />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack}>Cancelar</Button>
            <SubmitButton kind='primary' handleSubmit={formProps.handleSubmit}>
              {buttonLabel}
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<GrupoExamesFormModel>
      initialValues={props.initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validate}
      onSubmitSucceeded={goBack}
      suppressNotificationError
    />
  )
}
