/**
 * Palavras que devem permanecer em caixa alta
 *
 * Ao adicionar uma nova excecao, se ela for composta por mais de uma palavra, adicione cada uma separadamente
 * para os metodos do string-extensions funcionarem corretamente. Por exemplo, se quiser adicionar
 * a excecao 'EAB II', voce deve adicionar 'EAB' e 'II' na lista.
 */
export const uppers: string[] = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'ACE',
  'ASB',
  'ACS',
  'AD',
  'TSB',
  'NASF',
  'UBS',
  'CS',
  'CIAP2',
  'CID10',
  'SIGTAP',
  'EN',
  'ESF',
  'ESFSB_M1',
  'ESFSB_M2',
  'EACS',
  'EPEN',
  'NASF1',
  'ENASF2',
  'EMSI',
  'EMSIAL',
  'EACSSB_M1',
  'EACSSB_M2',
  'ESFR',
  'ESFRSB_MI',
  'ESFF',
  'ESFFSB',
  'EAB',
  'EAB1',
  'EAB2',
  'EAB3',
  'EAB1SB',
  'EAB2SB',
  'EAB3SB',
  'EMAD1',
  'EMAP',
  'ESB',
  'ESF1',
  'ESF1SB_M1',
  'ESF1SB_M2',
  'ESF2',
  'ESF2SB_M1',
  'ESF2SB_M2',
  'ESF3',
  'ESF3SB_M1',
  'ESF3SB_M2',
  'ESF4',
  'ESF4SB_M1',
  'ESF4SB_M2',
  'SFTRANS',
  'ESFTRANSSB_M1',
  'ESFTRANSSB_M2',
  'ESFRSB_MII',
  'ECR',
  'ESB',
  'MI',
  'MII',
  'MIII',
  'NASF3',
  'EMAD2',
  'ECD',
  'ESSE',
  'EAP',
  'EABP-I',
  'ABP-I',
  'EABP-II',
  'EABP-II',
  'EABP-III',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'IGG',
  'IGM',
  'HBSAG',
  'ABO',
  'TIA',
  '(TIA)',
  'HIV',
  'HIV-2',
  'EAS',
  'EQU',
  'ANTI-HIV-1',
  'HDL',
  'LDL',
  'BITE',
  'WING',
  'TRA/ART',
  'GUNA',
  '(BITE',
  'WING)',
  '(TRA/ART)',
  '(GUNA)',
  'CEO',
]
