import { Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { CheckJustificativaAcessoProntuario } from 'view/prontuario/justificativa/CheckJustificativaAcessoProntuario'

import { CuidadoCompartilhadoHeaderTabs } from '../components/CuidadoCompartilhadoHeaderTabs'
import { DiscussaoCasoPageContent } from '../components/DiscussaoCasoPageContent'
import { CuidadoCompartilhadoEvolucoes } from '../cuidado-compartilhado/components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { CuidadoCompartilhadoPanel } from '../cuidado-compartilhado/components/CuidadoCompartilhadoPanel'
import { CidadaoCuidadoCompartilhado, CuidadoCompartilhadoModel } from '../model-cuidadocompartilhado'

interface VisualizarCuidadoCompartilhadoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const VisualizarCuidadoCompartilhadoView = (props: VisualizarCuidadoCompartilhadoViewProps) => {
  const { cuidadoCompartilhado } = props
  const match = useRouteMatch()

  const renderDiscussaoCaso = (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => (
    <CheckJustificativaAcessoProntuario
      cidadaoId={cidadao.id}
      prontuarioId={cuidadoCompartilhado.prontuario.id}
      basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
    >
      <DiscussaoCasoPageContent
        cuidadoCompartilhado={cuidadoCompartilhado}
        cidadao={cidadao}
        headerHeight={headerHeight}
      >
        <VFlow>
          <CuidadoCompartilhadoPanel cuidadoCompartilhado={cuidadoCompartilhado} />

          <VFlow vSpacing={0.5}>
            <Heading level={3}>Discussão</Heading>

            <Box>
              <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhado.id} removeLastLine />
            </Box>
          </VFlow>
        </VFlow>
      </DiscussaoCasoPageContent>
    </CheckJustificativaAcessoProntuario>
  )

  return (
    <>
      <Breadcrumb title='Visualizar discussão' />
      <CuidadoCompartilhadoHeaderTabs
        cuidadoCompartilhado={cuidadoCompartilhado}
        renderDiscussaoCaso={renderDiscussaoCaso}
        isVisualizacao={true}
      />
    </>
  )
}
