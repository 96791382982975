import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Form, FormProps } from 'components/form'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { Cbo, Lotacao } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import React from 'react'
import { isCboMedicoOuDentista } from 'types/NivelCbo'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath, MetaRoot } from 'util/metaPath'
import {
  createValidator,
  ErrorObject,
  oneRequired,
  required,
  requiredRichText,
  richTextMaxLength,
} from 'util/validation'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoFormProps
  extends Omit<FormProps<CuidadoCompartilhadoPlanoModel>, 'validate' | 'decorators'> {}

const validator = (isSomenteCiap: boolean) =>
  createValidator<CuidadoCompartilhadoPlanoModel>(
    {
      lotacao: [required],
      prioridade: [required],
      discussao: [requiredRichText, richTextMaxLength(1000)],
      cidadaoAceitaAtendTic: [required],
    },
    (values: CuidadoCompartilhadoPlanoModel, errors: ErrorObject<CuidadoCompartilhadoPlanoModel>) => {
      if (isSomenteCiap) {
        errors.problemasECondicoes = { ciap: required(values.problemasECondicoes?.ciap) }
      } else {
        errors.problemasECondicoes = oneRequired([values.problemasECondicoes?.ciap, values.problemasECondicoes?.cid10])
      }

      return errors
    }
  )

const calculator = (meta: MetaRoot<CuidadoCompartilhadoPlanoModel>) => [
  {
    field: meta.lotacao.absolutePath(),
    updates: {
      [meta.cbo.absolutePath()]: (value: Lotacao, _, prevValues: CuidadoCompartilhadoPlanoModel) => {
        if (!isUndefinedOrNull(value)) return value.cbo
        return prevValues.cbo
      },
    },
  },
  {
    field: meta.cbo.absolutePath(),
    updates: {
      [meta.lotacao.absolutePath()]: (
        value: Cbo,
        allValues: CuidadoCompartilhadoPlanoModel,
        prevValues: CuidadoCompartilhadoPlanoModel
      ) => {
        return isUndefinedOrNull(value) || (!isEmpty(prevValues) && value?.id !== prevValues.lotacao?.cbo?.id)
          ? null
          : allValues.lotacao
      },
    },
  },
]

const meta = metaPath<CuidadoCompartilhadoPlanoModel>()
const decorator = createDecorator(...calculator(meta))

export const CuidadoCompartilhadoForm = (props: CuidadoCompartilhadoFormProps) => {
  const { onSubmit, ...rest } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const isMedicoOuDentista = isCboMedicoOuDentista(acesso.cbo.cbo2002)

  const handleSubmit = (values: CuidadoCompartilhadoPlanoModel, formApi: FormApi<CuidadoCompartilhadoPlanoModel>) => {
    onSubmit(values, formApi)
    setTimeout(formApi.reset)
  }

  return (
    <Form<CuidadoCompartilhadoPlanoModel>
      validate={validator(!isMedicoOuDentista)}
      decorators={[decorator]}
      onSubmit={handleSubmit}
      {...rest}
    />
  )
}
