import useSession from 'components/auth/useSession'
import { LotacaoOuEstagio, SessaoDataFragment } from 'graphql/types.generated'

import { isLotacaoOrEstagio } from './useSessionUtils'

type Profissional = SessaoDataFragment['profissional']

interface AcessoLotacaoOrEstagioResult
  extends Pick<ReturnType<typeof useSession>, 'hasAuthorization' | 'refresh' | 'tipoEstabelecimento'> {
  acesso: LotacaoOuEstagio
  profissional: Profissional
}

export const useAcessoLotacaoOrEstagio = (): AcessoLotacaoOrEstagioResult => {
  const {
    data: { acesso, profissional },
    tipoEstabelecimento,
    hasAuthorization,
    refresh,
  } = useSession()

  return isLotacaoOrEstagio(acesso) ? { acesso, profissional, tipoEstabelecimento, hasAuthorization, refresh } : null
}
