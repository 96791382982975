/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Tag, useTheme, VFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { confirm } from 'components/modals/confirm'
import { PivotTableProps } from 'components/pivot-table/PivotTable'
import { Aggregator } from 'components/pivot-table/types'
import { ReactElement, useState } from 'react'
import { DndProvider } from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

import { Box } from '../box/Box'
import { Dropable } from '../dragndrop/Dropable'
import { ItemTypes } from '../dragndrop/types/ItemTypes'
import { Aggregators } from './Aggregators'

interface BoardProps<T extends any> {
  keys: Map<keyof T, Array<string>>
  keyMapping: PivotTableProps<T>['keyMapping']
  numberKeys: string[]
  isBuilding: boolean
  handleSubmit: (values: [Array<keyof T>, Array<keyof T>], filterValues: Map<keyof T, Set<string>>) => void
  handleReset: () => void
  handleAggregatorChange: (aggregator: Aggregator) => void
  handleAggregatorKeyChange: (key: keyof T) => void
  aggregator: Aggregator
  aggregatorKey: keyof T
}

export function Board<T extends any>(props: BoardProps<T>) {
  const {
    keys,
    keyMapping,
    handleSubmit,
    numberKeys,
    isBuilding,
    handleAggregatorChange,
    handleAggregatorKeyChange,
    handleReset,
    aggregator,
    aggregatorKey,
  } = props

  const deepCopy = new Map<keyof T, Set<string>>()

  keys.forEach((value, key) => deepCopy.set(key, new Set(value)))

  const [availableKeys, setAvailableKeys] = useState<Array<keyof T>>(Array.from(keys.keys()))
  const [rowKeys, setRowKeys] = useState<Array<keyof T>>([])
  const [columnKeys, setColumnKeys] = useState<Array<keyof T>>([])
  const [filterState, setFilterState] = useState<Map<keyof T, Set<string>>>(new Map<keyof T, Set<string>>(deepCopy))
  const theme = useTheme()

  const onTableCreate = () => {
    handleSubmit([rowKeys, columnKeys], filterState)
  }
  const onTableReset = () => {
    confirm({
      title: 'Deseja limpar a tabela ?',
      type: 'danger',
      onConfirm: () => {
        handleUpdateColumnKeys([])
        handleUpdateRowKeys([])
        handleLimparFiltros()
        handleReset()
        handleUpdateAvailableKeys(Array.from(keys.keys()))
      },
      confirmLabel: 'Limpar',
    })()
  }

  const onKeyNav = (key: keyof T, dir: 'left' | 'right', origin: 'campos_disponiveis' | 'linhas' | 'colunas') => {
    if (origin === 'campos_disponiveis') {
      dir === 'right' && setColumnKeys([...columnKeys, key])
      dir === 'left' && setRowKeys([...rowKeys, key])
    } else if (origin === 'colunas') {
      dir === 'right' && setRowKeys([...rowKeys, key])
      dir === 'left' && setAvailableKeys([...availableKeys, key])
    } else if (origin === 'linhas') {
      dir === 'right' && setColumnKeys([...columnKeys, key])
      dir === 'left' && setAvailableKeys([...availableKeys, key])
    }
  }
  const handleUpdateAvailableKeys = (availableKeys: Array<keyof T>) => setAvailableKeys(availableKeys)
  const handleUpdateRowKeys = (rowKeys: Array<keyof T>) => setRowKeys(rowKeys)
  const handleUpdateColumnKeys = (columnKeys: Array<keyof T>) => setColumnKeys(columnKeys)

  const handleFilterUpdate = (key: keyof T, filtro: Set<string>) => {
    if (filtro.size < 1) {
      filterState.delete(key)
    } else {
      filterState.set(key, filtro)
    }
    setFilterState(new Map(filterState))
  }

  const handleTagFilterRemove = (key: keyof T, value: string) => {
    const values = filterState.get(key) || new Set<string>()
    values?.delete(value)
    handleFilterUpdate(key, values)
  }

  const handleLimparFiltros = () => {
    setFilterState(deepCopy)
  }

  const filterValuesTags: ReactElement[] = []

  for (let [key, values] of filterState) {
    const tags: ReactElement[] = []

    if (keys.get(key)?.length === values.size) {
      continue
    }

    let idx = 0
    for (let value of values) {
      if (idx < 3) {
        tags.push(
          <Tag key={value} removable onRemove={() => handleTagFilterRemove(key, value)} style={tagStyle}>
            {keyMapping.get(key).formatter?.(value) ?? value}
          </Tag>
        )
      } else {
        break
      }
      idx++
    }

    if (values.size > 3) {
      tags.push(
        <Tag key={key as string} type='info' style={tagStyle}>
          {`+ ${values.size - 3} ${keyMapping.get(key).keyName}`}
        </Tag>
      )
    }

    filterValuesTags.push(
      <HFlow hSpacing={0.25} alignItems='center' key={key as string}>
        <div>{`${keyMapping.get(key).keyName}`}</div>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
          `}
        >
          {tags}
        </div>
      </HFlow>
    )
  }

  const filterValuesBox = <VFlow vSpacing={0.5}>{filterValuesTags}</VFlow>

  return (
    <DndProvider options={HTML5toTouch}>
      <Grid>
        <Cell md={6} sm={12} xs={12}>
          <Box label='Campos disponíveis'>
            <Dropable<T>
              keyState={availableKeys}
              filterState={filterState}
              type={ItemTypes.FILTER}
              keyMapping={keyMapping}
              keys={keys}
              handleKeyUpdate={handleUpdateAvailableKeys}
              handleFilterUpdate={handleFilterUpdate}
              onKeyNav={onKeyNav}
              id='campos_disponiveis'
            />
          </Box>
        </Cell>
        <Cell md={6} sm={12} xs={12}>
          <Box label='Colunas' icon='hamburguerMenu' rotation='90'>
            <Dropable<T>
              id='colunas'
              keyState={columnKeys}
              filterState={filterState}
              keyMapping={keyMapping}
              keys={keys}
              handleKeyUpdate={handleUpdateColumnKeys}
              handleFilterUpdate={handleFilterUpdate}
              onKeyNav={onKeyNav}
              type={ItemTypes.FILTER}
            />
          </Box>
        </Cell>
        <Cell md={6} sm={12} xs={12}>
          <Box label='Linhas' icon='hamburguerMenu'>
            <Dropable<T>
              keyState={rowKeys}
              filterState={filterState}
              handleKeyUpdate={handleUpdateRowKeys}
              handleFilterUpdate={handleFilterUpdate}
              type={ItemTypes.FILTER}
              keyMapping={keyMapping}
              keys={keys}
              onKeyNav={onKeyNav}
              id='linhas'
            />
          </Box>
        </Cell>
        <Cell md={6} sm={12} xs={12}>
          <Box label='Forma de apresentação'>
            <div
              css={css`
                padding: 0.75rem;
                margin: 0.25rem;
                min-height: 7.18rem;
              `}
            >
              <Aggregators
                numberKeys={numberKeys}
                keyMapping={keyMapping}
                handleAggregatorChange={handleAggregatorChange}
                handleAggregatorKeyChange={handleAggregatorKeyChange}
                aggregator={aggregator}
                aggregatorKey={aggregatorKey}
              />
            </div>
          </Box>
        </Cell>
        <Cell sm={12}>
          <div
            css={css`
              border: 1px solid ${theme.pallete.gray.c80};
              padding: 0.75rem 1.25rem;
            `}
          >
            <Grid wrap alignItems='center'>
              <Cell size={10}>
                <HFlow alignItems='center'>
                  <b>Filtros aplicados</b>
                  {filterValuesBox}
                </HFlow>
              </Cell>
              <Cell size={2}>
                <Button
                  onClick={handleLimparFiltros}
                  size='small'
                  kind='normal'
                  skin='outline'
                  style={{ float: 'right' }}
                >
                  Limpar filtros
                </Button>
              </Cell>
            </Grid>
          </div>
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='medium' onClick={onTableReset}>
              Limpar tabela
            </Button>
            <Button kind='primary' size='medium' onClick={onTableCreate} loading={isBuilding} disabled={isBuilding}>
              Gerar tabela
            </Button>
          </HFlow>
        </Cell>
      </Grid>
    </DndProvider>
  )
}

const tagStyle = css`
  background-color: ${blue.c90};
  color: ${blue.c40};
  border: solid 1px ${blue.c40};
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
`
