import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { FormRenderProps, SubmitButton, TextAreaField } from 'components/form'
import { useSalvarJustificativaVisualizarProntuarioMutation } from 'graphql/hooks.generated'
import React from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

import { useJustificativaVerProntuarioId } from '../useJustificativaVerProntuarioId'

const meta = metaPath<JustificativaFormModel>()

interface JustificativaModalProps {
  cidadaoId: string
  prontuarioId: ID
  isModalOpen: boolean
  redirecionarParaFolhaRosto?: boolean
  onReject?(): void
  onConfirm?(justificativaId: ID): void
}

interface JustificativaFormModel {
  justificativa: string
}

export const validate = createValidator<JustificativaFormModel>({
  justificativa: [required],
})

export const JustificativaVisualizarProntuarioModal = (props: JustificativaModalProps) => {
  const { cidadaoId, prontuarioId, isModalOpen, redirecionarParaFolhaRosto = true, onConfirm, onReject } = props

  const history = useHistory()
  const [salvarJustificativaVisualizarProntuario] = useSalvarJustificativaVisualizarProntuarioMutation()
  const handleRejection = useErrorHandler()
  const pathCidadao = `/cidadao/${cidadaoId}`
  const [, setJustificativaId, deleteJustificativaId] = useJustificativaVerProntuarioId(pathCidadao, cidadaoId)

  const handleOnSubmit = (values: JustificativaFormModel) => {
    salvarJustificativaVisualizarProntuario({
      variables: { input: { prontuarioId: prontuarioId, justificativa: values.justificativa } },
    })
      .then(({ data: { salvarJustificativaVisualizarProntuario: justificativaId } }) => {
        setJustificativaId(justificativaId)
        onConfirm?.(justificativaId)
        redirecionarParaFolhaRosto && history.push(`${pathCidadao}/folha-rosto`)
      })
      .catch(handleRejection)
  }

  const hadleCancel = () => {
    deleteJustificativaId()
    onReject?.()
  }

  const justificativaModalForm = (formProps: FormRenderProps) => {
    return (
      <Modal open={isModalOpen} onClose={onReject} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2.45rem' }}>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={3} style={{ marginRight: '1rem' }} />
            <VFlow vSpacing={0}>
              <Heading level={1}>Justificativa para visualizar prontuário</Heading>
            </VFlow>
          </div>
          <TextAreaField
            style={{ resize: 'none', height: '5rem' }}
            name={meta.justificativa}
            label='Informe uma justificativa para visualizar o prontuário:'
            maxLength={500}
            required
          />
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={hadleCancel}>Cancelar</Button>
            <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return <Form render={justificativaModalForm} validate={validate} onSubmit={handleOnSubmit} />
}
