/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { PopperButton } from 'components/popper'
import { GrupoExame, Procedimento } from 'graphql/types.generated'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { ExameListSelectFieldModel } from '../ExameListSelectField'

interface GrupoExamePopperButtonProps {
  grupoExame: GrupoExame
  examesSelecionados: ExameListSelectFieldModel[]
  addItem(item: ExameListSelectFieldModel): void
  removeItem(item: ExameListSelectFieldModel): void
  checkAllItems(items: Procedimento[], isAllSelected: boolean): void
}

export const GrupoExamePopperButton = (props: GrupoExamePopperButtonProps) => {
  const { grupoExame, examesSelecionados, addItem, removeItem, checkAllItems } = props

  const [containerRef, setContainerRef] = useState<HTMLDivElement>()
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const isAllSelected = useMemo(() => {
    if (examesSelecionados) {
      const examesSelecionadosId = examesSelecionados.map(({ exame }) => exame.id)

      return grupoExame.procedimentos.every((proced) => examesSelecionadosId.includes(proced.id))
    } else {
      return false
    }
  }, [examesSelecionados, grupoExame.procedimentos])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, exame: Procedimento) => {
      if (event.target.checked) {
        addItem({ _id: null, exame })
      } else {
        removeItem(examesSelecionados.find((exameSelecionado) => exameSelecionado.exame.id === exame.id))
      }
    },
    [addItem, examesSelecionados, removeItem]
  )

  const renderPopper = () => (
    <VFlow vSpacing={0} style={classes.list}>
      <Checkbox
        label='Todos os exames'
        style={css(classes.listItem, classes.firstListItem)}
        onChange={() => checkAllItems(grupoExame.procedimentos, isAllSelected)}
        checked={isAllSelected}
      />

      {grupoExame.procedimentos.map((proced) => (
        <Checkbox
          key={proced.id}
          label={proced.descricao.capitalize()}
          style={classes.listItem}
          checked={(examesSelecionados || []).map(({ exame }) => exame.id).includes(proced.id)}
          onChange={(event) => handleChange(event, proced)}
        />
      ))}
    </VFlow>
  )

  return (
    <div ref={setContainerRef}>
      <PopperButton
        renderPopper={renderPopper}
        placement='right'
        styleCallback={(isOpen) => css(classes.button, isOpen && classes.buttonActive)}
        container={containerRef}
      >
        {grupoExame.nome.capitalize()} <Icon icon='angleRight' />
      </PopperButton>
    </div>
  )
}

const styles = (theme: Theme) => ({
  button: css`
    width: 100%;
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 2px;

    span {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  `,
  buttonActive: css`
    &,
    &:not(:disabled):hover {
      background-color: ${theme.pallete.primary.c90};
      border-color: ${theme.pallete.primary.c60};
    }
  `,
  list: css`
    background: ${theme.pallete.surface.main};
    border-radius: 2px;
    box-shadow: ${theme.shadows.outer[40]};

    > div:not(:last-of-type) {
      border-bottom: 1px solid ${theme.pallete.divider};
    }
  `,
  listItem: css`
    width: 100%;
    min-width: 16rem;
    margin-left: -0.25rem;
    padding: 0.5rem 0.75rem;
  `,
  firstListItem: css`
    font-weight: bold;
  `,
})
