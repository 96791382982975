/** @jsx jsx */
import { jsx } from '@emotion/core'
import { memo, useMemo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoAtendimentoOdontoDw from './dw/HistoricoAtendimentoOdontoDw'
import HistoricoAtendimentoOdontoPec from './pec/HistoricoAtendimentoOdontoPec'

interface HistoricoAtendimentoOdontoPanelProps extends HistoricoPanelProps {}

function HistoricoAtendimentoOdontoPanel(props: HistoricoAtendimentoOdontoPanelProps) {
  const { isAtendRecente, idAtend, idadeOrigemAtendimento, cpfOuCnsCidadao, uuidAtend, dataNascimento } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  const selectContent = useMemo(() => {
    return isAtendRecente || atendimentoPecProcessado ? (
      <HistoricoAtendimentoOdontoPec
        idAtend={idAtend}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
        dataNascimento={dataNascimento}
      />
    ) : (
      <HistoricoAtendimentoOdontoDw
        uuidAtend={uuidAtend}
        cpfOuCnsCidadao={cpfOuCnsCidadao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    )
  }, [
    isAtendRecente,
    atendimentoPecProcessado,
    idAtend,
    idadeOrigemAtendimento,
    dataNascimento,
    uuidAtend,
    cpfOuCnsCidadao,
  ])

  return selectContent
}

export default memo(HistoricoAtendimentoOdontoPanel)
