import { focusBoxShadow, Icon, Text, Theme, useStyles } from 'bold-ui'
import { SideMenuList } from 'components/layout/SideMenu/SideMenuList'
import { Location } from 'history'
import React, { CSSProperties, useState } from 'react'
import { NavHashLink, NavHashLinkProps } from 'react-router-hash-link'

interface SideMenuNewsItemProps extends NavHashLinkProps {
  title: string
  children?: React.ReactNode
  expandedDefault?: boolean
  hashchildren?: string[]
}

export function SideMenuNewsItem(props: SideMenuNewsItemProps) {
  const { title, children, hashchildren, expandedDefault, ...rest } = props
  const [expanded, setExpanded] = useState(expandedDefault)
  const { classes } = useStyles(createStyles)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (children) {
      e.preventDefault()
      setExpanded(!expanded)
    }
  }

  const isActive = (_, location: Location) => {
    let active = false
    if (hashchildren) {
      hashchildren.forEach((item) => {
        if (item === location.hash) {
          active = true
        }
      })
    }
    return active
  }

  return (
    <li>
      <NavHashLink
        smooth
        className={classes.link}
        onClick={handleClick}
        isActive={children ? isActive : undefined}
        {...rest}
      >
        <Text color='inherit'>{title}</Text>
        {children && <Icon style={classes} icon={expanded ? 'angleUp' : 'angleDown'} />}
      </NavHashLink>
      {children && expanded && <SideMenuList>{children}</SideMenuList>}
    </li>
  )
}

export const createStyles = (theme: Theme) => ({
  title: {
    flex: 1,
    marginLeft: '1.75rem',
    overflow: 'hidden',
  } as CSSProperties,
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.pallete.text.main,
    outline: 'none',
    padding: '0.75rem 1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',

    '&:hover': {
      background: theme.pallete.gray.c90,
    },

    '&:focus': {
      borderRadius: 4,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },

    '&.active': {
      color: theme.pallete.primary.main,
    },
  } as CSSProperties,
})
