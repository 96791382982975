import { HFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, MonthField, TextField } from 'components/form'
import { FormState } from 'final-form'
import { TransmissaoRecebimentoCnesFiltro } from 'graphql/types.generated'
import React from 'react'

interface TransmissaoRecebimentoCnesFilterProps {
  initialValues: TransmissaoRecebimentoCnesInput
  refetch(): Promise<any>
  onChange(values: TransmissaoRecebimentoCnesInput): void
}

type TransmissaoRecebimentoCnesInput = TransmissaoRecebimentoCnesFiltro

export function TransmissaoRecebimentoCnesFilter(props: TransmissaoRecebimentoCnesFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<TransmissaoRecebimentoCnesInput>) => onChange(formState.values)

  const renderFilter = () => {
    return (
      <HFlow alignItems='center'>
        <FormDebouncedValueSpy onChange={handleChange} />
        <TextField name='query' icon='zoomOutline' placeholder='Pesquise pelo CNES' />
        <MonthField name='competencia' placeholder='MM/AAAA' clearable={false} />
      </HFlow>
    )
  }

  return <Form<TransmissaoRecebimentoCnesInput> initialValues={initialValues} render={renderFilter} />
}
