/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { pink } from 'bold-ui/lib/styles/colors'
import { DateTime } from 'components/date'
import { Panel } from 'components/Panel'
import { formatDistanceToNowStrict } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ReactComponent as Icon } from 'images/historico/icone-escuta-inicial.svg'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { CuidadoCompartilhadoModel } from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

interface CuidadoCompartilhadoPanelProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const CuidadoCompartilhadoPanel = (props: CuidadoCompartilhadoPanelProps) => {
  const { cuidadoCompartilhado } = props

  const hasCiap = !isEmpty(cuidadoCompartilhado.ciap)
  const hasCid10 = !isEmpty(cuidadoCompartilhado.cid10)
  const hasBoth = hasCiap && hasCid10
  const separator = hasBoth && ' | '

  const solicitanteHasEquipe = !isUndefinedOrNull(cuidadoCompartilhado.lotacaoSolicitante.equipe)

  return (
    <Panel title='Cuidado compartilhado' icon={<Icon css={styles.icon} />} color={pink.c40}>
      <VFlow>
        <InfoLabel title='Problemas/condições'>
          <Text>
            {hasCiap &&
              `${cuidadoCompartilhado.ciap.descricao.capitalize()} - ${cuidadoCompartilhado.ciap.codigo} (CIAP 2)`}
            {separator}
            {hasCid10 &&
              `${cuidadoCompartilhado.cid10.nome.capitalize()} - ${cuidadoCompartilhado.cid10.codigo} (CID 10)`}
          </Text>
        </InfoLabel>
        <InfoLabel title='Discussão de caso clínico'>
          <Text dangerouslySetInnerHTML={{ __html: cuidadoCompartilhado.discussao }} style={styles.richText} />
        </InfoLabel>
        <HFlow>
          <InfoLabel title='Encaminhado por'>
            {cuidadoCompartilhado.lotacaoSolicitante.profissional.nome} |{' '}
            {solicitanteHasEquipe && `${cuidadoCompartilhado.lotacaoSolicitante.equipe.nome.titleCase()} | `}
            {cuidadoCompartilhado.lotacaoSolicitante.unidadeSaude.nome}
          </InfoLabel>
          <InfoLabel title='Data de início'>
            <DateTime value={cuidadoCompartilhado.iniciadoEm} format='DD/MM/YYYY' />
          </InfoLabel>
          <InfoLabel title='Horário de início'>
            <DateTime value={cuidadoCompartilhado.iniciadoEm} format='HH:mm' />
          </InfoLabel>
          <InfoLabel title='Tempo de espera'>
            {formatDistanceToNowStrict(cuidadoCompartilhado.dataUltimaEvolucao, {
              locale: ptBR,
              unit: 'hour',
            })}
          </InfoLabel>
        </HFlow>
      </VFlow>
    </Panel>
  )
}

const styles = {
  icon: css`
    height: 1.5rem;
    width: 1.5rem;
  `,
  richText: css`
    word-break: break-all;
  `,
}
