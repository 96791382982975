/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, InfoLabel, useTheme } from 'bold-ui'
import { MarcadorConsumoAlimentar, PerguntasQuestionarioEnum } from 'graphql/types.generated'
import { mcaMenoresDeSeisMesesQuestions } from 'view/atendimentos/detail/soap/objetivo/marcadores-consumo-alimentar/model'

import { convertOpcaoMCAEnumToString } from '../../../utils/historicoUtils'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoMCAMenorSeisMesesTableBoxProps {
  marcadorConsumoAlimentar: MarcadorConsumoAlimentar
}

export function HistoricoMCAMenorSeisMesesTableBox({
  marcadorConsumoAlimentar,
}: HistoricoMCAMenorSeisMesesTableBoxProps) {
  const respostasMCA = convertOpcaoMCAEnumToString(marcadorConsumoAlimentar)
  const theme = useTheme()
  return (
    <HistoricoDetailTable title='Marcadores de consumo alimentar' sectionStyle='O'>
      <Grid>
        <Cell size={12}>
          <Heading level={4}>Crianças menores que 6 meses</Heading>
        </Cell>

        <Cell size={12}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.leiteMaterno}>
            {respostasMCA.get(PerguntasQuestionarioEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO)}
          </InfoLabel>
        </Cell>

        <Cell
          size={12}
          style={css`
            border-bottom: 1px solid ${theme.pallete.divider};
          `}
        >
          <Heading level={4}>Ontem a criança consumiu</Heading>
        </Cell>

        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.mingau}>
            {respostasMCA.get(PerguntasQuestionarioEnum.MINGAU)}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.formulaInfantil}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FORMULA_INFANTIL)}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.comidaDeSal}>
            {respostasMCA.get(PerguntasQuestionarioEnum.COMIDA_SAL)}
          </InfoLabel>
        </Cell>

        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.aguaOuCha}>
            {respostasMCA.get(PerguntasQuestionarioEnum.AGUA_CHA)}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.sucoDeFruta}>
            {respostasMCA.get(PerguntasQuestionarioEnum.SUCO_FRUTA)}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.outros}>
            {respostasMCA.get(PerguntasQuestionarioEnum.OUTROS_ALIMENTOS_BEBIDAS)}
          </InfoLabel>
        </Cell>

        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.leiteDeVaca}>
            {respostasMCA.get(PerguntasQuestionarioEnum.LEITE_VACA)}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title={mcaMenoresDeSeisMesesQuestions.fruta}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FRUTA)}
          </InfoLabel>
        </Cell>
      </Grid>
    </HistoricoDetailTable>
  )
}
