import { Button, DropdownItem, HFlow, Tooltip } from 'bold-ui'
import React from 'react'

export interface MenuAcoesAgendamentoButtonProps {
  icon: React.ReactElement
  text: string
  tooltip?: string
  isDropdown?: boolean
  loading?: boolean
  disabled?: boolean
  onClick?(): void
}

export default function MenuAcoesAgendamentoButton(props: MenuAcoesAgendamentoButtonProps) {
  const { icon, text, isDropdown, tooltip, loading, onClick, disabled } = props
  return (
    <Tooltip text={tooltip}>
      {isDropdown ? (
        <DropdownItem onClick={onClick} disabled={disabled}>
          <HFlow alignItems='center'>
            {icon} {text}
          </HFlow>
        </DropdownItem>
      ) : (
        <Button size='small' skin='ghost' loading={loading} onClick={onClick} disabled={disabled}>
          {icon}
        </Button>
      )}
    </Tooltip>
  )
}
