/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'

interface ObservacaoLabelProps {
  observacao: string
}

export const ObservacaoLabel = (props: ObservacaoLabelProps) => {
  const { observacao } = props

  return (
    <div css={classes.label}>
      <HLabel title='Observações:'>
        <Text fontStyle='italic' style={classes.text}>
          {observacao}
        </Text>
      </HLabel>
    </div>
  )
}

const classes = {
  label: css`
    padding: 0.5rem 0;
  `,
  text: css`
    word-break: break-all;
  `,
}
