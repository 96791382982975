import React from 'react'
export interface NewsGifProps {
  src: string
  alt: string
}

export const NewsImg = (props: NewsGifProps) => {
  const { alt, src } = props
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%' }}>
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: '100%',
          maxHeight: '430px',
          border: 'solid 1px',
          imageRendering: '-webkit-optimize-contrast',
        }}
      />
    </div>
  )
}
