import { Alert, Cell, Grid, Heading, Icon, VFlow } from 'bold-ui'
import { StickyButton } from 'components/sticky-button/StickyButton'
import React from 'react'

import { NewsInternalEstabilizacaoV45 as NewsInternalEstabilizacaoVQuatroCinco } from './NewsInternalEstabilizacaoV_45'
import { NewsInternalEstabilizacaoV50 as NewsInternalEstabilizacaoVCincoZero } from './NewsInternalEstabilizacaoV_50'
import { NewsInternalEstabilizacaoV51 as NewsInternalEstabilizacaoVCincoUm } from './NewsInternalEstabilizacaoV_51'
import { NewsInternalPanelV4_50 as NewsInternalPanelVQuatroCinco } from './NewsInternalPanelV4_50'
import { NewsInternalPanelCincoZero } from './NewsInternalPanelV5_00'
import { NewsInternalPanelCincoUm } from './NewsInternalPanelV5_10'
import { NewsInternalPanelCincoDois } from './NewsInternalPanelV5_20'
import { SideMenuNews } from './sideMenu/SideMenuNews'

export function News() {
  return (
    <VFlow>
      <Heading level={1}>Novidades da versão</Heading>
      <Grid>
        <Cell size={3}>
          <SideMenuNews />
        </Cell>
        <Cell size={7}>
          <VFlow>
            <Alert type='info' inline>
              <strong>O PEC é frequentemente aprimorado com melhorias e correções!</strong> Para conhecer as próximas
              novidades, o sistema deve ser atualizado com a versão mais recente disponível.
            </Alert>

            <VFlow>
              <Heading level={1} id='versao-5-2'>
                Versão 5.2
              </Heading>
              <NewsInternalPanelCincoDois />

              <Heading level={1} id='versao-5-1-estabilizacao'>
                • Versões de estabilização 5.1
              </Heading>
              <NewsInternalEstabilizacaoVCincoUm />

              <Heading level={1} id='versao-5-1'>
                Versão 5.1
              </Heading>
              <NewsInternalPanelCincoUm />

              <VFlow>
                <Heading level={1} id='versao-5-0-estabilizacao'>
                  Versões de estabilização 5.0
                </Heading>
                <NewsInternalEstabilizacaoVCincoZero />

                <Heading level={1} id='versao-5-0'>
                  Versão 5.0
                </Heading>
                <NewsInternalPanelCincoZero />

                <Heading level={1} id='versao-4-5-estabilizacao'>
                  Versões de estabilização 4.5
                </Heading>
                <NewsInternalEstabilizacaoVQuatroCinco />

                <Heading level={1} id='versao-4-5'>
                  Versão 4.5
                </Heading>
                <NewsInternalPanelVQuatroCinco />
                <StickyButton
                  showsAt={800}
                  size='small'
                  style={{ left: '90%' }}
                  onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
                >
                  <Icon icon='arrowUp' />
                  Voltar ao topo
                </StickyButton>
              </VFlow>
            </VFlow>
          </VFlow>
        </Cell>
      </Grid>
    </VFlow>
  )
}
