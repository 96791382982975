/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Tabs, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { TabLink } from 'components/route'
import { Fragment } from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'

import { ListaCuidadoCompartilhadoExecutanteView } from './ListaCuidadoCompartilhadoExecutanteView'
import { ListaCuidadoCompartilhadoSolicitanteView } from './ListaCuidadoCompartilhadoSolicitanteView'

export const ListaCuidadoCompartilhadoRootView = () => {
  const match = useRouteMatch()

  return (
    <Fragment>
      <PageContainer fluid style={styles.pageContainer}>
        <VFlow>
          <Heading level={1}>Cuidados compartilhados</Heading>
          <Tabs>
            <TabLink to={`${match.url}/solicitacoes`}>Solicitados por mim</TabLink>
            <TabLink to={`${match.url}/compartilhamentos`}>Compartilhados comigo</TabLink>
          </Tabs>
        </VFlow>
      </PageContainer>
      <PageContent fluid type='transparent'>
        <Switch>
          <Route exact path={`${match.url}/compartilhamentos`} component={ListaCuidadoCompartilhadoExecutanteView} />
          <Route exact path={`${match.url}/solicitacoes`} component={ListaCuidadoCompartilhadoSolicitanteView} />
          <Redirect path={`${match.url}`} to={`${match.url}/solicitacoes`} />
        </Switch>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  pageContainer: css`
    padding: 1rem 2rem 0rem 2rem;
  `,
}
