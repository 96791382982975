/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, colors, HFlow, Text, Theme, useTheme } from 'bold-ui'
import familiarImage from 'images/soap/ic_familiares.svg'
import { useMemo } from 'react'

import { shadowIcon } from '../../historico/utils/historicoUtils'

interface FamiliarButtonProps {
  familiarAtivo: boolean
  handleClick(): void
}

export function FamiliarButton(props: FamiliarButtonProps) {
  const { familiarAtivo, handleClick } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme, familiarAtivo), [familiarAtivo, theme])

  return (
    <Button size='small' style={classes.familiarButton} onClick={handleClick}>
      <HFlow alignItems='center' hSpacing={0.3}>
        <img src={familiarImage} css={shadowIcon(colors.pink.c70)} alt='Familiar' />
        <Text fontWeight='bold' style={classes.familiarButtonText}>
          Familiar
        </Text>
      </HFlow>
    </Button>
  )
}

const styles = (theme: Theme, familiarAtivo: boolean) => ({
  familiarButton: css`
    border: 1px ${familiarAtivo ? colors.pink.c50 : colors.pink.c60} solid;
    background-color: ${familiarAtivo ? colors.pink.c60 : theme.pallete.gray.c100};
    margin: 0;
    width: 10rem;
    justify-content: flex-start;
    &:not(:disabled):hover {
      background-color: ${familiarAtivo ? colors.pink.c50 : colors.pink.c90};
    }
  `,
  familiarButtonText: css`
    color: ${familiarAtivo ? theme.pallete.gray.c100 : colors.pink.c40};
  `,
})
