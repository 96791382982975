import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

/**
 * based on https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/8-useStorage/useStorage.js
 */

export function useLocalStorage<T = any>(
  key: string,
  checkVersionCompatibility?: boolean,
  defaultValue?: T,
  modifier?: (value: T) => T
) {
  return useStorage(key, defaultValue, window.localStorage, modifier, checkVersionCompatibility)
}

export function useSessionStorage<T = any>(
  key: string,
  checkVersionCompatibility?: boolean,
  defaultValue?: T,
  modifier?: (value: T) => T
) {
  return useStorage(key, defaultValue, window.sessionStorage, modifier, checkVersionCompatibility)
}

function useStorage<T = any>(
  key: string,
  defaultValue: T,
  storageObject: Storage,
  modifier?: (value: T) => T,
  checkVersionCompatibility?: boolean
): [T, Dispatch<SetStateAction<T>>, (updateState?: boolean) => void] {
  const [value, setValue] = useState<T>(() => {
    const __defaultValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue
    return getStorageValue<T>(key, storageObject, __defaultValue, modifier, checkVersionCompatibility)
  })

  useEffect(() => {
    if (value === undefined) storageObject.removeItem(key)
    else storageObject.setItem(key, JSON.stringify({ item: value, __version: process.env.REACT_APP_VERSION }))
  }, [key, value, storageObject])

  const remove = useCallback(
    (updateState: boolean = true) => {
      storageObject.removeItem(key)
      updateState && setValue(undefined)
    },
    [key, storageObject]
  )

  return [value, setValue, remove]
}

function getStorageValue<T = any>(
  key: string,
  storageObject: Storage,
  defaultValue?: T,
  modifier?: (value: T) => T,
  checkVersionCompatibility?: boolean
): T {
  const jsonValue = storageObject.getItem(key)
  if (jsonValue != null) {
    let parsedValue = JSON.parse(jsonValue)
    parsedValue =
      checkVersionCompatibility && parsedValue.__version !== process.env.REACT_APP_VERSION
        ? defaultValue
        : parsedValue.item
    return modifier ? modifier(parsedValue) : parsedValue
  } else {
    return defaultValue
  }
}

export const getSessionStorageValue = <T = any>(
  key: string,
  defaultValue?: T,
  modifier?: (value: T) => T,
  checkVersionCompatibility?: boolean
): T => {
  return getStorageValue(key, window.sessionStorage, defaultValue, modifier, checkVersionCompatibility)
}

export const getLocalStorageValue = <T = any>(
  key: string,
  defaultValue?: T,
  modifier?: (value: T) => T,
  checkVersionCompatibility?: boolean
): T => {
  return getStorageValue(key, window.localStorage, defaultValue, modifier, checkVersionCompatibility)
}
