import { createValidator, required, requiredRichText, richTextMaxLength } from 'util/validation'
import {
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

export const discussaoCasoExecutanteValidator = createValidator<DiscussaoCasoExecutanteFieldsModel>({
  resposta: [requiredRichText, richTextMaxLength(1000)],
  conduta: [required],
})

export const discussaoCasoSolicitanteValidator = createValidator<DiscussaoCasoSolicitanteFieldsModel>({
  pergunta: [requiredRichText, richTextMaxLength(1000)],
})
