import { HeadingSection, VFlow } from 'bold-ui'
import { AlterarSenhaForm } from 'components/form/alterar-senha/AlterarSenhaForm'
import { AlterarSenhaModel } from 'components/form/alterar-senha/model'
import { PageContent } from 'components/layout/PageContent'
import { useRedefinirSenhaMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

interface RecuperarSenhaURL {
  token: string
}

export const RecuperarSenhaView = () => {
  const match = useRouteMatch<RecuperarSenhaURL>()
  const history = useHistory()

  const [recuperarSenha] = useRedefinirSenhaMutation()
  const initialValues = useMemo(
    () =>
      ({
        token: match.params.token,
      } as AlterarSenhaModel),
    [match.params.token]
  )

  const goBack = () => history.push('/')

  const handleSubmit = (values: AlterarSenhaModel) =>
    recuperarSenha({
      variables: {
        input: {
          token: values.token,
          usuario: values.usuario,
          novaSenhaInput: {
            novaSenha: values.novaSenhaInput.novaSenha,
            confirmacaoSenha: values.novaSenhaInput.confirmacaoSenha,
          },
        },
      },
    })

  return (
    <PageContent type='filled' style={{ marginTop: '2rem' }}>
      <VFlow>
        <HeadingSection title='Recuperar senha' level={1} />
        <AlterarSenhaForm
          initialValues={initialValues}
          tokenRequired
          usuarioRequired
          cancelarLabel='Sair'
          onCancelar={goBack}
          onSubmit={handleSubmit}
          onSubmitSucceeded={goBack}
        />
      </VFlow>
    </PageContent>
  )
}
