/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Modal, ModalBody, ModalProps } from 'bold-ui'
import { format } from 'date-fns'
import HistoricoDetalhesPanel from 'view/atendimentos/detail/historico/components/accordion/detail/HistoricoDetalhesPanel'
import {
  HistoricoDetalhesModel,
  IdadeOrigemAtendimento,
} from 'view/atendimentos/detail/historico/model/model-historico'

interface DetalhesUltimosContatosModalProps extends ModalProps {
  historicoAtendimento: HistoricoDetalhesModel
  title: string
  titleColor: string
  dataNascimentoCidadao: LocalDate
}

export function DetalhesUltimosContatosModal(props: DetalhesUltimosContatosModalProps) {
  const { historicoAtendimento, dataNascimentoCidadao, title, titleColor, ...modalProps } = props

  const idadeOrigemAtendimento: IdadeOrigemAtendimento = {
    dataAtendimento: format(historicoAtendimento.dataAtendimento, 'yyyy-MM-dd'),
    dataNascimentoCidadao,
    origemAtendimento: historicoAtendimento.origemAtendimento,
  }

  return (
    <Modal {...modalProps}>
      <ModalBody>
        <Heading
          level={1}
          style={css`
            color: ${titleColor};
          `}
        >
          {title}
        </Heading>
        <HistoricoDetalhesPanel
          historicoAtendimento={historicoAtendimento}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimentoCidadao}
        />
      </ModalBody>
    </Modal>
  )
}
