import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { DEFAULT_SELECT_SIZE } from 'components/form/field/select/useAsyncQuerySelect'
import { ProcedimentoSelect } from 'components/select/ProcedimentoSelect/ProcedimentoSelect'
import { GrupoProcedimentoEnum, IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useEffect, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { AppendItemExames } from 'view/atendimentos/detail/soap/plano/exames/components/AppendItemExames'

interface ExamesSelectProps {
  identidadeGeneroDbEnum: IdentidadeGeneroEnum
  sexo: SexoEnum
  dataNascimento: LocalDate
  placeholder?: string
  onChange(value: ProcedimentoSelectModel): void
}

export const ExamesSelect = (props: ExamesSelectProps) => {
  const { sexo, identidadeGeneroDbEnum, dataNascimento, placeholder, onChange } = props

  const [inputValue, setInputValue] = useState<ProcedimentoSelectModel>()
  const { atendimentoProfissional } = useAtendimentoContext()

  useEffect(() => {
    if (inputValue) {
      onChange(inputValue)
      setInputValue(undefined)
    }
  }, [inputValue, onChange])

  return (
    <ProcedimentoSelect
      sexo={sexo}
      loadItemsOnOpen
      dataNascimento={dataNascimento}
      dataAtendimento={dateAsYyyyMmDd(atendimentoProfissional.iniciadoEm)}
      gruposProcedimento={[GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA]}
      onChange={(item: ProcedimentoSelectModel) => setInputValue(item)}
      placeholder={placeholder}
      identidadeGeneroDbEnum={identidadeGeneroDbEnum}
      value={inputValue}
      components={{
        AppendItem: ({ items }) => AppendItemExames(items?.length, DEFAULT_SELECT_SIZE),
      }}
      filtroPadrao
    />
  )
}
