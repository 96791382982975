import { ApolloError } from 'apollo-client'
import { Alert, AlertType, HFlow, Link, VFlow } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import { METRICS_ENABLED } from 'config/util'
import { SessaoDocument, useLoginMutation, useSobreQuery } from 'graphql/hooks.generated'
import qs from 'qs'
import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { notify } from 'util/multitab'

import { GovBrButton } from './GovBrButton'
import { LoginForm, LoginFormModel } from './LoginForm'
import { LoginSeparator } from './LoginSeparator'

export function LoginView() {
  const location = useLocation()
  const history = useHistory()
  const { performance } = useFirebase()
  const [login] = useLoginMutation({ refetchQueries: [{ query: SessaoDocument }], awaitRefetchQueries: true })
  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })
  const [error, setError] = useState<ApolloError>()

  const traceEnabled = METRICS_ENABLED && info?.internetHabilitada
  const esqueciSenhaDisponivel = info?.internetHabilitada && info?.smtpConfigurado && info?.linkInstalacaoConfigurado

  const loginTrace = useMemo(() => {
    const newTrace = performance.trace('Login')
    newTrace.putAttribute('Versao', process.env.REACT_APP_VERSION)
    newTrace.putAttribute(
      'Resolucao',
      window.screen.width * window.devicePixelRatio + 'x' + window.screen.height * window.devicePixelRatio
    )
    if (traceEnabled) {
      newTrace.start()
    }
    return newTrace
  }, [performance, traceEnabled])

  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const doLogin = (form: LoginFormModel) => {
    history.push('/')
    return login({
      variables: { input: { ...form } },
      context: {
        headers: {
          'Api-Consumer-Id': 'ESUS_WEB_CLIENT',
        },
      },
    })
      .then(() => {
        notify('LOGIN')
        window.localStorage.removeItem('NEWS')
        traceEnabled && loginTrace.stop()
        history.push('/')
      })
      .catch((result) => {
        if (result?.graphQLErrors?.[0]?.extensions?.classification === 'UsuarioJaLogadoException') {
          const doForceLogin = () => doLogin({ ...form, force: true })
          confirm({
            title: result?.graphQLErrors?.[0]?.message,
            cancelLabel: 'Cancelar',
            confirmLabel: 'Continuar',
            onConfirm: doForceLogin,
          })()
        } else {
          setError(result)
        }
      })
  }

  return (
    <VFlow>
      {searchParams?.error && <SimpleError errorMessage={searchParams.error} />}
      {error && <Errors error={error} />}
      {searchParams.sessionExpired !== undefined && (
        <Alert
          type='info'
          inline
          styles={{
            container: { padding: '0.5rem 0' },
          }}
        >
          Sua sessão expirou. Informe seu usuário e senha novamente.
        </Alert>
      )}
      {searchParams.emailEnviado !== undefined && (
        <Alert
          type='warning'
          inline
          styles={{
            container: { padding: '0.5rem 0' },
          }}
        >
          E-mail de redefinição de senha enviado. Caso não tenha recebido, entre em contato com o coordenador da sua
          UBS.
        </Alert>
      )}
      <LoginForm onLogin={doLogin} />

      {esqueciSenhaDisponivel && (
        <Link component={RouterLink} to='/recuperarSenha'>
          Esqueci minha senha
        </Link>
      )}
      {info?.internetHabilitada && info?.govBREnabled && (
        <VFlow>
          <LoginSeparator />
          <HFlow justifyContent='center'>
            <GovBrButton href='/oauth2/authorization/govbr' />
          </HFlow>
        </VFlow>
      )}
    </VFlow>
  )
}

interface ErrorsProps {
  error: ApolloError
}

const Errors = (props: ErrorsProps) => {
  const { error } = props
  return (
    <>
      {error.graphQLErrors
        .filter((graphqlError) => {
          const errorInfo = errorMap[graphqlError.extensions.classification] || { show: true }

          return errorInfo.show
        })
        .map((graphqlError, index) => {
          const errorInfo = errorMap[graphqlError.extensions.classification] || { type: 'danger' }
          return (
            <Alert key={index} type={errorInfo.type} inline={true}>
              {graphqlError.message}
            </Alert>
          )
        })}
    </>
  )
}

interface SimpleErrorProps {
  errorMessage: String
}

const SimpleError = (props: SimpleErrorProps) => {
  return (
    <Alert type='danger' inline={true}>
      {props.errorMessage}
    </Alert>
  )
}

interface ErrorInfo {
  show: boolean
  type?: AlertType
}

const errorMap: { [key: string]: ErrorInfo } = {
  BadCredentialsException: { type: 'warning', show: true },
  UsuarioJaLogadoException: { show: false },
}
