import { Alert } from 'bold-ui'
import React from 'react'

export interface PrescricaoDigitalIntegracaoMessageProps {
  success: boolean
  message?: string
  errorCode?: string

  handleAlertClose: () => void
}

export const PrescricaoDigitalIntegracaoMessage = ({
  success,
  message,
  errorCode,
  handleAlertClose,
}: PrescricaoDigitalIntegracaoMessageProps) => {
  return (
    <Alert inline type={success ? 'success' : 'warning'} onCloseClick={handleAlertClose}>
      {message ??
        prescricaoDigitalErrorCodeToMessage[errorCode] ??
        'Não foi possível assinar o documento. Tente novamente mais tarde.'}
    </Alert>
  )
}

const ContaGovBrConfiabilidadeInsuficienteMessage = () => {
  return (
    <>
      É necessário possuir conta Gov.br nível ouro ou prata para utilizar a assinatura eletrônica digital.{' '}
      <a href='https://confiabilidades.acesso.gov.br/' target='_blank' rel='noopener noreferrer'>
        Aumente o nível da sua conta
      </a>{' '}
      e tente novamente.
    </>
  )
}

enum PrescricaoDigitalErrorCodeEnum {
  ASSINATURA_INVALIDA = 1,
  CONFIABILIDADE_INSUFICIENTE = 2,
  CPFS_DIFERENTES = 3,
  DOCUMENTO_JA_ASSINADO = 4,
  PROFISSIONAL_SEM_CONSELHO = 5,
}

const prescricaoDigitalErrorCodeToMessage: Record<PrescricaoDigitalErrorCodeEnum, React.ReactNode> = {
  [PrescricaoDigitalErrorCodeEnum.ASSINATURA_INVALIDA]: 'Não foi possível obter a assinatura.',
  [PrescricaoDigitalErrorCodeEnum.CONFIABILIDADE_INSUFICIENTE]: <ContaGovBrConfiabilidadeInsuficienteMessage />,
  [PrescricaoDigitalErrorCodeEnum.CPFS_DIFERENTES]: 'O CPF do prescritor não condiz com o CPF da assinatura.',
  [PrescricaoDigitalErrorCodeEnum.DOCUMENTO_JA_ASSINADO]: 'O documento já foi assinado previamente.',
  [PrescricaoDigitalErrorCodeEnum.PROFISSIONAL_SEM_CONSELHO]:
    'É necessário possuir um conselho de classe cadastrado para realizar uma prescrição digital.',
}
