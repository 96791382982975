import useLocalStorage from '@rehooks/local-storage'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

/*
  Usado para manipular o localStorage sem gerar dependencia do valor atual,
  através do SetStateAction<T> que retorna (prevValue) no `setState`.
*/

export default function useLocalStorageState<T = string>(key: string, initialValue?: T) {
  const [storage, updateStorage, deleteStorage] = useLocalStorage<T>(key, initialValue)
  const [state, setState] = useState<T>(storage)

  useEffect(() => updateStorage(state), [state, updateStorage])

  return [state, setState, deleteStorage] as [T | null, Dispatch<SetStateAction<T>>, Dispatch<void>]
}
