import { InfoLabel } from 'bold-ui'
import React from 'react'
import { formatNumber } from 'util/number'

import { ExameQueryModel, ResultadoEspecificoPrimeiroBloco } from '../../model'
import { getResultadoExameEspecificoProperties } from '../../util'

interface ResultadoExameMarcadoresBioquimicosViewProps {
  value: ResultadoEspecificoPrimeiroBloco
  exame: ExameQueryModel
}

export const ResultadoExameMarcadoresBioquimicosView = (props: ResultadoExameMarcadoresBioquimicosViewProps) => {
  const { value, exame } = props

  const { unit, precision = 0 } = getResultadoExameEspecificoProperties(exame)

  return <InfoLabel title={`Resultado (${unit})`}>{formatNumber(value, precision)}</InfoLabel>
}
