import { Cell, Grid, Heading, Tag, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { HLabel } from 'components/HLabel'
import { TableRowMenu, TableRowMenuButton } from 'components/table'
import { css } from 'emotion'
import { FormApi } from 'final-form'
import { SituacaoProblema } from 'graphql/types.generated'
import React, { Fragment, useState } from 'react'
import { MetaRoot } from 'util/metaPath'
import { createValidator, maxLength } from 'util/validation'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { ObservacaoFormGrid } from 'view/atendimentos/detail/components/ObservacaoFormGrid'
import { SituacaoProblemaTag } from 'view/atendimentos/detail/components/SituacaoProblemaTag'

import { Problema } from '../../../../aside/types/ProblemaModel'
import { TipoPreNatal } from '../../../../pre-natal/model'
import { CiapCidRowState, ProblemaCondicaoModel } from '../model'
import { ProblemasCondicoesForm, ProblemasCondicoesFormProps } from '../ProblemasCondicoesForm'

interface CiapCidRowProps
  extends Pick<ProblemasCondicoesFormProps, 'problemasCondicoesAvaliados' | 'problemasCondicoesAnteriores'> {
  name: MetaRoot<ProblemaCondicaoModel>
  model: ProblemaCondicaoModel
  prontuarioId: ID
  somenteCiap: boolean
  problemasAtivosLatentes: Problema[]
  dataAtendimento: Instant
  tipoPreNatal?: TipoPreNatal
  onChange(values: ProblemaCondicaoModel): void
  onRemove(item: ProblemaCondicaoModel): void
}

const validator = createValidator<ProblemaCondicaoModel>({
  observacao: [maxLength(200)],
})

export function CiapCidRow(props: CiapCidRowProps) {
  const {
    model,
    somenteCiap,
    name,
    tipoPreNatal,
    dataAtendimento,
    prontuarioId,
    problemasAtivosLatentes,
    onChange,
    onRemove,
    ...formProps
  } = props

  const [state, setState] = useState<CiapCidRowState>(CiapCidRowState.DEFAULT)

  const { isEditable, tooltipText: removeTooltipText } = getEditableItemRemoveTooltipText(model)

  const isEditableAndResolvido = isEditable && model.problemaCondicaoEvoluir?.situacao === SituacaoProblema.RESOLVIDO

  const editTooltipText = isEditableAndResolvido
    ? 'Não é possível evoluir um problema ou condição com a situação Resolvido.'
    : 'Editar'

  const handleExibirLinha = () => setState(CiapCidRowState.DEFAULT)
  const handleEditarObservacao = () => setState(CiapCidRowState.OBSERVACAO)
  const handleEditar = () => setState(CiapCidRowState.EDICAO)

  const handleRemove = () => onRemove(model)

  const handleSubmit = (values: ProblemaCondicaoModel, formApi: FormApi) => {
    onChange(values)
    setTimeout(formApi.reset)
    handleExibirLinha()
  }

  const renderForm = (formRenderProps: FormRenderProps<ProblemaCondicaoModel>) => (
    <VFlow
      vSpacing={0.5}
      style={css`
        padding: 1rem 0;
      `}
    >
      <Heading level={4}>Adicionar observação</Heading>
      <Grid
        style={css`
          margin: 0;
        `}
      >
        {model.ciap && (
          <Cell size={4} flexGrow={1}>
            <HLabel title='CIAP 2:'>{`${model.ciap.descricao} - ${model.ciap.codigo}`}</HLabel>
          </Cell>
        )}
        {model.cid && (
          <Cell size={3} flexGrow={1}>
            <HLabel title='CID 10:'>{`${model.cid.nome} - ${model.cid.codigo}`}</HLabel>
          </Cell>
        )}
      </Grid>
      <ObservacaoFormGrid name={name.observacao} onCancel={handleExibirLinha} onSubmit={formRenderProps.handleSubmit} />
    </VFlow>
  )

  return (
    <EditableRow editing={state !== CiapCidRowState.DEFAULT}>
      {state === CiapCidRowState.DEFAULT && (
        <Fragment>
          <Grid
            style={css`
              margin: 0;
            `}
          >
            <Cell size={somenteCiap ? 6 : 4} alignSelf='center'>
              {model.ciap ? `${model.ciap.descricao} - ${model.ciap.codigo}` : '-'}
            </Cell>
            {!somenteCiap && (
              <Cell size={3} alignSelf='center'>
                {model.cid ? `${model.cid.nome} - ${model.cid.codigo}` : '-'}
              </Cell>
            )}
            <Cell size={2} alignSelf='center' flexGrow={1}>
              {model.incluirNaListaProblemas && model.situacaoProblema ? (
                <SituacaoProblemaTag situacao={model.situacaoProblema} />
              ) : (
                <span>Não incluído</span>
              )}
            </Cell>
            <Cell
              size={3}
              alignSelf='center'
              style={css`
                padding: 0.25rem 0;
                text-align: right;
              `}
            >
              <TableRowMenu>
                <TableRowMenuButton
                  text={model.observacao ? 'Editar observação' : 'Adicionar observação'}
                  icon='chatOutline'
                  onClick={handleEditarObservacao}
                />

                <TableRowMenuButton
                  text={editTooltipText}
                  icon='penOutline'
                  onClick={handleEditar}
                  disabled={isEditableAndResolvido}
                />

                <TableRowMenuButton
                  text={removeTooltipText}
                  icon='trashOutline'
                  onClick={handleRemove}
                  disabled={isEditable}
                />
              </TableRowMenu>
            </Cell>
          </Grid>
          {model.isAutomatico && (
            <Tag
              style={css`
                margin-bottom: 0.5rem;
              `}
            >
              Avaliação adicionada automaticamente
            </Tag>
          )}
          <Observacao observacao={model.observacao} />
        </Fragment>
      )}
      {state === CiapCidRowState.OBSERVACAO && (
        <Form<ProblemaCondicaoModel>
          render={renderForm}
          initialValues={model}
          validate={validator}
          onSubmit={handleSubmit}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      )}
      {state === CiapCidRowState.EDICAO && (
        <VFlow
          style={css`
            padding: 1rem 0;
          `}
        >
          <Heading level={4}>Editar problema / condição</Heading>
          <ProblemasCondicoesForm
            edicao
            initialValues={model}
            onCancel={handleExibirLinha}
            onSubmit={handleSubmit}
            dataAtendimento={dataAtendimento}
            prontuarioId={prontuarioId}
            tipoPreNatal={tipoPreNatal}
            problemasAtivosLatentes={problemasAtivosLatentes}
            initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
            {...formProps}
          />
        </VFlow>
      )}
    </EditableRow>
  )
}
