import ReactPDF, { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Theme, useTheme } from 'bold-ui'
import React from 'react'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintBoxProps {
  children: React.ReactNode
  level?: 1 | 2 | 3 | 4 | 5 | 6
  title?: string
  titleStyles?: ReactPDF.Style
}

export const PrintBox = (props: PrintBoxProps) => {
  const { children, level = 5, title, titleStyles } = props
  const theme = useTheme()
  const { getTitleStyle } = usePrinterStyles()
  const heading = getTitleStyle(level)
  const classes = createStyles(theme, heading)

  return (
    <View style={classes.wrapper}>
      {title && <Text style={[classes.title, titleStyles]}>{title}</Text>}
      {children}
    </View>
  )
}

const createStyles = (theme: Theme, heading: ReactPDF.Style) => {
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 15,
      border: 1,
      borderRadius: 3,
      borderColor: theme.pallete.gray.c50,
      paddingVertical: 5,
    },
    title: {
      ...heading,
      marginBottom: 5,
      borderBottomWidth: 1,
      borderBottomColor: theme.pallete.divider,
      marginHorizontal: 15,
    },
  })
}
