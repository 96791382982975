import { Omit } from 'bold-ui'
import {
  CboAndPermissionAuthorization,
  getAuthorizationProps,
  PermissionAuthorization,
} from 'components/auth/model-authorization'
import { useCheckPermissionAndCboAuthorization } from 'components/auth/useCheckModuleAuthorization'
import useSession from 'components/auth/useSession'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { SideMenuItemConfig } from 'components/layout/SideMenu/SideMenu'
import { getSideMenuItems, SideMenuConfiguration } from 'components/layout/SideMenu/SideMenuItems'
import { FlagsContextModel, useFlags } from 'config/useFlagsContext'
import { partition } from 'lodash'
import React from 'react'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

type ItemType = (PermissionAuthorization | CboAndPermissionAuthorization) &
  Required<Pick<SideMenuItemConfig, 'title' | 'to' | 'acceptEstagio'>>

export interface ModuloSelectFieldProps extends Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'> {}

export const ModuloSelectField = (props: ModuloSelectFieldProps) => {
  const { isEstagio } = useSession()
  const flags = useFlags()
  const checkPermissionAndCboAuthorization = useCheckPermissionAndCboAuthorization()
  const config = useVideochamadasConfiguration()

  const authorizedItems: ItemType[] = getItems(flags, config).filter(
    (item) => checkPermissionAndCboAuthorization(item) && (!isEstagio || item.acceptEstagio)
  )

  const itemToString = (item: ItemType) => item && item.title

  return <SelectField<ItemType> clearable={false} items={authorizedItems} itemToString={itemToString} {...props} />
}

export const createItems = (menuItems: SideMenuItemConfig[]): ItemType[] => {
  const createdItems: ItemType[] = []

  const [itemsWithoutChildren, itemsWithChildren] = partition(menuItems, (item) => !item.children)

  itemsWithoutChildren.forEach((item) => {
    createdItems.push({
      title: item.title,
      to: item.to,
      acceptEstagio: item.acceptEstagio ?? true,
      ...getAuthorizationProps(item),
    })
  })

  itemsWithChildren.forEach((item) => {
    item.children.forEach((child) => {
      const groupName = child.group
      child.items.forEach((innerItem) => {
        if (innerItem) {
          const title = groupName ? `${item.title}/${groupName}/${innerItem.title}` : `${item.title}/${innerItem.title}`
          createdItems.push({
            title,
            to: innerItem.to,
            acceptEstagio: innerItem.acceptEstagio ?? true,
            ...getAuthorizationProps(innerItem),
          })
        }
      })
    })
  })

  return createdItems
}

export const getItems = (flags: FlagsContextModel, config: SideMenuConfiguration) => {
  return createItems(getSideMenuItems(flags, config))
}
