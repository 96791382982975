import { FlagsContextModel } from 'config/useFlagsContext'
import { Calculation } from 'final-form-calculate'
import {
  TipoAtendimentoEnum,
  TipoConsultaOdontoEnum,
  TipoEncaminhamentoOdontoEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { Meta, MetaRoot } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { getProcedimentosWithNewProced } from 'view/atendimentos/detail/soap/finalizacao/calculator/calculatorIndividual'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'
import { calculateProcedimentoAutomaticoOdontologico } from 'view/atendimentos/utils/procedimentoAutomaticoUtils'

import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'

const tipoConsultaCalculator = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  cbo2002: string,
  flags: FlagsContextModel
): Calculation => ({
  field: meta.tipoConsulta.absolutePath(),
  updates: {
    [meta.condutasOdonto.absolutePath()]: (tipoConsulta: TipoConsultaOdontoEnum, allValues: SoapState) => {
      const tipoConsultaNullOrManutencao =
        !tipoConsulta || tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_MANUTENCAO
      const condutaToRemove = tipoConsultaNullOrManutencao
        ? TipoEncaminhamentoOdontoEnum.TRATAMENTO_CONCLUIDO
        : TipoEncaminhamentoOdontoEnum.ALTA_DO_EPISODIO
      return allValues.finalizacao.condutasOdonto?.filter((c: TipoEncaminhamentoOdontoEnum) => c !== condutaToRemove)
    },
    [meta.procedimentosAdministrativos.absolutePath()]: (
      tipoConsulta: TipoConsultaOdontoEnum,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      if (!isEmpty(prevValues)) {
        const procedToAdd = calculateProcedimentoAutomaticoOdontologico(
          tipoEstabelecimento,
          prevValues.finalizacao.tipoAtendimento,
          tipoConsulta,
          cbo2002,
          flags
        )

        const procedRef = procedimentosAutomaticos.find((p) => p.codigo === procedToAdd)
        return getProcedimentosWithNewProced(allValues.finalizacao.procedimentosAdministrativos, [procedRef])
      }
    },
  },
})

const tipoAtendimentoCalculator = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  cbo2002: string,
  flags: FlagsContextModel
): Calculation => ({
  field: [meta.tipoAtendimento.absolutePath()],
  updates: {
    [meta.tipoConsulta.absolutePath()]: (tipoAtendimento: TipoAtendimentoEnum, allValues: SoapState) =>
      tipoAtendimento === TipoAtendimentoEnum.URGENCIA &&
      allValues.finalizacao?.tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_RETORNO
        ? null
        : allValues.finalizacao.tipoConsulta,
    [meta.procedimentosAdministrativos.absolutePath()]: (allValues: SoapState, prevValues: SoapState) => {
      if (!isEmpty(prevValues)) {
        const procedToAdd = calculateProcedimentoAutomaticoOdontologico(
          tipoEstabelecimento,
          prevValues.finalizacao.tipoAtendimento,
          prevValues.finalizacao.tipoConsulta,
          cbo2002,
          flags
        )

        const procedRef = procedimentosAutomaticos.find((p) => p.codigo === procedToAdd)
        return getProcedimentosWithNewProced(allValues.finalizacao?.procedimentosAdministrativos, [procedRef])
      }
      return allValues.finalizacao.procedimentosAdministrativos
    },
  },
})

export const createFinalizacaoOdontoCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  cbo2002: string,
  flags: FlagsContextModel
): Calculation[] => [
  tipoConsultaCalculator(meta, procedimentosAutomaticos, tipoEstabelecimento, cbo2002, flags),
  tipoAtendimentoCalculator(meta, procedimentosAutomaticos, tipoEstabelecimento, cbo2002, flags),
]
