import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as Consulta } from 'images/historico/icone-consulta.svg'
import { ReactComponent as PreNatal } from 'images/historico/icone-pre-natal.svg'
import { ReactComponent as AcompanhamentosIcon } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as GarantiaAcessoIcon } from 'images/side-menu/garantia-acesso.svg'
import { ReactComponent as ProfissionaisIcon } from 'images/side-menu/profissionais.svg'
import { ReactComponent as UnidadeDeSaude } from 'images/side-menu/unidade-de-saude.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'
import { ReactComponent as AcompanhamentoTerritorioIcon } from 'images/visualizacao-territorial/casa-icone.svg'
import React from 'react'

import AbaRNDS from '../../images/novidades/cincoDois/aba_RNDS.png'
import AcompanhamentoTerritorioPaused from '../../images/novidades/cincoDois/acompanhamento_territorio_paused.png'
import AcompanhamentoTerritorioGif from '../../images/novidades/cincoDois/acompanhamento_territorio.gif'
import AgendamentoPreNatalPaused from '../../images/novidades/cincoDois/agendamento_pre_natal_paused.png'
import AgendamentoPreNatalSugestoes from '../../images/novidades/cincoDois/agendamento_pre_natal_sugestoes.png'
import AgendamentoPreNatalGif from '../../images/novidades/cincoDois/agendamento_pre_natal.gif'
import BuscaAtivaVacinacaoRelatorioPaused from '../../images/novidades/cincoDois/busca_ativa_pesquisa_relatorio_paused.png'
import BuscaAtivaVacinacaoRelatorioGif from '../../images/novidades/cincoDois/busca_ativa_pesquisa_relatorio.gif'
import BuscaAtivaVacinacaoFaixaEtariaPaused from '../../images/novidades/cincoDois/busca_ativa_vacinacao_faixa_etaria_paused.png'
import BuscaAtivaVacinacaoFaixaEtariaGif from '../../images/novidades/cincoDois/busca_ativa_vacinacao_faixa_etaria.gif'
import BuscaAtivaVacinacaoGrupoAtendimentoPaused from '../../images/novidades/cincoDois/busca_ativa_vacinacao_grupo_atendimento_paused.png'
import BuscaAtivaVacinacaoGrupoAtendimentoGif from '../../images/novidades/cincoDois/busca_ativa_vacinacao_grupo_atendimento.gif'
import BuscaAtivaVacinacaoImunosDosesPaused from '../../images/novidades/cincoDois/busca_ativa_vacinacao_imunos_doses_paused.png'
import BuscaAtivaVacinacaoImunosDosesGif from '../../images/novidades/cincoDois/busca_ativa_vacinacao_imunos_doses.gif'
import BuscaAtivaVacinacaoInativacaoBotoes from '../../images/novidades/cincoDois/busca_ativa_vacinacao_inativacao_botoes.png'
import CuidadosCompartilhados from '../../images/novidades/cincoDois/cuidados_compartilhados.png'
import CuidadosSolicitados from '../../images/novidades/cincoDois/cuidados_solicitados.png'
import DiscussaoDeCasoCuidadoExecutante from '../../images/novidades/cincoDois/discussao_executante.png'
import DiscussaoDeCasoCuidadoSolicitante from '../../images/novidades/cincoDois/discussao_solicitante.png'
import EquipesVinculadasRelatorioInfo from '../../images/novidades/cincoDois/equipes_vinculadas_relatorio_info.png'
import EquipesVinculadasRelatorioPaused from '../../images/novidades/cincoDois/equipes_vinculadas_relatorio_paused.png'
import EquipesVinculadasRelatorio from '../../images/novidades/cincoDois/equipes_vinculadas_relatorio.gif'
import EquipesVinculadasVisualizacaoPaused from '../../images/novidades/cincoDois/equipes_vinculadas_visualizacao_paused.png'
import EquipesVinculadasVisualizacaoGif from '../../images/novidades/cincoDois/equipes_vinculadas_visualizacao.gif'
import GestaoDeFilas from '../../images/novidades/cincoDois/gestao_filas_cuidados.png'
import PrescricaoDigitalAssinarPaused from '../../images/novidades/cincoDois/prescricao_digital_assinar_paused.png'
import PrescricaoDigitalAssinarGif from '../../images/novidades/cincoDois/prescricao_digital_assinar.gif'
import PrescricaoDigitalGerarPaused from '../../images/novidades/cincoDois/prescricao_digital_gerar_paused.png'
import PrescricaoDigitalGerarGif from '../../images/novidades/cincoDois/prescricao_digital_gerar.gif'
import CompartilhamentodeCuidado from '../../images/novidades/cincoDois/tela_cuidado_compartilhado.png'
import TelaGestaoGarantiaAcesso from '../../images/novidades/cincoDois/tela_gestaogarantia.png'
import TelaVideochamada from '../../images/novidades/cincoDois/tela_videochamada.png'
import TeleinterconsultaModulo from '../../images/novidades/cincoDois/teleinterconsulta_modulo.png'
import TeleinterconsultaSalaEspera from '../../images/novidades/cincoDois/teleinterconsulta_sala_espera.png'
import VideochamadaHabilitarPaused from '../../images/novidades/cincoDois/videochamadas_habilitar_paused.png'
import VideochamadaHabilitarGif from '../../images/novidades/cincoDois/videochamadas_habilitar.gif'
import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoDois() {
  const {
    ENVIO_RNDS_ENABLED,
    UPA_ENABLED,
    VISUALIZACAO_TERRITORIO_ENABLED,
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED,
  } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Facilidades no agendamento de consultas de pré-natal
          </Text>
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          <Text fontSize={0.875} component='li'>
            Gestão da garantia do acesso
          </Text>
          <Text fontSize={0.875} component='li'>
            Prescrição digital
          </Text>
          <Text fontSize={0.875} component='li'>
            Teleinterconsulta
          </Text>
          <Text fontSize={0.875} component='li'>
            Vínculo entre equipes do PEC
          </Text>
          {ENVIO_RNDS_ENABLED && (
            <Text fontSize={0.875} component='li'>
              RNDS - Envio de vacinas e atendimentos individuais
            </Text>
          )}
          {UPA_ENABLED && (
            <Text fontSize={0.875} component='li'>
              e-SUS APS Ampliado
            </Text>
          )}
          {VISUALIZACAO_TERRITORIO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Acompanhamento do Território
            </Text>
          )}
          {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Cuidado Compartilhado
            </Text>
          )}
        </VFlow>
      </HFlow>
      <NewsHeadingSection
        title='Facilidades no agendamento de consultas de pré-natal'
        icon={<PreNatal style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='agendamento-pre-natal-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            Com a nova funcionalidade de agendamentos, durante um atendimento de pré-natal, será possível realizar o{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              agendamento da próxima consulta para acompanhamento de pré-natal e a consulta odontológica de pré-natal
            </Text>
            . O PEC irá{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              sugerir a data e lotação de profissional
            </Text>
            , sendo que a pessoa responsável pelo atendimento tem liberdade para alterar essas informações conforme
            disponibilidade das partes envolvidas.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para o acompanhamento de pré-natal, a data da próxima consulta é sugerida conforme a idade gestacional,
            respeitando as cadências estipuladas na caderneta da gestante, enquanto a lotação de profissional alterna
            entre profissionais com CBO de médico e enfermeiro.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para as consultas odontológicas, é sugerida uma consulta a cada trimestre da gestação, enquanto a lotação de
            profissional sugerida será de profissional com CBO de dentista.
          </Text>
          <NewsGif
            src={AgendamentoPreNatalGif}
            pausedSrc={AgendamentoPreNatalPaused}
            alt='Demonstração de funcionalidade de agendamento de acompanhamento de pré-natal.'
          />
          <Text fontSize={0.875} component='li'>
            Ainda durante os atendimentos de pré-natal, serão apresentadas as{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              sugestões de quais serão todas as datas
            </Text>{' '}
            que deverão ocorrer os{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              próximos acompanhamentos de pré-natal
            </Text>
            , bem como um{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              atendimento odontológico
            </Text>{' '}
            a cada trimestre da gestação.
          </Text>
          <NewsImg
            src={AgendamentoPreNatalSugestoes}
            alt='Imagem exibindo as sugestões de datas para os próximos acompanhamentos de pré-natal e atendimentos odontológicos'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='busca-ativa-vacinacao-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Inclusão do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes
            </Text>{' '}
            para Vacinas do calendário vacinal e Vacinas da Covid-19;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Puérperas
            </Text>{' '}
            para Vacinas da Covid-19;
          </Text>
          <Text fontSize={0.875} component='li'>
            Exportação do relatório da busca ativa no formato{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              PDF
            </Text>{' '}
            para impressão e download das{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Vacinas do calendário vacinal
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Vacinas da Covid-19
            </Text>
            ;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Faixa etária
            </Text>{' '}
            em cada grupo-alvo disponível para Vacinas do calendário vacinal e Vacinas de Covid-19;
          </Text>
          <NewsGif
            src={BuscaAtivaVacinacaoFaixaEtariaGif}
            pausedSrc={BuscaAtivaVacinacaoFaixaEtariaPaused}
            alt='Demonstração de funcionalidade do filtro por faixa etária na busca ativa de vacinação.'
          />
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Grupo de atendimento
            </Text>{' '}
            para Vacinas da Covid-19;
          </Text>
          <NewsGif
            src={BuscaAtivaVacinacaoGrupoAtendimentoGif}
            pausedSrc={BuscaAtivaVacinacaoGrupoAtendimentoPaused}
            alt='Demonstração de funcionalidade do filtro por grupo de atendimento na busca ativa de vacinação.'
          />
          <Text fontSize={0.875} component='li'>
            Exibição somente dos imunobiológicos e doses pertinentes aos grupos-alvos selecionados na combo de filtros
            avançados;
          </Text>
          <NewsGif
            src={BuscaAtivaVacinacaoImunosDosesGif}
            pausedSrc={BuscaAtivaVacinacaoImunosDosesPaused}
            alt='Demonstração da exibição dos imunobiológicos e doses pertinentes aos grupos-alvos selecionados na busca ativa de vacinação.'
          />
          <Text fontSize={0.875} component='li'>
            Inativação dos botões{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Exportar CSV
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Imprimir
            </Text>{' '}
            até finalizar o processamento do último relatório solicitado;
          </Text>
          <NewsImg
            src={BuscaAtivaVacinacaoInativacaoBotoes}
            alt='Imagem exibindo os botões inativados até finalizar o processamento do último relatório solicitado.'
          />
          <Text fontSize={0.875} component='li'>
            Separação do campo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              endereço
            </Text>{' '}
            em diversas colunas no relatório do formato CSV;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão da coluna{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Microárea
            </Text>{' '}
            no relatório em formato CSV;
          </Text>
          <Text fontSize={0.875} component='li'>
            Alteração do local da tabela de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Relatórios em processamento/processados
            </Text>{' '}
            para otimização da tela;
          </Text>
          <NewsGif
            src={BuscaAtivaVacinacaoRelatorioGif}
            pausedSrc={BuscaAtivaVacinacaoRelatorioPaused}
            alt='Demonstração de funcionalidade de busca ativa de vacinação.'
          />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Gestão da garantia do acesso.'
        icon={<GarantiaAcessoIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='gestao-garantia-acesso-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            O PEC passa a contar com o novo módulo de Gestão da garantia do acesso, cujo objetivo é permitir que os
            profissionais criem uma lista para gerir a demanda reprimida dos estabelecimentos de saúde. Através do
            módulo, poderão ser visualizados os contatos e demandas dos cidadãos que buscaram atendimento e não
            conseguiram acesso imediato ao serviço, podendo então direcioná-los para um possível agendamento futuro.
          </Text>
          <Text fontSize={0.875}>
            Dentre as principais funcionalidades e implementações realizadas no novo módulo, temos:
          </Text>
          <Text fontSize={0.875} component='li'>
            Agendamento de consulta para o cidadão de forma direta através do seu registro na Gestão da garantia do
            acesso;
          </Text>
          <Text fontSize={0.875} component='li'>
            Filtragem da lista de registros através dos campos de cidadão, equipe e tipo de atendimento ou de serviço;
          </Text>
          <Text fontSize={0.875} component='li'>
            Edição dos registros da lista;
          </Text>
          <Text fontSize={0.875} component='li'>
            Adaptação do módulo para os Centros de Especialização Odontológica (CEO).
          </Text>
          <br></br>
          <NewsImg src={TelaGestaoGarantiaAcesso} alt='Imagem da tela do módulo de gestão da garantia do acesso.' />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Prescrição Digital'
        icon={<Consulta style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='prescricao-digital-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Ao encaminhar a prescrição de medicamentos para o cidadão, será possível optar por realizar a impressão ou{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              gerar uma prescrição digital
            </Text>{' '}
            através da opção “Prescrição digital”, caso a instalação esteja habilitada para gerar prescrições digitais.
            O documento da prescrição digital será gerado no formato{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              PDF
            </Text>{' '}
            e enviado ao cidadão, que poderá utilizá-lo para retirar medicamentos. Na farmácia, será possível registrar
            que os medicamentos da prescrição foram fornecidos. Para utilizar a funcionalidade é necessário ativar a
            integração com o servidor de Prescrição digital.
          </Text>
          <Text fontSize={0.875} component='li'>
            A prescrição digital será encaminhada ao cidadão via{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              e-mail.
            </Text>{' '}
            É possível editar o endereço de e-mail do cidadão antes de encaminhar clicando no ícone de edição ao lado do
            campo.
          </Text>
          <NewsGif
            src={PrescricaoDigitalGerarGif}
            pausedSrc={PrescricaoDigitalGerarPaused}
            alt='Demonstração de funcionalidade da geração da prescrição digital.'
          />
          <Text fontSize={0.875} component='li'>
            Para gerar a prescrição digital, é necessário realizar uma{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              assinatura eletrônica
            </Text>{' '}
            do documento. O PEC irá abrir uma nova janela para o processo de assinatura.
          </Text>
          <NewsGif
            src={PrescricaoDigitalAssinarGif}
            pausedSrc={PrescricaoDigitalAssinarPaused}
            alt='Demonstração de funcionalidade de assinatura do documento da prescrição digital.'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Teleinterconsulta'
        icon={<VideochamadaIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='teleinterconsulta-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            A partir desta atualização, é possível realizar{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              teleinterconsultas
            </Text>{' '}
            diretamente no PEC através do novo módulo{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Videochamadas
            </Text>
            . Assim, essa modalidade de consulta entre dois profissionais pode agora ser feita através de um canal
            oficial de comunicação.
          </Text>
          <br></br>
          <NewsImg src={TelaVideochamada} alt='Imagem da tela das Videochamadas no PEC' />
          <br></br>
          <Text fontSize={0.875}>
            Para utilizar essa nova funcionalidade, é necessário que o acesso à instalação seja feito utilizando o
            protocolo HTTPS e que o{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Administrador da Instalação
            </Text>{' '}
            habilite as videochamadas nas configurações de internet:
          </Text>
          <br></br>
          <NewsGif
            src={VideochamadaHabilitarGif}
            pausedSrc={VideochamadaHabilitarPaused}
            alt='Demonstração de como habilitar videochamadas no sistema.'
          />
          <br></br>
          <Text fontSize={0.875}>
            Depois disso, basta que seja criada uma nova chamada no módulo Videochamadas e que seja enviado o link de
            acesso para o profissional com quem se deseja realizar a teleinterconsulta, seja ele usuário do PEC ou não.
            O profissional que receber o link consegue então acessar a teleinterconsulta após preencher seus dados.
          </Text>
          <Carousel>
            <CarouselItem image={TeleinterconsultaModulo} alt='Imagem do módulo de Videochamadas' />
            <CarouselItem image={TeleinterconsultaSalaEspera} alt='Sala de espera para usuários externos ao PEC' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Vínculo entre equipes do PEC'
        icon={<AcompanhamentosIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='equipes-vinculadas-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Visando possibilitar que profissionais que atuam em equipes especialistas (por exemplo a ESB) possam
            visualizar dados de cidadãos vinculados a equipes como ESF, EAP, entre outras. Com a nova versão do XML do
            CNES (3.1), será possível importar o vínculo que existe entre as equipes. Após a importação, é possível
            visualizar os vínculos na aba de equipes dentro do cadastro de unidades de saúde.
          </Text>
          <NewsGif
            src={EquipesVinculadasVisualizacaoGif}
            pausedSrc={EquipesVinculadasVisualizacaoPaused}
            alt='Demonstração da visualização de equipes vinculadas'
          />
          <Text fontSize={0.875} component='li'>
            Alguns relatórios do sistema foram adaptados para considerar o vínculo entre as equipes. Dessa forma, quando
            um profissional pertencente a uma equipe vinculada a outra consultar as informações, mesmo que o cidadão não
            seja acompanhado por sua equipe, o relatório será apresentado.
          </Text>
          <NewsGif
            src={EquipesVinculadasRelatorio}
            pausedSrc={EquipesVinculadasRelatorioPaused}
            alt='Demonstração do impacto da vinculação de equipes nos relatórios'
          />
          <ul>
            <Text fontSize={0.875} component='li'>
              Acompanhamento de cidadãos vinculados
            </Text>
            <Text fontSize={0.875} component='li'>
              Acompanhamento de condições de saúde
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de crianças menores de 5 anos
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de gestante/puérpera
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de risco cardiovascular
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de cadastro territorial
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de cadastro domiciliar e territorial
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de cadastro individual
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de situação do território
            </Text>
          </ul>
          <Text fontSize={0.875} component='li'>
            Os relatórios impactados apresentam a mensagem da imagem abaixo em seu cabeçalho:
          </Text>
          <NewsImg src={EquipesVinculadasRelatorioInfo} alt='Imagem do aviso de vinculação de equipes nos relatórios' />
        </VFlow>
      </NewsHeadingSection>

      {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
        <NewsHeadingSection
          title='Cuidado Compartilhado'
          icon={<ProfissionaisIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
          id='cuidado-compartilhado-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Todas as unidades que possuirem equipes multidisciplinares (eMulti) ou equipes de Núcleos de Apoio à Saúde
              da Família (NASF) vinculadas a uma Equipe de Saúde da Família (ESF) ou uma Equipe de Saúde Bucal (ESB)
              terão acesso na seção do Plano de atendimentos individuais e de atendimentos odontológicos à nova aba de
              Compartilhamento do Cuidado. O objetivo do compartilhamento de cuidado é possibilitar que os profissionais
              da APS discutam o caso clínico do cidadão com um profissional da equipe eMulti ou NASF.
            </Text>
            <NewsImg src={CompartilhamentodeCuidado} alt='Imagem do Cuidado Compartilhado no PEC' />
            <Text fontSize={0.875} component='li'>
              Informação importante: O profissional solicitante é quem iniciou o cuidado compartilhado e o executante o
              profissional que foi indicado para discutir o caso.
            </Text>
            <Text fontSize={0.875} component='li'>
              O PEC conta com alguns módulos novos para que o profissional solicitante e o profissional executante
              consigam conversar sobre o caso clínico do cidadão. Através do menu lateral, dentro da opção "Gestão de
              filas" existe a opção "Cuidados Compartilhados". Existem duas listagem no módulo de "Cuidado
              Compartilhados":
            </Text>
            <Carousel>
              <CarouselItem image={GestaoDeFilas} alt='Imagem do módulo de Gestão de Filas' />
              <CarouselItem
                image={CuidadosSolicitados}
                alt='Imagem dos Cuidados Compartilhados - Solicitados por mim'
              />
              <CarouselItem
                image={CuidadosCompartilhados}
                alt='Imagem dos Cuidados Compartilhados - Compartilhados comigo'
              />
            </Carousel>
            <Text fontSize={0.875} component='li'>
              "Solicitados por mim" que exibe todos os cuidados solicitados pelo usuário, além dos cuidados solicitados
              pela equipe do usuário.
            </Text>
            <Text fontSize={0.875} component='li'>
              "Compartilhados comigo" que exibe todos os cuidados compartilhados com o usuário e com a equipe do
              usuário.
            </Text>
            <Text fontSize={0.875} component='li'>
              Em ambas as listagens as colunas das tabelas são iguais, sendo que todos as linhas possuem um status
              ("Respondido" e "Aguardando") e duas ações possíveis ("Visualizar discussão" e "Discutir caso"). As
              seguintes regras serão aplicadas:
              <ul>
                <Text fontSize={0.875} component='li'>
                  Na aba "Solicitados por mim" o usuário só poderá clicar em "Discutir caso" caso tenha solicitado
                  aquele compartilhamento e caso o status seja "Respondido";
                </Text>
                <Text fontSize={0.875} component='li'>
                  Na aba "Compartilhados comigo" o usuário só poderá clicar em "Discutir caso" caso aquele
                  compartilhamento tenha sido compartilhado com ele e caso o status seja "Aguardando";
                </Text>
                <Text fontSize={0.875} component='li'>
                  Sempre que o status for "Aguardando" o sistema exibirá um contador de horas que exibirá há quantas
                  horas aquela discussão está aguardando resposta;
                </Text>
                <Text fontSize={0.875} component='li'>
                  O botão "Visualizar discussão" poderá ser acessado por todos os profissionais de nível superior da
                  equipe do profissional solicitante ou do profissional executante.
                </Text>
              </ul>
            </Text>
            <Text fontSize={0.875} component='li'>
              Ao acessar o módulo de "Discutir o caso" o sistema exibirá três abas: "Folha de rosto", "Histórico" e
              "Discussão do caso". As duas primeiras abas são identicas às abas de um atendimento. A aba de "Discussão
              do caso" conta com:
            </Text>
            <Carousel>
              <CarouselItem
                image={DiscussaoDeCasoCuidadoSolicitante}
                alt='Imagem do registro da discussão de caso do Cuidado Compartilhado feita pelo Solicitante'
              />
              <CarouselItem
                image={DiscussaoDeCasoCuidadoExecutante}
                alt='Imagem do registro da discussão de caso do Cuidado Compartilhado feita pelo Executante'
              />
            </Carousel>
            <ul>
              <Text fontSize={0.875} component='li'>
                Um resumo com todas as informações inseridas no momento de criação do cuidado compartilhado;
              </Text>
              <Text fontSize={0.875} component='li'>
                Um histórico com todas as respostas para aquela discussão;
              </Text>
              <Text fontSize={0.875} component='li'>
                Por enquanto caso o usuário seja um profissional executante será possível inserir uma conduta de
                "Devolutiva de discussão do caso clínico" que contará com três tipos de devolutiva "Pedido de
                esclarecimento", "Opinião formativa" e "Parecer técnico conclusivo", sendo obrigatório inserir
                observações sobre a devolutiva;
              </Text>
              <Text fontSize={0.875} component='li'>
                Por enquanto caso o usuário seja um profissional solicitante será possível inserir uma conduta de
                "Devolutiva de discussão do caso clínico", sendo obrigatório inserir observações sobre a devolutiva.
              </Text>
            </ul>
            <Text fontSize={0.875} component='li'>
              A lógica é sempre de pergunta e resposta, portanto o solicitante sempre envia uma dúvida que será
              respondida pelo executante. Esse ciclo poderá ser repetido até todas as dúvidas serem sanadas.
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}

      {ENVIO_RNDS_ENABLED && (
        <NewsHeadingSection title='RNDS - Envio de vacinas e atendimentos individuais' id='rnds-vacina-rpm-5-2'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Para as instalações com a RNDS configurada, os seguintes registros serão enviados à Rede Nacional de Dados
              em Saúde (RNDS):
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                <b>Vacinação</b>: Envio ao finalizar o atendimento de vacinação. As transcrições de caderneta também
                serão enviadas, tanto as cadastradas no atendimento de vacinação quanto no atendimento individual.
              </Text>
              <Text fontSize={0.875} component='li'>
                <b>Atendimentos individuais</b>: Envio ao finalizar o atendimento individual do cidadão.
              </Text>
            </ul>
            <Text fontSize={0.875} component='li'>
              Nova página para visualização da quantidade de registros enviados, localizada no módulo de "Gestão
              municipal", aba "RNDS". A página exibe informações sobre os envios dos registros de vacinação e
              atendimentos individuais.
            </Text>
            <NewsImg src={AbaRNDS} alt='Imagem da aba RNDS do módulo de Gestão Municipal' />
          </VFlow>
        </NewsHeadingSection>
      )}

      {UPA_ENABLED && (
        <NewsHeadingSection
          title='E-sus APS Ampliado'
          icon={<UnidadeDeSaude style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
          id='esusAmpliado-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              O e-SUS APS Ampliado passará a reconhecer alguns novos estabelecimentos no sistema.
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}

      {VISUALIZACAO_TERRITORIO_ENABLED && (
        <NewsHeadingSection
          title='Acompanhamento do Território'
          icon={
            <AcompanhamentoTerritorioIcon
              style={{ marginRight: '0.25rem', width: '32px', height: '32px', fill: blue.c60 }}
            />
          }
          id='acompanhamento-territorio-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A versão 5.2 permite visualizar os cadastros sob responsabilidade da sua equipe de forma mais intuitiva,
              facilitando a gestão do seu território adscrito e a formação de núcleos familiares. Basta acessar a aba
              "Território" na seção Acompanhamentos e conferir as novidades.
            </Text>
            <Text fontSize={0.875} component='li'>
              Nessa tela, você verá imóveis e seus moradores organizados por microáreas e logradouros, assim como os
              respectivos índices e filtros de cadastros completos e atualizados. Outras informações de apoio também
              estarão lá, como data da última visita e possíveis inconsistências de cadastro.
            </Text>
            <br></br>
            <NewsGif
              src={AcompanhamentoTerritorioGif}
              pausedSrc={AcompanhamentoTerritorioPaused}
              alt='Demonstração de funcionalidade do Acompanhamento do Território.'
            />
            <br></br>
            <Text fontSize={0.875} component='li'>
              Além disso, é possível corrigir eventuais divergências de cadastro através da edição de logradouro, que
              cria automaticamente atualizações para os imóveis do mesmo, possibilitando a higienização da sua base de
              cadastros.
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}
    </VFlow>
  )
}
