/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { merge, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import { useBuscaCidadaoListagemLazyQuery } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { LinkVerEquipesVinculadasView } from 'view/vinculacao-equipes/LinkVerEquipesVinculadasView'

import AcompanhamentoCondicaoSaudeForm, { validate as validateForm } from './AcompanhamentoCondicaoSaudeForm'
import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'
import AcompanhamentoCondicaoSaudeListing from './AcompanhamentoCondicaoSaudeListing'
import {
  AcompanhamentoSaudeRelatorioModal,
  RelatorioOperacionalAuthorization,
} from './components/AcompanhamentoSaudeRelatorioModal'
import convertModelToInput from './convertModelToInput'
import { DoSearchPanel } from './DoSearchPanel'

const GERAR_RELATORIO_MAX_ELEMENTS = 500

export default function AcompanhamentoCondicaoSaudeView() {
  const [currentFilter, setCurrentFilter] = useState({})
  const [executeQuery, { loading, data }] = useBuscaCidadaoListagemLazyQuery()
  const { hasAuthorization } = useSession()

  const cidadaos = data?.acompanhamentoCondicaoSaude

  const permissions: RelatorioOperacionalAuthorization = {
    hasCriancaPermission: hasAuthorization(Permissions.relatorios.operacional.crianca),
    hasGestantePermission: hasAuthorization(Permissions.relatorios.operacional.gestante),
    hasHipertensaoPermission: hasAuthorization(Permissions.relatorios.operacional.riscoCardiovascular),
  }

  const canAcessarModalRelatorio =
    permissions.hasCriancaPermission || permissions.hasGestantePermission || permissions.hasHipertensaoPermission

  const disableGerarRelatorio =
    loading ||
    cidadaos?.pageInfo.totalElements > GERAR_RELATORIO_MAX_ELEMENTS ||
    cidadaos?.pageInfo.totalElements === 0 ||
    !canAcessarModalRelatorio

  const handleSubmit = (form: AcompanhamentoCondicaoSaudeFormModel) => {
    const input = convertModelToInput(form)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const onChangeFilter = (filter) => {
    const input = filter(currentFilter)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const renderForm = (formProps: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { dirtySinceLastSubmit, values } = formProps
    const showTable = !dirtySinceLastSubmit && (loading || !isUndefinedOrNull(cidadaos))

    return (
      <Fragment>
        <Breadcrumb title='Acompanhamento de condições de saúde' />
        <VFlow style={{ borderBottom: '1px solid lightgray' }}>
          <PageContent type='filled'>
            <TitleGroup title='Acompanhamento de condições de saúde' style={styles.titleAcompanhamento} />
            <AcompanhamentoCondicaoSaudeForm {...formProps} />
          </PageContent>
        </VFlow>
        <PageContent>
          <VFlow>
            {showTable ? (
              <Fragment>
                <TitleGroup title='Cidadãos encontrados' style={styles.titleCidadaosEncontrados} />
                <VFlow>
                  <LinkVerEquipesVinculadasView equipe={values?.equipeResponsavel} />
                  <AcompanhamentoCondicaoSaudeListing
                    onChange={onChangeFilter}
                    resultCidadaos={cidadaos}
                    loading={loading}
                  />
                </VFlow>
                <AcompanhamentoSaudeRelatorioModal
                  filter={merge({}, currentFilter, { pageParams: { page: 0, size: cidadaos?.pageInfo.totalElements } })}
                  disableGerarRelatorio={disableGerarRelatorio}
                  canAcessarModalRelatorio={canAcessarModalRelatorio}
                  permissions={permissions}
                />
              </Fragment>
            ) : (
              <DoSearchPanel />
            )}
          </VFlow>
        </PageContent>
      </Fragment>
    )
  }

  return (
    <Form<AcompanhamentoCondicaoSaudeFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={{ faixaEtariaFilter: AgeRangeFilter.TODAS_FAIXAS }}
    />
  )
}

const styles = {
  titleAcompanhamento: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `,
  titleCidadaosEncontrados: css`
    margin-bottom: 1rem;
  `,
}
