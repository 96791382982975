import { HeadingSection, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import React from 'react'
import Permissions from 'types/Permissions'

import { ConfiguracoesPerformanceBox } from './ConfiguracoesPerformanceBox'

export const ConfiguracoesAvancadasView = () => {
  return (
    <VFlow vSpacing={2}>
      <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarNumeroDeRequisicoesSimultaneas}>
        <HeadingSection level={2} title='Performance'>
          <BoxGroup>
            <ConfiguracoesPerformanceBox />
          </BoxGroup>
        </HeadingSection>
      </CheckPermission>
    </VFlow>
  )
}
