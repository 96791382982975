import { VFlow } from 'bold-ui'
import { useCuidadoCompartilhadoEvolucoesQuery } from 'graphql/hooks.generated'
import React from 'react'

import { CuidadoCompartilhadoEvolucaoItem } from './CuidadoCompartilhadoEvolucaoItem'

interface CuidadoCompartilhadoEvolucoesProps {
  cuidadoCompartilhadoId: ID
  removeLastLine?: boolean
}

export const CuidadoCompartilhadoEvolucoes = (props: CuidadoCompartilhadoEvolucoesProps) => {
  const { cuidadoCompartilhadoId, removeLastLine } = props

  const {
    data: { cuidadoCompartilhadoEvolucoes },
  } = useCuidadoCompartilhadoEvolucoesQuery({
    variables: {
      input: {
        idCuidadoCompartilhado: cuidadoCompartilhadoId,
      },
    },
  })

  return (
    <VFlow vSpacing={0.25}>
      {cuidadoCompartilhadoEvolucoes?.content?.map((evolucao) => (
        <CuidadoCompartilhadoEvolucaoItem evolucao={evolucao} key={evolucao.id} removeLastLine={removeLastLine} />
      ))}
    </VFlow>
  )
}
