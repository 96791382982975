import { Cell, FormControl, Grid, Theme, useStyles, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { ErrorField } from 'components/form'
import { toDate } from 'date-fns'
import React, { CSSProperties } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { PlanoFormProps } from '../PlanoForm'
import { CiapPlanoField, CiapPlanoModel, CiapPlanoProps } from './CiapPlanoField'
import { ProcedimentoPlanoField, ProcedimentoPlanoModel } from './ProcedimentoPlanoField'

export interface IntervencoesProcedimentosPlanoModel {
  ciaps?: CiapPlanoModel[]
  procedimentos?: ProcedimentoPlanoModel[]
  error?: string
}

interface IntervencoesProcedimentosViewProps
  extends Omit<CiapPlanoProps, 'name'>,
    Pick<PlanoFormProps, 'isAtendimentoOdonto' | 'isAtendimentoProcedimentos'> {
  name: MetaPath<IntervencoesProcedimentosPlanoModel>
  dataAtendimento: Instant
}

export function IntervencoesProcedimentosView(props: IntervencoesProcedimentosViewProps) {
  const { isAtendimentoOdonto, isAtendimentoProcedimentos, cidadao, name, dataAtendimento } = props
  const { classes } = useStyles(createStyles)
  const alert = useAlert()

  const alertProcedimentoAutomaticoAlterado = () => {
    alert(
      'info',
      'Um procedimento automático foi alterado na lista de intervenções e/ou procedimentos clínicos realizados.'
    )
  }

  return (
    <VFlow vSpacing={0}>
      <FormControl
        label='Intervenções e/ou procedimentos clínicos realizados'
        required={isAtendimentoProcedimentos}
        style={classes.heading}
      >
        <Grid>
          <Cell size={12}>
            <ErrorField name={name.error} />
          </Cell>
          {!isAtendimentoOdonto && (
            <Cell size={12}>
              <CiapPlanoField name={name.ciaps} cidadao={cidadao} />
            </Cell>
          )}
          <Cell size={12}>
            <ProcedimentoPlanoField
              name={name.procedimentos}
              cidadao={cidadao}
              filtrarProcedimentosOdonto={isAtendimentoOdonto}
              dataAtendimento={dateAsYyyyMmDd(toDate(dataAtendimento))}
              onAutomaticChange={alertProcedimentoAutomaticoAlterado}
            />
          </Cell>
        </Grid>
      </FormControl>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  heading: {
    '> label': {
      ...theme.typography.variant('h3'),
    } as CSSProperties,
  } as CSSProperties,
})
