import { Cell } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import CheckPermission from 'components/auth/CheckPermission'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React from 'react'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { meta as metaSoapState } from 'view/atendimentos/atendimento-individual/model'

import { TipoPreNatal } from '../../pre-natal/model'
import {
  AgendarConsultaSection,
  AgendarConsultaSectionModel,
  AgendarConsultaSectionProps,
} from './AgendarConsultaSection'
import { AgendarProximasConsultasSectionModel } from './proximas-consultas/AgendarProximasConsultasSection'
import { ProximasConsultasPanel, ProximasConsultasPanelProps } from './proximas-consultas/ProximasConsultasPanel'

export interface AgendarConsultaPanelModel extends AgendarConsultaSectionModel {
  openedAccordions?: (string | number)[]
  proximasConsultas?: AgendarProximasConsultasSectionModel
}

interface AgendarConsultaPanelProps
  extends Partial<Omit<ProximasConsultasPanelProps, 'name' | 'idadeGestacionalEmDias'>>,
    Partial<Pick<AgendarConsultaSectionProps, 'hasPainelLateral' | 'isRegistroTardio' | 'tipoPreNatal'>> {
  name: MetaPath<AgendarConsultaPanelModel>
}

export const AgendarConsultaPanel = React.memo((props: AgendarConsultaPanelProps) => {
  const { name, isRegistroTardio, tipoPreNatal, dataAtendimento, prontuarioId, hasPainelLateral } = props

  const {
    input: { value: idadeGestacional },
  } = usePecField({ name: metaSoapState.acompanhamentoPreNatal.idadeGestacional, subscription: { value: true } })

  const hasInfoToCalculateSugestoes =
    !isUndefinedOrNull(idadeGestacional?.dias) &&
    !isUndefinedOrNull(dataAtendimento) &&
    !isUndefinedOrNull(prontuarioId)

  const isInicioOuContinuacaoPreNatal =
    tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL || tipoPreNatal === TipoPreNatal.CONTINUACAO_PRE_NATAL

  const shouldRenderProximasConsultas = hasInfoToCalculateSugestoes && isInicioOuContinuacaoPreNatal

  return (
    <CheckPermission permission={Permissions.visualizarAgenda.agendar}>
      <FormSectionAccordion
        name={name.openedAccordions}
        title='Agendar consulta'
        uuid='agendarConsulta'
        dangerouslySetExpanded={shouldRenderProximasConsultas}
      >
        <AgendarConsultaSection
          name={name}
          errorPath={name}
          isRegistroTardio={isRegistroTardio}
          hasPainelLateral={hasPainelLateral}
        />
        {shouldRenderProximasConsultas && (
          <Cell size={12}>
            <ProximasConsultasPanel
              name={name.proximasConsultas}
              idadeGestacionalEmDias={idadeGestacional.dias}
              dataAtendimento={dataAtendimento}
              prontuarioId={prontuarioId}
            />
          </Cell>
        )}
      </FormSectionAccordion>
    </CheckPermission>
  )
})
