import { HeadingSection } from 'bold-ui'
import React from 'react'

import { TransmissaoLinksDestinoQueryInput, TransmissaoLinksDestinoTable } from './TransmissaoLinksDestinoTable'

export interface TransmissaoLinksDestinoProps {
  initialFilter?: TransmissaoLinksDestinoQueryInput
}

export const TransmissaoLinksDestinoBox = (props: TransmissaoLinksDestinoProps) => {
  return (
    <HeadingSection title='Instalações de destino para envio online' level={2}>
      <TransmissaoLinksDestinoTable />
    </HeadingSection>
  )
}
