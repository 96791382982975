import { ExternalStyles } from 'bold-ui'
import { Agendado, OrigemAgendamentoEnum } from 'graphql/types.generated'
import React from 'react'

import { situacaoSincronizacaoAgendaOnlineRecord } from '../../model'
import { StatusSincronizacaoAgendaOnline } from '../listagem/StatusSincronizacaoAgendaOnline'
import { AgendaOnlineMarker } from './AgendaOnlineMarker'

export interface AgendamentoMarkerProps extends Pick<Agendado, 'origem' | 'situacaoSincronizacao'> {
  isAgendaOnlineAtiva: boolean
  styles?: ExternalStyles
  defaultIcon?: JSX.Element
}

export function AgendamentoMarker(props: AgendamentoMarkerProps) {
  const { origem, situacaoSincronizacao, isAgendaOnlineAtiva, styles, defaultIcon = null } = props

  const isAgendadoPeloCidadao = origem === OrigemAgendamentoEnum.MEUDIGISUS

  const { mostrarMinimizado, ...syncStatusProps } = situacaoSincronizacaoAgendaOnlineRecord[situacaoSincronizacao] || {}

  const showIconeStatusSincronizacao = isAgendaOnlineAtiva && mostrarMinimizado

  return isAgendadoPeloCidadao ? (
    <AgendaOnlineMarker styles={styles} />
  ) : showIconeStatusSincronizacao ? (
    <StatusSincronizacaoAgendaOnline {...syncStatusProps} styles={styles} />
  ) : (
    defaultIcon
  )
}
