import { HFlow, Text } from 'bold-ui'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { EquipeAdSelectFieldDocument } from 'graphql/hooks.generated'
import { EquipeAdSelectFieldQuery, EquipeAdSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type EquipeSelectModel = EquipeAdSelectFieldQuery['equipesAD']['content'][0]

export interface EquipeSelectFieldProps extends AsyncSelectFieldProps<EquipeSelectModel> {
  localidadeId?: ID
  equipeId?: ID
  mostrarEquipesRelacionadas?: boolean
}

export function EquipeAdSelectField(props: EquipeSelectFieldProps) {
  const { localidadeId, equipeId, mostrarEquipesRelacionadas, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EquipeSelectModel,
    EquipeAdSelectFieldQuery,
    EquipeAdSelectFieldQueryVariables
  >({
    query: EquipeAdSelectFieldDocument,
    extractItems: (data) => data?.equipesAD?.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarEquipesRelacionadas,
        localidadeId,
        equipeId,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (option: EquipeSelectModel) => option?.nome

  const renderItem = (item: EquipeSelectModel) => (
    <>
      <Text fontWeight='bold'>
        {item.nome?.capitalize()} – {item.tipoEquipe.sigla}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>INE:</Text>
        {item.ine}
      </HFlow>
    </>
  )

  return (
    <SelectField<EquipeSelectModel> itemToString={itemToString} renderItem={renderItem} {...selectProps} {...rest} />
  )
}
