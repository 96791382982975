import { DropdownItem, Tooltip } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { DropdownButton } from 'components/dropdown'
import React, { MouseEvent } from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'
import { grupoCboVisualizarProntuario } from 'view/cidadao/acessos'

import { CidadaoListaEspera } from './model'

interface ListaEsperaTableDropdownMenuProps {
  cidadao: CidadaoListaEspera
  listaEsperaId: ID
}

export function ListaEsperaTableDropdownMenu(props: ListaEsperaTableDropdownMenuProps) {
  const { cidadao, listaEsperaId } = props
  const cidadaoHasProntuario = !!cidadao.prontuario
  const history = useHistory()
  const { checkAuthorization, hasCboAuth } = useSession()

  const hasPermissaoVisualizarProntuario =
    checkAuthorization(Permissions.visualizarProntuario) && hasCboAuth(grupoCboVisualizarProntuario)
  const hasPermissaoVisualizarCidadao = checkAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao)
  const hasAnyPermission = hasPermissaoVisualizarProntuario || hasPermissaoVisualizarCidadao

  if (!hasAnyPermission) {
    return null
  }

  const handleClickVisualizarProntuario = () => {
    history.push(`/cidadao/${cidadao.id}/folha-rosto`)
  }

  const handleClickVisualizarCidadao = () => {
    history.push(`/cidadao/${cidadao.id}/informacoes`)
  }

  const handleClickEditar = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    history.push(`/gestao-filas/garantia-acesso/editar-cidadao/${listaEsperaId}`)
  }

  return (
    <DropdownButton stopPropagationOnClick onKeyDown={(e) => e.stopPropagation()}>
      <DropdownItem onClick={handleClickEditar}>Editar</DropdownItem>
      {hasPermissaoVisualizarProntuario && (
        <Tooltip text={!cidadaoHasProntuario && 'Cidadão não possui prontuário'}>
          <DropdownItem disabled={!cidadaoHasProntuario} onClick={handleClickVisualizarProntuario}>
            Visualizar prontuário
          </DropdownItem>
        </Tooltip>
      )}
      {hasPermissaoVisualizarCidadao && (
        <DropdownItem onClick={handleClickVisualizarCidadao}>Visualizar cidadão</DropdownItem>
      )}
    </DropdownButton>
  )
}
