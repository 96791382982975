import { Observable } from 'bold-ui'
import { firebaseApp } from 'config/firebase'
import firebase from 'firebase'
import React, { useContext } from 'react'

import { useFirebaseQuery } from '../../graphql/hooks.generated'

interface FirebaseStoreState {
  analytics: firebase.analytics.Analytics
  performance: firebase.performance.Performance
}

class FirebaseStore extends Observable<FirebaseStoreState> {
  private stateValue: FirebaseStoreState = {
    analytics: firebaseApp.analytics(),
    performance: firebaseApp.performance(),
  }

  get analytics(): firebase.analytics.Analytics {
    return this.stateValue.analytics
  }

  get performance() {
    return this.stateValue.performance
  }

  public changeDataCollection = (firebaseEnabled: boolean = false) => {
    this.analytics.setAnalyticsCollectionEnabled(firebaseEnabled)
    this.performance.dataCollectionEnabled = firebaseEnabled
    this.performance.instrumentationEnabled = firebaseEnabled

    setTimeout(() => this.notify(this.stateValue))
  }
}

const isSupported = process.env.NODE_ENV !== 'test'
const defaultFirabaseStore = isSupported ? new FirebaseStore() : null
const FirebaseContext = React.createContext<FirebaseStore>(defaultFirabaseStore)

export default () => {
  const firebaseStore = useContext(FirebaseContext)

  const performance = isSupported ? firebaseStore.performance : null
  const analytics = isSupported ? firebaseStore.analytics : null

  const {
    data: { info },
  } = useFirebaseQuery({
    fetchPolicy: 'cache-first',
  })

  if (isSupported) {
    firebaseStore.changeDataCollection(info?.firebaseEnabled)
  }

  return { performance, analytics, isSupported }
}
