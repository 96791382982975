import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import { useSalvarCuidadoCompartilhadoMutation } from 'graphql/hooks.generated'
import { useLocalStorage } from 'hooks/useStorage'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { CuidadoCompartilhadoHeaderTabs } from '../components/CuidadoCompartilhadoHeaderTabs'
import {
  CidadaoCuidadoCompartilhado,
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  DiscussaoCondutaViewEnum,
  ResponsavelCuidadoCompartilhado,
} from '../model-cuidadocompartilhado'
import { getResponsabilidadeCuidadoCompartilhado } from '../util'
import { DiscussaoCasoView } from './discussao-caso/DiscussaoCasoView'
import { logRegistrarDiscussao } from './logRegistrarDiscussao'

interface CuidadoCompartilhadoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

const initialValuesSolicitante: DiscussaoCasoSolicitanteFieldsModel = {
  condutaGroup: DiscussaoCondutaViewEnum.NOVA_PERGUNTA,
  pergunta: null,
}

const initialValuesExecutante: DiscussaoCasoExecutanteFieldsModel = {
  condutaGroup: DiscussaoCondutaViewEnum.DEVOLUTIVA_DISCUSSAO_CASO,
  conduta: null,
  resposta: null,
}

const computeInitialValues = (responsavel: ResponsavelCuidadoCompartilhado): DiscussaoCasoFormModel => {
  switch (responsavel) {
    case 'EXECUTANTE':
      return { executanteFields: initialValuesExecutante }
    case 'SOLICITANTE':
      return { solicitanteFields: initialValuesSolicitante }
  }
}

export const CuidadoCompartilhadoView = (props: CuidadoCompartilhadoViewProps) => {
  const { cuidadoCompartilhado } = props

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const history = useHistory()
  const match = useRouteMatch()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const responsavelCuidado: ResponsavelCuidadoCompartilhado = getResponsabilidadeCuidadoCompartilhado(
    acesso.id,
    cuidadoCompartilhado.lotacaoExecutante.id,
    cuidadoCompartilhado.lotacaoSolicitante.id
  )

  const initialValues = useMemo(() => computeInitialValues(responsavelCuidado), [responsavelCuidado])

  const [formInitialized, setFormInitialized] = useState(false)
  const [cacheState, setCacheState, deleteCacheState] = useLocalStorage<DiscussaoCasoFormModel>(
    `${acesso.id}/cuidado-compartilhado/${cuidadoCompartilhado.id}`,
    true,
    initialValues
  )

  const [salvar] = useSalvarCuidadoCompartilhadoMutation()

  const handleSubmit = (values: DiscussaoCasoFormModel) =>
    salvar({
      variables: {
        input: {
          idCuidadoCompartilhado: cuidadoCompartilhado.id,
          devolutivaInput: values.executanteFields && {
            conduta: values.executanteFields.conduta,
            resposta: values.executanteFields.resposta,
          },
          perguntaInput: values.solicitanteFields && { pergunta: values.solicitanteFields.pergunta },
        },
      },
    })
      .then(() => {
        alert('success', 'Conduta para cuidado compartilhado registrada com sucesso.')
        logRegistrarDiscussao(analytics.logEvent, values, responsavelCuidado)
        deleteCacheState()
        goBack()
      })
      .catch(handleRejection)

  const handleFormChange = useCallback(
    (values: DiscussaoCasoFormModel) => {
      /* Avoids the error of updating a component while rendering a different component. */
      if (formInitialized && !isUndefinedOrNull(values)) {
        values.lastSaved = new Date().getTime()
        setCacheState(values)
      }
    },
    [formInitialized, setCacheState]
  )

  const goBack = useCallback(
    () => history.push(match.path.substring(0, match.path.lastIndexOf('/', match.path.lastIndexOf('/') - 1))),
    [history, match.path]
  )

  const handleCancel = () => {
    confirm({
      title: 'Deseja cancelar o cuidado compartilhado?',
      body: 'As alterações realizadas serão perdidas.',
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
      onConfirm: () => {
        deleteCacheState()
        goBack()
      },
    })()
  }

  useEffect(() => {
    setFormInitialized(true)
  }, [])

  const renderDiscussaoCaso = (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => (
    <DiscussaoCasoView
      cuidadoCompartilhado={cuidadoCompartilhado}
      cidadao={cidadao}
      headerHeight={headerHeight}
      initialValues={cacheState}
      responsavelCuidado={responsavelCuidado}
      handleFormChange={handleFormChange}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
    />
  )

  return (
    <CuidadoCompartilhadoHeaderTabs
      cuidadoCompartilhado={cuidadoCompartilhado}
      renderDiscussaoCaso={renderDiscussaoCaso}
      handleCancel={handleCancel}
      isVisualizacao={false}
    />
  )
}
