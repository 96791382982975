import { Box } from 'components/Box'
import { BoxGroup } from 'components/BoxGroup'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { ConfiguracaoPerformanceModel, ConfiguracoesPerformanceForm } from './ConfiguracoesPerformanceForm'

export interface ConfiguracoesPerformanceProps {
  initialValues?: ConfiguracaoPerformanceModel
  valorPadrao?: boolean
  formProps?: FormRenderProps
}

export const ConfiguracoesPerformanceBox = () => {
  return (
    <Box>
      <BoxGroup>
        <ConfiguracoesPerformanceForm />
      </BoxGroup>
    </Box>
  )
}
