import { CheckboxGroupField, CheckboxGroupFieldProps, CheckboxOption } from 'components/form/field/CheckboxGroupField'
import { ParteBucalEnum } from 'graphql/types.generated'
import React from 'react'

import { getParteBucal } from '../../util'

export interface SextantesCheckboxGroupFieldProps
  extends Omit<CheckboxGroupFieldProps<ParteBucalEnum[]>, 'label' | 'options' | 'lines' | 'vSpacing'> {}

export default function SextantesCheckboxGroupField(props: SextantesCheckboxGroupFieldProps) {
  return <CheckboxGroupField label='Sextante' options={options} lines={2} {...props} />
}

const options: CheckboxOption<ParteBucalEnum>[] = [
  ParteBucalEnum.SEXTANTE_S1,
  ParteBucalEnum.SEXTANTE_S2,
  ParteBucalEnum.SEXTANTE_S3,
  ParteBucalEnum.SEXTANTE_S6,
  ParteBucalEnum.SEXTANTE_S5,
  ParteBucalEnum.SEXTANTE_S4,
].map((sextante) => ({ label: getParteBucal(sextante).initials, value: sextante }))
