import { PrivateRoute } from 'components/route'
import React from 'react'
import { Switch, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import ListaEsperaAgendarConsultaModalForm from './agendamento/ListaEsperaAgendarConsultaModalForm'
import ListaEsperaEditarCidadaoModalForm from './edicao/ListaEsperaEditarCidadaoModalForm'
import ListaEsperaView from './ListaEsperaView'
import ListaEsperaRemoverCidadaoModalForm from './remocao/ListaEsperaRemoverCidadaoModalForm'

export function ListaEsperaRootView() {
  const match = useRouteMatch()

  return (
    <>
      <ListaEsperaView />
      <Switch>
        <PrivateRoute
          path={`${match.url}/remover-cidadao/:listaEsperaId/`}
          component={ListaEsperaRemoverCidadaoModalForm}
          permission={Permissions.visualizarListaDeAtendimento}
        />
        <PrivateRoute
          path={`${match.url}/agendar-consulta/:listaEsperaId/`}
          component={ListaEsperaAgendarConsultaModalForm}
          permission={Permissions.visualizarAgenda.agendar}
        />
        <PrivateRoute
          path={`${match.url}/editar-cidadao/:listaEsperaId/`}
          component={ListaEsperaEditarCidadaoModalForm}
          permission={Permissions.visualizarListaDeAtendimento}
        />
      </Switch>
    </>
  )
}
