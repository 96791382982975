/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, ExternalStyles, FormControl, Grid, HFlow, Icon, Theme, Tooltip } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormRenderProps, SelectField, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { TipoEncaminhamentoSelectField } from 'components/form/field/select/TipoEncaminhamentoSelectField'
import { PopperButton, PopperControls } from 'components/popper'
import theme from 'config/theme'
import { FormState } from 'final-form'
import createDecorator from 'final-form-calculate'
import { TipoEncaminhamentoExternoEnum } from 'graphql/types.generated'
import { useState } from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { EncaminhamentoExternoFilterModel, EspecialidadeSisregModel } from '../model'

interface EncaminhamentoExternoFilterProps {
  filter: EncaminhamentoExternoFilterModel
  dataReferencia: Instant
  onChange(values: EncaminhamentoExternoFilterModel): void
  especialidadeSelectFieldValues: { id: ID; descricao: string }[]
}

const name = metaPath<EncaminhamentoExternoFilterModel>()

const itemToString = (item: EspecialidadeSisregModel) => item?.descricao?.capitalize()

export const EncaminhamentoExternoFilter = ({
  filter,
  onChange,
  dataReferencia,
  especialidadeSelectFieldValues,
}: EncaminhamentoExternoFilterProps) => {
  const handlePopperSubmit = (model: EncaminhamentoExternoFilterModel) => {
    onChange({ ...filter, somenteMeus: model.somenteMeus, periodo: model.periodo })
  }

  const [activeFiltersCount, setActiveFiltersCount] = useState(0)
  const renderPopper = ({ close }: PopperControls) => {
    const submitAndClose = (values: EncaminhamentoExternoFilterModel) => {
      handlePopperSubmit(values)
      const filtrosAtivos = +!!(values.periodo?.endDate || values.periodo?.startDate) + +!!values.somenteMeus
      setActiveFiltersCount(filtrosAtivos)
      close()
    }

    return (
      <Form<EncaminhamentoExternoFilterModel>
        initialValues={filter}
        onSubmit={submitAndClose}
        render={(formProps: FormRenderProps<EncaminhamentoExternoFilterModel>) => {
          return (
            <Box style={classes.extraFilterBox}>
              <Grid>
                <Cell size={12}>
                  <CheckboxField name={name.somenteMeus} label='Ver somente os meus encaminhamentos' />
                </Cell>
                <Cell size={12}>
                  <DateRangeField name={name.periodo} label='Período' maxDate={new Date(dataReferencia)} />
                </Cell>
                <Cell size={12} alignSelf='flex-end'>
                  <HFlow justifyContent='flex-end'>
                    <Button size='small' onClick={close}>
                      Fechar
                    </Button>
                    <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                      Filtrar
                    </SubmitButton>
                  </HFlow>
                </Cell>
              </Grid>
            </Box>
          )
        }}
      />
    )
  }

  const handleChange = ({ values }: FormState<EncaminhamentoExternoFilterModel>) => {
    onChange({ ...filter, tipoEncaminhamento: values.tipoEncaminhamento, especialidade: values.especialidade })
  }

  const classes = createStyles(theme)

  const renderForm = ({ values }: FormRenderProps<EncaminhamentoExternoFilterModel>) => {
    return (
      <HFlow alignItems='center' justifyContent='space-between'>
        <FormSpy onChange={handleChange} />
        <HFlow>
          <FormControl label='Tipo de encaminhamento'>
            <TipoEncaminhamentoSelectField name={name.tipoEncaminhamento} disabled={!!values.especialidade} />
          </FormControl>
          <FormControl label='Especialidade'>
            <Tooltip
              text={
                !!values.tipoEncaminhamento &&
                values.tipoEncaminhamento !== TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO &&
                'Necessário que o tipo de encaminhamento seja serviço especializado'
              }
            >
              <SelectField<EspecialidadeSisregModel>
                name={name.especialidade}
                items={especialidadeSelectFieldValues}
                itemToString={itemToString}
                disabled={
                  !!values.tipoEncaminhamento &&
                  values.tipoEncaminhamento !== TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO
                }
              />
            </Tooltip>
          </FormControl>
        </HFlow>
        <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
          <Icon icon='filterOutline' />
          Filtros{activeFiltersCount > 0 ? ` (${activeFiltersCount})` : ''}
        </PopperButton>
      </HFlow>
    )
  }

  return <Form<EncaminhamentoExternoFilterModel> render={renderForm} decorators={filtersDecorators} />
}

const createStyles = (theme: Theme) => ({
  searchField: css`
    ${theme.breakpoints.up('lg')} {
      width: 18.6rem;
    }
  ` as ExternalStyles,
  extraFilterBox: css`
    width: 25rem;
  `,
})

const filtersDecorators = [
  createDecorator({
    field: name.especialidade.absolutePath(),
    updates: {
      [name.tipoEncaminhamento.absolutePath()]: (value: EspecialidadeSisregModel) => {
        if (value) {
          return TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO
        }
      },
    },
  }),
]
