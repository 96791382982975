/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Theme, Tooltip, VFlow } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import useFirebase from 'components/firebase/useFirebase'
import { resolveValue, usePecField } from 'components/form/final-form/hooks/useField'
import theme from 'config/theme'
import { FaixaEtariaConsumoAlimentar } from 'graphql/types.generated'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AccordionItemState } from 'react-accessible-accordion'
import { useForm, useFormState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { OBSERVACAO_DISABLE_LIMPAR_CAMPOS_MCA } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { MarcadoresConsumoPanelModel } from '../model'
import MCAFormAlert from './MCAFormAlert'

interface MCAFormAccordionProps {
  name: MetaPath<MarcadoresConsumoPanelModel>
  title: string
  uuid: string
  heading: string
  faixaEtariaMCA: FaixaEtariaConsumoAlimentar
  children: React.ReactNode
  hasError: boolean
  isAtendimentoObservacao: boolean
  onClearFields: () => void
}

function MCAFormAccordion(props: MCAFormAccordionProps) {
  const { name, title, uuid, heading, hasError, children, isAtendimentoObservacao, onClearFields } = props
  const isAccordionClosedRef = useRef(true)
  const {
    input: { value },
    tools: { resetToUndefined },
  } = usePecField({ name })

  const [shouldDisableLimparCampos, setShouldDisableLimparCampos] = useState(true)
  const { analytics } = useFirebase()

  const { initialValues } = useFormState({ subscription: { initialValues: true } })
  const mcaInitialValues = resolveValue(initialValues, name)

  const form = useForm<SoapState>()

  const handleOnClickClearFields = (): void => {
    resetToUndefined()
    onClearFields()
    analytics.logEvent('click_limpar_campos_mca')
  }

  const canClearFields = useCallback(
    (model: MarcadoresConsumoPanelModel): boolean => {
      return (
        model &&
        Object.entries(value).some(
          ([key, value]: [string, string | string[]]) => value?.length && key !== name.openedAccordions?.alias
        )
      )
    },
    [name.openedAccordions, value]
  )

  const hasInitialValue = canClearFields(mcaInitialValues)

  useEffect(() => {
    // TODO (Legacy): #11873
    const unsubscribe = form.subscribe(
      ({ values }) => {
        if (isAtendimentoObservacao && hasInitialValue) {
          setShouldDisableLimparCampos(true)
        } else {
          const disabled = !canClearFields(values)
          setShouldDisableLimparCampos(disabled)
        }
      },
      { values: true }
    )
    return () => unsubscribe()
  }, [canClearFields, form, hasInitialValue, isAtendimentoObservacao])

  const classes = createStyles(theme)

  return (
    <FormSectionAccordion name={name.openedAccordions} title={title} uuid={uuid} style={classes.accordionStyle}>
      <AccordionItemState>
        {({ expanded }) => {
          if (expanded) {
            if (isAccordionClosedRef.current) {
              analytics.logEvent('expand_accordion_mca')
              isAccordionClosedRef.current = false
            }
            return (
              <div
                css={css`
                  padding: 1rem;
                `}
              >
                <VFlow>
                  <Grid
                    style={css`
                      padding: 0;
                    `}
                    justifyContent='space-between'
                  >
                    <Cell size={10}>
                      <Heading
                        level={4}
                        style={css`
                          margin-bottom: 0.6rem;
                        `}
                      >
                        {heading}
                      </Heading>
                    </Cell>
                    <Cell size={10}>
                      <MCAFormAlert hasError={hasError} />
                    </Cell>
                    <Cell
                      size={2}
                      style={css`
                        text-align: right;
                      `}
                    >
                      <Tooltip
                        text={isAtendimentoObservacao && hasInitialValue && OBSERVACAO_DISABLE_LIMPAR_CAMPOS_MCA}
                      >
                        <Button
                          skin='outline'
                          kind='primary'
                          color='primary'
                          size='small'
                          onClick={handleOnClickClearFields}
                          disabled={shouldDisableLimparCampos}
                          style={css`
                            margin-left: auto;
                          `}
                        >
                          Limpar campos
                        </Button>
                      </Tooltip>
                    </Cell>
                  </Grid>
                  {children}
                </VFlow>
              </div>
            )
          }
          isAccordionClosedRef.current = true
          return null
        }}
      </AccordionItemState>
    </FormSectionAccordion>
  )
}

export default React.memo(MCAFormAccordion)

const createStyles = (theme: Theme) => ({
  accordionStyle: css`
    margin: 0;
    border-radius: 2px;
    border: 1px solid ${theme.pallete.gray.c40};
    box-shadow: ${theme.shadows.outer[10]};
  `,
})
