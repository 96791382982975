import useSession from 'components/auth/useSession'
import { useFlags } from 'config/useFlagsContext'
import { endOfDay, endOfMinute, startOfDay, startOfMinute, subHours } from 'date-fns'
import { OrdemListaAtendimentoEnum, StatusAtendimento, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useCallback } from 'react'
import { InstantRange } from 'util/date/dateRange'

import { LA_UPA_PERIODO_IN_HOURS } from '../model'
import { AtendimentosFilterModel } from './model'

const statusAtendimento = [
  StatusAtendimento.AGUARDANDO_ATENDIMENTO,
  StatusAtendimento.EM_ATENDIMENTO,
  StatusAtendimento.EM_ESCUTA_INICIAL,
]

export function useListaAtendimentoFilterDefault(): { getFilterDefault: () => AtendimentosFilterModel } {
  const { UPA_ENABLED } = useFlags()
  const { tipoEstabelecimento } = useSession()
  const { getServerTimeNow } = useServerTime()

  const getFilterDefault = useCallback(() => {
    const isUpa = UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
    const serverTime = getServerTimeNow().getTime()

    const filterDefault: AtendimentosFilterModel = {
      filter: {
        periodo: getListaAtendimentoPeriodoPadrao(serverTime, tipoEstabelecimento, UPA_ENABLED),
        ordem: OrdemListaAtendimentoEnum.CLASSIFICACAO_RISCO,
        statusAtendimento,
        tiposServico: [],
        equipes: [],
        responsaveis: [],
        isSomenteMeusAtendimentos: false,
        isSomenteNaoFinalizados: false,
        isPeriodo24h: isUpa,
      },
      lastUpdate: serverTime,
      lastDailyUpdate: serverTime,
      pageParams: {},
    }

    return filterDefault
  }, [UPA_ENABLED, getServerTimeNow, tipoEstabelecimento])

  return { getFilterDefault }
}

function getListaAtendimentoPeriodoPadrao(
  serverTime: Instant,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  UPA_ENABLED: Boolean
): InstantRange {
  const isEstabelecimento24horas = UPA_ENABLED && tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

  if (isEstabelecimento24horas) {
    return {
      startDate: startOfMinute(subHours(serverTime, LA_UPA_PERIODO_IN_HOURS)).getTime(),
      endDate: endOfMinute(serverTime).getTime(),
    }
  } else {
    return {
      startDate: startOfDay(serverTime).getTime(),
      endDate: endOfDay(serverTime).getTime(),
    }
  }
}
