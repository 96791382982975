import axios, { AxiosPromise } from 'axios'

import { ImpressaoAtendimentoInput } from './ImpressaoAtendimentoIndividual'

export interface ImpressaoAtendimentosHistoricoInput {
  atendimentos: ImpressaoAtendimentoInput[]
}

export class ImpressaoAtendimentosHistorico {
  imprimirAtendimentosHistorico(input: ImpressaoAtendimentosHistoricoInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirAtendimentosHistorico', input, { responseType: 'blob' })
  }
}
