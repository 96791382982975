/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonGroup, Modal, ModalBody, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import useFirebase from 'components/firebase/useFirebase'
import { TitleGroup } from 'components/TitleGroup'
import { usePrescricaoDigitalEnabledQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { isHttpsConnection } from 'util/https'

import { MedicamentoFormModel, PrescricaoDigitalFormModel } from '../model'
import { ImpressaoPrescricaoView } from './impressao/ImpressaoPrescricaoView'
import { PrescricaoDigitalGerarView } from './prescricao-digital/PrescricaoDigitalGerarView'

enum ActiveTab {
  IMPRIMIR,
  PRESCRICAO_DIGITAL,
}

export interface EncaminharPrescricaoModalProps {
  atendimentoId: ID
  medicamentos: MedicamentoFormModel[]
  prescricaoDigital?: PrescricaoDigitalFormModel
  updatePrescricaoDigital?: (prescricao: PrescricaoDigitalFormModel) => void
}

export default function EncaminharPrescricaoModal(props: EncaminharPrescricaoModalProps) {
  const { medicamentos, atendimentoId, prescricaoDigital, updatePrescricaoDigital } = props

  const { analytics } = useFirebase()

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.IMPRIMIR)

  const {
    data: {
      acesso: {
        municipio: { id: municipioId },
      },
    },
  } = useSession()

  const { data: infoPrescricaoDigital } = usePrescricaoDigitalEnabledQuery()
  const isPrescricaoDigitalEnabled = infoPrescricaoDigital?.info?.isPrescricaoDigitalEnabled

  const {
    cidadao: { id: cidadaoId, dataNascimento: cidadaoDataNascimento, sexo: cidadaoSexo },
  } = useAtendimentoContext()

  const history = useHistory()
  const handleModalClose = () => history.goBack()
  const handleClickPrescricaoTab = () => {
    analytics.logEvent('click_aba_prescricao_digital')
    setActiveTab(ActiveTab.PRESCRICAO_DIGITAL)
  }

  return (
    <Fragment>
      <Modal open onClose={handleModalClose} closeOnBackdropClick={false} style={styles.mainContainer}>
        <ModalBody>
          <VFlow vSpacing={0.5}>
            <TitleGroup
              title='Encaminhar prescrição'
              style={css`
                margin-bottom: 1.5rem;
              `}
            />
            <ButtonGroup>
              <Button
                size='small'
                kind={activeTab === ActiveTab.IMPRIMIR ? 'primary' : 'normal'}
                onClick={() => setActiveTab(ActiveTab.IMPRIMIR)}
              >
                Imprimir
              </Button>
              <Tooltip
                text={
                  !isPrescricaoDigitalEnabled
                    ? 'Prescrição digital não habilitada na sua instalação, comunique o administrador da sua instalação para ativá-la.'
                    : !isHttpsConnection &&
                      'É necesário utilizar uma conexão segura com HTTPS para poder utilizar a prescrição digital.'
                }
              >
                <Button
                  size='small'
                  disabled={!isPrescricaoDigitalEnabled || !isHttpsConnection}
                  kind={activeTab === ActiveTab.PRESCRICAO_DIGITAL ? 'primary' : 'normal'}
                  onClick={handleClickPrescricaoTab}
                >
                  Prescrição digital
                </Button>
              </Tooltip>
            </ButtonGroup>
          </VFlow>
        </ModalBody>
        {activeTab === ActiveTab.IMPRIMIR && (
          <ImpressaoPrescricaoView
            medicamentos={medicamentos}
            atendimentoId={atendimentoId}
            municipioId={municipioId}
            cidadaoDataNascimento={cidadaoDataNascimento}
            cidadaoSexo={cidadaoSexo}
            onModalClose={handleModalClose}
          />
        )}
        {activeTab === ActiveTab.PRESCRICAO_DIGITAL && (
          <PrescricaoDigitalGerarView
            medicamentos={medicamentos}
            prescricaoDigital={prescricaoDigital}
            updatePrescricaoDigital={updatePrescricaoDigital}
            atendimentoId={atendimentoId}
            municipioId={municipioId}
            cidadaoId={cidadaoId}
            cidadaoDataNascimento={cidadaoDataNascimento}
            cidadaoSexo={cidadaoSexo}
            onModalClose={handleModalClose}
          />
        )}
      </Modal>
    </Fragment>
  )
}

const styles = {
  mainContainer: css`
    width: 42.5rem;
    height: auto;
  `,
}
