import { ApolloQueryResult, FetchPolicy } from 'apollo-client'
import { SelectDownshiftRenderProps, SelectProps } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { SelectFieldProps } from 'components/form/final-form'
import { DocumentNode } from 'graphql'
import { useApolloClient } from 'graphql/hooks'
import { PageParams } from 'graphql/types.generated'
import { deburr, isEmpty, isFunction } from 'lodash'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const DEFAULT_SELECT_SIZE = 50

export const DEFAULT_SELECT_PAGE_PARAM: Readonly<PageParams> = {
  size: DEFAULT_SELECT_SIZE,
  fetchPageInfo: false,
}

export type AsyncSelectFieldProps<TItems> = Omit<SelectFieldProps<TItems>, 'items' | 'itemToString'>

export interface UseAsyncQuerySelectProps<TOption, TData, TVariables> {
  query: DocumentNode
  variables(inputQuery: string): TVariables
  extractItems(data: TData): TOption[]
  itemToString?(item: TOption): string
  skip?(inputQuery: string): boolean
  fetchPolicy?: FetchPolicy
  debounceTime?: number

  refetchOnFilterChange?: boolean
  refetchOnVariablesChange?: boolean
}

interface UseAsyncQuerySelectResult<TOption> {
  selectProps: Pick<SelectProps<TOption>, 'inputRef' | 'items' | 'loading' | 'onFilterChange' | 'onFocus'>
  skipping?: boolean
}

export function useAsyncQuerySelect<TOption, TData, TVariables>(
  props: UseAsyncQuerySelectProps<TOption, TData, TVariables>
): UseAsyncQuerySelectResult<TOption> {
  const {
    query,
    variables,
    extractItems,
    itemToString,
    skip,
    fetchPolicy,
    debounceTime,
    refetchOnFilterChange = true,
    refetchOnVariablesChange = false,
  } = props

  const apollo = useApolloClient()
  const handleRejection = useErrorHandler()
  const [data, setData] = useState<TData>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSkipping, setIsSkipping] = useState(false)
  const items = useMemo(() => extractItems(data), [data, extractItems])

  const refetch = useCallback(
    (queryVariables: TVariables, inputString?: string) => {
      if (!skip || !skip(inputString || '')) {
        setIsSkipping(false)
        setIsLoading(true)

        return apollo
          .query<TData, TVariables>({
            query,
            variables: queryVariables,
            fetchPolicy: fetchPolicy || 'network-only',
          })
          .then((res: ApolloQueryResult<TData>) => {
            setData(res.data)
            return res
          })
          .catch(handleRejection)
          .finally(() => setIsLoading(false))
      } else {
        setData(null)
        setIsSkipping(true)
        setIsLoading(false)
      }
    },
    [apollo, fetchPolicy, handleRejection, query, skip]
  )

  const debouncedRefetch = useMemo(() => debounce(refetch, debounceTime || 350), [refetch, debounceTime])

  const handleFilterChangeWithRefetch = useCallback(
    (inputString: string, downshift: SelectDownshiftRenderProps<TOption>) => {
      // Carrega somente quando o dropdown está aberto
      if (downshift.isOpen) {
        if (inputString) {
          return debouncedRefetch(variables(inputString), inputString)
        } else {
          // Carrega imediatamente (sem debounce) caso filtro seja vazio
          return refetch(variables(inputString), inputString)
        }
      }
    },
    [debouncedRefetch, refetch, variables]
  )

  const handleFilterChangeWithoutRefetch = useCallback(
    (inputString: string, downshift: SelectDownshiftRenderProps<TOption>) => {
      const items = downshift.items

      const hasConversionFunction = isFunction(itemToString)
      const hasItems = items && items.length !== 0

      if (!hasConversionFunction || !hasItems) handleFilterChangeWithRefetch(inputString, downshift)
      else if (isEmpty(inputString)) downshift.setVisibleItems(items)
      else {
        const stdInputString = deburr(inputString).toLowerCase()
        const stdingItem = (item: TOption): string => deburr(itemToString(item)).toLowerCase()

        const filteredItems = items.filter((item) => stdingItem(item).includes(stdInputString))
        downshift.setVisibleItems(filteredItems)
      }
    },
    [handleFilterChangeWithRefetch, itemToString]
  )

  const handleFilterChange = useCallback(
    refetchOnFilterChange ? handleFilterChangeWithRefetch : handleFilterChangeWithoutRefetch,
    [handleFilterChangeWithRefetch, handleFilterChangeWithoutRefetch, refetchOnFilterChange]
  )

  const inputRef = useRef<HTMLInputElement>()
  const firstRender = useRef(true)
  useEffect(() => {
    if (refetchOnVariablesChange && !firstRender.current) {
      const inputString = inputRef.current?.value
      refetch(variables(inputString), inputString)
    }
    firstRender.current = false
  }, [refetch, refetchOnVariablesChange, variables])

  useEffect(() => {
    if (isSkipping) {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch, isSkipping])

  const handleFocus = useCallback(() => setData(null), [])

  return {
    selectProps: {
      inputRef,
      items,
      loading: isLoading,
      onFilterChange: handleFilterChange,
      onFocus: handleFocus,
    },
    skipping: isSkipping || undefined,
  }
}
