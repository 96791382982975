import { css } from '@emotion/core'
import { Alert, Cell, FormControl, Grid, HFlow, Icon, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { FooterButton } from 'components/footer-button'
import { CheckboxField, DateField, Form, FormRenderProps, TextAreaField, TextField } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectField } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { isAfter, isBefore, parseISO, subDays } from 'date-fns'
import { FormApi } from 'final-form'
import { OrigemAtendimento, TipoRegistroVacinacaoEnum } from 'graphql/types.generated'
import React, { Fragment, useMemo, useRef } from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, nomeFabricante, nomeLote, required } from 'util/validation'
import { ImunobiologicoSelectField } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectField } from 'view/lote-imunobiologico/components/LoteImunobiologicoFabricanteSelectField'
import { covidImunosRecord } from 'view/lote-imunobiologico/record'

import { RegistroVacinacaoFormInput } from '../detail/vacinacao/VacinacaoCalendarioView'
import { createOutrosImunobiologicosCalculator } from './calculator'
import { ImunobiologicoModel, RegistroVacinacaoFormModel } from './model'
import { sairSemSalvarConfirm } from './VacinacaoModal'
import { VacinacaoModalHeader } from './VacinacaoModalHeader'
import { VacinadoExteriorField } from './VacinadoExteriorField'

interface DosesAnterioresVacinaModalProps {
  initialValues?: RegistroVacinacaoFormModel
  imunobiologico?: ImunobiologicoModel
  doseId?: ID
  doseNome?: string
  idadeRecomendada?: LocalDate
  modalOpen: boolean
  handleModalClose(): void
  handleOnSubmit(input: RegistroVacinacaoFormInput): void
  outrosImunobiologicos?: boolean
  dataAtendimento: Instant
  dataNascimento: LocalDate
  isAcompanhamentoVacinal?: boolean
}

const meta = metaPath<RegistroVacinacaoFormModel>()

const validator = (dataMaximaAplicacao: Date, outrosImunobiologicos: boolean, dataMinimaAplicacao: Date) =>
  createValidator<RegistroVacinacaoFormModel>(
    {
      dataAplicacao: [required],
      loteFabricante: [maxLength(60)],
      loteNome: [maxLength(30)],
      outrosImunosImunobiologico: outrosImunobiologicos && [required],
    },
    (values: RegistroVacinacaoFormModel, errors: ErrorObject<RegistroVacinacaoFormModel>) => {
      if (values.dataAplicacao) {
        if (isAfter(parseISO(values.dataAplicacao), dataMaximaAplicacao)) {
          errors.dataAplicacao = 'Deve ser anterior à data do atendimento.'
        }

        if (isBefore(parseISO(values.dataAplicacao), dataMinimaAplicacao)) {
          errors.dataAplicacao = 'Deve ser posterior à data de nascimento do cidadão.'
        }
      }

      if (values.outrosImunosImunobiologico) {
        errors.outrosImunosDose = required(values.outrosImunosDose)
      }

      if (values.loteNome) {
        errors.loteNome = nomeLote(values.loteNome)
      }

      if (values.loteFabricante) {
        errors.loteFabricante = nomeFabricante(values.loteFabricante?.nome)
      }
      return errors
    }
  )

export const DosesAnterioresVacinaModal = (props: DosesAnterioresVacinaModalProps) => {
  const {
    initialValues,
    imunobiologico,
    modalOpen,
    idadeRecomendada,
    doseNome,
    handleOnSubmit,
    handleModalClose,
    outrosImunobiologicos,
    dataAtendimento,
    dataNascimento,
  } = props

  const dataMaximaAplicacao = subDays(dataAtendimento, 1)
  const dataMinimaAplicacao = parseISO(dataNascimento)
  const alert = useAlert()

  const continuarRegistro = useRef(false)
  const handleSubmit = (formValues: RegistroVacinacaoFormModel, formApi: FormApi) => {
    handleOnSubmit({
      formValues: {
        ...formValues,
        tipoRegistroVacinacao: TipoRegistroVacinacaoEnum.APLICACAO,
        imunobiologicoId: imunobiologico?.id,
        imunobiologicoNome: imunobiologico?.nome,
        imunobiologicoSigla: imunobiologico?.sigla,
        dataRegistro: new Date(dataAtendimento).toISOString(),
        isRegistroAnterior: true,
        origemDados: OrigemAtendimento.PEC,
        doseNome,
      },
      onSuccess: () => {
        formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
        setTimeout(formApi.reset)
        if (!continuarRegistro.current) {
          handleModalClose()
          alert('success', 'Registro salvo com sucesso.')
        }
      },
    })
  }

  const handleFinalizar = (handleSubmit: () => void, isContinuarSemFechar: boolean) => {
    continuarRegistro.current = isContinuarSemFechar
    handleSubmit()
  }

  const onModalClose = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    if (formRenderProps.dirty) {
      sairSemSalvarConfirm(() => {
        setTimeout(formRenderProps.form.reset)
        handleModalClose()
      })
    } else {
      handleModalClose()
    }
  }

  const renderForm = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    return (
      <Modal open={modalOpen} onClose={() => onModalClose(formRenderProps)} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow>
            <VacinacaoModalHeader
              idadeRecomendada={idadeRecomendada}
              imunobiologicoNome={imunobiologico?.nome}
              imunobiologicoSigla={imunobiologico?.sigla}
              nomeDose={doseNome}
              outrosImunobiologicos={outrosImunobiologicos}
              labelTipoRegistro='Transcrição de caderneta'
            />
            <Grid
              style={css`
                width: 50rem;
              `}
            >
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Fragment>
                    <Cell size={12}>
                      <Alert inline type='info'>
                        As transcrições de caderneta inseridas nesta tela não são contabilizadas como doses aplicadas e
                        não devem ser utilizadas para o registro de aplicações realizadas neste atendimento.
                      </Alert>
                    </Cell>

                    <Fragment>
                      <Cell size={6}>
                        <ImunobiologicoSelectField
                          name={meta.outrosImunosImunobiologico}
                          label='Imunobiológico'
                          required
                          semRegras
                        />
                      </Cell>
                      <Cell size={4}>
                        <DoseImunobiologicoVacinacaoSelectField
                          name={meta.outrosImunosDose}
                          label='Dose'
                          semRegras
                          imunobiologicoId={values.outrosImunosImunobiologico?.id}
                          required={values.outrosImunosImunobiologico}
                          disabled={!values.outrosImunosImunobiologico}
                        />
                      </Cell>
                    </Fragment>
                    <Cell size={4}>
                      <DateField
                        label='Data da aplicação'
                        name={meta.dataAplicacao}
                        icon='calendarOutline'
                        minDate={dataMinimaAplicacao}
                        maxDate={dataMaximaAplicacao}
                        required
                      />
                    </Cell>
                    {!!covidImunosRecord[imunobiologico?.id || values.outrosImunosImunobiologico?.id] && (
                      <>
                        <Cell size={2}>
                          <HFlow hSpacing={0}>
                            <FormControl label='Estratégia'>
                              <CheckboxField name={meta.isPesquisaEstrategia} label='Pesquisa' />
                            </FormControl>
                            <Tooltip text='Indique se a dose foi aplicada durante a etapa de Ensaio Clínico.'>
                              <Icon icon='infoCircleFilled' size={1} />
                            </Tooltip>
                          </HFlow>
                        </Cell>
                        <Cell size={5}>
                          <VacinadoExteriorField
                            label='Vacinado no exterior'
                            name={meta.isAplicadoExterior}
                            initialValue={false}
                          />
                        </Cell>
                      </>
                    )}
                    {!covidImunosRecord[imunobiologico?.id || values.outrosImunosImunobiologico?.id] && (
                      <Cell size={8} />
                    )}
                    <Cell size={6}>
                      <TextField name={meta.loteNome} label='Lote' maxLength={30} />
                    </Cell>
                    <Cell size={6}>
                      <LoteImunobiologicoFabricanteSelectField
                        name={meta.loteFabricante}
                        label='Fabricante'
                        maxLength={60}
                        icon={null}
                        disabled={!!covidImunosRecord[imunobiologico?.id || values.outrosImunosImunobiologico?.id]}
                      />
                    </Cell>
                    <Cell size={12}>
                      <TextAreaField
                        style={css`
                          height: 5rem;
                          resize: none;
                        `}
                        name={meta.observacoes}
                        label='Observações'
                        maxLength={300}
                      />
                    </Cell>
                  </Fragment>
                )}
              </FormSpy>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <FooterButton kind='normal' onClick={() => onModalClose(formRenderProps)}>
              Cancelar
            </FooterButton>

            <FooterButton kind='primary' onClick={() => handleFinalizar(formRenderProps.handleSubmit, true)}>
              Salvar e adicionar outro
            </FooterButton>

            <FooterButton kind='primary' onClick={() => handleFinalizar(formRenderProps.handleSubmit, false)}>
              Salvar
            </FooterButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const validatorRA = useMemo(() => validator(dataMaximaAplicacao, outrosImunobiologicos, dataMinimaAplicacao), [
    dataMaximaAplicacao,
    dataMinimaAplicacao,
    outrosImunobiologicos,
  ])
  const decorator = useMemo(() => createOutrosImunobiologicosCalculator(meta, true), [])

  return (
    <Form<RegistroVacinacaoFormModel>
      initialValues={initialValues}
      subscription={{ submitting: true, dirty: true }}
      render={renderForm}
      validate={validatorRA}
      onSubmit={handleSubmit}
      decorators={outrosImunobiologicos && [decorator]}
    />
  )
}
