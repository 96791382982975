import { resolveValue } from 'components/form/final-form/hooks/useField'
import { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import { AgendarConsultaPanelModel } from '../detail/soap/finalizacao/components/AgendarConsultaPanel'

export const createAgendarConsultasCalculations = (name: MetaRoot<AgendarConsultaPanelModel>): Calculation[] => [
  createLotacaoAgendarConsultasCalculation(name),
  createIsForaUbsAgendarConsultasCalculation(name),
]

export const createLotacaoAgendarConsultasCalculation = <TFormState>(
  name: MetaRoot<AgendarConsultaPanelModel>
): Calculation => ({
  field: name.lotacao.absolutePath(),
  updates: {
    [name.data.absolutePath()]: (lotacaoValue: string, allValues: TFormState) => {
      const value = resolveValue(allValues, name)
      return lotacaoValue && value?.data
    },
    [name.isForaUbs.absolutePath()]: () => undefined,
  },
})

export const createIsForaUbsAgendarConsultasCalculation = (name: MetaRoot<AgendarConsultaPanelModel>): Calculation => ({
  field: name.isForaUbs.absolutePath(),
  updates: {
    [name.localAtendimento.absolutePath()]: () => undefined,
  },
})
