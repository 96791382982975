/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { CheckboxField, Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useCriarVideochamadaMutation } from 'graphql/hooks.generated'
import { capitalize } from 'lodash'
import { Fragment, useCallback, useState } from 'react'
import { isHttpsConnection } from 'util/https'
import { metaPath } from 'util/metaPath'

import { TermoModal, TermosRecord } from '../../components/TermoModal'
import { CameraPreview } from './componentes/CameraPreview'
import { AtorVideochamada } from './model'
import {
  termosVideochamadaCidadaoPresente,
  termosVideochamadaProfissionalPec,
  termosVideochamadaProfissionalPecCidadaoPresente,
} from './termos'

const meta = metaPath<CriarVideochamadaFormModel>()

type ModalTermosResponsabilidadeState =
  | {
      open: true
      termos: TermosRecord<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO>
      readonly: boolean
    }
  | { open: false }

interface CriarVideochamadaFormModel {
  cidadaoPresente: boolean
}

export function CriarVideochamadaView() {
  const alert = useAlert()
  const {
    acesso: { cbo },
    profissional: {
      nome,
      usuario: { aceitouTermoTeleinterconsulta },
    },
    refresh: refreshAcesso,
  } = useAcessoLotacaoOrEstagio()

  const [audioEnabled, setAudioEnabled] = useState(true)
  const [videoEnabled, setVideoEnabled] = useState(true)

  const [modalTermoResponsabilidadeState, setModalTermoResponsabilidadeState] = useState<
    ModalTermosResponsabilidadeState
  >({ open: false })
  const [criarVideochamada] = useCriarVideochamadaMutation()

  const handleAcceptTermos = useCallback(
    async (
      autorizacoes: Partial<Record<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO, boolean>>,
      cidadaoPresente: boolean
    ) => {
      setModalTermoResponsabilidadeState({ open: false })
      if (!isHttpsConnection) {
        alert('danger', 'Não é possível iniciar uma videochamada em um acesso sem HTTPS.')
      } else if (!autorizacoes[AtorVideochamada.PROFISSIONAL] && !aceitouTermoTeleinterconsulta) {
        alert('danger', 'O profissional precisa autorizar a teleinterconsulta.')
      } else if (!autorizacoes[AtorVideochamada.CIDADAO] && cidadaoPresente) {
        alert('danger', 'O cidadão precisa autorizar a teleinterconsulta.')
      } else {
        try {
          const {
            data: { criarVideochamada: videochamada },
          } = await criarVideochamada({
            variables: {
              input: {
                termoProfissionalAceito: autorizacoes[AtorVideochamada.PROFISSIONAL],
                termoCidadaoAceito: autorizacoes[AtorVideochamada.CIDADAO],
              },
            },
          })

          await refreshAcesso()

          window.open(
            `/videochamada/${videochamada.id}?video=${videoEnabled}&audio=${audioEnabled}&cidadaoPresente=${cidadaoPresente}`,
            'videochamada',
            `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}, noreferrer`
          )
        } catch {
          alert('danger', 'Erro ao criar videochamada.')
        }
      }
    },
    [aceitouTermoTeleinterconsulta, alert, audioEnabled, criarVideochamada, refreshAcesso, videoEnabled]
  )

  const handleVisualizarTermo = useCallback(() => {
    setModalTermoResponsabilidadeState({
      open: true,
      termos: termosVideochamadaProfissionalPec,
      readonly: true,
    })
  }, [])

  const handleSubmit = useCallback(
    async ({ cidadaoPresente }: CriarVideochamadaFormModel) => {
      if (!cidadaoPresente && aceitouTermoTeleinterconsulta) await handleAcceptTermos({}, cidadaoPresente)
      else
        setModalTermoResponsabilidadeState({
          open: true,
          termos: getTermosAceitacao(cidadaoPresente, aceitouTermoTeleinterconsulta),
          readonly: false,
        })
    },
    [aceitouTermoTeleinterconsulta, handleAcceptTermos]
  )

  const renderForm = useCallback(
    ({ handleSubmit, values: { cidadaoPresente } }: FormRenderProps<CriarVideochamadaFormModel>) => (
      <form onSubmit={handleSubmit} noValidate>
        <VFlow>
          {modalTermoResponsabilidadeState.open && (
            <TermoModal
              {...modalTermoResponsabilidadeState}
              onAccept={(autorizacoes) => handleAcceptTermos(autorizacoes, cidadaoPresente)}
              onClose={() => setModalTermoResponsabilidadeState({ open: false })}
              confirmText='Iniciar chamada'
              style={css`
                height: ${modalTermoResponsabilidadeState.readonly ? '35.75rem' : '44.25rem'};
              `}
            />
          )}
          <Heading level={2}>Teleinterconsulta</Heading>
          <Text>
            As videochamadas e-SUS APS permitem que os profissionais do PEC realizem teleinterconsultas com
            profissionais externos através de um canal oficial de comunicação.
            <br />
            Você está realizando essa chamada como
            <Text fontWeight='bold'>{` ${nome} | ${capitalize(cbo.nome)}`}</Text>
          </Text>
          <Box>
            <HFlow justifyContent='space-between'>
              <CheckboxField
                name={meta.cidadaoPresente}
                label={<Text fontWeight='bold'>Cidadão participará presencialmente</Text>}
              />
              <div css={styles.buttonContainer}>
                <Button type='submit' kind='primary'>
                  Criar chamada
                </Button>
              </div>
            </HFlow>
          </Box>
          {aceitouTermoTeleinterconsulta && (
            <Button skin='outline' size='small' kind='primary' onClick={handleVisualizarTermo}>
              <Icon
                icon='fileVisualizationOutline'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Ver termo de responsabilidade do profissional
            </Button>
          )}
        </VFlow>
      </form>
    ),
    [
      aceitouTermoTeleinterconsulta,
      cbo.nome,
      handleAcceptTermos,
      handleVisualizarTermo,
      modalTermoResponsabilidadeState,
      nome,
    ]
  )

  return (
    <Fragment>
      <Breadcrumb title='Videochamadas' />
      <PageHeaderSection title='Videochamadas e-SUS APS' />
      <PageContent>
        <Box style={styles.container}>
          <Grid>
            <Cell size={6}>
              <Form<CriarVideochamadaFormModel> render={renderForm} onSubmit={handleSubmit} />
            </Cell>
            <Cell size={1} />
            <Cell size={5}>
              <CameraPreview
                audioEnabled={audioEnabled}
                videoEnabled={videoEnabled}
                setAudioEnabled={setAudioEnabled}
                setVideoEnabled={setVideoEnabled}
              />
            </Cell>
          </Grid>
        </Box>
      </PageContent>
    </Fragment>
  )
}

const getTermosAceitacao = (cidadaoPresente: boolean, aceitouTermoTeleinterconsulta: boolean) =>
  cidadaoPresente
    ? aceitouTermoTeleinterconsulta
      ? termosVideochamadaCidadaoPresente
      : termosVideochamadaProfissionalPecCidadaoPresente
    : termosVideochamadaProfissionalPec

const styles = {
  container: css`
    padding: 2.5rem;
  `,
  buttonContainer: css`
    display: flex;
    align-items: end;
  `,
}
