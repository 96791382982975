import { AcessoCbo, SessaoQuery, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

export type AcessoProfissionalType = SessaoQuery['sessao']['acesso']

export interface CboAndPermissionAuthorization {
  hasAnyCbo: AcessoCbo[][]
  hasAnyPermission: Permission[]
}

export interface PermissionAuthorization {
  permission: Authorization
}

export interface TipoEstabelecimentoAuthorization {
  tiposEstabelecimento?: TipoEstabelecimentoEnum[]
}

export type AllAuthorizations = (PermissionAuthorization | CboAndPermissionAuthorization) &
  TipoEstabelecimentoAuthorization
export type Authorization = Permission | Permission[] | AcessoCbo[]

export const isCboAndPermissionAuthorization = (
  x: CboAndPermissionAuthorization | PermissionAuthorization | TipoEstabelecimentoAuthorization
): x is CboAndPermissionAuthorization => !!(x as CboAndPermissionAuthorization).hasAnyCbo

export const getAuthorizationProps = (item: AllAuthorizations): AllAuthorizations => {
  const cboOrPermissionAuthorizations = isCboAndPermissionAuthorization(item)
    ? {
        hasAnyCbo: item.hasAnyCbo,
        hasAnyPermission: item.hasAnyPermission,
      }
    : {
        permission: item.permission,
      }

  return {
    ...cboOrPermissionAuthorizations,
    tiposEstabelecimento: item.tiposEstabelecimento,
  }
}

export const hasPermissionByTipoEstabelecimento = (props): props is TipoEstabelecimentoAuthorization =>
  !!props.tiposEstabelecimento
