import { Cell, Grid } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { EquipeSelectField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { FormState } from 'final-form'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { TipoAtendimentoSelectField } from './components/TipoAtendimentoSelectField'
import { ListaEsperaFilterModel } from './model'

const path = metaPath<ListaEsperaFilterModel>()

interface ListaEsperaTableFilterProps {
  onChange(values: ListaEsperaFilterModel): void
}

export default function ListaEsperaTableFilter(props: ListaEsperaTableFilterProps) {
  const { onChange } = props
  const {
    acesso: { unidadeSaude },
  } = useAcessoLotacaoOrEstagio()
  const isCEO = unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  const handleChange = (formState: FormState<ListaEsperaFilterModel>) => onChange(formState.values)

  const renderForm = () => {
    return (
      <Grid>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Cell size={4}>
          <TextField name={path.nomeCpfCns} label='Cidadão' placeholder='Pesquise pelo nome, CNS ou CPF' />
        </Cell>
        {!isCEO ? (
          <>
            <Cell size={4}>
              <EquipeSelectField name={path.equipes} unidadeSaudeId={unidadeSaude.id} label='Equipe' multiple />
            </Cell>
            <Cell size={4}>
              <TipoAtendimentoSelectField name={path.tiposAtendimento} label='Tipo de atendimento' multiple />
            </Cell>
          </>
        ) : (
          <Cell size={4}>
            <TipoServicoSelectField name={path.tiposServico} label='Tipo de serviço' multiple />
          </Cell>
        )}
      </Grid>
    )
  }

  return <Form<ListaEsperaFilterModel> render={renderForm} />
}
