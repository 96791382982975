/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DropdownButton } from 'components/dropdown'
import useFirebase from 'components/firebase/useFirebase'
import { HideOnScreenSize } from 'components/responsive'
import { ButtonLink } from 'components/route'
import { Action } from 'graphql/types.generated'
import { useHistory, useRouteMatch } from 'react-router'
import {
  CuidadoCompartilhadoItem,
  CuidadoCompartilhadoItemActions,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'
import { getResponsabilidadeCuidadoCompartilhado } from 'view/cuidados-compartilhados/util'

interface ListaCuidadoCompartilhadoTableActionsProps {
  item: CuidadoCompartilhadoItem
  resolveDiscutirAction(actions: CuidadoCompartilhadoItemActions): Action
}

export const ListaCuidadoCompartilhadoTableActions = (props: ListaCuidadoCompartilhadoTableActionsProps) => {
  const { item, resolveDiscutirAction } = props

  const match = useRouteMatch()
  const history = useHistory()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const responsavelCuidado: ResponsavelCuidadoCompartilhado = getResponsabilidadeCuidadoCompartilhado(
    acesso.id,
    item.lotacaoExecutante.id,
    item.lotacaoSolicitante.id
  )

  const logEventVisualizar = () => {
    analytics.logEvent(
      responsavelCuidado === 'EXECUTANTE'
        ? 'visualizar_CC_executante'
        : responsavelCuidado === 'SOLICITANTE'
        ? 'visualizar_CC_solicitante'
        : 'visualizar_CC_outros'
    )
  }

  const logEventDiscutir = () => {
    analytics.logEvent(responsavelCuidado === 'EXECUTANTE' ? 'discutir_CC_executante' : 'discutir_CC_solicitante')
  }

  const visualizarDiscussaoLink = `${match.path.substring(0, match.path.lastIndexOf('/'))}/visualizar/${item.id}`
  const discutirCasoLink = `${match.url}/${item.id}`
  const discutirAction = resolveDiscutirAction(item.actions)

  return (
    item.actions && (
      <HFlow justifyContent='flex-end' alignItems='center' style={styles.hFlow}>
        <HideOnScreenSize down='lg'>
          <HFlow hSpacing={0.5} justifyContent='flex-end'>
            <Tooltip text={item.actions.visualizarDiscussao.hint}>
              <ButtonLink
                size='small'
                skin='ghost'
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventVisualizar()
                }}
                to={visualizarDiscussaoLink}
                disabled={!item.actions.visualizarDiscussao.enabled}
              >
                <Icon icon='nurseVisualizationOutline' />
              </ButtonLink>
            </Tooltip>
            <Tooltip text={discutirAction.hint}>
              <ButtonLink
                size='small'
                skin='ghost'
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventDiscutir()
                }}
                to={discutirCasoLink}
                disabled={!discutirAction.enabled}
              >
                <Icon icon='multipleUsersOutline' />
              </ButtonLink>
            </Tooltip>
          </HFlow>
        </HideOnScreenSize>
        <HideOnScreenSize up='lg'>
          <DropdownButton icon='dots' stopPropagationOnClick>
            <Tooltip text={item.actions.visualizarDiscussao.hint}>
              <DropdownItem
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventVisualizar()
                  history.push(visualizarDiscussaoLink)
                }}
                disabled={!item.actions.visualizarDiscussao.enabled}
              >
                Visualizar discussão
              </DropdownItem>
            </Tooltip>

            <Tooltip text={discutirAction.hint}>
              <DropdownItem
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventDiscutir()
                  history.push(discutirCasoLink)
                }}
                disabled={!discutirAction.enabled}
              >
                Discutir caso
              </DropdownItem>
            </Tooltip>
          </DropdownButton>
        </HideOnScreenSize>
      </HFlow>
    )
  )
}

const styles = {
  hFlow: css`
    height: 100%;
  `,
}
