import { CidadaoAtendimentoSelectFieldModel, EquipeSelectModel } from 'components/form'
import { TipoServicoSelectFieldModel } from 'components/form/field/select/TipoServicoSelectField'
import { ListaEsperaMotivoSaidaEnum, ListaEsperaTableQuery, PageParams } from 'graphql/types.generated'

import { TipoAtendimentoSelectModel } from './components/TipoAtendimentoSelectField'

export interface ListaEsperaModel {
  id: ID
  cidadao: CidadaoAtendimentoSelectFieldModel
  tipoAtendimento?: TipoAtendimentoSelectModel
  tipoServico?: TipoServicoSelectFieldModel
  equipe?: EquipeSelectModel
  motivo?: string
  dataEntrada?: Instant
}

export interface ListaEsperaRemocaoModel {
  listaEsperaId: ID
  motivoSaida: ListaEsperaMotivoSaidaEnum
  tentativasContato: number
  observacaoSaida: string
}

export interface ListaEsperaFilterModel {
  nomeCpfCns?: string
  equipes?: EquipeSelectModel[]
  tiposAtendimento?: TipoAtendimentoSelectModel[]
  tiposServico?: TipoServicoSelectFieldModel[]
  pageParams: PageParams
}

export interface ListaEsperaEdicaoModel {
  listaEsperaId: ID
  equipe?: EquipeSelectModel
  tipoAtendimento?: TipoAtendimentoSelectModel
  tipoServico?: TipoServicoSelectFieldModel
  motivo: string
}

export type ListaEsperaItem = ListaEsperaTableQuery['listaEsperaByUnidadeSaudeId']['content'][0]

export type CidadaoListaEspera = ListaEsperaTableQuery['listaEsperaByUnidadeSaudeId']['content'][0]['cidadao']

export interface ListaEsperaFormModel extends Omit<ListaEsperaModel, 'id' | 'dataEntrada'> {}

export const motivoSaidaRecord: Record<ListaEsperaMotivoSaidaEnum, string> = {
  ATENDIDO_OUTRO_ESTABELECIMENTO: 'Atendido em outro estabelecimento',
  ATENDIMENTO_AGENDADO: 'Atendimento agendado',
  MUDOU_TERRITORIO: 'Mudou-se de território',
  NAO_RESPONDEU_TENTATIVAS_CONTATO: 'Não respondeu à tentativas de contato',
  OUTROS: 'Outros',
}
