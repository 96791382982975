import React from 'react'
import { memo, useMemo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoAtendimentoDomiciliarADPanel from './app/HistoricoAtendimentoDomiciliarADPanel'
import HistoricoAtendimentoDomiciliarDwPanel from './dw/HistoricoAtendimentoDomiciliarDwPanel'

interface HistoricoAtendimentoDomiciliarPanelProps extends HistoricoPanelProps {}

function HistoricoAtendimentoDomiciliarPanel(props: HistoricoAtendimentoDomiciliarPanelProps) {
  const { isAtendRecente, idAtend, idadeOrigemAtendimento, uuidAtend, cpfOuCnsCidadao } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  const selectContent = useMemo(() => {
    return isAtendRecente || atendimentoPecProcessado ? (
      <HistoricoAtendimentoDomiciliarADPanel idAtend={idAtend} idadeOrigemAtendimento={idadeOrigemAtendimento} />
    ) : (
      <HistoricoAtendimentoDomiciliarDwPanel
        uuidAtend={uuidAtend}
        cpfOuCnsCidadao={cpfOuCnsCidadao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    )
  }, [isAtendRecente, atendimentoPecProcessado, idAtend, idadeOrigemAtendimento, uuidAtend, cpfOuCnsCidadao])

  return selectContent
}

export default memo(HistoricoAtendimentoDomiciliarPanel)
