/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormRenderProps, NumberField, SubmitButton } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'

import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import { cpfCnsDecorator, name } from './LicencaMaternidadeForm'
import { getLicencaMaternidadeText } from './LicencaMaternidadeTextBox'
import { licencaMaternidadeFormValidator } from './validator'

export interface LicencaMaternidadeFormModalProps {
  onSubmit?(values: AtestadoModel): void
  cidadao: CidadaoAtestado
  initialValues: AtestadoModel
  handleModalClose(): void
}

export default function LicencaMaternidadeFormModal(props: LicencaMaternidadeFormModalProps) {
  const { cidadao, initialValues, onSubmit, handleModalClose } = props

  const possuiCpfCns = cidadao.cpf || cidadao.cns
  const alert = useAlert()

  const handleSubmit = (values: AtestadoModel) => {
    onSubmit(values)
    handleModalClose()
    alert('success', 'Licença maternidade salva com sucesso')
  }

  const handleCancel = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => {
          handleModalClose()
          alert('success', 'Edição de licença maternidade cancelada com sucesso.')
        },
      })()
    } else {
      handleModalClose()
      alert('success', 'Edição de licença maternidade cancelada com sucesso.')
    }
  }

  const renderLicencaMaternidadeForm = (formProps: FormRenderProps<AtestadoModel>) => {
    const values = resolveValue(formProps.values, name) || ({} as AtestadoModel)
    values.descricao = getLicencaMaternidadeText(cidadao, values.cpfCns, values.naoPossui, initialValues.data)

    return (
      <Modal open size='small' onClose={() => handleCancel(formProps.dirty)}>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Editar licença maternidade</Heading>
            {!possuiCpfCns && (
              <Grid>
                <Cell size={6}>
                  <NumberField
                    name={name.cpfCns}
                    label='CPF/CNS'
                    disabled={values.naoPossui}
                    required={!values.naoPossui}
                    maxLength={15}
                  />
                </Cell>
                <Cell>
                  <CheckboxField
                    name={name.naoPossui}
                    label='Não possui'
                    style={css`
                      margin-top: 1.75rem;
                      font-style: h5;
                    `}
                  />
                </Cell>
              </Grid>
            )}
            <Box>
              <VFlow
                vSpacing={1}
                style={css`
                  justify-content: center;
                  align-items: center;
                  margin: 1rem 1.75rem;
                `}
              >
                <Heading level={3}>LICENÇA MATERNIDADE</Heading>
                <Text>{values.descricao}</Text>
              </VFlow>
            </Box>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleCancel(formProps.dirty)}>Cancelar</Button>
            <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      render={renderLicencaMaternidadeForm}
      onSubmit={handleSubmit}
      validate={!possuiCpfCns && licencaMaternidadeFormValidator}
      decorators={[cpfCnsDecorator]}
      suppressNotificationError
    />
  )
}
