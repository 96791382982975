import { Form, FormRenderProps } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import createDecorator from 'final-form-calculate'
import { AcessoCbo } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { MetaPath, metaPath } from 'util/metaPath'

import { createAgendarConsultasCalculations } from '../../../../../agenda/calculatorAgendarConsulta'
import { agendarConsultasValidator } from '../../validator/validatorAgendamento'
import { AgendarConsultaPanelModel } from '../AgendarConsultaPanel'
import { AgendarConsultaSection, AgendarConsultaSectionModel } from '../AgendarConsultaSection'

interface AgendarProximaConsultaFormProps {
  name: MetaPath<AgendarConsultaSectionModel>
  initialValues: AgendarConsultaPanelModel
  setIsFormOpen(isFormOpen: boolean): void
  cbosAcesso?: AcessoCbo[]
}

const meta = metaPath<AgendarConsultaSectionModel>()

const decorators = [createDecorator(...createAgendarConsultasCalculations(meta))]

export function AgendarProximaConsultaForm(props: AgendarProximaConsultaFormProps) {
  const { name, initialValues, setIsFormOpen, cbosAcesso } = props

  const { getServerTimeNow } = useServerTime()

  const {
    input: { onChange },
  } = usePecField({ name })

  const handleSubmitForm = (agendamentoValues: AgendarConsultaPanelModel) => {
    onChange(agendamentoValues)
    setIsFormOpen(false)
  }

  const validator = agendarConsultasValidator(getServerTimeNow, true)

  const renderForm = ({ handleSubmit }: FormRenderProps<AgendarConsultaSectionModel>) => (
    <AgendarConsultaSection
      name={meta}
      errorPath={name}
      isProximaConsulta
      setIsFormAgendarProximaConsultaOpen={setIsFormOpen}
      onSubmitFormAgendarProximaConsulta={handleSubmit}
      cbosAcesso={cbosAcesso}
    />
  )

  return (
    <Form<AgendarConsultaSectionModel>
      render={renderForm}
      initialValues={initialValues}
      decorators={decorators}
      validate={validator}
      onSubmit={handleSubmitForm}
    />
  )
}
