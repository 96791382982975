/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { isBefore } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useState } from 'react'

import { CancelarAgendamentoButton } from './buttons/CancelarAgendamentoButton'
import JustificativaCancelarAgendamentoModal from './JustificativaCancelarAgendamentoModal'
import { AcoesAgendamentoProps } from './model'
import { excluirAgendamentoTooltip } from './utils'

export function AcoesAgendamentoReserva(props: AcoesAgendamentoProps) {
  const { event } = props

  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)

  const { getServerTimeNow } = useServerTime()
  const isPassado = isBefore(event.start, getServerTimeNow())

  return (
    <Fragment>
      <JustificativaCancelarAgendamentoModal
        event={event}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
      <HFlow
        hSpacing={0.5}
        justifyContent='flex-end'
        alignItems='center'
        style={css`
          height: 100%;
        `}
      >
        <CancelarAgendamentoButton
          {...props}
          openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
          disabled={isPassado}
          tooltip={excluirAgendamentoTooltip(isPassado)}
        />
      </HFlow>
    </Fragment>
  )
}
