import api from 'api'
import { ImpressaoAgendamentoInput } from 'api/ImpressaoAgenda'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

export const downloadImpressaoAgendamentos = (input: ImpressaoAgendamentoInput) => {
  return api.impressaoAgendamento
    .gerarAgendamentos(input)
    .then((response) => {
      downloadFile(
        new Blob([response.data]),
        `Agendamentos_${input.nomeProfissional.split(' ').join('_')}_${new Date().getTime()}.pdf`
      )
      return response
    })
    .catch(defaultHandleError)
}
