import { HFlow, Icon, Text } from 'bold-ui'
import { StatusConexaoRndsEnum } from 'graphql/types.generated'
import React from 'react'

interface StatusConexaoRndsTagInterface {
  statusConexaoRnds: StatusConexaoRndsEnum
}

export default function StatusConexaoRndsTag(props: StatusConexaoRndsTagInterface) {
  const { statusConexaoRnds } = props

  return (
    <HFlow alignItems='center' hSpacing={0.5}>
      {statusConexaoRnds === StatusConexaoRndsEnum.CONEXAO_BEM_SUCEDIDA ? (
        <>
          <Icon icon='checkCircleOutline' fill='success' size={1} />
          <Text color='success'>Conexão bem-sucedida</Text>
        </>
      ) : statusConexaoRnds === StatusConexaoRndsEnum.SEM_CONEXAO ? (
        <>
          <Icon icon='banOutline' fill='danger' size={1} />
          <Text color='danger'>Sem conexão</Text>
        </>
      ) : (
        statusConexaoRnds === StatusConexaoRndsEnum.CERTIFICADO_EXPIRADO && (
          <>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={1} />
            <Text color='alert'>Certificado expirado</Text>
          </>
        )
      )}
    </HFlow>
  )
}
