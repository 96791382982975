import { EncaminhamentoInternoInput, EscutaInicialInput, ProcedimentoAtendimentoInput } from 'graphql/types.generated'

import { convertAgendarConsulta } from '../detail/soap/finalizacao'
import { ManterCidadaoLista } from '../detail/soap/finalizacao/components/DesfechoAtendimentoPanel'
import { ProcedimentoSigtapFieldModel } from '../detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { convertMedicoesPanelToInput } from '../detail/soap/objetivo'
import { EscutaInicialState } from './model'

export const convertEscutaInicialModelToInput = (
  atendimentoId: ID,
  values: EscutaInicialState,
  isPreAtendimento: boolean
): EscutaInicialInput => ({
  id: atendimentoId,
  ciapId: values.motivoConsulta.ciap.id,
  motivoConsulta: values.motivoConsulta.texto,
  medicoes: convertMedicoesPanelToInput(values.medicoes),
  desfecho: {
    manterCidadaoLista: isPreAtendimento || values.desfecho.manterCidadaoLista === ManterCidadaoLista.SIM,
    atendimento: convertAtendimentoDesfecho(values, isPreAtendimento),
  },
  classificacaoRiscoEnum: !isPreAtendimento ? values.classificacaoRisco : undefined,
  procedimentos: convertProcedimentos(values.procedimentos),
  procedimentosAdministrativos: convertProcedimentos(values.procedimentosAdministrativos),
  agendamentoConsulta: !isPreAtendimento ? convertAgendarConsulta(values.agendamentoConsulta) : undefined,
  imprimirEscuta: values.imprimirEscutaInicial,
})

const convertAtendimentoDesfecho = (
  values: EscutaInicialState,
  isPreAtendimento: boolean
): EncaminhamentoInternoInput =>
  isPreAtendimento || values.desfecho.manterCidadaoLista === ManterCidadaoLista.SIM
    ? {
        equipe: values.desfecho.atendimento?.equipe?.id,
        responsavel: values.desfecho.atendimento?.responsavel?.id,
        tiposServico: values.desfecho.atendimento?.tiposServico,
      }
    : undefined

const convertProcedimentos = (listaProcedimentos: ProcedimentoSigtapFieldModel[]): ProcedimentoAtendimentoInput[] =>
  listaProcedimentos?.map(
    (procedimento) =>
      ({
        id: procedimento.procedimento.id,
        cid10PrincipalId: procedimento.procedimento.procedimentoReferencia?.id,
        isAutomatico: procedimento.isAutomatico,
      } as ProcedimentoAtendimentoInput)
  ) ?? []
