import createCalculator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { ImunobiologicoSelectModel } from '../components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectFieldModel } from '../components/LoteImunobiologicoFabricanteSelectField'
import { LoteImunobiologicoFormModel } from '../insert/LoteImunologicoTooltipAddForm'
import { covidImunosRecord } from '../record'

const path = metaPath<LoteImunobiologicoFormModel>()

export const fabricanteCalculator = createCalculator({
  field: path.imunobiologico.absolutePath(),
  updates: {
    [path.fabricante.absolutePath()]: (value: ImunobiologicoSelectModel, values: LoteImunobiologicoFormModel) => {
      if (covidImunosRecord[value?.id]) {
        return { nome: covidImunosRecord[value?.id] } as LoteImunobiologicoFabricanteSelectFieldModel
      } else {
        return Object.values(covidImunosRecord).includes(values?.fabricante?.nome) ? null : values.fabricante
      }
    },
  },
})
