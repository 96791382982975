import { PageLoading } from 'components/loading'
import { useCuidadoCompartilhadoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Redirect, useParams } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { VisualizarCuidadoCompartilhadoView } from './VisualizarCuidadoCompartilhadoView'

export const VisualizarCuidadoCompartilhadoRootView = () => {
  const { id } = useParams<{ id: string }>()

  const {
    data: { cuidadoCompartilhado },
    loading: isLoading,
  } = useCuidadoCompartilhadoQuery({
    variables: {
      id,
    },
  })

  if (isLoading) {
    return <PageLoading message='Carregando dados do cuidado compartilhado...' />
  } else if (isUndefinedOrNull(cuidadoCompartilhado)) {
    return <Redirect to='/404' />
  } else {
    return <VisualizarCuidadoCompartilhadoView cuidadoCompartilhado={cuidadoCompartilhado} />
  }
}
