import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

export const medicaoPerimetroCefalicoMaisRecente = (
  medicoes: HistoricoMedicaoModel[],
  perimetroCefalicoAntecedente: HistoricoMedicaoModel
) => {
  const medicoesPerimetroCefalico =
    medicoes?.length &&
    medicoes
      .filter((medicao) => medicao.valorPerimetroCefalico)
      .reduce((prev, curr) => (prev.dataMedicao > curr.dataMedicao ? prev : curr), perimetroCefalicoAntecedente)

  return medicoesPerimetroCefalico?.valorPerimetroCefalico
    ? {
        dataMedicao: medicoesPerimetroCefalico?.dataMedicao,
        valorMedicao: medicoesPerimetroCefalico?.valorPerimetroCefalico,
      }
    : undefined
}
