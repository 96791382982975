import { Tag, Theme, useStyles } from 'bold-ui'
import { blue, gray, green, orange, red, yellow } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { format } from 'date-fns'
import { ClassificacaoRisco, TipoServico } from 'graphql/types.generated'
import React, { CSSProperties } from 'react'

export interface AtendimentoTagProps {
  horaAgendamento?: Instant
  nomeProfissional?: string
  classificacaoRisco?: ClassificacaoRisco
  tipoServico?: TipoServico
}

interface ClassificacaoRiscoType {
  nome: string
  descricao: string
}

function createMap(classes: any) {
  const classificacaoRiscoMap: Record<ClassificacaoRisco, ClassificacaoRiscoType> = {
    [ClassificacaoRisco.PEC_ALTA]: { nome: classes.alta, descricao: 'Risco alto' },
    [ClassificacaoRisco.PEC_INTERMEDIARIA]: { nome: classes.intermediaria, descricao: 'Risco intermediário' },
    [ClassificacaoRisco.PEC_BAIXA]: { nome: classes.baixa, descricao: 'Risco baixo' },
    [ClassificacaoRisco.PEC_NAO_AGUDA]: { nome: classes.nao_aguda, descricao: 'Não aguda' },
    [ClassificacaoRisco.NAO_CLASSIFICADO]: { nome: null, descricao: '' },
  }
  return classificacaoRiscoMap
}

export function AtendimentoTag(props: AtendimentoTagProps) {
  const { horaAgendamento, nomeProfissional, tipoServico, classificacaoRisco } = props
  const { classes } = useStyles(createStyles)
  const classificacaoRiscoMap = createMap(classes)

  if (horaAgendamento) {
    return (
      <Tag style={[classes.tipo_servico, { maxWidth: '100%' }]} icon='calendarFilled'>
        <Ellipsis>{`${format(horaAgendamento, 'HH:mm')} | ${nomeProfissional}`}</Ellipsis>
      </Tag>
    )
  }

  if (tipoServico) {
    return (
      <Tag style={classes.tipo_servico}>
        <span className={classes.tagSpan}>{tipoServico.nome}</span>
      </Tag>
    )
  }

  if (classificacaoRisco && classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO) {
    const risco: ClassificacaoRiscoType = classificacaoRiscoMap[classificacaoRisco]
    return (
      risco && (
        <Tag style={risco.nome} icon='userFilled'>
          {risco.descricao}
        </Tag>
      )
    )
  }
}

const createStyles = (theme: Theme) => ({
  alta: {
    backgroundColor: red.c90,
    color: red.c40,
  } as CSSProperties,
  intermediaria: {
    backgroundColor: yellow.c90,
    color: orange.c40,
  } as CSSProperties,
  baixa: {
    backgroundColor: green.c90,
    color: green.c40,
  } as CSSProperties,
  nao_aguda: {
    backgroundColor: blue.c90,
    color: blue.c40,
  } as CSSProperties,
  tipo_servico: {
    backgroundColor: gray.c90,
    color: gray.c20,
  } as CSSProperties,
  tagSpan: { maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' } as CSSProperties,
})
