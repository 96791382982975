import { HeadingSection, VFlow } from 'bold-ui'
import { useTransmissaoRecebimentoCnesQuery } from 'graphql/hooks.generated'
import { TransmissaoRecebimentoCnesFiltro } from 'graphql/types.generated'
import React, { useState } from 'react'

import { TransmissaoRecebimentoImportFileBox } from '../loteTable/TransmissaoRecebimentoImportFileBox'
import { TransmissaoRecebimentoCnesTable } from './TransmissaoRecebimentoCnesTable'

const initialFilter = {
  competencia: { month: new Date().getMonth(), year: new Date().getFullYear() },
  pageParams: {
    sort: ['fichasRecebidas', 'estabelecimentoOrigem'],
  },
}

export function TransmissaoRecebimentoCnesView() {
  const [filter, setFilter] = useState<TransmissaoRecebimentoCnesFiltro>(initialFilter)

  const result = useTransmissaoRecebimentoCnesQuery({ variables: { input: filter }, context: { newRequest: true } })
  return (
    <VFlow>
      <HeadingSection title='Lotes recebidos agrupados por CNES' level={2}>
        <TransmissaoRecebimentoCnesTable queryResult={result} filter={filter} setFilter={setFilter} />
      </HeadingSection>
      <TransmissaoRecebimentoImportFileBox refetchQuery={result.refetch} />
    </VFlow>
  )
}
