/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { RadioField, RichTextField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { Field } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import {
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCondutaViewEnum,
  LabelEvolucaoCuidadoCompartilhadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import { discussaoCasoExecutanteValidator } from './validator-discussaocaso'

interface DiscussaoCasoExecutanteFieldsProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
}

export const DiscussaoCasoExecutanteFields = (props: DiscussaoCasoExecutanteFieldsProps) => {
  const { path } = props
  const theme = useTheme()

  const styles = createStyles(theme)

  return (
    <Field<DiscussaoCasoExecutanteFieldsModel>
      name={path.absolutePath()}
      validate={discussaoCasoExecutanteValidator}
      render={() => {
        return (
          <Fragment>
            <RadioFieldContainer
              name={path.condutaGroup}
              value={DiscussaoCondutaViewEnum.DEVOLUTIVA_DISCUSSAO_CASO}
              style={styles.radioFieldContainer}
            >
              <Text fontWeight='bold'>Devolutiva de discussão do caso clínico</Text>
            </RadioFieldContainer>
            <VFlow vSpacing={0.5}>
              <div css={styles.paper}>
                <VFlow>
                  <Text>
                    Responda a pergunta relacionada ao relato de caso, caso faltem informações solicite aqui mais
                    informações para o profissional solicitante.
                  </Text>
                  <FormControl label='Tipo de devolutiva' required>
                    <HFlow>
                      <RadioField
                        label={
                          LabelEvolucaoCuidadoCompartilhadoRecord[
                            CondutaCuidadoCompartilhadoEnum.PRESTAR_ESCLARECIMENTO
                          ]
                        }
                        name={path.conduta}
                        value={CondutaCuidadoCompartilhadoEnum.PRESTAR_ESCLARECIMENTO}
                      />
                      <RadioField
                        label={
                          LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.OPINIAO_FORMATIVA]
                        }
                        name={path.conduta}
                        value={CondutaCuidadoCompartilhadoEnum.OPINIAO_FORMATIVA}
                      />
                      <RadioField
                        label={
                          LabelEvolucaoCuidadoCompartilhadoRecord[
                            CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO
                          ]
                        }
                        name={path.conduta}
                        value={CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO}
                      />
                    </HFlow>
                  </FormControl>
                  <RichTextField name={path.resposta} label='Observações' maxLength={1000} required />
                </VFlow>
              </div>
            </VFlow>
          </Fragment>
        )
      }}
    />
  )
}

const createStyles = (theme: Theme) => ({
  radioFieldContainer: css`
    background-color: ${theme.pallete.gray.c90};
  `,
  paper: css`
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 0 0 2px 2px;
    margin-top: -1px;
  `,
})
