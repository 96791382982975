/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { isEmpty } from 'lodash'

import { CuidadoCompartilhadoPlanoTableItemModel } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoTablePanelProps {
  row: CuidadoCompartilhadoPlanoTableItemModel
}

export const CuidadoCompartilhadoTablePanel = (props: CuidadoCompartilhadoTablePanelProps) => {
  const { row } = props

  const hasCiap = !isEmpty(row.ciap)
  const hasCid10 = !isEmpty(row.cid10)
  const hasBoth = hasCiap && hasCid10
  const separator = hasBoth ? ' | ' : ''

  return (
    <VFlow vSpacing={0.5} style={styles.panel}>
      <InfoLabel title='Problemas/condições'>
        <Text>
          {hasCiap && `${row.ciap.descricao.capitalize()} - ${row.ciap.codigo} (CIAP 2)`}
          {separator}
          {hasCid10 && `${row.cid10.nome.capitalize()} - ${row.cid10.codigo} (CID 10)`}
        </Text>
      </InfoLabel>

      <InfoLabel title='Discussão de caso clínico'>
        <Text dangerouslySetInnerHTML={{ __html: row.discussao }} style={styles.discussao} />
      </InfoLabel>

      <InfoLabel title='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediada por tecnologia?'>
        <Text>{row.cidadaoAceitaAtendTic ? 'Sim' : 'Não'}</Text>
      </InfoLabel>

      <HLabel title='Adicionado por:'>
        <Text>
          {row.lotacaoSolicitante.profissional.nome} - {row.lotacaoSolicitante.cbo.nome}
        </Text>
      </HLabel>
    </VFlow>
  )
}

const styles = {
  panel: css`
    padding: 1rem;
    background: ${colors.gray.c90};
  `,
  discussao: css`
    word-break: break-all;
  `,
}
