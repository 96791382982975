import { cep, createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { LogradouroEditFormModel, VisualizacaoTerritorialBuscaFormModel } from './model'

export const validateVisualizacaoTerritorialForm = createValidator<VisualizacaoTerritorialBuscaFormModel>({
  ine: [required],
  cnes: [required],
  cep: [cep],
})

export const validateLogradouroEditForm = (cepNaoEncontrado) =>
  createValidator<LogradouroEditFormModel>(
    {
      cep: [required, cep],
      bairro: [required, maxLength(72)],
      logradouro: [required, maxLength(72)],
      tipoLogradouro: [required],
    },
    (value, errors): ErrorObject<LogradouroEditFormModel> => {
      if (cepNaoEncontrado) {
        errors.cep = 'CEP não encontrado.'
        return errors
      }
    }
  )
