import { CuidadoCompartilhadoFragment } from 'graphql/types.generated'

import { ResponsavelCuidadoCompartilhado } from './model-cuidadocompartilhado'

export const getResponsabilidadeCuidadoCompartilhado = (
  lotacaoAcessoId: ID,
  lotacaoExecutanteId: ID,
  lotacaoSolicitanteId: ID
): ResponsavelCuidadoCompartilhado => {
  if (lotacaoAcessoId === lotacaoExecutanteId) {
    return 'EXECUTANTE'
  } else if (lotacaoAcessoId === lotacaoSolicitanteId) {
    return 'SOLICITANTE'
  }
  return null
}

export const lotacaoCuidadoCompartilhadoFormatter = (lotacao: CuidadoCompartilhadoFragment['lotacaoSolicitante']) => {
  const nome = `${lotacao.profissional.nome} | ${lotacao.cbo.nome.toUpperCase()}`
  const equipe = `${lotacao.equipe ? `| Equipe ${lotacao.equipe.ine} - ${lotacao.equipe.nome}` : ''}`
  return `${nome} ${equipe}`
}
