/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { useRovingTabIndex } from 'bold-ui/lib/hooks'
import { DropdownButton } from 'components/dropdown'
import React from 'react'

export interface MenuAcoesAgendamentoProps {
  isDropdown: boolean
  children: React.ReactNode
}

export default function MenuAcoesAgendamento({ children, isDropdown }: MenuAcoesAgendamentoProps) {
  const rootRef = useRovingTabIndex({
    getItems: (root) => Array.from(root.querySelectorAll('button')),
  })

  return (
    <div ref={rootRef}>
      <HFlow
        hSpacing={0.5}
        alignItems='center'
        style={css`
          height: 100%;
        `}
      >
        {isDropdown ? <DropdownButton>{children}</DropdownButton> : children}
      </HFlow>
    </div>
  )
}
