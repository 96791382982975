import { Cell, Grid, useTheme } from 'bold-ui'
import { dateFnsDefaultLocale } from 'components/agenda/agendaLocalizer'
import { SelectField, SelectFieldProps } from 'components/form'
import { ErrorField } from 'components/form/final-form'
import { addMinutes, format } from 'date-fns'
import { useHorarioAgendaSelectFieldQuery } from 'graphql/hooks.generated'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { getItemsHorarioFinal } from './getItemsHorarioFinal'
import { getProximoHorarioIndisponivel } from './getProximoHorarioIndisponivel'

export interface HorarioAgendaSelectFieldModel {
  inicial: Date
  final: Date
}

export interface HorarioAgendaSelectFieldProps
  extends Omit<SelectFieldProps<Date>, 'items' | 'itemToString' | 'name' | 'label' | 'value'> {
  name: MetaPath<HorarioAgendaSelectFieldModel>
  lotacaoId: ID
  showHorarioFinal?: boolean
  dia?: Date
  value?: HorarioAgendaSelectFieldModel
  labels?: { inicial: string; final?: string }
  verificarReservasAgendaOnline?: boolean
  atencaoDomiciliar?: boolean
}

const itemToString = (item: Date) => item && format(item, 'HH:mm', { locale: dateFnsDefaultLocale })

export function HorarioAgendaSelectField(props: HorarioAgendaSelectFieldProps) {
  const {
    name,
    lotacaoId,
    value,
    dia = new Date(),
    showHorarioFinal = false,
    labels = { inicial: 'Horário inicial', final: 'Horário final' },
    disabled,
    verificarReservasAgendaOnline = true,
    atencaoDomiciliar = false,
    ...rest
  } = props

  const theme = useTheme()

  const {
    data: { horariosAgenda },
    loading,
  } = useHorarioAgendaSelectFieldQuery({
    variables: {
      input: { lotacaoId: lotacaoId, dia: format(dia, 'yyyy-MM-dd'), isAtencaoDomiciliar: atencaoDomiciliar },
    },
    skip: disabled || !lotacaoId,
  })

  const {
    input: { value: horarioInicial, onChange: onChangeHorarioInicial },
  } = useField<Date>(name.inicial.absolutePath())
  const {
    input: { onChange: onChangeHorarioFinal },
  } = useField<Date>(name.final.absolutePath())
  const setHorarioInicial = useCallback(onChangeHorarioInicial, [name.inicial])
  const setHorarioFinal = useCallback(onChangeHorarioFinal, [name.final])

  const horariosMap = useMemo(() => new Map(horariosAgenda?.map((h) => [new Date(h.horario), h])), [horariosAgenda])

  useEffect(() => {
    if (!loading) {
      const horarioDia =
        horarioInicial &&
        Array.from(horariosMap?.keys() ?? []).find(
          (h) => h.getHours() === horarioInicial.getHours() && h.getMinutes() === horarioInicial.getMinutes()
        )

      setHorarioInicial(horariosMap.get(horarioDia)?.isOcupado ? undefined : horarioDia)
      setHorarioFinal(undefined)
    }
  }, [horariosMap, horarioInicial, setHorarioFinal, setHorarioInicial, loading])

  useEffect(() => {
    !loading &&
      horarioInicial &&
      horariosMap.has(horarioInicial) &&
      setHorarioFinal(addMinutes(horarioInicial, horariosMap.get(horarioInicial).duracao))
  }, [horarioInicial, horariosMap, loading, setHorarioFinal])

  const isReservadoAgendaOnline = useCallback(
    (date: Date) => verificarReservasAgendaOnline && horariosMap.get(date).isReservadoAgendaOnline,
    [horariosMap, verificarReservasAgendaOnline]
  )

  const proximoHorarioOcupado = useMemo(
    () =>
      getProximoHorarioIndisponivel(
        horarioInicial,
        horariosAgenda?.map((h) => ({
          ...h,
          isOcupado: h.isOcupado || (verificarReservasAgendaOnline && h.isReservadoAgendaOnline),
        }))
      ),
    [horarioInicial, horariosAgenda, verificarReservasAgendaOnline]
  )

  const horariosFinais = useMemo(
    () => showHorarioFinal && getItemsHorarioFinal(horariosMap, horarioInicial, proximoHorarioOcupado),
    [horarioInicial, horariosMap, proximoHorarioOcupado, showHorarioFinal]
  )

  return (
    <Grid>
      <Cell size={showHorarioFinal ? 6 : 12}>
        <SelectField<Date>
          label={labels.inicial}
          value={value?.inicial}
          items={Array.from(horariosMap.keys()).sort()}
          itemToString={itemToString}
          itemIsEqual={(h) => horariosMap.get(h).isOcupado || isReservadoAgendaOnline(h)}
          customText={(h) => (horariosMap.get(h).isOcupado ? 'Ocupado' : 'Reservado')}
          selectedItemStyle={(h) =>
            horariosMap.get(h).isOcupado
              ? { icon: 'checkCircleOutline', color: theme.pallete.status.success.main }
              : { icon: 'checkCircleOutline', color: theme.pallete.status.info.main }
          }
          selectedItemTooltip={(h) =>
            !horariosMap.get(h).isOcupado && isReservadoAgendaOnline(h) && 'Reservado para agenda online'
          }
          name={name.inicial}
          disabled={disabled}
          {...rest}
        />
        <ErrorField name={name} ignoreObjectError />
      </Cell>
      {showHorarioFinal && (
        <Cell size={6}>
          <SelectField<Date>
            label={labels.final}
            value={value?.final}
            items={horariosFinais}
            itemToString={itemToString}
            name={name.final}
            disabled={disabled}
            {...rest}
          />
        </Cell>
      )}
    </Grid>
  )
}
