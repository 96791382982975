import { ApolloQueryResult } from 'apollo-client'
import { HFlow, Icon, TableCell, TableRow, Tooltip } from 'bold-ui'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import {} from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'

import { GrupoExameEditAction } from './GrupoExameEditAction'
import { GrupoExameExcludeAction } from './GrupoExameExcludeAction'
import { GrupoExameRowItem } from './GrupoExameTable'

interface GrupoExameTableRowProps {
  grupoExame: GrupoExameRowItem
  procedimentos: ProcedimentoSelectModel[]
  expandedRow: string
  handleClick(grupoExame: GrupoExameRowItem): any
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export function GrupoExameTableRow(props: GrupoExameTableRowProps) {
  const { grupoExame, refetch, procedimentos, expandedRow, handleClick } = props

  const procedureRender = (item: ProcedimentoSelectModel) => {
    return (
      <li key={item.id}>
        <b>{item.descricao.capitalize()}</b> - {showCodigoProcedimento(item.codigo)}
        <br />
        {item.procedimentoReferencia && (
          <>
            <b>{item.procedimentoReferencia.descricao.capitalize()}</b> - {item.procedimentoReferencia.codigo}
          </>
        )}
      </li>
    )
  }

  return (
    <React.Fragment key={grupoExame.id}>
      <Tooltip placement='left-start' text={expandedRow === grupoExame.id ? 'Minimizar' : 'Expandir'}>
        <TableRow onClick={handleClick(grupoExame)}>
          <TableCell style={{ paddingLeft: '26px' }}>
            <HFlow alignItems='center' justifyContent='flex-start'>
              <Icon icon={expandedRow === grupoExame.id ? 'angleUp' : 'angleDown'} />
              {grupoExame.nome.capitalize()}
            </HFlow>
          </TableCell>
          <TableCell>{grupoExame.quantidadeProcedimentos}</TableCell>
          <TableCell>
            <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0}>
              <GrupoExameEditAction grupoExame={grupoExame} />
              <GrupoExameExcludeAction grupoExame={grupoExame} refetch={refetch} />
            </HFlow>
          </TableCell>
        </TableRow>
      </Tooltip>
      {expandedRow && expandedRow === grupoExame.id && procedimentos && (
        <TableRow style={{ backgroundColor: '#f0f0f5' }}>
          <TableCell colSpan={3}>
            <ul style={{ padding: '0 16px', margin: '16px 0', columns: '2' }}>
              {procedimentos.map((item) => procedureRender(item))}
            </ul>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}
