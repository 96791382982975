/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { RouterLink } from 'components/route'
import { useSobreQuery } from 'graphql/hooks.generated'
import logo from 'images/esus.svg'

export function InitialContent({ children }) {
  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })

  return (
    <div css={styles.container}>
      <div css={styles.logo}>
        <img css={styles.img} src={logo} alt='Logo do e-SUS Atenção Primária' />
      </div>
      {children}
      <div css={styles.info}>
        <p>
          Sistema fornecido pelo <br />
          <Text fontWeight='bold'>Ministério da Saúde</Text>
        </p>
        <hr css={styles.separator} />
        <VFlow vSpacing={0.5}>
          {info && <Text fontStyle='italic'>Versão {info?.versao}</Text>}
          <RouterLink
            style={css`
              padding: 1rem;
              margin: 1rem;
            `}
            to='/termosUsoExterno'
          >
            Termos de uso
          </RouterLink>
        </VFlow>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  `,
  logo: css`
    margin-bottom: 4rem;
    text-align: center;
  `,
  img: css`
    height: 50px;
  `,
  info: css`
    margin-top: 4rem;
    text-align: center;
  `,
  separator: css`
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
  `,
}
