/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import theme from 'config/theme'
import { isEmpty } from 'lodash'
import { CuidadoCompartilhadoItem } from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

export const ListaCuidadoCompartilhadoTablePanel = (props: { row: CuidadoCompartilhadoItem }) => {
  const { row } = props

  const hasCiap = !isEmpty(row.ciap)
  const hasCid10 = !isEmpty(row.cid10)
  const hasBoth = hasCiap && hasCid10
  const separator = hasBoth ? ' | ' : ''

  return (
    <Grid gap={0} gapVertical={0.5} style={styles.panel}>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <InfoLabel title='Problemas/condições'>
            <Text>
              {hasCiap && `${row.ciap.descricao.capitalize()} - ${row.ciap.codigo} (CIAP 2)`}
              {separator}
              {hasCid10 && `${row.cid10.nome.capitalize()} - ${row.cid10.codigo} (CID 10)`}
            </Text>
          </InfoLabel>
        </VFlow>
      </Cell>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <InfoLabel title='Discussão de caso clínico'>
            <Text dangerouslySetInnerHTML={{ __html: row.discussao }} style={styles.discussao} />
          </InfoLabel>
        </VFlow>
      </Cell>
    </Grid>
  )
}

const styles = {
  panel: css`
    background: ${theme.pallete.primary.c90};
    padding: 1rem;
  `,
  discussao: css`
    word-break: break-all;
  `,
}
