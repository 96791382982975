import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { createValidator, empty, ErrorObject, maxLength, range, required, validate } from 'util/validation'

import { ListaEsperaRemocaoModel } from '../model'

export const removerCidadaoListaEsperaValidator = () =>
  createValidator<ListaEsperaRemocaoModel>(
    {
      motivoSaida: [required],
      observacaoSaida: [maxLength(200)],
    },
    (values: ListaEsperaRemocaoModel, errors: ErrorObject<ListaEsperaRemocaoModel>) => {
      if (values.motivoSaida === ListaEsperaMotivoSaidaEnum.OUTROS) {
        errors.observacaoSaida = required(values.observacaoSaida)
      }
      if (values.motivoSaida === ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO) {
        errors.tentativasContato = validate(values.tentativasContato, [required, range(1, 100)])
      } else {
        errors.tentativasContato = empty(values.tentativasContato)
      }
      return errors
    }
  )
