import { HeadingSection } from 'bold-ui'
import { useAlert } from 'components/alert'
import { alterarSenhaModalForm } from 'components/form/alterar-senha/AlterarSenhaModalForm'
import { AlterarSenhaModel } from 'components/form/alterar-senha/model'
import { useApolloClient } from 'graphql/hooks'
import {
  useRedefinirSenhaAdminInstalacaoMutation,
  ValidateNovoAdminDocument,
  ValidateResetAdminDocument,
} from 'graphql/hooks.generated'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import ContraChaveForm, { ContraChaveFormModel } from './components/ResetInstaladorContraChaveForm'

interface UrlParams {
  isEditAdmin: string
}

interface ModalResetSenhaInstaladorProps {
  isModalOpen: boolean
  possuiCns: boolean
  onButtonClicked(confirm: boolean): void
}

export default function ResetInstaladorContraChaveView() {
  const client = useApolloClient()
  const { params, url } = useRouteMatch<UrlParams>()
  const history = useHistory()
  const alert = useAlert()
  const [resetSenhaMutation] = useRedefinirSenhaAdminInstalacaoMutation()

  const isEdit = params.isEditAdmin === '1'

  const handleSubmit = async (values: ContraChaveFormModel) => {
    const response = await client.query({
      query: isEdit ? ValidateResetAdminDocument : ValidateNovoAdminDocument,
      variables: {
        input: {
          cpf: values.cpf,
          contraChave: values.contraChave,
        },
      },
    })
    if (response.data) {
      if (isEdit) {
        const confirmed = await alterarSenhaModalForm({
          onSubmit: (alterarSenhaValues: AlterarSenhaModel) => {
            return resetSenhaMutation({
              variables: {
                input: {
                  cpf: values.cpf,
                  contraChave: values.contraChave,
                  novaSenhaInput: {
                    novaSenha: alterarSenhaValues.novaSenhaInput.novaSenha,
                    confirmacaoSenha: alterarSenhaValues.novaSenhaInput.confirmacaoSenha,
                  },
                },
              },
            })
          },
        })()

        if (!confirmed) return

        alert('success', 'Administrador da instalação salvo com sucesso.')
        sessionStorage.removeItem('contra-chave')
        history.push(`/`)
      } else {
        sessionStorage.setItem('contra-chave', values.contraChave)
        history.push(`${url}/profissional/${values.cpf}`)
      }
    }
  }

  return (
    <>
      <HeadingSection level={1} title={isEdit ? 'Redefinição da senha do instalador' : 'Definir novo instalador'} />
      <ContraChaveForm onSubmit={handleSubmit} isEditAdmin={isEdit} />
    </>
  )
}
