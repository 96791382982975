import { resolveName } from 'components/form/final-form/hooks/useField'
import { isEmpty } from 'lodash'
import React from 'react'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { CondutaOdontoPanel, CondutaOdontoPanelProps } from './components/CondutaOdontoPanel'
import { CondutaPanel, CondutaPanelProps } from './components/CondutaPanel'
import { FinalizacaoAtendimentoFormProps } from './FinalizacaoAtendimentoForm'

interface FinalizacaoAtendimentoCondutaPanelProps
  extends Pick<FinalizacaoAtendimentoFormProps, 'isAtendimentoOdonto' | 'isAtendimentoProcedimentos' | 'name'>,
    Omit<CondutaPanelProps, 'name' | 'hasEncaminhamentoExterno'>,
    Omit<CondutaOdontoPanelProps, 'name' | 'hasEncaminhamentoExterno'> {}

export function FinalizacaoAtendimentoCondutaPanel(props: FinalizacaoAtendimentoCondutaPanelProps) {
  const {
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    tipoConsulta,
    hasNotificacaoCasoSuspeito,
    isObservacao,
    name,
  } = props

  const {
    input: { value: encaminhamentosEspecializadosRecentes },
  } = useField(resolveName(meta.plano?.encaminhamentoExterno?.encaminhamentosEspecializadosRecentes), {
    subscription: { value: true },
  })
  const {
    input: { value: encaminhamentosRecentes },
  } = useField(resolveName(meta.plano?.encaminhamentoExterno?.encaminhamentosRecentes), {
    subscription: { value: true },
  })
  const hasEncaminhamentoExterno = !isEmpty(encaminhamentosRecentes) || !isEmpty(encaminhamentosEspecializadosRecentes)

  if (isAtendimentoOdonto)
    return (
      <CondutaOdontoPanel
        name={name.condutasOdonto}
        tipoConsulta={tipoConsulta}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      />
    )

  if (!isAtendimentoProcedimentos)
    return (
      <CondutaPanel
        name={name.conduta}
        hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
        isObservacao={isObservacao}
      />
    )

  return null
}
