/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, TableFooter, Text, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { DateTime } from 'components/date'
import { TableBox, usePagedTableProps } from 'components/table'
import { useHistoricoTecidosMolesQuery } from 'graphql/hooks.generated'
import {
  CaracteristicaLesao,
  HistoricoTecidosMolesQuery,
  PageInfo,
  TecidosMolesQueryInput,
} from 'graphql/types.generated'
import { useCallback, useState } from 'react'

import { showDescricaoCodigoProcedimento } from '../../util'
import { CaracteristicasLesaoPanel } from '../components/caracteristicas-lesao/CaracteristicasLesaoPanel'
import { HistoricoTecidosMolesQueryModel } from '../model'
import { HistoricoTecidosMolesTableHeader } from './HistoricoTecidosMolesTableHeader'
import HistoricoTecidosMolesTableRow from './HistoricoTecidosMolesTableRow'

// usado pra fazer o content required pro usePagedTableProps, uma vez que no type da query ele é opcional
type HistoricoTecidosMolesTableModel = {
  content: HistoricoTecidosMolesQuery['tecidosMoles']['content']
  pageInfo: PageInfo
}

interface HistoricoTecidosMolesTableProps {
  prontuarioId: ID
  showTitle?: boolean
  showEmpty?: boolean
}

export default function HistoricoTecidosMolesTable(props: HistoricoTecidosMolesTableProps) {
  const { prontuarioId, showTitle, showEmpty } = props

  const theme = useTheme()

  const [state, setState] = useState<TecidosMolesQueryInput>({
    prontuarioId,
    pageParams: {
      size: 5,
    },
  })

  const {
    data: { tecidosMoles },
    loading,
  } = useHistoricoTecidosMolesQuery({
    variables: { input: state },
  })

  const tableFooterProps = usePagedTableProps({
    loading,
    result: tecidosMoles as HistoricoTecidosMolesTableModel,
    onChange: setState,
  })

  const onChangeFilter = useCallback((filter: string) => {
    setState((state) => ({
      ...state,
      query: filter,
    }))
  }, [])

  return tableFooterProps?.rows?.length > 0 || !!state.query || loading || showEmpty ? (
    <VFlow>
      {showTitle && <Heading level={3}>Histórico de evoluções</Heading>}

      <TableBox
        header={<HistoricoTecidosMolesTableHeader onChangeFilter={onChangeFilter} />}
        css={
          !showTitle &&
          css`
            margin-top: -0.5rem;
          `
        }
      >
        <AccordionDataTable<HistoricoTecidosMolesQueryModel>
          columns={[
            {
              name: 'data',
              header: 'Data dos procedimentos',
              render: renderData,
              size: 2,
              xl: 1,
            },
            {
              name: 'local',
              header: 'Local',
              render: renderLocal,
              size: 2,
            },
            {
              name: 'problemas',
              header: 'CID 10',
              render: renderProblema,
              size: 3,
            },
            {
              name: 'procedimentos',
              header: 'Procedimentos',
              render: renderProcedimentos,
              size: 3,
              xl: 4,
            },
            {
              name: 'profissional',
              header: 'Profissional',
              render: renderProfissional,
              size: 2,
            },
          ]}
          components={{
            AccordionPanel: ({ row }) => (
              <CaracteristicasLesaoPanel
                items={extractCaracteristicasLesaoEnum(row.caracteristicasLesao)}
                descricaoSintomatologia={row.descricaoSintomatologia}
              />
            ),
            Row: HistoricoTecidosMolesTableRow,
          }}
          disableRow={(row) => !row.caracteristicasLesao?.length && !row.descricaoSintomatologia}
          disableRowMessage='Não foram inseridas características da lesão nesse registro'
          {...tableFooterProps}
        />
        <TableFooter
          {...tableFooterProps}
          pageSize={state.pageParams?.size}
          sizeOptions={[5, 15, 25, 50]}
          style={css`
            border-top: 0;
            background-color: ${theme.pallete.surface.main};
          `}
        />
      </TableBox>
    </VFlow>
  ) : null
}

const renderData = (item: HistoricoTecidosMolesQueryModel) => (
  <DateTime
    value={item.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm}
    format='DD/MM/YYYY'
    style={css`
      white-space: nowrap;
    `}
  />
)

const renderLocal = (item: HistoricoTecidosMolesQueryModel) => <Text>{item.parteBucal.descricao}</Text>

const renderProblema = (item: HistoricoTecidosMolesQueryModel) => (
  <Text>{item.cid10 ? `${item.cid10.nome} - ${item.cid10.codigo}` : '-'}</Text>
)

const renderProcedimentos = (item: HistoricoTecidosMolesQueryModel) =>
  !!item.evolucaoProcedimentoOdonto?.procedimentos?.length ? (
    <VFlow vSpacing={0}>
      {item.evolucaoProcedimentoOdonto?.procedimentos.map((proced) => (
        <Text key={proced.id}>{showDescricaoCodigoProcedimento(proced)}</Text>
      ))}
    </VFlow>
  ) : (
    <Text>-</Text>
  )

const renderProfissional = (item: HistoricoTecidosMolesQueryModel) => (
  <VFlow vSpacing={0}>
    {item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.profissional.nome.titleCase()}
    {item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.cbo.nome}
  </VFlow>
)

export const extractCaracteristicasLesaoEnum = (items: Partial<CaracteristicaLesao>[]) =>
  items?.map((item) => item?.caracteristicaLesao)
