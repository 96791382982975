/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel } from 'bold-ui'
import { formatDate } from 'util/date/formatDate'

import { HistoricoVacinacaoDwModel } from './HistoricoVacinacaoDw'

export default function HistoricoVacinacaoDwTableItem(historico: HistoricoVacinacaoDwModel) {
  const imunoEstrategiaDose = `${historico.dimensaoImunobiologico.nome}, ${
    historico.dimensaoEstrategiaVacinacao?.nome ? `${historico.dimensaoEstrategiaVacinacao?.nome.capitalize()}, ` : ''
  }${historico.dimensaoDoseImunobiologico.nome.capitalize()}`

  const loteFab =
    !historico.nomeLote && !historico.nomeFabricante
      ? '-'
      : `${historico.nomeLote ?? '-'}/${historico.nomeFabricante.titleCase() ?? '-'}`

  const dataAplicacao = formatDate(historico.dataRegistroVacinaAplicada)

  return (
    <Grid
      style={css`
        padding: 0.5rem 0;
      `}
    >
      <Cell size={5}>
        <InfoLabel title='Imunobiológico/Estratégia/Dose'>{imunoEstrategiaDose}</InfoLabel>
      </Cell>
      <Cell size={4}>
        <InfoLabel title='Lote/Fabricante'>{loteFab}</InfoLabel>
      </Cell>
      <Cell size={3}>
        <InfoLabel title='Data de aplicação'>{dataAplicacao}</InfoLabel>
      </Cell>
    </Grid>
  )
}
