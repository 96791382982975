import useLocalStorage from '@rehooks/local-storage'
import { isBefore, subDays } from 'date-fns'
import { useCallback, useEffect } from 'react'

interface AtmosphereTransportCacheModel {
  lastSuccessfulTransport?: AtmosphereTransport
  currentTransport: AtmosphereTransport
  lastSuccessfulTransportUpdate: Date
}

type AtmosphereTransport = 'websocket' | 'long-polling'

const defaultAtmosphereTransport: AtmosphereTransportCacheModel = {
  currentTransport: 'websocket',
  lastSuccessfulTransportUpdate: new Date(),
}

// Hook used to cache and manage atmosphere transports.
// Last successful transport is saved and used to evict transport fallback for configured time (default: 1 day)
export default () => {
  const [transportCache, setTransportCache] = useLocalStorage<AtmosphereTransportCacheModel>(
    'atmosphere-transport',
    defaultAtmosphereTransport
  )

  const reset = useCallback(
    () => setTransportCache({ ...defaultAtmosphereTransport, lastSuccessfulTransportUpdate: new Date() }),
    [setTransportCache]
  )

  useEffect(() => {
    if (!transportCache || isBefore(new Date(transportCache.lastSuccessfulTransportUpdate), subDays(new Date(), 1))) {
      reset()
    }
  }, [reset, transportCache])

  const setlastSuccessfulTransport = useCallback(
    (transport: AtmosphereTransport) => {
      if (transport !== transportCache.lastSuccessfulTransport) {
        setTransportCache({
          currentTransport: transportCache.currentTransport,
          lastSuccessfulTransport: transport,
          lastSuccessfulTransportUpdate: new Date(),
        })
      }
    },
    [setTransportCache, transportCache.currentTransport, transportCache.lastSuccessfulTransport]
  )

  const setCurrentTransport = useCallback(
    (transport: AtmosphereTransport) =>
      transport !== transportCache.currentTransport &&
      setTransportCache({
        currentTransport: transport,
        lastSuccessfulTransport: transportCache.lastSuccessfulTransport,
        lastSuccessfulTransportUpdate: transportCache.lastSuccessfulTransportUpdate,
      }),
    [
      setTransportCache,
      transportCache.currentTransport,
      transportCache.lastSuccessfulTransport,
      transportCache.lastSuccessfulTransportUpdate,
    ]
  )
  return {
    currentTransport: transportCache.currentTransport,
    lastSuccessfulTransport: transportCache.lastSuccessfulTransport,
    setlastSuccessfulTransport,
    setCurrentTransport,
  }
}
