/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { CuidadoCompartilhadoItem } from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

export const ListaCuidadoCompartilhadoTableRow = (
  props: AccordionDataTableRowDefaultProps<CuidadoCompartilhadoItem>
) => {
  const { columns, row, disabled } = props

  return <AccordionDataTableRowDefault columns={columns} row={row} disabled={disabled} style={styles.row} />
}

const styles = {
  row: css`
    align-items: stretch;
  `,
}
