/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoAtendimentoDomiciliarAdQuery } from 'graphql/hooks.generated'
import { memo } from 'react'
import { HistoricoPecPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertToMedicaoModel,
  mountInfosAdministrativasAtendimentosRecentes,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoAvaliacaoPecPanel from '../../atendimento-individual/consulta/HistoricoAvaliacaoPecPanel'
import HistoricoObjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoObjetivoPecPanel'
import HistoricoPlanoPecPanel from '../../atendimento-individual/consulta/HistoricoPlanoPecPanel'
import HistoricoSubjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoSubjetivoPecPanel'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import HistoricoAtendimentoDomiciliarDadosSectionPanel from './HistoricoAtendimentoDomiciliarDadosSectionPanel'

interface HistoricoAtendimentoDomiciliarADPanelProps extends HistoricoPecPanelProps {}

function HistoricoAtendimentoDomiciliarADPanel(props: HistoricoAtendimentoDomiciliarADPanelProps) {
  const { idAtend, idadeOrigemAtendimento } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoAtendimentoDomiciliarAdQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico)}
    >
      <HistoricoSection section='S'>
        <HistoricoSubjetivoPecPanel evolucaoSubjetivo={historico.evolucaoSubjetivo} />
      </HistoricoSection>
      <HistoricoSection section='O'>
        <HistoricoObjetivoPecPanel
          evolucaoObjetivo={historico.evolucaoObjetivo}
          medicoes={convertToMedicaoModel(historico.medicoes)}
        />
      </HistoricoSection>
      <HistoricoSection section='A'>
        <HistoricoAvaliacaoPecPanel evolucaoAvaliacao={historico.evolucaoAvaliacao} />
      </HistoricoSection>
      <HistoricoSection section='P'>
        <HistoricoPlanoPecPanel evolucaoPlano={historico.evolucaoPlano} isOrigemApp />
      </HistoricoSection>
      <HistoricoSection section='AD'>
        <HistoricoAtendimentoDomiciliarDadosSectionPanel
          atendimentoProfissionalAD={historico.atendimentoProfissionalAD}
          procedimentos={historico.procedimentosAtendimento}
          cidadaoAD={historico.cidadaoAD}
        />
      </HistoricoSection>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoDomiciliarADPanel)
