import ReactPDF, { StyleSheet, View } from '@react-pdf/renderer'
import { Theme, useTheme } from 'bold-ui'
import React from 'react'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintHeaderProps {
  underline?: boolean
  styles?: ReactPDF.Style
  children: React.ReactNode
}

export const PrintHeader = (props: PrintHeaderProps) => {
  const { underline, styles, children } = props
  const theme = useTheme()
  const { config } = usePrinterStyles()
  const classes = createStyles(theme)

  return <View style={[config.row, underline && classes.line, styles]}>{children}</View>
}

const createStyles = (theme: Theme) => {
  return StyleSheet.create({
    line: {
      borderBottomWidth: 1,
      borderBottomColor: theme.pallete.divider,
    },
  })
}
