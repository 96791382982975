/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { noop } from 'lodash'

import { VideocallStream } from '../model'
import { StreamPlayerVideochamada } from './StreamPlayerVideochamada'

interface StreamPlayersVideochamadaLayoutProps {
  streams: VideocallStream[]
  pipActive?: boolean
  setPipActive?(newVal: boolean): void
}

export function StreamPlayersVideochamadaLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams } = props

  const anyPresenting = streams.some((stream) => stream.presenting)

  return anyPresenting || streams.length > 2 ? (
    <StreamPlayersVideochamadaPresentationLayout {...props} />
  ) : (
    <StreamPlayersVideochamadaVideocallLayout {...props} />
  )
}

function StreamPlayersVideochamadaPresentationLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams, pipActive = false, setPipActive } = props

  const [participants, presentations] = streams.reduce(
    (partition, stream) => {
      partition[+stream.presenting].push(stream)
      return partition
    },
    [[], []] as [VideocallStream[], VideocallStream[]]
  )

  const remotePresentation = presentations.find((presentation) => presentation.remote)
  const pipStreamId = (remotePresentation ?? participants.find((participant) => participant.remote))?.id

  return (
    <div css={styles.presentationLayoutContainer}>
      {presentations.length > 0 && (
        <div css={styles.presentationsContainer}>
          {presentations.map((stream) => (
            <StreamPlayerVideochamada
              key={stream.id}
              nomeParticipante={stream.label}
              stream={stream.stream}
              corParticipante={stream.color}
              fit='contain'
              showMicrophoneFeedback={false}
              style={css(styles.presentation, pipActive && stream.id === pipStreamId ? styles.presentationOnPip : null)}
              muted
              pipActive={pipActive && stream.id === pipStreamId}
              setPipActive={pipActive && stream.id === pipStreamId ? setPipActive : noop}
              disablePictureInPicture={!stream.remote}
            />
          ))}
        </div>
      )}
      <HFlow hSpacing={0.5} justifyContent='center'>
        {participants.sort(remoteFirst).map((stream) => (
          <StreamPlayerVideochamada
            key={stream.id}
            nomeParticipante={stream.label}
            stream={stream.stream}
            muted={!stream.remote}
            corParticipante={stream.color}
            style={styles.presentationParticipant}
            pipActive={pipActive && stream.id === pipStreamId}
            setPipActive={pipActive && stream.id === pipStreamId ? setPipActive : noop}
            disablePictureInPicture={!stream.remote}
            minimizado
          />
        ))}
      </HFlow>
    </div>
  )
}

function StreamPlayersVideochamadaVideocallLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams, pipActive = false, setPipActive } = props

  const pipStreamId = streams.find((stream) => stream.remote)?.id

  return (
    <div css={styles.playersContainer}>
      {streams.map((stream) => (
        <StreamPlayerVideochamada
          key={stream.id}
          nomeParticipante={stream.label}
          stream={stream.stream}
          corParticipante={stream.color}
          muted={!stream.remote}
          style={[styles.video, !stream.remote && streams.length > 1 && styles.camMinimizada]}
          pipActive={stream.id === pipStreamId && pipActive}
          setPipActive={stream.id === pipStreamId && pipActive ? setPipActive : noop}
          disablePictureInPicture={!stream.remote}
        />
      ))}
    </div>
  )
}

const remoteFirst = (a: VideocallStream, b: VideocallStream): number => Number(b.remote) - Number(a.remote)

const styles = {
  playersContainer: css`
    position: relative;
    height: 100%;
  `,
  video: css`
    transition: width 0.3s linear;
    width: 100%;
    height: 100%;
    min-height: 15.75rem;
    position: relative;
  `,
  camMinimizada: css`
    z-index: 3;
    position: absolute;
    bottom: 1rem;
    right: 0.5rem;
    width: 17.5rem;
    height: 10rem;
    min-height: 0;
  `,
  presentationLayoutContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
  `,
  presentationsContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 1rem;
    overflow: hidden;
    height: 0;
  `,
  participantsContainer: css`
    display: flex;
    justify-content: space-around;
  `,
  presentationParticipant: css`
    height: 10rem;
    width: 16rem;
  `,
  presentation: css`
    height: 100%;
    margin-left: 0.5rem;

    &:first-of-type {
      margin-left: 0;
    }
  `,
  presentationOnPip: css`
    flex-grow: 1;
  `,
}
