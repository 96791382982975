/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Icon } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { Form, FormDebouncedValueSpy, SwitchField } from 'components/form'
import { TipoAtendimentoSelectField } from 'components/form/field/select/TipoAtendimentoSelectField/TipoAtendimentoSelectField'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { DisponibilidadeDadosClinicosHistorico } from 'graphql/types.generated'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { ExibirPorHistoricoAtendimentoFilterEnum } from 'types/enums'
import { metaPath } from 'util/metaPath'

import {
  HistoricoAtendimentoAdvancedFilterFormModel,
  HistoricoAtendimentoFilterFormModel,
} from '../../model/historicoFilterFormModel'
import HistoricoAtendimentoActiveFilters from './HistoricoAtendimentoActiveFilters'
import HistoricoAtendimentoFilterPopper from './HistoricoAtendimentoFilterPopper'
import { HistoricoAtendimentoSortDropdown } from './HistoricoAtendimentoSortDropDown'

const path = metaPath<HistoricoAtendimentoFilterFormModel>()

export interface HistoricoAtendimentoFilterProps {
  filtros: HistoricoAtendimentoFilterFormModel
  disponibilidadeDadosClinicos: DisponibilidadeDadosClinicosHistorico
  onChangeFiltros(values: HistoricoAtendimentoFilterFormModel): void
}

export default function HistoricoAtendimentoFilter(props: HistoricoAtendimentoFilterProps) {
  const { filtros, disponibilidadeDadosClinicos, onChangeFiltros } = props
  const { analytics } = useFirebase()
  const [counterFiltrosAvancadosAplicados, setCounterFiltrosAvancadosAplicados] = useState(0)

  useEffect(() => {
    setCounterFiltrosAvancadosAplicados(
      (filtros.filtrosAvancados?.periodo?.startDate && filtros.filtrosAvancados?.periodo?.endDate ? 1 : 0) +
        (filtros.filtrosAvancados?.subtiposAtendimento?.length ?? 0) +
        (filtros.filtrosAvancados?.ciaps2?.length ?? 0) +
        (filtros.filtrosAvancados?.cids10?.length ?? 0) +
        (filtros.filtrosAvancados?.cbos?.length ?? 0) +
        (filtros.filtrosAvancados?.equipes?.length ?? 0) +
        (filtros.filtrosAvancados?.profissionais?.length ?? 0)
    )
  }, [filtros])

  const handleChangeFiltrosAvancados = useCallback(
    (values: HistoricoAtendimentoAdvancedFilterFormModel) => {
      let newFilter = { ...filtros, filtrosAvancados: values }

      newFilter.pageParams.page = 0

      if (
        filtros.periodoExibicao === ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO &&
        !values.periodo?.startDate &&
        !values.periodo?.endDate
      ) {
        newFilter.periodoExibicao = ExibirPorHistoricoAtendimentoFilterEnum.TODOS_ATENDIMENTOS
      } else if (values.periodo?.startDate || values.periodo?.endDate) {
        newFilter.periodoExibicao = ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO
      }

      onChangeFiltros(newFilter)
    },
    [filtros, onChangeFiltros]
  )

  const handleChangeFiltros = useCallback(
    (formState: FormState<HistoricoAtendimentoFilterFormModel>) => {
      let newFilter = { ...filtros, ...formState.values }

      newFilter.pageParams.page = 0

      if (
        newFilter.periodoExibicao !== ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO &&
        newFilter.filtrosAvancados
      ) {
        newFilter.filtrosAvancados.periodo = undefined
      }

      if (newFilter.tiposAtendimento !== filtros.tiposAtendimento && newFilter.filtrosAvancados) {
        newFilter.filtrosAvancados.subtiposAtendimento = undefined
      }

      onChangeFiltros(newFilter)
    },
    [filtros, onChangeFiltros]
  )

  const onClearFiltrosAvancados = () => {
    onChangeFiltros({
      ...filtros,
      periodoExibicao: ExibirPorHistoricoAtendimentoFilterEnum.TODOS_ATENDIMENTOS,
      filtrosAvancados: {},
    })
  }

  const renderPopper = ({ close }: PopperControls) => {
    analytics.logEvent('open_filtros_avancados_historico')
    return (
      <HistoricoAtendimentoFilterPopper
        initialValues={filtros.filtrosAvancados}
        tiposAtendimento={filtros.tiposAtendimento}
        clear={onClearFiltrosAvancados}
        close={close}
        onSubmit={handleChangeFiltrosAvancados}
        disponibilidadeDadosClinicos={disponibilidadeDadosClinicos}
      />
    )
  }

  const renderFiltroAdministrativo = () => {
    return (
      <Fragment>
        <FormDebouncedValueSpy onChange={handleChangeFiltros} wait={400} />
        <Cell size={4}>
          <TipoAtendimentoSelectField name={path.tiposAtendimento} label='Tipo de atendimento' multiple />
        </Cell>
        <Cell
          size={3}
          style={css`
            align-items: flex-end;
            display: inline-flex;
            justify-content: flex-start;
          `}
        >
          <SwitchField name={path.somenteMeusAtendimentos} label='Ver somente os meus atendimentos' />
        </Cell>
        <Cell
          size={3}
          style={css`
            align-items: flex-end;
            display: inline-flex;
            justify-content: flex-end;
          `}
        >
          <HistoricoAtendimentoSortDropdown name={path.periodoExibicao} />
        </Cell>
      </Fragment>
    )
  }

  return (
    <Grid>
      <Form<HistoricoAtendimentoFilterFormModel>
        render={renderFiltroAdministrativo}
        onSubmit={onChangeFiltros}
        initialValues={
          {
            periodoExibicao: filtros.periodoExibicao,
            somenteMeusAtendimentos: filtros.somenteMeusAtendimentos,
            tiposAtendimento: filtros.tiposAtendimento,
          } as any
        }
      />
      <Cell
        size={2}
        style={css`
          align-items: flex-end;
          display: inline-flex;
          justify-content: flex-end;
        `}
      >
        <PopperButton kind='primary' size='small' skin='default' placement='bottom-end' renderPopper={renderPopper}>
          <Icon icon='filterOutline' />
          {`Outros filtros ${counterFiltrosAvancadosAplicados > 0 ? ` (${counterFiltrosAvancadosAplicados}) ` : ''}`}
        </PopperButton>
      </Cell>
      {filtros?.filtrosAvancados && (
        <Cell size={12}>
          <HistoricoAtendimentoActiveFilters filters={filtros.filtrosAvancados} />
        </Cell>
      )}
    </Grid>
  )
}
