import { Alert, VFlow } from 'bold-ui'
import { Action, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import React from 'react'

import { CuidadoCompartilhadoItemActions, ListaCuidadoCompartilhadoFilterModel } from '../model-cuidadocompartilhado'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (value: ListaCuidadoCompartilhadoFilterModel): CuidadosCompartilhadoQueryInput => ({
  pageParams: value.pageParams,
  executadosPorMim: value.somenteOsMeus,
  executadosPelaMinhaEquipe: !value.somenteOsMeus,
})

const sort = ['-classificacaoRisco', 'status', 'ultimaAtualizacao']

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions.discutirCasoExecutante

export const ListaCuidadoCompartilhadoExecutanteView = () => {
  return (
    <VFlow vSpacing={0.5}>
      <Alert type='info' inline>
        A lista apresenta todos os cuidados compartilhados com você e com a sua equipe.
      </Alert>
      <ListaCuidadoCompartilhadoView
        filterToQueryInput={filterToQueryInput}
        defaultSort={sort}
        filterSomenteOsMeusLabel='Ver somente cuidados compartilhados comigo'
        resolveDiscutirAction={resolveDiscutirAction}
      />
    </VFlow>
  )
}
