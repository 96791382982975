/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import {
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  SubmitButton,
  TextAreaField,
} from 'components/form'
import { LotacaoAgendaSelectField } from 'components/form/field/select/LotacaoAgendaSelectField/LotacaoAgendaSelectField'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { useAgendarConsultaListaEsperaMutation, useListaEsperaQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { isDataAgendamentoInvalida } from 'util/atendimento/isDataAgendamentoInvalida'
import { metaPath } from 'util/metaPath'
import { AgendarConsultaSectionModel } from 'view/atendimentos/detail/soap/finalizacao/components/AgendarConsultaSection'

import { createAgendarConsultaCalculations } from './calculatorAgendarConsulta'
import { convertModelToInput } from './converter'
import { agendarConsultaListaEsperaValidator } from './validator'

export interface ListaEsperaAgendaModel {
  agendamentoConsultaInput: AgendarConsultaSectionModel
}

interface UrlParams {
  listaEsperaId: ID
}

const name = metaPath<ListaEsperaAgendaModel>()

export default function ListaEsperaAgendarConsultaModalForm() {
  const { listaEsperaId } = useParams<UrlParams>()
  const { getServerTimeNow } = useServerTime()
  const { analytics } = useFirebase()
  const serverTime = getServerTimeNow()
  const history = useHistory()
  const alert = useAlert()
  const [save] = useAgendarConsultaListaEsperaMutation()
  const validator = useMemo(() => agendarConsultaListaEsperaValidator(getServerTimeNow), [getServerTimeNow])
  const decorator = useMemo(() => createAgendarConsultaCalculations(name), [])

  const {
    data: { listaEsperaItemById: listaEspera },
  } = useListaEsperaQuery({
    variables: {
      input: listaEsperaId,
    },
  })

  if (!listaEspera?.cidadao) {
    return null
  }

  const goBack = () => history.push('/gestao-filas/garantia-acesso')

  const handleSubmit = (values: ListaEsperaAgendaModel) => {
    return save({ variables: { input: { ...convertModelToInput(values, listaEsperaId) } } }).then(() => {
      goBack()
      alert('success', 'Agendamento de consulta cadastrado com sucesso.')
      analytics.logEvent('agendar_cidadao_GGA')
    })
  }

  const renderForm = (formProps: FormRenderProps<ListaEsperaAgendaModel>) => {
    const values = formProps.values
    const dataAgendamentoEhInvalida = isDataAgendamentoInvalida(values?.agendamentoConsultaInput?.data, serverTime)
    const lotacaoPreenchida = !isEmpty(values?.agendamentoConsultaInput?.lotacao)
    return (
      <Modal onClose={goBack} open size='small'>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Agendar consulta</Heading>
            <Alert type='info'>Ao agendar, o registro é removido da lista.</Alert>
            <ErrorField name={FORM_ERROR} type='alert' />
            <Grid>
              <Cell size={12}>
                <Heading level={3}>
                  {(listaEspera.cidadao?.nomeSocial ?? listaEspera.cidadao?.nome)?.titleCase()}
                </Heading>
                <VFlow vSpacing={0}>
                  <HFlow>
                    <HLabel title='CPF'>
                      <Cpf value={listaEspera.cidadao?.cpf} />
                    </HLabel>
                    <HLabel title='CNS'>{listaEspera.cidadao?.cns}</HLabel>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell size={12}>
                <LotacaoAgendaSelectField
                  name={name.agendamentoConsultaInput.lotacao}
                  label='Profissional'
                  isAgendaAd={false}
                  loadItemsOnOpen={false}
                  somenteCadastrarAgendamento
                  required
                  title='Profissional'
                />
              </Cell>
              <Cell size={6}>
                <DateField
                  name={name.agendamentoConsultaInput.data}
                  label='Data do agendamento'
                  required={lotacaoPreenchida}
                  disabled={!lotacaoPreenchida}
                  minDate={serverTime}
                  maxDate={addMonths(serverTime, 6)}
                  title='Data do agendamento'
                />
              </Cell>
              <Cell size={6}>
                <HorarioAgendaSelectField
                  name={name.agendamentoConsultaInput.horario}
                  lotacaoId={values?.agendamentoConsultaInput?.lotacao?.id}
                  disabled={dataAgendamentoEhInvalida}
                  required={!dataAgendamentoEhInvalida}
                  labels={{ inicial: 'Horário do agendamento' }}
                  dia={
                    values.agendamentoConsultaInput?.data ? parseISO(values.agendamentoConsultaInput.data) : undefined
                  }
                  title='Horário do agendamento'
                />
              </Cell>
              <Cell size={12}>
                <TextAreaField
                  label='Observações'
                  name={name.agendamentoConsultaInput.observacoes}
                  maxLength={200}
                  style={styles.observacao}
                  title='Observações'
                />
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack} title='Cancelar' size='medium' style={styles.button}>
              Cancelar
            </Button>
            <SubmitButton
              title='Salvar'
              size='medium'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              style={styles.button}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<ListaEsperaAgendaModel>
      render={renderForm}
      onSubmit={handleSubmit}
      suppressNotificationError
      validate={validator}
      decorators={[decorator]}
    />
  )
}

const styles = {
  observacao: css`
    height: 5rem;
    resize: none;
  `,
  button: css`
    height: 3rem;
    width: 9rem;
  `,
}
