import ReactPDF, { View } from '@react-pdf/renderer'
import React from 'react'

import { ConselhoClasseImpressao } from './model'
import { PrintSignatureProfissional } from './PrintSignatureProfissional'

export interface PrintBodyProps {
  children: React.ReactNode
  styles?: ReactPDF.Style
  showSignature?: boolean
  nomeProfissional?: string
  cboProfissional?: string
  conselhoClasse?: ConselhoClasseImpressao
  data: string
  municipio: string
  uf: string
}

export const PrintBody = (props: PrintBodyProps) => {
  const { children, styles, showSignature, ...rest } = props

  return (
    <View style={styles}>
      {children}
      {showSignature && <PrintSignatureProfissional {...rest} />}
    </View>
  )
}
