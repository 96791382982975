import {
  Button,
  FormControl,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  Theme,
  Tooltip,
  useStyles,
  VFlow,
} from 'bold-ui'
import { useAlert } from 'components/alert'
import { CheckboxField } from 'components/form/final-form/CheckboxField'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { NumberField } from 'components/form/final-form/NumberField'
import { SubmitButton } from 'components/form/final-form/SubmitButton'
import { confirm } from 'components/modals/confirm'
import { useAlterarQtdRequisicoesMutation, useQuantidadeRequisicoesQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'
import { createValidator, range, required } from 'util/validation'

export interface ConfiguracaoPerformanceModel {
  qtdRequisicoesSimultaneas?: number
  valorPadrao?: boolean
}

const validator = createValidator<ConfiguracaoPerformanceModel>({
  qtdRequisicoesSimultaneas: [required, range(8, 100)],
})

export const ConfiguracoesPerformanceForm = () => {
  const [alterarQuantidadeRequisicoes] = useAlterarQtdRequisicoesMutation()
  const { classes } = useStyles(iconStyle)
  const alert = useAlert()
  const { data } = useQuantidadeRequisicoesQuery()
  const [open, setOpen] = useState(false)

  if (!data) {
    return null
  }

  const qtdRequisicoes = data?.avancada?.performance?.qtdRequisicoesSimultaneas

  const initialValues: ConfiguracaoPerformanceModel = {
    qtdRequisicoesSimultaneas: qtdRequisicoes,
    valorPadrao: qtdRequisicoes === 16,
  }

  const save = (values: ConfiguracaoPerformanceModel) => {
    return alterarQuantidadeRequisicoes({
      variables: {
        qtdRequisicoesSimultaneas: values.qtdRequisicoesSimultaneas,
      },
    }).then(() => {
      alert('success', 'Quantidade de requisições simultâneas foi alterada com sucesso.')
    })
  }

  const handleCheckboxClicked = (props: FormRenderProps) => (evt) => {
    if (props.values.valorPadrao === true) {
      props.form.change('valorPadrao', false)
    } else {
      return setOpen(true)
    }
  }

  const handleSubmit = (values: ConfiguracaoPerformanceModel) => {
    if (values.valorPadrao === false) {
      return confirm({
        title: `Deseja alterar o número de requisições?`,
        body:
          'A alteração do Número de requisições pode prejudicar o desempenho do sistema,' +
          ' entre em contato com o suporte do NTI para mais informações.',
        confirmLabel: 'Alterar',
        onConfirm: () => {
          return save(values)
        },
      })()
    } else {
      return save(values)
    }
  }

  const renderForm = (formProps: FormRenderProps) => {
    const handleCancel = (props: FormRenderProps) => () => {
      props.form.change('valorPadrao', false)
      setOpen(false)
    }

    const modalHandleSubmit = (props: FormRenderProps) => () => {
      props.form.change('qtdRequisicoesSimultaneas', 16)
      props.form.change('valorPadrao', true)
      props.form.submit()
      setOpen(false)
    }

    const values = formProps.values as ConfiguracaoPerformanceModel

    return (
      <>
        <VFlow>
          <HFlow>
            <NumberField
              name='qtdRequisicoesSimultaneas'
              label={
                <HFlow alignItems='center' hSpacing={0.5}>
                  <span>Número de requisições simultâneas processadas</span>
                  <Tooltip
                    style={{ maxWidth: '595px' }}
                    text={
                      'O sistema pode ficar congestionado se houver uma ' +
                      ' grande quantidade de profissionais utilizando-o ao mesmo tempo.' +
                      ' Ajustar a quantidade de requisições que podem ser processadas' +
                      ' simultaneamente pela instalação pode auxiliar' +
                      ' essa situação.'
                    }
                  >
                    <Icon icon='infoCircleFilled' size={1} style={classes.icon} />
                  </Tooltip>
                </HFlow>
              }
              disabled={values.valorPadrao}
              clearable={false}
              maxLength={3}
            />
            <FormControl label={<span>&nbsp;</span>}>
              <Button size='small' kind='primary' onClick={formProps.handleSubmit} disabled={values.valorPadrao}>
                Salvar
              </Button>
            </FormControl>
          </HFlow>
          <CheckboxField name='valorPadrao' label='Usar valor padrão' onChange={handleCheckboxClicked(formProps)} />
        </VFlow>

        <Modal open={open} onClose={handleCancel(formProps)} closeOnBackdropClick={false} size='small'>
          <ModalBody>
            <VFlow vSpacing={2}>
              <HFlow>
                <Icon icon='questionMarkFilled' size={3} fill='primary' />
                <Heading level={3}>Deseja alterar o número de requisições para o valor padrão 16?</Heading>
              </HFlow>
              <Text>
                A alteração do Número de requisições pode prejudicar o desempenho do sistema, entre em contato com o
                suporte do NTI para mais informações.
              </Text>
            </VFlow>
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={handleCancel(formProps)}>Cancelar</Button>
              <SubmitButton kind='primary' handleSubmit={modalHandleSubmit(formProps)}>
                Alterar
              </SubmitButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  return (
    <Form<ConfiguracaoPerformanceModel>
      render={renderForm}
      validate={validator}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}

const iconStyle = (theme: Theme) => ({
  icon: {
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  } as React.CSSProperties,
})
