import axios, { AxiosPromise } from 'axios'
import { ImpressaoMedicamentosInput } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/modal/impressao/ImpressaoMedicamentosInput'
import {
  PrescricaoDigitalEmitInput,
  PrescricaoDigitalInvalidarInput,
} from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/modal/prescricao-digital/model-prescricao-digital'

export class PrescricaoDigital {
  gerarPrescricaoDigital(input: ImpressaoMedicamentosInput): AxiosPromise {
    return axios.post('/api/atendimento/gerarPrescricaoDigital', input, { responseType: 'text' })
  }

  emitirPrescricaoDigital(input: PrescricaoDigitalEmitInput): AxiosPromise {
    return axios.post('/api/atendimento/emitirPrescricaoDigital', input, { responseType: 'text' })
  }

  downloadPrescricaoDigital(codigoAcessoPrescricao: string): AxiosPromise {
    return axios.post(
      `/api/atendimento/downloadPrescricaoDigital`,
      { codigoPrescricaoDigital: codigoAcessoPrescricao },
      { responseType: 'blob' }
    )
  }

  invalidarPrescricaoDigital(input: PrescricaoDigitalInvalidarInput): AxiosPromise {
    return axios.post(`/api/atendimento/invalidarPrescricaoDigital`, {
      atendimentoId: input.atendimentoId,
      codigoPrescricaoDigital: input.codigoPrescricao,
    })
  }
}
