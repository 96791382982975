/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Checkbox, Dropdown, DropdownItem, HFlow, Icon, TextField, Tooltip, useTheme } from 'bold-ui'
import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { FixedSizeList } from 'react-window'

import { ItemTypes } from './types/ItemTypes'

export interface DraggableProps<T> {
  name: keyof T
  type: ItemTypes
  origin: 'campos_disponiveis' | 'linhas' | 'colunas'
  value: string
  filterValues: Array<string>
  filterState: Set<string>
  onDragEnd: () => void
  onKeyNav: (key: keyof T, dir: 'left' | 'right', origin: 'campos_disponiveis' | 'linhas' | 'colunas') => void
  handleFilterUpdate: (key: keyof T, filtro: Set<string>) => void
  formatter?: (value: string) => string
}

export function Draggable<T>(props: DraggableProps<T>) {
  const {
    name,
    type,
    origin,
    value,
    filterValues,
    filterState,
    onDragEnd,
    onKeyNav,
    handleFilterUpdate,
    formatter,
  } = props
  const [searchedFilterSet, setSearchedFilterSet] = useState<Array<string>>(filterValues)
  const [open, setOpen] = useState(false)
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement>()
  const theme = useTheme()
  const all = filterState.size === 0 ? 0 : filterState.size === filterValues.length ? 2 : 1

  const styles = {
    button: css`
      border: solid 1px ${theme.pallete.gray.c60};
      color: ${theme.pallete.gray.c10};
      border-radius: 2px;
      box-shadow: ${theme.shadows.outer[10]};
      padding-left: 0px;
      font-size: 13px;
    `,
    buttonError: css`
      border: solid 1px ${theme.pallete.gray.c60};
      color: ${theme.pallete.gray.c10};
      border-radius: 2px;
      box-shadow: ${theme.shadows.outer[10]};
      padding-left: 0px;
      font-size: 13px;
      border-color: ${theme.pallete.status.danger.main};
    `,
    dndBox: css`
      display: inline-block;
      margin: 0.25rem 0.25rem;
    `,
    dndBoxDragging: css`
      box-shadow: ${theme.shadows.outer[10]};
    `,
    dropdownItem: css`
      width: 100%;
      cursor: pointer;
      border-top: 1px solid ${theme.pallete.gray.c80};
      padding: 0.25rem;
    `,
    dropdownArea: css`
      max-height: 12rem;
      overflow: auto;
    `,
    dropdown: css`
      padding: 0rem;
    `,
    search: css`
      padding: 0.5rem;
    `,
    noOutline: css`
      outline-color: ${theme.pallete.surface.main};
    `,
  }

  const [{ isDragging }, drag] = useDrag({
    item: { type, name: name, origin },
    end: (_item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (dropResult != null && dropResult.result !== '') {
        onDragEnd()
        if (filterState.size === 0 && dropResult.result === 'campos_disponiveis') {
          handleFilterUpdate(name, new Set<string>(filterValues))
        }
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const handleClick = () => (open ? handleClose() : setOpen(true))
  const handleClose = () => {
    setOpen(false)
    setSearchedFilterSet(filterValues)
  }
  const isError = origin !== 'campos_disponiveis' && filterState.size === 0
  const handleSelect = (element: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isTrusted) {
      filterState.has(element) ? filterState.delete(element) : filterState.add(element)
      handleFilterUpdate(name, new Set<string>(filterState))
    }
  }
  const handleKeyDown = (filterKey: keyof T) => (event: any) => {
    const key = event.nativeEvent.key
    if (key === 'ArrowRight') {
      onKeyNav(filterKey, 'right', origin)
      onDragEnd()
    } else if (key === 'ArrowLeft') {
      onKeyNav(filterKey, 'left', origin)
      onDragEnd()
    }
  }
  const handleSearch = () => (event: any) => {
    const searchResults = new Array<string>()
    const searchText: string = (event.currentTarget.value as string).toLocaleLowerCase()
    filterValues.forEach((element: string) => {
      const stringElement = element + ''
      const loweredElement = stringElement.toLocaleLowerCase()
      const found = loweredElement.search(searchText) !== -1
      found && searchResults.push(element)
    })
    setSearchedFilterSet(searchResults)
  }
  const handleSelectAll = () => (event: any) => {
    if (event.nativeEvent.isTrusted) {
      if (all === 2) {
        handleFilterUpdate(name, new Set<string>(new Set<string>()))
      } else {
        handleFilterUpdate(name, new Set<string>(filterValues))
      }
    }
  }

  const Row = ({ index, style }) => {
    const showTodos = searchedFilterSet.length === filterValues.length
    if (index === 0 && showTodos) {
      return (
        <DropdownItem key='todos' css={styles.dropdownItem}>
          <Checkbox label='Todos os itens' onChange={handleSelectAll()} checked={all === 2} indeterminate={all === 1} />
        </DropdownItem>
      )
    }

    const value = searchedFilterSet[showTodos ? index - 1 : index]
    if (value || Number(value) === 0) {
      const bigValue = value.length > 45

      const key = name + value
      const selected = filterState.has(value)

      const label = formatter?.(value) ?? value

      return (
        <Tooltip text={bigValue && value}>
          <DropdownItem key={key} css={styles.dropdownItem} style={style}>
            <Checkbox
              title={value}
              label={bigValue ? `${label.substr(0, 45)}...` : label}
              onChange={handleSelect(value)}
              checked={selected}
              onMouseDown={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}
            />
          </DropdownItem>
        </Tooltip>
      )
    } else {
      return null
    }
  }
  return (
    <div ref={drag} css={[styles.dndBox, isDragging && styles.dndBoxDragging]}>
      <React.Fragment>
        <Tooltip text={isError && 'Selecione pelo menos uma opção.'}>
          <Button
            style={isError ? styles.buttonError : styles.button}
            innerRef={setButtonRef}
            onClick={handleClick}
            onKeyDown={handleKeyDown(name)}
            size='small'
            kind='primary'
            skin='ghost'
          >
            <HFlow hSpacing={0.5}>
              <Icon icon='dragdrop' />
              {value}
              {open ? <Icon icon='angleUp' /> : <Icon icon='angleDown' />}
            </HFlow>
          </Button>
        </Tooltip>
        <Dropdown
          anchorRef={buttonRef}
          open={open}
          autoclose={false}
          onClose={handleClose}
          popperProps={{ placement: 'bottom' }}
          style={styles.dropdown}
        >
          <div css={styles.dropdownArea} onBlur={(e) => e.stopPropagation()}>
            <DropdownItem css={styles.noOutline}>
              <div css={styles.search}>
                <TextField
                  name='iconized'
                  id='iconized'
                  placeholder='Pesquisa'
                  icon='zoomOutline'
                  onChange={handleSearch()}
                />
              </div>
            </DropdownItem>
            <FixedSizeList
              height={
                filterValues.length + 1 > 5
                  ? theme.typography.sizes.html * 8
                  : theme.typography.sizes.html * 2.2 * (filterValues.length + 1)
              }
              itemCount={
                searchedFilterSet.length === filterValues.length
                  ? searchedFilterSet.length + 1
                  : searchedFilterSet.length
              }
              itemSize={34}
              width={400}
            >
              {Row}
            </FixedSizeList>
          </div>
        </Dropdown>
      </React.Fragment>
    </div>
  )
}
