import { ListaEsperaAgendarConsultaInput } from 'graphql/types.generated'
import { convertAgendarConsulta } from 'view/atendimentos/detail/soap/finalizacao'

import { ListaEsperaAgendaModel } from './ListaEsperaAgendarConsultaModalForm'

export const convertModelToInput = (
  values: ListaEsperaAgendaModel,
  listaEsperaId: ID
): ListaEsperaAgendarConsultaInput => ({
  listaEsperaId: listaEsperaId,
  agendamentoConsultaInput: convertAgendarConsulta(values.agendamentoConsultaInput),
})
