/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Theme, useTheme } from 'bold-ui'
import { RadioField, RadioFieldProps } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { MetaRoot } from 'util/metaPath'

import {
  RELATORIOS_GERENCIAIS_PERIODO_MAPPING,
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisUnidadeMedidaTempoEnum,
} from '../../common/model'
import { RangeField } from './RangeField'
import { RelatoriosGerenciaisPeriodoFormModel } from './RelatoriosGerenciaisPeriodoForm'

interface PeriodoRadioFieldProps extends Pick<RadioFieldProps, 'disabled'> {
  namePeriodoForm: MetaRoot<RelatoriosGerenciaisPeriodoFormModel>
}

export function PeriodoRadioField(props: PeriodoRadioFieldProps) {
  const { namePeriodoForm, disabled } = props

  const nameOpcaoSelecionada = namePeriodoForm.opcaoSelecionada

  const { input: inputUnidadePeriodoSelecionada } = usePecField({
    name: namePeriodoForm.unidade,
    subscription: { value: true },
  })

  const { input: inputOpcaoSelecionada } = usePecField({
    name: nameOpcaoSelecionada,
    subscription: { value: true },
  })

  const theme = useTheme()
  const { tresPrimeirasOpcoesContainer, outraOpcaoContainer } = createStyles(theme)

  const unidadePeriodoSelecionada = inputUnidadePeriodoSelecionada.value as RelatoriosGerenciaisUnidadeMedidaTempoEnum
  const opcoes = RELATORIOS_GERENCIAIS_PERIODO_MAPPING.get(unidadePeriodoSelecionada)

  const opcaoSelecionada = inputOpcaoSelecionada.value as RelatoriosGerenciaisOpcoesPeriodoEnum

  return (
    <Grid wrap>
      <Cell sm={6}>
        <div css={tresPrimeirasOpcoesContainer}>
          <HFlow>
            <RadioField
              name={nameOpcaoSelecionada}
              value={RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA}
              label={opcoes.get(RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA).label}
              disabled={disabled}
            />
            <RadioField
              name={nameOpcaoSelecionada}
              value={RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA}
              label={opcoes.get(RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA).label}
              disabled={disabled}
            />
            <RadioField
              name={nameOpcaoSelecionada}
              value={RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA}
              label={opcoes.get(RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA).label}
              disabled={disabled}
            />
          </HFlow>
        </div>
      </Cell>
      <Cell sm={6} xs={6}>
        <div css={outraOpcaoContainer}>
          <HFlow>
            <RadioField
              name={nameOpcaoSelecionada}
              value={RelatoriosGerenciaisOpcoesPeriodoEnum.OUTRO}
              label='Outro'
              disabled={disabled}
            />
            <RangeField
              unidadePeriodoSelecionada={unidadePeriodoSelecionada}
              name={namePeriodoForm.periodo}
              disabled={disabled || opcaoSelecionada !== RelatoriosGerenciaisOpcoesPeriodoEnum.OUTRO}
            />
          </HFlow>
        </div>
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  tresPrimeirasOpcoesContainer: css`
    border: 1px solid ${theme.pallete.gray.c80};
    padding: 0.5rem 0.5rem;
    width: 32.5rem;
  `,
  outraOpcaoContainer: css`
    padding: 0.2rem;
  `,
})
