import { VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useListaCuidadoCompartilhadoLazyQuery } from 'graphql/hooks.generated'
import { Action, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { CuidadoCompartilhadoItemActions, ListaCuidadoCompartilhadoFilterModel } from '../model-cuidadocompartilhado'
import ListaCuidadoCompartilhadoTable from './lista-cuidado-compartilhado-table/ListaCuidadoCompartilhadoTable'
import {
  ListaCuidadoCompartilhadoFilter,
  ListaCuidadoCompartilhadoInlineFilterModel,
} from './ListaCuidadoCompartilhadoFilter'

export interface CuidadoCompartilhadoAtmosphereResponse {
  idProfissionalSolicitante: string
  idEquipeSolicitante: string
  idProfissionalExecutante: string
  idEquipeExecutante: string
}

interface ListaCuidadoCompartilhadoViewProps {
  defaultSort: string[]
  filterSomenteOsMeusLabel: string
  filterToQueryInput(value: ListaCuidadoCompartilhadoFilterModel): CuidadosCompartilhadoQueryInput
  resolveDiscutirAction(actions: CuidadoCompartilhadoItemActions): Action
}

export const ListaCuidadoCompartilhadoView = (props: ListaCuidadoCompartilhadoViewProps) => {
  const { filterToQueryInput, filterSomenteOsMeusLabel, defaultSort, resolveDiscutirAction } = props
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()

  const [executeQuery, { data, loading }] = useListaCuidadoCompartilhadoLazyQuery()
  const [filter, setFilter] = useState<ListaCuidadoCompartilhadoFilterModel>({
    somenteOsMeus: true,
    pageParams: {
      size: 5,
      page: 0,
      sort: defaultSort,
    },
  })

  const debounceRefetch = debounce(() => {
    executeQuery({ variables: { input: filterToQueryInput(filter) } })
  }, 350)

  const onAtmosphereMessage = (response: CuidadoCompartilhadoAtmosphereResponse) => {
    const profissinalSolicitanteMatches = response.idProfissionalSolicitante === profissional.id
    const profissinalExecutanteMatches = response.idProfissionalExecutante === profissional.id
    const equipeFieldsNotNull = !!acesso.equipe && (!!response.idEquipeSolicitante || !!response.idEquipeExecutante)
    const equipeSolicitanteMatches = equipeFieldsNotNull && response.idEquipeSolicitante === acesso.equipe?.id
    const equipeExecutanteMatches = equipeFieldsNotNull && response.idEquipeExecutante === acesso.equipe?.id

    if (
      profissinalSolicitanteMatches ||
      profissinalExecutanteMatches ||
      equipeSolicitanteMatches ||
      equipeExecutanteMatches
    ) {
      debounceRefetch()
    }
  }

  useAtmosphere<CuidadoCompartilhadoAtmosphereResponse>({
    topic: `cuidadocompartilhado`,
    onMessage: onAtmosphereMessage,
  })

  const onFilterChange = (value: ListaCuidadoCompartilhadoInlineFilterModel) => {
    setFilter((oldValue: ListaCuidadoCompartilhadoFilterModel) => ({
      ...oldValue,
      somenteOsMeus: value.somenteOsMeus,
    }))
  }

  useEffect(() => {
    executeQuery({ variables: { input: filterToQueryInput(filter) } })
  }, [executeQuery, filter, filterToQueryInput])

  return (
    <VFlow vSpacing={0.5}>
      <ListaCuidadoCompartilhadoFilter
        onChange={onFilterChange}
        value={filter}
        filterSomenteOsMeusLabel={filterSomenteOsMeusLabel}
      />
      <ListaCuidadoCompartilhadoTable
        loading={loading}
        onPageChange={setFilter}
        queryPayload={data?.cuidadosCompartilhado}
        resolveDiscutirAction={resolveDiscutirAction}
      />
    </VFlow>
  )
}
