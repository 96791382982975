import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { LogEventType } from 'view/atendimentos/atendimento-individual/logFinalizarAtendimento'
import {
  DiscussaoCasoFormModel,
  DiscussaoCondutaViewEnum,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

export const logRegistrarDiscussao = (
  logEvent: LogEventType,
  values: DiscussaoCasoFormModel,
  responsabilidade: ResponsavelCuidadoCompartilhado
) => {
  if (responsabilidade === 'SOLICITANTE') {
    if (values.solicitanteFields.condutaGroup === DiscussaoCondutaViewEnum.NOVA_PERGUNTA) {
      logEvent('CC_conduta_nova_pergunta')
    }
  } else {
    if (values.executanteFields.conduta === CondutaCuidadoCompartilhadoEnum.PRESTAR_ESCLARECIMENTO) {
      logEvent('CC_conduta_pedido_esclarecimento')
    }

    if (values.executanteFields.conduta === CondutaCuidadoCompartilhadoEnum.OPINIAO_FORMATIVA) {
      logEvent('CC_conduta_opiniao_formativa')
    }

    if (values.executanteFields.conduta === CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO) {
      logEvent('CC_conduta_parecer_tecnico')
    }
  }
}
