import useSession from 'components/auth/useSession'
import { getSessionStorageValue } from 'hooks/useStorage'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { JustificativaVerProntuario } from './useJustificativaVerProntuarioId'

export function useRemoveJustificativaAcessoProntuarioOnNavigation() {
  const { data: { acesso } = {} } = useSession()

  const location = useLocation()

  useEffect(() => {
    if (acesso) {
      const key = `${acesso.id}/justificativa-ver-prontuario`
      const justificativa = getSessionStorageValue<JustificativaVerProntuario>(key)
      if (justificativa && !location.pathname.includes(justificativa.basePath)) {
        window.sessionStorage.removeItem(key)
      }
    }
  }, [acesso, location.pathname])
}
