/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Radio, Select, VFlow } from 'bold-ui'
import { PivotTableProps } from 'components/pivot-table/PivotTable'
import { Aggregator, aggregators } from 'components/pivot-table/types'
import React from 'react'

export type AggregatorsProps<T extends any> = {
  numberKeys: string[]
  keyMapping: PivotTableProps<T>['keyMapping']
  handleAggregatorChange: (aggregator: Aggregator) => void
  handleAggregatorKeyChange: (key: keyof T) => void
  aggregator: Aggregator
  aggregatorKey: keyof T
}

export function Aggregators<T extends any>(props: AggregatorsProps<T>) {
  const { numberKeys, keyMapping, handleAggregatorKeyChange, handleAggregatorChange, aggregator, aggregatorKey } = props

  const itemToString = (item: keyof T | null) => (item ? keyMapping.get(item).keyName || (item as string) : '')

  const handleKeySelect = (item: keyof T) => {
    handleAggregatorKeyChange(item)
  }

  const handleAggregatorSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const idx = Number(evt.target.value)
    handleAggregatorChange(aggregators[idx])
  }

  return (
    <VFlow>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        `}
      >
        {aggregators
          .filter((f) => (numberKeys.length === 0 ? !f.keyDependent : true))
          .map((f, idx) => (
            <div
              key={f.id}
              css={css`
                margin-bottom: 0.5rem;
                margin-right: 1rem;
              `}
            >
              <Radio
                name='aggregator'
                checked={aggregator.id === f.id}
                disabled={f.keyDependent && numberKeys.length === 0}
                label={f.label}
                value={idx}
                onChange={handleAggregatorSelect}
              />
            </div>
          ))}
      </div>
      {!['contagem', 'percentual'].includes(aggregator?.id) && (
        <Select<keyof T>
          disabled={numberKeys.length === 0}
          items={numberKeys as Array<keyof T>}
          itemToString={itemToString}
          value={aggregatorKey}
          onChange={handleKeySelect}
        />
      )}
    </VFlow>
  )
}
