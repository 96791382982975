import { SelectField, SelectFieldProps } from 'components/form'
import { ListaEsperaTipoAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoAtendimentoSelectModel {
  value: ListaEsperaTipoAtendimentoEnum
  nome: string
}

export interface TipoAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoAtendimentoSelectModel>, 'items' | 'itemToString'> {}

export function TipoAtendimentoSelectField(props: TipoAtendimentoSelectFieldProps) {
  const itemToString = (item: TipoAtendimentoSelectModel) => item && item.nome

  return (
    <SelectField<TipoAtendimentoSelectModel>
      items={Object.values(tipoAtendimentoRecord).sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...props}
    />
  )
}

export const tipoAtendimentoRecord: Record<ListaEsperaTipoAtendimentoEnum, TipoAtendimentoSelectModel> = {
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA,
    nome: 'Consulta médica',
  },
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA,
    nome: 'Consulta odontológica',
  },
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM,
    nome: 'Consulta de enfermagem',
  },
}
