/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormControl, HFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { useCidadaoEmailUpdateMutation } from 'graphql/hooks.generated'
import { FormProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, email, required } from 'util/validation'

interface EmailUpdateFormModel {
  email: string
}

const validate = createValidator<EmailUpdateFormModel>({
  email: [required, email],
})

const path = metaPath<EmailUpdateFormModel>()

interface EmailUpdateFormProps extends Omit<FormProps<EmailUpdateFormModel>, 'onSubmit' | 'validate' | 'render'> {
  cidadaoId: ID
  onSubmitSuccess?: (newValue: string) => void
}

export const EmailUpdateForm = (props: EmailUpdateFormProps) => {
  const { cidadaoId, onSubmitSuccess, ...rest } = props

  const [updateEmailMutation] = useCidadaoEmailUpdateMutation()
  const handleRejection = useErrorHandler()

  const handleAtualizarEmailClicked = ({ email }: EmailUpdateFormModel) =>
    updateEmailMutation({
      variables: {
        input: {
          cidadaoId,
          email,
        },
      },
    })
      .then(() => {
        onSubmitSuccess?.(email)
      })
      .catch(handleRejection)

  const renderForm = ({ handleSubmit }: FormRenderProps<EmailUpdateFormModel>) => {
    return (
      <HFlow hSpacing={0.5}>
        <TextField lowercase name={path.email} label='E-mail' maxLength={50} />
        <FormControl label={<span>&nbsp;</span>}>
          <SubmitButton kind='primary' size='small' handleSubmit={handleSubmit}>
            Atualizar
          </SubmitButton>
        </FormControl>
      </HFlow>
    )
  }

  return (
    <Form<EmailUpdateFormModel>
      onSubmit={handleAtualizarEmailClicked}
      validate={validate}
      render={renderForm}
      suppressNotificationError
      {...rest}
    />
  )
}
