import { simNaoEnumToBoolean } from 'components/form/field/SimNaoRadioGroupField'
import { EncerrarGestacaoInput, PreNatalInput } from 'graphql/types.generated'

import { EncerrarGestacaoFormModel, PreNatalFormModel } from './model'

export function convertPreNatalFormToInput(preNatalForm?: PreNatalFormModel): PreNatalInput {
  return (
    preNatalForm && {
      tipoGravidez: preNatalForm.tipoGravidez?.value,
      alturaUterina: preNatalForm.alturaUterina,
      edema: preNatalForm.edema,
      movimentacaoFetal: simNaoEnumToBoolean[preNatalForm.movimentacaoFetal],
      gravidezPlanejada: simNaoEnumToBoolean[preNatalForm.gravidezPlanejada],
      batimentoCardiacoFetal: preNatalForm.batimentoCardiacoFetal,
    }
  )
}

export function convertEncerrarGestacaoFormToInput(
  encerrarGestacaoForm?: EncerrarGestacaoFormModel
): EncerrarGestacaoInput {
  return (
    encerrarGestacaoForm && {
      tipoGravidez: encerrarGestacaoForm.tipoGravidez?.value,
      dataDesfecho: encerrarGestacaoForm.dataDesfecho,
    }
  )
}
