import { Icon, Theme, useStyles } from 'bold-ui'
import { useRovingTabIndex } from 'bold-ui/lib/hooks'
import CheckPermission from 'components/auth/CheckPermission'
import { AllAuthorizations, getAuthorizationProps } from 'components/auth/model-authorization'
import { useCheckPermissionAndCboAuthorization } from 'components/auth/useCheckModuleAuthorization'
import useSession from 'components/auth/useSession'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { SearchInput } from 'components/search/SearchInput'
import React, { CSSProperties, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { NavLinkProps } from 'react-router-dom'

import { filterSideMenuItems } from './SideMenuFilterItems'
import { SideMenuGroupTitle } from './SideMenuGroupTitle'
import { SideMenuItem } from './SideMenuItem'
import { SideMenuLink } from './SideMenuLink'
import { SideMenuList } from './SideMenuList'
import { SIDE_MENU_TRANSITION_DELAY } from './SideMenuNav'
import { SideMenuSubLink } from './SideMenuSubLink'

export type SideMenuItemConfigChildren = Array<{
  items: SideMenuSubItem[]
  group?: string
}>

export type SideMenuItemConfig = AllAuthorizations & {
  title: string
  icon: React.ReactNode
  acceptEstagio?: boolean
  to: NavLinkProps['to']
  children?: SideMenuItemConfigChildren
  logEventParameter?: string
}

export type SideMenuSubItem = AllAuthorizations &
  Pick<SideMenuItemConfig, 'title' | 'to' | 'logEventParameter' | 'acceptEstagio'>

export interface SideMenuProps {
  items: SideMenuItemConfig[]
  expanded: boolean
  visualExpanded: boolean // se a largura da side e maior que SIDE_MENU_WIDTH
  searchInputRef?: Ref<HTMLInputElement>
  onNavigate(): void
}

export function SideMenu(props: SideMenuProps) {
  const { expanded, visualExpanded, items, searchInputRef, onNavigate } = props

  const { isEstagio } = useSession()
  const [filter, setFilter] = useState('')
  const [expandedItems, setExpandedItems] = useState<Array<NavLinkProps['to']>>([])
  const checkPermissionAndCboAuthorization = useCheckPermissionAndCboAuthorization()

  const handleSearch = (filter: string) => {
    setFilter(filter)
    setExpandedItems(items.map((i) => i.to))
  }

  useEffect(() => {
    if (!expanded) {
      setFilter('')
      setExpandedItems([])
    }
  }, [expanded])

  const hasAuthorization = useCallback(
    (item: SideMenuSubItem) =>
      item && checkPermissionAndCboAuthorization(item) && (!isEstagio || item.acceptEstagio !== false),
    [checkPermissionAndCboAuthorization, isEstagio]
  )

  const filteredItems = useMemo<SideMenuItemConfig[]>(
    () =>
      filterSideMenuItems(items, filter).filter((item) =>
        item.children
          ? item.children.filter(
              (child) => child && child.items.filter((subItem) => hasAuthorization(subItem)).length > 0
            ).length > 0
          : true
      ),
    [items, filter, hasAuthorization]
  )

  const handleNavigate = () => {
    const activeElement = document.activeElement as HTMLElement
    activeElement.blur()
    onNavigate()
  }

  const handleExpandItem = (item: SideMenuItemConfig) => () => {
    if (!expandedItems.includes(item.to)) {
      setExpandedItems((currItems) => [...currItems, item.to])
    } else {
      setExpandedItems((currItems) => currItems.filter((i) => i !== item.to))
    }
  }

  const rootRef = useRovingTabIndex({
    getItems: (root) => Array.from(root.querySelectorAll('a')),
  })

  const { classes } = useStyles(createStyles, expanded, visualExpanded)

  return (
    <nav ref={rootRef} className={classes.nav}>
      <SideMenuList>
        <SideMenuItem data-cy='SideMenu.campoPesquisa'>
          {expanded && visualExpanded ? (
            <SearchInput inputRef={searchInputRef} placeholder='Pesquise por módulos' onSearch={handleSearch} />
          ) : (
            <span className={classes.searchIcon}>
              <Icon icon='zoomOutline' />
            </span>
          )}
        </SideMenuItem>
        {filteredItems.length === 0 && (
          <SideMenuItem className={classes.noResult}>Nenhum resultado encontrado</SideMenuItem>
        )}
        {filteredItems.map((item) => (
          <CheckPermission key={item.title} acceptEstagio={item.acceptEstagio} {...getAuthorizationProps(item)}>
            <SideMenuLink
              title={item.title}
              to={item.to}
              icon={item.icon}
              logEventParameter={item.logEventParameter}
              highlight={filter}
              details={expanded && visualExpanded}
              onNavigate={handleNavigate}
              expanded={expandedItems.includes(item.to)}
              onExpand={handleExpandItem(item)}
              data-cy={'SideMenu.' + item.title}
            >
              {item.children &&
                item.children
                  .filter((c) => !!c)
                  .map(
                    (group, groupIndex) =>
                      !!group.items.find((i) => hasAuthorization(i)) && (
                        <React.Fragment key={groupIndex}>
                          {group.group && (
                            <SideMenuGroupTitle>
                              <NormalizedHighlighter searchWords={filter} textToHighlight={group.group} />
                            </SideMenuGroupTitle>
                          )}
                          {group.items
                            .filter((i) => i)
                            .map((subitem) => (
                              <CheckPermission key={subitem.title} {...getAuthorizationProps(subitem)}>
                                <SideMenuSubLink
                                  key={subitem.title}
                                  title={subitem.title}
                                  to={subitem.to}
                                  logEventParameter={subitem.logEventParameter}
                                  highlight={filter}
                                  onNavigate={handleNavigate}
                                  data-cy={'SideMenu.' + subitem.title}
                                />
                              </CheckPermission>
                            ))}
                        </React.Fragment>
                      )
                  )}
            </SideMenuLink>
          </CheckPermission>
        ))}
      </SideMenuList>
    </nav>
  )
}

export const createStyles = (theme: Theme, expanded: boolean, visualExpanded: boolean) => ({
  nav: {
    background: theme.pallete.surface.main,
    borderRight: `1px solid ${theme.pallete.divider}`,
    transition: 'width .3s ease',
    transitionDelay: `${!expanded ? 0 : SIDE_MENU_TRANSITION_DELAY}ms`,
    padding: '1rem 0',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.shadows.outer[40],
    overflowX: visualExpanded ? 'auto' : 'hidden',
    overflowY: 'auto',
    height: '100%',

    '&::-webkit-scrollbar': {
      display: !expanded && 'none',
      width: '0.25rem',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.pallete.gray.c90,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1rem',
      backgroundColor: theme.pallete.gray.c70,
    },
  } as CSSProperties,
  searchIcon: {
    background: theme.pallete.gray.c90,
    border: `1px solid ${theme.pallete.divider}`,
    display: 'inline-flex',
    alignItems: 'center',
    padding: 'calc(0.25rem - 1px) calc(0.5rem - 1px)',
    marginLeft: '-5px', // consideramos a borda direita dos items ativos, para centralizar o ícone de pesquisa
    borderRadius: theme.radius.input,
  } as CSSProperties,
  noResult: {
    marginTop: '0.75rem',
    textAlign: 'center',
  } as CSSProperties,
})
