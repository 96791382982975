import css from '@emotion/css'
import { Cell, Grid, HFlow, Icon, InfoLabel, TableFooter, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { useListaEsperaTableLazyQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { upperFirst } from 'lodash'
import React, { Fragment, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { humanizeAge } from 'util/date/humanize-age'

import { tipoAtendimentoRecord } from './components/TipoAtendimentoSelectField'
import { convertListaEsperaFilterModelToInput } from './convert-garantiaAcesso'
import { ListaEsperaTableDropdownMenu } from './ListaEsperaTableDropdownMenu'
import { ListaEsperaFilterModel, ListaEsperaItem } from './model'

const PLACEHOLDER = '—'

type ListaEsperaTopicModel = { idUbs: number }

interface ListaEsperaTableProps {
  filter: ListaEsperaFilterModel
  onChangeFilter: (filter: ListaEsperaFilterModel) => void
}

const shouldExecuteQuery = (filter: ListaEsperaFilterModel) => {
  const hasValidNameCpfCnsQuery = filter.nomeCpfCns?.trim()?.length === 0 || filter.nomeCpfCns?.trim()?.length >= 3
  const otherFiltersEnabled = filter.equipes.length > 0 || filter.tiposAtendimento.length > 0

  return hasValidNameCpfCnsQuery || otherFiltersEnabled
}

export default function ListaEsperaTable(props: ListaEsperaTableProps) {
  const { filter, onChangeFilter } = props
  const match = useRouteMatch()
  const {
    acesso: { unidadeSaude },
    tipoEstabelecimento,
  } = useAcessoLotacaoOrEstagio()
  const [executeQuery, { data, loading, refetch }] = useListaEsperaTableLazyQuery({ fetchPolicy: 'cache-and-network' })
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  useEffect(() => {
    if (shouldExecuteQuery(filter)) {
      executeQuery({ variables: { input: convertListaEsperaFilterModelToInput(filter) } })
    }
  }, [executeQuery, filter])

  const listaEspera = data?.listaEsperaByUnidadeSaudeId

  useAtmosphere<ListaEsperaTopicModel>({
    topic: `listaespera/${unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetch(),
  })

  const qtdCidadaosListados = listaEspera?.pageInfo?.totalElements

  const tableProps = usePagedTableProps({
    loading,
    result: listaEspera,
    onChange: onChangeFilter,
  })

  const renderActions = (item: ListaEsperaItem) => (
    <Fragment>
      <HFlow hSpacing={0.5} justifyContent='flex-end'>
        <Tooltip text='Remover da lista'>
          <ButtonLink
            size='small'
            skin='ghost'
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            to={`${match.url}/remover-cidadao/${item.id}`}
          >
            <Icon icon='userTimes' />
          </ButtonLink>
        </Tooltip>
        <CheckPermission permission={Permissions.visualizarAgenda.agendar}>
          <Tooltip text='Agendar consulta'>
            <ButtonLink
              size='small'
              skin='ghost'
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              to={`${match.url}/agendar-consulta/${item.id}`}
            >
              <Icon icon='calendarOutline' />
            </ButtonLink>
          </Tooltip>
        </CheckPermission>
        <ListaEsperaTableDropdownMenu cidadao={item.cidadao} listaEsperaId={item.id} />
      </HFlow>
    </Fragment>
  )
  return (
    <VFlow
      style={css`
        padding-top: 1rem;
      `}
    >
      <Text fontWeight='bold' fontSize={1}>
        {'registro'.pluralizeAndConcatValue(qtdCidadaosListados || 0)} na lista de gestão da garantia do acesso
      </Text>
      <TableBox>
        <AccordionDataTable<ListaEsperaItem>
          loading={loading}
          columns={[
            {
              name: 'dataEntrada',
              header: 'Data',
              render: (item) => <DateTime format='DD/MM/YYYY' value={item.dataEntrada} />,
              sortable: true,
            },
            {
              name: 'cidadao',
              header: 'Cidadão',
              render: renderCidadao,
            },
            {
              name: 'telefone',
              header: 'Telefone',
              render: renderTelefone,
            },
            {
              name: 'equipe',
              header: 'Equipe',
              render: renderEquipe,
              hide: isCEO,
            },
            {
              name: 'tipoAtendimento',
              header: 'Tipo de atendimento',
              render: (item) => <Text>{tipoAtendimentoRecord[item.tipoAtendimento].nome}</Text>,
              hide: isCEO,
            },
            {
              name: 'tipoServico',
              header: 'Tipo de serviço',
              render: (item) => <Text>{upperFirst(item.tipoServico?.nome.toLowerCase()) || PLACEHOLDER}</Text>,
              hide: !isCEO,
            },
            {
              name: 'actions',
              render: renderActions,
            },
          ]}
          components={{ AccordionPanel: panelMotivoProcura }}
          {...tableProps}
        />
        <TableFooter {...tableProps} pageSize={filter.pageParams?.size} sizeOptions={[5, 15, 25, 50]} />
      </TableBox>
    </VFlow>
  )
}

const renderCidadao = (item: ListaEsperaItem) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{(item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()}</Text>
    <Text fontWeight='bold'>{humanizeAge(item.cidadao.dataNascimento)}</Text>
    {item.cidadao.cpf ? (
      <HLabel title='CPF'>
        <Cpf value={item.cidadao.cpf} />
      </HLabel>
    ) : item.cidadao.cns ? (
      <HLabel title='CNS'>{item.cidadao.cns}</HLabel>
    ) : (
      <Text>Cidadão sem CPF/CNS cadastrado</Text>
    )}
  </VFlow>
)

const renderTelefone = (item: ListaEsperaItem) => (
  <VFlow vSpacing={0.1}>
    <Telefone value={item.cidadao.telefoneContato} />
    <Telefone value={item.cidadao.telefoneCelular} />
    <Telefone value={item.cidadao.telefoneResidencial} />
  </VFlow>
)

const renderEquipe = (item: ListaEsperaItem) =>
  item.equipe ? (
    <VFlow vSpacing={0}>
      <InfoLabel title={item.equipe.nome} placeholder={PLACEHOLDER}>
        {item.equipe.area && <Text>{'Área ' + item.equipe.area}</Text>}
      </InfoLabel>
    </VFlow>
  ) : (
    <Text>Sem equipe</Text>
  )

const panelMotivoProcura = ({ row }) => {
  const vezesString = row.qtdCidadaoPresente > 1 ? 'vezes' : 'vez'
  return (
    <Grid
      gap={0}
      style={css`
        background: ${theme.pallete.gray.c90};
        padding: 1rem;
      `}
    >
      <Cell size={12}>
        <Tag
          type='normal'
          style={css`
            background: ${theme.pallete.gray.c40};
            color: white;
          `}
        >
          Presente {row.qtdCidadaoPresente} {vezesString} na lista
        </Tag>
      </Cell>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <InfoLabel title='Motivo da consulta' placeholder={PLACEHOLDER}>
            {row.motivoEspera}
          </InfoLabel>
        </VFlow>
      </Cell>
    </Grid>
  )
}
