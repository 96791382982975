import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { OrientacaoSexualEnum } from 'graphql/types.generated'
import React from 'react'

export interface OrientacaoSexualSelectModel {
  identificador: OrientacaoSexualEnum
  nome: string
}

export interface OrientacaoSexualSelectFieldProps
  extends Omit<SelectFieldProps<OrientacaoSexualSelectModel>, 'items' | 'itemToString'> {}

export function OrientacaoSexualSelectField(props: OrientacaoSexualSelectFieldProps) {
  const itemToString = (item: OrientacaoSexualSelectModel) => item && item.nome

  return <SelectField<OrientacaoSexualSelectModel> items={items} itemToString={itemToString} {...props} />
}

const items: OrientacaoSexualSelectModel[] = [
  { identificador: OrientacaoSexualEnum.HETEROSSEXUAL, nome: 'Heterossexual' },
  { identificador: OrientacaoSexualEnum.HOMOSSEXUAL, nome: 'Homossexual (gay / lésbica)' },
  { identificador: OrientacaoSexualEnum.BISSEXUAL, nome: 'Bissexual' },
  { identificador: OrientacaoSexualEnum.OUTRO_ORIENTACAO_SEXUAL, nome: 'Outro' },
]
