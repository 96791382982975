import React, { memo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoProcedimentoDw from './dw/HistoricoProcedimentoDw'
import HistoricoProcedimentoPec from './pec/HistoricoProcedimentoPec'

interface HistoricoProcedimentoPanelProps extends HistoricoPanelProps {}

function HistoricoProcedimentoPanel(props: HistoricoProcedimentoPanelProps) {
  const {
    isAtendRecente,
    idAtend,
    uuidAtend,
    cpfOuCnsCidadao,
    idadeOrigemAtendimento,
    dataNascimento,
    isAtendObservacao,
  } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  return isAtendRecente || atendimentoPecProcessado ? (
    <HistoricoProcedimentoPec
      idAtend={idAtend}
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      dataNascimento={dataNascimento}
      isAtendObservacao={isAtendObservacao}
    />
  ) : (
    <HistoricoProcedimentoDw
      uuidAtend={uuidAtend}
      cpfOuCnsCidadao={cpfOuCnsCidadao}
      idadeOrigemAtendimento={idadeOrigemAtendimento}
    />
  )
}

export default memo(HistoricoProcedimentoPanel)
