import { Alert, Link } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import React from 'react'

interface ExibindoSomenteNaoFinalizadosAlertProps {
  onClear(): void
}

export const ExibindoSomenteNaoFinalizadosAlert = ({ onClear }: ExibindoSomenteNaoFinalizadosAlertProps) => {
  const { analytics } = useFirebase()

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_msg_LA')
  }

  return (
    <Alert type='warning' style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
      Estão sendo exibidos somente os <strong>Atendimentos não finalizados</strong>. Para visualizar apenas os
      atendimentos atuais,{' '}
      <Link tabIndex={1} onClick={handleOnClear} style={{ fontSize: 'inherit' }}>
        reverta para o filtro padrão
      </Link>
      .
    </Alert>
  )
}
