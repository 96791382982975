import css from '@emotion/css'
import { PageParams } from 'api/schema'
import { HFlow, TableFooter, Tag, Text, VFlow } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { DateTime } from 'components/date'
import { TableBox, usePagedTableProps } from 'components/table'
import { differenceInHours } from 'date-fns'
import { Action, StatusCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Dispatch, SetStateAction } from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import {
  ClassificacaoRiscoCuidadoRecord,
  CuidadoCompartilhadoItem,
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoListingQueryPayload,
  StatusCuidadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import { ListaCuidadoCompartilhadoTableActions } from './ListaCuidadoCompartilhadoTableActions'
import { ListaCuidadoCompartilhadoTablePanel } from './ListaCuidadoCompartilhadoTablePanel'
import { ListaCuidadoCompartilhadoTableRow } from './ListaCuidadoCompartilhadoTableRow'

interface ListaCuidadoCompartilhadoTableProps {
  loading: boolean
  queryPayload?: CuidadoCompartilhadoListingQueryPayload
  onPageChange: Dispatch<SetStateAction<{ pageParams?: PageParams }>>
  resolveDiscutirAction(actions: CuidadoCompartilhadoItemActions): Action
}

export default function ListaCuidadoCompartilhadoTable(props: ListaCuidadoCompartilhadoTableProps) {
  const { loading, queryPayload, onPageChange, resolveDiscutirAction } = props

  const { getServerTimeNow } = useServerTime()

  const tableProps = usePagedTableProps({
    loading,
    result: queryPayload,
    onChange: onPageChange,
  })

  const renderActions = (item: CuidadoCompartilhadoItem) => (
    <ListaCuidadoCompartilhadoTableActions item={item} resolveDiscutirAction={resolveDiscutirAction} />
  )

  return (
    <TableBox>
      <AccordionDataTable<CuidadoCompartilhadoItem>
        loading={loading}
        columns={[
          {
            name: 'dataInicio',
            header: 'Data de início',
            render: (item) => renderDataInicio(item, getServerTimeNow()),
            size: 3,
            lg: 2,
          },
          {
            name: 'cidadao',
            header: 'Cidadão',
            render: renderCidadao,
            size: 2,
            xl: 2,
          },
          {
            name: 'prioridade',
            header: 'Prioridade',
            render: renderPrioridade,
            size: 2,
            xl: 1,
          },
          {
            name: 'solicitante',
            header: 'Solicitante',
            render: renderSolicitante,
            size: 2,
            xl: 3,
          },
          {
            name: 'executante',
            header: 'Executante',
            render: renderExecutante,
            size: 2,
            xl: 3,
          },
          {
            name: 'actions',
            render: renderActions,
            size: 1,
            lg: 2,
            xl: 1,
          },
        ]}
        components={{ AccordionPanel: ListaCuidadoCompartilhadoTablePanel, Row: ListaCuidadoCompartilhadoTableRow }}
        {...tableProps}
      />
      <TableFooter
        {...tableProps}
        pageSize={queryPayload?.pageInfo.size}
        sizeOptions={[5, 10, 30, 50]}
        style={styles.tableFooter}
      />
    </TableBox>
  )
}

const renderDataInicio = (item: CuidadoCompartilhadoItem, serverTime: Date) => (
  <VFlow vSpacing={0.25}>
    <DateTime format='DD/MM/YYYY - HH:mm' value={item.iniciadoEm} />
    {renderStatus(item, serverTime)}
  </VFlow>
)

const renderCidadao = (item: CuidadoCompartilhadoItem) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{(item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()}</Text>
    <Text>{humanizeAge(item.cidadao.dataNascimento)}</Text>
  </VFlow>
)

const renderPrioridade = (item: CuidadoCompartilhadoItem) => (
  <VFlow vSpacing={0}>
    <StatusSquare
      color={ClassificacaoRiscoCuidadoRecord[item.classificacaoRisco].cor}
      description={ClassificacaoRiscoCuidadoRecord[item.classificacaoRisco].descricao}
    />
  </VFlow>
)
const renderSolicitante = (item: CuidadoCompartilhadoItem) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{item.lotacaoSolicitante.profissional.nome.titleCase()}</Text>
    <Text>{item.lotacaoSolicitante.equipe?.nome}</Text>
    <Text>{item.lotacaoSolicitante.unidadeSaude.nome}</Text>
  </VFlow>
)

const renderExecutante = (item: CuidadoCompartilhadoItem) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{item.lotacaoExecutante.profissional.nome.titleCase()}</Text>
    <Text>{item.lotacaoExecutante.equipe?.nome}</Text>
    <Text>{item.lotacaoExecutante.unidadeSaude.nome}</Text>
  </VFlow>
)

const renderStatus = (item: CuidadoCompartilhadoItem, serverTime: Date) => {
  const statusConfig = StatusCuidadoRecord[item.status]

  return (
    <Tag icon={statusConfig.icon} style={styles.tag(statusConfig)}>
      <HFlow hSpacing={0.25}>
        <strong>{statusConfig.descricao}</strong>

        {item.status === StatusCuidadoCompartilhadoEnum.AGUARDANDO &&
          `há ${differenceInHours(serverTime, item.dataUltimaEvolucao)}h`}
      </HFlow>
    </Tag>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
  tag: (statusConfig: { descricao: string; cor?: ColorScale }) => css`
    background-color: ${statusConfig.cor.c90};
    color: ${statusConfig.cor.c40};
    font-weight: normal;
  `,
}
