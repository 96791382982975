/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'

import { getDentePosicaoProtese, getParteBucal, isDenteDeciduo } from '../../util'
import { condicoesDeProtese, OdontogramaSetActiveRef } from '../odontograma-field/model'
import { OdontogramaCell, OdontogramaCellProps } from './components/OdontogramaCell'
import { CoroaFaceEnum } from './Coroa'
import Dente11 from './dentes/Dente11'
import Dente12 from './dentes/Dente12'
import Dente13 from './dentes/Dente13'
import Dente14 from './dentes/Dente14'
import Dente15 from './dentes/Dente15'
import Dente16 from './dentes/Dente16'
import Dente17 from './dentes/Dente17'
import Dente18 from './dentes/Dente18'
import Dente21 from './dentes/Dente21'
import Dente22 from './dentes/Dente22'
import Dente23 from './dentes/Dente23'
import Dente24 from './dentes/Dente24'
import Dente25 from './dentes/Dente25'
import Dente26 from './dentes/Dente26'
import Dente27 from './dentes/Dente27'
import Dente28 from './dentes/Dente28'
import Dente31 from './dentes/Dente31'
import Dente32 from './dentes/Dente32'
import Dente33 from './dentes/Dente33'
import Dente34 from './dentes/Dente34'
import Dente35 from './dentes/Dente35'
import Dente36 from './dentes/Dente36'
import Dente37 from './dentes/Dente37'
import Dente38 from './dentes/Dente38'
import Dente41 from './dentes/Dente41'
import Dente42 from './dentes/Dente42'
import Dente43 from './dentes/Dente43'
import Dente44 from './dentes/Dente44'
import Dente45 from './dentes/Dente45'
import Dente46 from './dentes/Dente46'
import Dente47 from './dentes/Dente47'
import Dente48 from './dentes/Dente48'
import Dente51 from './dentes/Dente51'
import Dente52 from './dentes/Dente52'
import Dente53 from './dentes/Dente53'
import Dente54 from './dentes/Dente54'
import Dente55 from './dentes/Dente55'
import Dente61 from './dentes/Dente61'
import Dente62 from './dentes/Dente62'
import Dente63 from './dentes/Dente63'
import Dente64 from './dentes/Dente64'
import Dente65 from './dentes/Dente65'
import Dente71 from './dentes/Dente71'
import Dente72 from './dentes/Dente72'
import Dente73 from './dentes/Dente73'
import Dente74 from './dentes/Dente74'
import Dente75 from './dentes/Dente75'
import Dente81 from './dentes/Dente81'
import Dente82 from './dentes/Dente82'
import Dente83 from './dentes/Dente83'
import Dente84 from './dentes/Dente84'
import Dente85 from './dentes/Dente85'
import { CoroaFaceModel, FillColor } from './types/Coroa'
import { DenteModel, DenteProps } from './types/Dente'

export interface OdontogramaModel {
  dentes?: Record<ParteBucalEnum, DenteModel>
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
}

interface OdontogramaErrorModel {
  dentes?: Record<ParteBucalEnum, string>
}

export interface OdontogramaProps {
  activeDente: ParteBucalEnum
  fillColors?: FillColor<any>[]
  showDeciduos?: boolean
  disabled?: boolean
  disableStateEffects?: boolean
  disableCoroaStateEffects?: boolean
  disabledCoroas?: ParteBucalEnum[]
  value?: OdontogramaModel
  error?: OdontogramaErrorModel
  readonly?: boolean
  onClickDente?(dente: ParteBucalEnum, ref?: SVGSVGElement): void
  onClickCoroa?(dente: ParteBucalEnum, face: CoroaFaceModel, faceEnum: CoroaFaceEnum): void
  setActiveRef: OdontogramaSetActiveRef
}

export function Odontograma(props: OdontogramaProps) {
  const {
    fillColors,
    showDeciduos,
    activeDente,
    disabled,
    disableStateEffects,
    disableCoroaStateEffects,
    disabledCoroas,
    readonly,
    value,
    error,
    onClickDente,
    onClickCoroa,
    setActiveRef,
  } = props

  const classes = useMemo(() => createStyles(), [])

  const getDenteProps = (dente: ParteBucalEnum, protese?: boolean): DenteProps => ({
    value: value?.dentes && value.dentes[dente],
    disableStateEffects: disableStateEffects,
    active: activeDente === dente,
    protese:
      protese || condicoesDeProtese.some((item) => value.dentes && value.dentes[dente]?.condicoes?.includes(item)),
    placement: getDentePosicaoProtese(dente),
    isDeciduo: isDenteDeciduo(dente),
    disabled: disabled,
    readonly: readonly,
    error: error?.[dente],
    onClick: (ref) => onClickDente && onClickDente(dente, ref),
    setActiveRef,
  })

  const getOdontogramaCellProps = (
    dente: ParteBucalEnum,
    protese?: boolean
  ): Omit<OdontogramaCellProps, 'children'> => ({
    coroaValue: value?.dentes && value.dentes[dente]?.coroa,
    number: getParteBucal(dente).number,
    placement: getDentePosicaoProtese(dente),
    disableStateEffects: disableStateEffects || disableCoroaStateEffects,
    protese: protese,
    isDeciduo: isDenteDeciduo(dente),
    disabled: disabled,
    disableCoroa: disabledCoroas?.includes(dente),
    readonly: readonly,
    onClickCoroa: (face, faceEnum) => onClickCoroa && onClickCoroa(dente, face, faceEnum),
  })

  return (
    <VFlow>
      <div css={classes.container}>
        <div css={[classes.protese, classes.proteseSuperior]}>
          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_18, value?.proteseTotalSuperior)}>
            <Dente18 {...getDenteProps(ParteBucalEnum.DENTE_18, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_17, value?.proteseTotalSuperior)}>
            <Dente17 {...getDenteProps(ParteBucalEnum.DENTE_17, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_16, value?.proteseTotalSuperior)}>
            <Dente16 {...getDenteProps(ParteBucalEnum.DENTE_16, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_15, value?.proteseTotalSuperior)}>
            <Dente15 {...getDenteProps(ParteBucalEnum.DENTE_15, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_14, value?.proteseTotalSuperior)}>
            <Dente14 {...getDenteProps(ParteBucalEnum.DENTE_14, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_13, value?.proteseTotalSuperior)}>
            <Dente13 {...getDenteProps(ParteBucalEnum.DENTE_13, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_12, value?.proteseTotalSuperior)}>
            <Dente12 {...getDenteProps(ParteBucalEnum.DENTE_12, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_11, value?.proteseTotalSuperior)}>
            <Dente11 {...getDenteProps(ParteBucalEnum.DENTE_11, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_21, value?.proteseTotalSuperior)}>
            <Dente21 {...getDenteProps(ParteBucalEnum.DENTE_21, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_22, value?.proteseTotalSuperior)}>
            <Dente22 {...getDenteProps(ParteBucalEnum.DENTE_22, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_23, value?.proteseTotalSuperior)}>
            <Dente23 {...getDenteProps(ParteBucalEnum.DENTE_23, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_24, value?.proteseTotalSuperior)}>
            <Dente24 {...getDenteProps(ParteBucalEnum.DENTE_24, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_25, value?.proteseTotalSuperior)}>
            <Dente25 {...getDenteProps(ParteBucalEnum.DENTE_25, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_26, value?.proteseTotalSuperior)}>
            <Dente26 {...getDenteProps(ParteBucalEnum.DENTE_26, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_27, value?.proteseTotalSuperior)}>
            <Dente27 {...getDenteProps(ParteBucalEnum.DENTE_27, value?.proteseTotalSuperior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_28, value?.proteseTotalSuperior)}>
            <Dente28 {...getDenteProps(ParteBucalEnum.DENTE_28, value?.proteseTotalSuperior)} />
          </OdontogramaCell>
        </div>

        {showDeciduos && (
          <Fragment>
            <div css={[classes.protese, classes.proteseSuperior, classes.proteseDeciduos]}>
              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_55, value?.proteseTotalSuperior)}>
                <Dente55 {...getDenteProps(ParteBucalEnum.DENTE_55, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_54, value?.proteseTotalSuperior)}>
                <Dente54 {...getDenteProps(ParteBucalEnum.DENTE_54, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_53, value?.proteseTotalSuperior)}>
                <Dente53 {...getDenteProps(ParteBucalEnum.DENTE_53, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_52, value?.proteseTotalSuperior)}>
                <Dente52 {...getDenteProps(ParteBucalEnum.DENTE_52, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_51, value?.proteseTotalSuperior)}>
                <Dente51 {...getDenteProps(ParteBucalEnum.DENTE_51, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_61, value?.proteseTotalSuperior)}>
                <Dente61 {...getDenteProps(ParteBucalEnum.DENTE_61, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_62, value?.proteseTotalSuperior)}>
                <Dente62 {...getDenteProps(ParteBucalEnum.DENTE_62, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_63, value?.proteseTotalSuperior)}>
                <Dente63 {...getDenteProps(ParteBucalEnum.DENTE_63, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_64, value?.proteseTotalSuperior)}>
                <Dente64 {...getDenteProps(ParteBucalEnum.DENTE_64, value?.proteseTotalSuperior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_65, value?.proteseTotalSuperior)}>
                <Dente65 {...getDenteProps(ParteBucalEnum.DENTE_65, value?.proteseTotalSuperior)} />
              </OdontogramaCell>
            </div>

            <div css={[classes.protese, classes.proteseInferior, classes.proteseDeciduos]}>
              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_85, value?.proteseTotalInferior)}>
                <Dente85 {...getDenteProps(ParteBucalEnum.DENTE_85, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_84, value?.proteseTotalInferior)}>
                <Dente84 {...getDenteProps(ParteBucalEnum.DENTE_84, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_83, value?.proteseTotalInferior)}>
                <Dente83 {...getDenteProps(ParteBucalEnum.DENTE_83, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_82, value?.proteseTotalInferior)}>
                <Dente82 {...getDenteProps(ParteBucalEnum.DENTE_82, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_81, value?.proteseTotalInferior)}>
                <Dente81 {...getDenteProps(ParteBucalEnum.DENTE_81, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_71, value?.proteseTotalInferior)}>
                <Dente71 {...getDenteProps(ParteBucalEnum.DENTE_71, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_72, value?.proteseTotalInferior)}>
                <Dente72 {...getDenteProps(ParteBucalEnum.DENTE_72, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_73, value?.proteseTotalInferior)}>
                <Dente73 {...getDenteProps(ParteBucalEnum.DENTE_73, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_74, value?.proteseTotalInferior)}>
                <Dente74 {...getDenteProps(ParteBucalEnum.DENTE_74, value?.proteseTotalInferior)} />
              </OdontogramaCell>

              <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_75, value?.proteseTotalInferior)}>
                <Dente75 {...getDenteProps(ParteBucalEnum.DENTE_75, value?.proteseTotalInferior)} />
              </OdontogramaCell>
            </div>
          </Fragment>
        )}

        <div css={[classes.protese, classes.proteseInferior]}>
          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_48, value?.proteseTotalInferior)}>
            <Dente48 {...getDenteProps(ParteBucalEnum.DENTE_48, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_47, value?.proteseTotalInferior)}>
            <Dente47 {...getDenteProps(ParteBucalEnum.DENTE_47, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_46, value?.proteseTotalInferior)}>
            <Dente46 {...getDenteProps(ParteBucalEnum.DENTE_46, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_45, value?.proteseTotalInferior)}>
            <Dente45 {...getDenteProps(ParteBucalEnum.DENTE_45, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_44, value?.proteseTotalInferior)}>
            <Dente44 {...getDenteProps(ParteBucalEnum.DENTE_44, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_43, value?.proteseTotalInferior)}>
            <Dente43 {...getDenteProps(ParteBucalEnum.DENTE_43, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_42, value?.proteseTotalInferior)}>
            <Dente42 {...getDenteProps(ParteBucalEnum.DENTE_42, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_41, value?.proteseTotalInferior)}>
            <Dente41 {...getDenteProps(ParteBucalEnum.DENTE_41, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_31, value?.proteseTotalInferior)}>
            <Dente31 {...getDenteProps(ParteBucalEnum.DENTE_31, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_32, value?.proteseTotalInferior)}>
            <Dente32 {...getDenteProps(ParteBucalEnum.DENTE_32, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_33, value?.proteseTotalInferior)}>
            <Dente33 {...getDenteProps(ParteBucalEnum.DENTE_33, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_34, value?.proteseTotalInferior)}>
            <Dente34 {...getDenteProps(ParteBucalEnum.DENTE_34, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_35, value?.proteseTotalInferior)}>
            <Dente35 {...getDenteProps(ParteBucalEnum.DENTE_35, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_36, value?.proteseTotalInferior)}>
            <Dente36 {...getDenteProps(ParteBucalEnum.DENTE_36, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_37, value?.proteseTotalInferior)}>
            <Dente37 {...getDenteProps(ParteBucalEnum.DENTE_37, value?.proteseTotalInferior)} />
          </OdontogramaCell>

          <OdontogramaCell {...getOdontogramaCellProps(ParteBucalEnum.DENTE_38, value?.proteseTotalInferior)}>
            <Dente38 {...getDenteProps(ParteBucalEnum.DENTE_38, value?.proteseTotalInferior)} />
          </OdontogramaCell>
        </div>
      </div>

      {fillColors && fillColors.length > 0 && (
        <HFlow
          hSpacing={1.5}
          style={css`
            padding: 0 0.5rem;
          `}
        >
          {fillColors.map((fillColor, key) => (
            <HFlow hSpacing={0.5} alignItems='center' key={key}>
              <div css={css(classes.subtitleSquare)} style={{ background: fillColor.color }} />

              <Text>{fillColor.title}</Text>
            </HFlow>
          ))}
        </HFlow>
      )}
    </VFlow>
  )
}

const createStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  `,
  protese: css`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  `,
  proteseSuperior: css`
    width: 100%;
    align-items: flex-end;
    margin-bottom: 1rem;
  `,
  proteseInferior: css`
    width: 100%;
    align-items: flex-start;
    margin-top: 1rem;
  `,
  proteseDeciduos: css`
    justify-content: center;
  `,
  subtitleSquare: css`
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
  `,
})
