import { VFlow } from 'bold-ui'
import React from 'react'
import { DiscussaoCasoPageContent } from 'view/cuidados-compartilhados/components/DiscussaoCasoPageContent'
import {
  CidadaoCuidadoCompartilhado,
  CuidadoCompartilhadoModel,
  DiscussaoCasoFormModel,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import { CuidadoCompartilhadoPanel } from '../components/CuidadoCompartilhadoPanel'
import { DiscussaoCasoForm } from './DiscussaoCasoForm'

interface DiscussaoCasoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  cidadao: CidadaoCuidadoCompartilhado
  headerHeight: number
  responsavelCuidado: ResponsavelCuidadoCompartilhado
  initialValues: DiscussaoCasoFormModel
  handleFormChange: (values: DiscussaoCasoFormModel) => void
  handleSubmit: (values: DiscussaoCasoFormModel) => Promise<void>
  handleCancel: () => void
}

export const DiscussaoCasoView = (props: DiscussaoCasoViewProps) => {
  const {
    cuidadoCompartilhado,
    cidadao,
    headerHeight,
    responsavelCuidado,
    initialValues,
    handleFormChange,
    handleCancel,
    handleSubmit,
  } = props

  return (
    <DiscussaoCasoPageContent cuidadoCompartilhado={cuidadoCompartilhado} cidadao={cidadao} headerHeight={headerHeight}>
      <VFlow>
        <CuidadoCompartilhadoPanel cuidadoCompartilhado={cuidadoCompartilhado} />

        <DiscussaoCasoForm
          cuidadoCompartilhadoId={cuidadoCompartilhado.id}
          responsavelCuidado={responsavelCuidado}
          initialValues={initialValues}
          handleFormChange={handleFormChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </VFlow>
    </DiscussaoCasoPageContent>
  )
}
