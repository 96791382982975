/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectHelperMenuItem, Spinner, useLocale, useTheme } from 'bold-ui'
import { CidadaoSelectFieldItem } from 'components/form/field/select/CidadaoSelectField/CidadaoSelectFieldItem'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { CidadaoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoSelectFieldQuery,
  CidadaoAtendimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import { useHistory } from 'react-router'

import { SelectFieldWithAddButton } from '../../../final-form/SelectField/SelectFieldWithAddButton'

export interface CidadaoAtendimentoSelectFieldProps extends AsyncSelectFieldProps<CidadaoAtendimentoSelectFieldModel> {
  ativo?: boolean
  obito?: boolean
  verificarPresenteNaListaAtendimento?: boolean
  addCidadaoCallbackUrl: string
  retrieveContato?: boolean
}

export type CidadaoAtendimentoSelectFieldModel = {
  id: string
  nome: string
  nomeSocial?: string
  cpf?: string
  cns?: string
  nomeMae?: string
  dataNascimento?: any
  presenteListaAtendimento?: boolean
  contato?: {
    telefoneCelular?: string
    email?: string
  }
}

export function CidadaoAtendimentoSelectField(props: CidadaoAtendimentoSelectFieldProps) {
  const history = useHistory()
  const {
    ativo,
    obito,
    verificarPresenteNaListaAtendimento = true,
    addCidadaoCallbackUrl,
    retrieveContato = false,
    ...rest
  } = props
  const theme = useTheme()

  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoSelectFieldQuery,
    CidadaoAtendimentoSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosCombo,
    variables: (inputString) => ({
      input: {
        query: inputString,
        ativo: ativo,
        obito: obito,
        limit: DEFAULT_SELECT_SIZE,
      },
      emAtencaoDomiciliar: false,
      retrieveContato,
    }),
    skip: (inputString) => inputString?.trim()?.length < 3,
    debounceTime: 500,
  })

  const isPresente = (cidadao: CidadaoAtendimentoSelectFieldModel) => cidadao.presenteListaAtendimento

  const itemToString = (item: CidadaoAtendimentoSelectFieldModel) => item?.nomeSocial || item?.nome

  const renderItem = (item: CidadaoAtendimentoSelectFieldModel) => {
    return item && <CidadaoSelectFieldItem item={item} />
  }

  const onClickCadastrarButton = () => {
    return history.push(`/cidadao?callbackUrl=${addCidadaoCallbackUrl}`)
  }

  return (
    <SelectFieldWithAddButton<CidadaoAtendimentoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      itemIsEqual={verificarPresenteNaListaAtendimento && isPresente}
      menuMinWidth={500}
      popperProps={{ modifiers: [{ name: 'preventOverflow', enabled: false }] }}
      addText='Cadastre um cidadão somente se não o encontrar na busca.'
      addButtonText='Cadastrar cidadão'
      onClickAddButton={onClickCadastrarButton}
      placeholder='Pesquise pelo CPF ou CNS.'
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para pesquisar por um cidadão.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
        LoadingItem: () => (
          <SelectHelperMenuItem>
            Pesquise pelo CPF/CNS para ter resultados mais rápido.
            <Spinner
              style={css`
                color: ${theme.pallete.primary.main};
                float: right;
              `}
            />
          </SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}
