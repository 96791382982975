import { AlergiaSelectFieldModel } from 'components/form/field/select/AlergiaSelectField/AlergiaSelectField'
import { parseISO } from 'date-fns'
import { CategoriaSubstanciaAlergia, CategoriaSubstanciaAlergiaLegado } from 'graphql/types.generated'
import { toString } from 'lodash'

import { convertDataProblema } from '../problemas-condicoes/converter'
import { AlergiaReacaoModel } from './model'

export const convertAlergiaReacao = (input: AlergiaSelectFieldModel, dataNascimentoCidadao: LocalDate) => {
  const coSubstanciaEspecifica = toString(input?.substanciaEspecifica?.coSubstanciaEspecifica)
  return {
    alergiaId: input?.id,
    tipoReacao: input?.tipoReacao,
    categoriaSubstancia:
      input?.substanciaEspecifica?.categoria ??
      (input?.categoriaSubstanciaEspecificaLegado === CategoriaSubstanciaAlergiaLegado.ALIMENTO
        ? CategoriaSubstanciaAlergia.ALIMENTO
        : undefined),
    substanciaCbara: (input?.substanciaEspecifica?.categoria === CategoriaSubstanciaAlergia.ALIMENTO ||
      input?.substanciaEspecifica?.categoria === CategoriaSubstanciaAlergia.AMBIENTE) && {
      id: coSubstanciaEspecifica,
      nome: input?.substanciaEspecifica?.nome,
    },
    substanciaImunobiologico: input?.substanciaEspecifica?.categoria === CategoriaSubstanciaAlergia.BIOLOGICO && {
      id: coSubstanciaEspecifica,
      nome: input?.substanciaEspecifica?.nome,
      sigla: input?.substanciaEspecifica?.sigla,
    },
    substanciaMedicamento: input?.substanciaEspecifica?.categoria === CategoriaSubstanciaAlergia.MEDICAMENTO && {
      id: coSubstanciaEspecifica,
      medicamento: {
        id: coSubstanciaEspecifica,
        principioAtivo: input?.substanciaEspecifica?.nome,
      },
    },
    criticidade: input?.criticidade,
    grauCerteza: input?.grauCerteza,
    manifestacoes: input?.ultimaEvolucao?.manifestacoes,
    manifestacoesAlergiaLegado: input?.ultimaEvolucao?.manifestacoesLegado,
    categoriaSubstanciaEspecificaLegado: input?.categoriaSubstanciaEspecificaLegado,
    substanciaEspecificaLegado: input?.substanciaEspecificaLegado,

    dataInicio: convertDataProblema(input?.ultimaEvolucao?.dataInicio, parseISO(dataNascimentoCidadao)),
  } as AlergiaReacaoModel
}
