import { blue, gray, orange, pink, purple, turquoise } from 'bold-ui/lib/styles/colors'
import { BairroSelectFieldModel, TipoLogradouroSelectModel } from 'components/form'
import { PeriodoUltimaAtualizacaoSelectModel } from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import { PeriodoUltimaVisitaSelectModel } from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { StatusCadastroSelectModel } from 'components/form/field/select/StatusCadastroSelectField'
import { VisualizarCidadaosImovelQueryResult } from 'graphql/hooks.generated'
import { PageParams, TerritorioMicroareaQuery, TipoLogradouro } from 'graphql/types.generated'
import { VisualizarCidadaosImovelQuery } from 'graphql/types.generated'
import React from 'react'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'

import { ReactComponent as AbrigoIcone } from '../../images/visualizacao-territorial/abrigo-icone.svg'
import { ReactComponent as ApartamentoIcone } from '../../images/visualizacao-territorial/apartamento-icone.svg'
import { ReactComponent as CasaIcone } from '../../images/visualizacao-territorial/casa-icone.svg'
import { ReactComponent as DomicilioIcone } from '../../images/visualizacao-territorial/casa-icone.svg'
import { ReactComponent as ComercioIcone } from '../../images/visualizacao-territorial/comercio-icone.svg'
import { ReactComponent as CrecheIcone } from '../../images/visualizacao-territorial/creche-icone.svg'
import { ReactComponent as DelegaciaIcone } from '../../images/visualizacao-territorial/delegacia-icone.svg'
import { ReactComponent as EscolaIcone } from '../../images/visualizacao-territorial/escola-icone.svg'
import { ReactComponent as EstabelecimentoReligiosoIcone } from '../../images/visualizacao-territorial/estabelecimento-religioso-icone.svg'
import { ReactComponent as InstituicaoLongaPermanenciaIcone } from '../../images/visualizacao-territorial/longa-permanencia-idosos-icone.svg'
import { ReactComponent as OutrosImoveisIcone } from '../../images/visualizacao-territorial/outros-imoveis.svg'
import { ReactComponent as PontoEstrategicoIcone } from '../../images/visualizacao-territorial/ponto-estrategico-icone.svg'
import { ReactComponent as TerrenoBaldioIcone } from '../../images/visualizacao-territorial/terreno-baldio-icone.svg'
import { ReactComponent as UnidadePrisionalIcone } from '../../images/visualizacao-territorial/unidade-prisional-icone.svg'
import { ReactComponent as UnidadeSocioEducativaIcone } from '../../images/visualizacao-territorial/unidade-socioeducativa-icone.svg'

export interface VisualizacaoTerritorialBuscaFormModel {
  cnes: string
  ine: string
  bairro?: string
  cep?: string
  tipoLogradouro?: TipoLogradouroSelectModel
  logradouro?: string
}
export interface VisualizacaoTerritorialCacheModel {
  visualizacaoFilter: VisualizacaoFilterModel
  currentMicroareaTab: string
  modalImovelId: ID
  expandedItems: ID[]
  pageParams: PageParams
}
export interface VisualizacaoTerritorialMicroareasFilterModel {
  isCadastroAtualizadoMenosDeUmAno?: PeriodoUltimaAtualizacaoSelectModel
  isCadastroCompleto?: StatusCadastroSelectModel
  isImovelVisitadoMenosDeUmMes?: PeriodoUltimaVisitaSelectModel
}

export interface VisualizacaoMicroareasFilterModel {
  isCadastroAtualizadoMenosDeUmAno?: boolean
  isCadastroCompleto?: boolean
  isImovelVisitadoMenosDeUmMes?: boolean
}

export interface VisualizacaoFilterModel {
  territorioEquipeFilter: VisualizacaoTerritorialBuscaFormModel
  territorioMicroareasFilter?: VisualizacaoMicroareasFilterModel
}

export type TerritorioMicroareaQueryResult = TerritorioMicroareaQuery['territorioMicroarea']
export type TerritorioMicroareaItemModel = TerritorioMicroareaQueryResult['logradouros']['content'][0]

export interface TipoImovelListModel {
  IconImovel: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  fill: string
  tooltip: string
  tipoResponsavel: TipoResponsavelEnum
}

export interface TerritorioModel {
  cnes: string
  microarea: string
  ine: string
  cep?: string
  isCadastroAtualizadoMenosDeUmAno?: boolean
  isCadastroCompleto?: boolean
  isImovelVisitadoMenosDeUmMes?: boolean
}

export type FamiliaVisualizacaoImovel = VisualizarCidadaosImovelQuery['imovel']['familias'][number]

export type CidadaoVisualizacaoImovel = VisualizarCidadaosImovelQuery['imovel']['familias'][number]['cidadaos'][number]

export interface VisualizacaoTerritorioInput extends Omit<VisualizacaoTerritorialBuscaFormModel, 'tipoLogradouro'> {
  tipoLogradouroId?: ID
}

export type TipoDomicilioTag = 'Casa' | 'Apartamento' | 'Cômodo' | 'Outro'

export type TipoLocalizacaoTag = 'Urbana' | 'Rural'

export interface ResponsavelTecnico {
  id: ID
  nome: string
  contato?: string
  cns?: string
  cargo?: string
}
export interface ImovelModel {
  imovelId: ID
  uuidDomicilio: ID
  tipoCdsImovelId: ID
  ultimaFichaCadastrada?: {
    id: ID
    isProcessado: StatusFichaEnum
  }
  ultimaFichaProcessada?: {
    tipoDomicilioId?: ID
    numeroMoradores?: number
  }
  cep: string
  complemento?: string
  nomeResponsavelTecnico?: string
  numero: string
  familias: FamiliasTerritorio[]
  dataUltimaVisita: number
  logradouro: string
  bairro: string
  nomeTipoLogradouro: string
  dataUltimaAtualizacao?: number
  isCadastroCompleto?: boolean
}

export enum TipoDomicilioEnum {
  CASA = '85',
  APARTAMENTO = '86',
  COMODO = '87',
  OUTRO = '88',
}

export enum TipoImovelEnum {
  DOMICILIO = '1',
  COMERCIO = '2',
  TERRENO_BALDIO = '3',
  PONTO_ESTRATEGICO = '4',
  ESCOLA = '5',
  CRECHE = '6',
  ABRIGO = '7',
  INST_LONGA_PERMANENCIA_IDOSOS = '8',
  UNIDADE_PRISIONAL = '9',
  UNIDADE_SOCIOEDUCATIVA = '10',
  DELEGACIA = '11',
  ESTABELECIMENTO_RELIGIOSO = '12',
  OUTROS = '99',
}

export enum TipoLocalizacaoEnum {
  URBANA = 83,
  RURAL = 84,
}

export enum TipoResponsavelEnum {
  FAMILIAR = 1,
  TECNICO = 2,
  SEM_RESPONSAVEL = 3,
}

export enum StatusFichaEnum {
  NAO_ENVIADA = 'NAO_ENVIADA',
  ENVIADA = 'ENVIADA',
  RASCUNHO = 'RASCUNHO',
}

export enum TipoInconsistenciaEnum {
  RESPONSAVEL_SEM_CADASTRO_INDIVIDUAL = 1,
  RESPONSAVEL_NAO_DECLARADO = 2,
  RESPONSAVEL_OUTRO_DOMICILIO = 3,
  RESPONSAVEL_MUDOUSE = 4,
  RESPONSAVEL_OBITO = 5,
}

export const tipoImovelRecord: Record<TipoImovelEnum, TipoImovelListModel> = {
  [TipoImovelEnum.DOMICILIO]: {
    IconImovel: DomicilioIcone,
    fill: blue.c60,
    tooltip: 'Domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoImovelEnum.COMERCIO]: {
    IconImovel: ComercioIcone,
    fill: orange.c60,
    tooltip: 'Comércio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.TERRENO_BALDIO]: {
    IconImovel: TerrenoBaldioIcone,
    fill: purple.c40,
    tooltip: 'Terreno baldio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.PONTO_ESTRATEGICO]: {
    IconImovel: PontoEstrategicoIcone,
    fill: orange.c60,
    tooltip: 'Ponto estratégico',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.ESCOLA]: {
    IconImovel: EscolaIcone,
    fill: turquoise.c60,
    tooltip: 'Escola',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.CRECHE]: {
    IconImovel: CrecheIcone,
    fill: turquoise.c60,
    tooltip: 'Creche',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.ABRIGO]: {
    IconImovel: AbrigoIcone,
    fill: pink.c40,
    tooltip: 'Abrigo',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.INST_LONGA_PERMANENCIA_IDOSOS]: {
    IconImovel: InstituicaoLongaPermanenciaIcone,
    fill: pink.c40,
    tooltip: 'Inst. Longa permanência para idosos',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.UNIDADE_PRISIONAL]: {
    IconImovel: UnidadePrisionalIcone,
    fill: blue.c20,
    tooltip: 'Unidade prisional',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.UNIDADE_SOCIOEDUCATIVA]: {
    IconImovel: UnidadeSocioEducativaIcone,
    fill: blue.c20,
    tooltip: 'Unidade socioeducativa',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.DELEGACIA]: {
    IconImovel: DelegaciaIcone,
    fill: blue.c20,
    tooltip: 'Delegacia',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.ESTABELECIMENTO_RELIGIOSO]: {
    IconImovel: EstabelecimentoReligiosoIcone,
    fill: orange.c60,
    tooltip: 'Estabelecimento religioso',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.OUTROS]: {
    IconImovel: OutrosImoveisIcone,
    fill: gray.c20,
    tooltip: 'Outros imóveis',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
}

export const domicilioTipoImovelRecord: Record<TipoDomicilioEnum, TipoImovelListModel> = {
  [TipoDomicilioEnum.APARTAMENTO]: {
    IconImovel: ApartamentoIcone,
    fill: blue.c60,
    tooltip: 'Apartamento',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.CASA]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.COMODO]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.OUTRO]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
}

export const domicilioTipoRecord: Record<TipoDomicilioEnum, TipoDomicilioTag> = {
  [TipoDomicilioEnum.CASA]: 'Casa',
  [TipoDomicilioEnum.APARTAMENTO]: 'Apartamento',
  [TipoDomicilioEnum.COMODO]: 'Cômodo',
  [TipoDomicilioEnum.OUTRO]: 'Outro',
}

export const localizacaoTipoRecord: Record<TipoLocalizacaoEnum, TipoLocalizacaoTag> = {
  [TipoLocalizacaoEnum.URBANA]: 'Urbana',
  [TipoLocalizacaoEnum.RURAL]: 'Rural',
}

export const IMOVEIS_COM_RESPONSAVEL_TECNICO: string[] = [
  TipoImovelEnum.ABRIGO,
  TipoImovelEnum.INST_LONGA_PERMANENCIA_IDOSOS,
  TipoImovelEnum.UNIDADE_PRISIONAL,
  TipoImovelEnum.UNIDADE_SOCIOEDUCATIVA,
  TipoImovelEnum.DELEGACIA,
]

export type SaveVisualizacaoCacheState = (modalImovelId: ID) => void

export type EditarAtualizarImovelCallback = (
  isUltimaFichaCadastradaProcessada: boolean,
  uuidOrigemImovel: ID,
  ultimaFichaCadastradaId: ID,
  imovelId: ID
) => void

export interface LogradouroEditFormModel {
  id: ID
  tipoLogradouro: TipoLogradouro
  logradouro: string
  bairro: BairroSelectFieldModel
  cep: string
}

export type EditarAtualizarCidadaoCallback = (
  isUltimaFichaCadastradaProcessada: boolean,
  uuidCidadao: ID,
  ultimaFichaCadastradaId: ID,
  imovelId: ID
) => void

export interface InconsistenciaImovelModel {
  titulo: string
  sugestao: string
}

export const inconsistenciasRecord: Record<TipoInconsistenciaEnum, InconsistenciaImovelModel> = {
  [TipoInconsistenciaEnum.RESPONSAVEL_SEM_CADASTRO_INDIVIDUAL]: {
    titulo: 'Responsável sem cadastro individual no território',
    sugestao:
      'A família é reativada assim que uma ficha de cadastro individual com o CPF ou CNS do responsável for cadastrada.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_NAO_DECLARADO]: {
    titulo: 'Responsável não declarado no cadastro individual',
    sugestao:
      'A família é reativada assim que a ficha de cadastro individual do responsável passar a registrar "Sim" no campo de "Responsável familiar".',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_OUTRO_DOMICILIO]: {
    titulo: 'Responsável em outro domicílio',
    sugestao:
      'Será necessário atualizar os domicílios, marcando a opção "Mudou-se" no grupo "Famílias", até que o responsável esteja cadastrado em apenas um domicílio.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_MUDOUSE]: {
    titulo: 'Responsável com mudança de território',
    sugestao:
      'É necessário informar o "CPF / CNS do responsável familiar" cujo cadastro individual não possua a informação da mudança de território.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_OBITO]: {
    titulo: 'Responsável com óbito no cadastro individual',
    sugestao:
      'É necessário informar o "CPF / CNS do responsável familiar" cujo cadastro individual não possua a informação do óbito.',
  },
}

export type FamiliaInconsistenciaModel = VisualizarCidadaosImovelQueryResult['data']['imovel']['familias'][0]

export interface AccordionItemHeaderProps {
  item: TerritorioMicroareaItemModel
  onEdit(): void
  processingLogradouroId: ID
  onCompleteProcessing(): void
  progressToken: string
}
