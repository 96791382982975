/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Alert, Cell, Grid, Heading, HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoVisitaDomiciliarQuery } from 'graphql/hooks.generated'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { mountInfosAdministrativas } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoDetailTable from '../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../HistoricoNoDetailAvailable'

interface HistoricoVisitaDomiciliarPanelProps extends HistoricoDwPanelProps {}

function HistoricoVisitaDomiciliarPanel({
  uuidAtend,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoVisitaDomiciliarPanelProps) {
  const {
    data: { historicoVisitaDomiciliar: detalhamento },
    loading,
  } = useHistoricoVisitaDomiciliarQuery({
    variables: {
      input: {
        uuidAtend,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalhamento) return <HistoricoNoDetailAvailable />

  const hasMedicao: boolean =
    !isEmpty(detalhamento.peso) ||
    !isEmpty(detalhamento.altura) ||
    !isEmpty(detalhamento.temperatura) ||
    !isEmpty(detalhamento.medicaoGlicemia) ||
    !isEmpty(detalhamento.medicaoPressaoArterial) ||
    !isEmpty(detalhamento.dimensaoTipoGlicemia)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativas(detalhamento)}
    >
      {detalhamento.stVisitaCompartilhada && (
        <Alert type='info' inline>
          <Text color='primary' fontWeight='bold'>
            Visita compartilhada com outro profissional
          </Text>
        </Alert>
      )}
      <HistoricoDetailTable title='Motivo da visita' sectionStyle='I'>
        <VerticalWrapper>
          {!isEmpty(detalhamento.motivoVisita) && <Text>{stringGrouper(...detalhamento.motivoVisita)}</Text>}
          {!isEmpty(detalhamento.buscaAtiva) && (
            <InfoLabel title='Busca ativa'>{stringGrouper(...detalhamento.buscaAtiva)}</InfoLabel>
          )}
          {!isEmpty(detalhamento.acompanhamento) && (
            <InfoLabel title='Acompanhamento'>{stringGrouper(...detalhamento.acompanhamento)}</InfoLabel>
          )}
          {!isEmpty(detalhamento.controleAmbiental) && (
            <InfoLabel title='Controle ambiental/vetorial'>
              {stringGrouper(...detalhamento.controleAmbiental)}
            </InfoLabel>
          )}
        </VerticalWrapper>
      </HistoricoDetailTable>
      <VFlow vSpacing={0.5}>
        {hasMedicao && (
          <HistoricoDetailTable title='Antropometria, sinais vitais e glicemia capilar' sectionStyle='I'>
            <Grid>
              {detalhamento.peso && (
                <Cell size={2}>
                  <InfoLabel title='Peso'>{detalhamento.peso} kg</InfoLabel>
                </Cell>
              )}
              {detalhamento.altura && (
                <Cell size={2}>
                  <InfoLabel title='Altura'>{detalhamento.altura} cm</InfoLabel>
                </Cell>
              )}
              {detalhamento.temperatura && (
                <Cell size={2}>
                  <InfoLabel title='Temperatura'>{detalhamento.temperatura} ºC</InfoLabel>
                </Cell>
              )}
              {detalhamento.medicaoPressaoArterial && (
                <Cell size={2}>
                  <InfoLabel title='Pressão arterial'>{detalhamento.medicaoPressaoArterial} mmHg</InfoLabel>
                </Cell>
              )}
              {detalhamento.medicaoGlicemia && (
                <Cell size={4}>
                  <HFlow>
                    <InfoLabel title='Glicemia capilar'>{detalhamento.medicaoGlicemia} mg/dL</InfoLabel>
                    <Text>(Momento da coleta {detalhamento.dimensaoTipoGlicemia.descricao})</Text>
                  </HFlow>
                </Cell>
              )}
            </Grid>
          </HistoricoDetailTable>
        )}
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Desfecho</Heading>
        <Text>{detalhamento.dimensaoDesfechoVisita.descricao}</Text>
      </VFlow>
    </HistoricoGapPanel>
  )
}

// TODO (RoyalFlush): Até arrumar o VFlow no bold
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    display: inline-block;
    margin: 0.25rem;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  & > :first-child {
    margin-top: 0;
  }
  &:empty {
    display: none;
  }
`

export default memo(HistoricoVisitaDomiciliarPanel)
