import firebase from 'firebase'

import { METRICS_ENABLED } from './util'

const firebaseConfigProd = {
  apiKey: 'AIzaSyCNyZ7P83KYCJ-6HiPK2oV_2v-qbSS_dPs',
  authDomain: 'esusab-pec-producao.firebaseapp.com',
  databaseURL: 'https://esusab-pec-producao.firebaseio.com',
  projectId: 'esusab-pec-producao',
  storageBucket: 'esusab-pec-producao.appspot.com',
  messagingSenderId: '1034535321855',
  appId: '1:1034535321855:web:ba511378ae275dbca88cca',
  measurementId: 'G-RXTX8LJCDF',
}

const firebaseConfigDev = {
  apiKey: 'AIzaSyDYEbRAPOoAba9pGg4dW90fnEIDskLzEbw',
  authDomain: 'pec---dev.firebaseapp.com',
  databaseURL: 'https://pec---dev.firebaseio.com',
  projectId: 'pec---dev',
  storageBucket: 'pec---dev.appspot.com',
  messagingSenderId: '189386864991',
  appId: '1:189386864991:web:8a938957edb72ca64c5eea',
  measurementId: 'G-43LSX16PMY',
}

const firebaseConfig = METRICS_ENABLED ? firebaseConfigProd : firebaseConfigDev

export const firebaseApp = firebase.initializeApp(firebaseConfig)
