/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel } from 'bold-ui'
import { format } from 'date-fns'
import { humanizeAge } from 'util/date/humanize-age'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { hasMedicoes } from '../../../utils/historicoUtils'
import { HistoricoSectionType } from '../../HistoricoSection'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoMedicoesAnterioresTableBoxProps {
  medicoes: HistoricoMedicaoModel[]
  dataNascimentoCidadao: LocalDate
  sectionStyle: HistoricoSectionType
  isAtendimentoPuericultura?: boolean
}

export default function HistoricoMedicoesAnterioresTableBox(props: HistoricoMedicoesAnterioresTableBoxProps) {
  const { medicoes, sectionStyle, dataNascimentoCidadao, isAtendimentoPuericultura } = props

  if (!hasMedicoes(medicoes)) return null

  return (
    <HistoricoDetailTable
      title={isAtendimentoPuericultura ? 'Medições anteriores da criança' : 'Medições'}
      sectionStyle={sectionStyle}
    >
      {medicoes.map((medicao) => (
        <Grid gapVertical={2}>
          {medicao.dataMedicao && (
            <Cell size={2}>
              <InfoLabel title='Data da medição'>{format(medicao.dataMedicao, 'dd/MM/yyyy')}</InfoLabel>
            </Cell>
          )}
          {medicao.dataMedicao && dataNascimentoCidadao && isAtendimentoPuericultura && (
            <Cell size={2}>
              <InfoLabel title='Idade'>{humanizeAge(dataNascimentoCidadao, medicao.dataMedicao)}</InfoLabel>
            </Cell>
          )}
          {medicao.valorPeso && (
            <Cell size={2}>
              <InfoLabel title='Peso'>{medicao.valorPeso} kg</InfoLabel>
            </Cell>
          )}
          {medicao.valorAltura && (
            <Cell size={2}>
              <InfoLabel title='Altura'>{medicao.valorAltura} cm</InfoLabel>
            </Cell>
          )}
          {medicao.valorImc && (
            <Cell size={2}>
              <InfoLabel title='IMC'>{medicao.valorImc.toFixed(2)} Kg/m²</InfoLabel>
            </Cell>
          )}
          {medicao.valorPerimetroCefalico && (
            <Cell size={2}>
              <InfoLabel title='Per. cefálico'>{medicao.valorPerimetroCefalico} cm</InfoLabel>
            </Cell>
          )}
        </Grid>
      ))}
    </HistoricoDetailTable>
  )
}
