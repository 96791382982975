/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading/LoadingIndicator'
import { useCuidadoCompartilhadoEvolucaoQuery } from 'graphql/hooks.generated'
import { CuidadoCompartilhadoEvolucaoQuery } from 'graphql/types.generated'
import {
  IdadeOrigemAtendimento,
  InformacoesAdministrativas,
} from 'view/atendimentos/detail/historico/model/model-historico'
import { LabelEvolucaoCuidadoCompartilhadoRecord } from 'view/cuidados-compartilhados/model-cuidadocompartilhado'

import CiapCidLabel from '../../header/components/CiapCidLabel'
import HistoricoGapPanel from '../HistoricoGapPanel'

interface HistoricoCuidadoCompartilhadoPanelProps {
  idCuidadoCompartilhadoEvol: ID
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

type CuidadoCompartilhadoEvolucao = CuidadoCompartilhadoEvolucaoQuery['cuidadoCompartilhadoEvolucao']

export const HistoricoCuidadoCompartilhadoPanel = (props: HistoricoCuidadoCompartilhadoPanelProps) => {
  const { idCuidadoCompartilhadoEvol, idadeOrigemAtendimento } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const {
    data: { cuidadoCompartilhadoEvolucao: evolucao },
    loading,
  } = useCuidadoCompartilhadoEvolucaoQuery({
    variables: {
      id: idCuidadoCompartilhadoEvol,
    },
  })

  if (loading) return <LoadingIndicator />
  if (!evolucao) return null

  const informacoesAdministrativas = getInformacoesAdministrativas(evolucao)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={informacoesAdministrativas}
      dataInicioCompartilhamento={evolucao.cuidadoCompartilhado.iniciadoEm}
    >
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>Problemas e condições</Text>
        <CiapCidLabel
          ciap={evolucao.cuidadoCompartilhado.ciap}
          cid10={evolucao.cuidadoCompartilhado.cid10}
          isCapitalized
        />
      </VFlow>
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>Discussão do caso</Text>
        <Text dangerouslySetInnerHTML={{ __html: evolucao.cuidadoCompartilhado.discussao }} />
      </VFlow>
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>Conduta</Text>
      </VFlow>
      <VFlow vSpacing={0} css={styles.box}>
        <VFlow>
          <Text fontWeight='bold'>{LabelEvolucaoCuidadoCompartilhadoRecord[evolucao.conduta]}</Text>
          <Text dangerouslySetInnerHTML={{ __html: evolucao.descricao }} />
        </VFlow>
      </VFlow>
    </HistoricoGapPanel>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    padding: 1em;
    border: 1px solid ${theme.pallete.divider};
    margin-bottom: 0px;
  `,
})

const getInformacoesAdministrativas = (evolucao: CuidadoCompartilhadoEvolucao): InformacoesAdministrativas => {
  const atendimento = evolucao.cuidadoCompartilhado.atendimentoProfissional.atendimento
  const lotacaoSolicitante = evolucao.cuidadoCompartilhado.lotacaoSolicitante
  const lotacaoExecutante = evolucao.cuidadoCompartilhado.lotacaoExecutante

  return {
    cnesUnidadeSaudePrincipal: atendimento?.unidadeSaude.cnes,
    nomeUnidadeSaudePrincipal: atendimento?.unidadeSaude.nome,
    localAtendimento: atendimento?.localAtendimento?.localAtendimentoExibicao,
    nomeProfissionalPrincipal: lotacaoSolicitante.profissional.nome,
    cnsProfissionalPrincipal: lotacaoSolicitante.profissional.cns,
    cboProfissionalPrincipal: lotacaoSolicitante.cbo.nome,
    nomeEquipePrincipal: lotacaoSolicitante.equipe?.nome,
    ineEquipePrincipal: lotacaoSolicitante.equipe?.ine,
    nomeProfissionalCompartilhado: lotacaoExecutante.profissional.nome,
    cnsProfissionalCompartilhado: lotacaoExecutante.profissional.cns,
    cboProfissionalCompartilhado: lotacaoExecutante.cbo.nome,
    nomeEquipeCompartilhado: lotacaoExecutante.equipe?.nome,
    ineEquipeCompartilhado: lotacaoExecutante.equipe?.ine,
    stCuidadoCompartilhado: true,
  }
}
