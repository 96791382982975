/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { Box } from 'components/Box'
import useFirebase from 'components/firebase/useFirebase'
import { CpfField, Form, FormRenderProps, TextField } from 'components/form'
import { PageContainer } from 'components/layout/PageContainer'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { useVideochamadaQuery } from 'graphql/hooks.generated'
import { Fragment, useCallback, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { cpf, createValidator, maxLength, minLength, nome, required } from 'util/validation'

import { TermoModal } from '../../components/TermoModal'
import { CameraPreview } from './componentes/CameraPreview'
import { useEntrarEmVideochamada } from './hooks/useEntrarEmVideochamada'
import { AtorVideochamada, LocalVideocallParticipant } from './model'
import { termosVideochamadaProfissionalVisitante } from './termos'
import { showChamadaEncerradaInformation, showChamadaLotadaInformation } from './utils'

const meta = metaPath<SalaEsperaFormModel>()

interface SalaEsperaVideochamadaUsuariosExternosViewProps {
  videochamadaId: ID
  nomeProfissional: string
  cboProfissional: string
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
  onEntrar(selfData: LocalVideocallParticipant): void
}

interface SalaEsperaFormModel {
  nomeParticipante: string
  cpf: string
}

const validator = createValidator<SalaEsperaFormModel>({
  nomeParticipante: [required, nome, minLength(5), maxLength(70)],
  cpf: [required, cpf],
})

export function SalaEsperaVideochamadaUsuariosExternosView(props: SalaEsperaVideochamadaUsuariosExternosViewProps) {
  const {
    videochamadaId,
    nomeProfissional,
    cboProfissional,
    onEntrar,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
  } = props
  const { analytics } = useFirebase()

  const { refetch: fetchVideochamada } = useVideochamadaQuery({
    variables: {
      id: videochamadaId,
    },
    skip: true,
  })

  const alert = useAlert()
  const { data: session, refresh: refreshSession } = useSession()
  const cboProfissionalExterno = session ? isLotacaoOrEstagio(session.acesso) && session.acesso.cbo.nome : null

  const [isTermoResponsabilidadeModalOpen, setIsTermoResponsabilidadeModalOpen] = useState(false)

  const entrarEmVideochamada = useEntrarEmVideochamada({ videochamadaId, onEntrar })

  const handleEntrarButtonClick = () => {
    refreshSession() // Garante que o usuário esteja logado/deslogado ao tentar iniciar a videochamada
    fetchVideochamada()
      .then((response) => {
        if (!response.data.videochamada) {
          showChamadaEncerradaInformation()
        } else if (response.data.videochamada.isLotada) {
          showChamadaLotadaInformation()
        } else setIsTermoResponsabilidadeModalOpen(true)
      })
      .catch(() => {
        alert('danger', 'Erro ao obter informações da chamada')
      })
  }

  const handleEntrar = useCallback(
    async (values: SalaEsperaFormModel, autorizacoes: Record<AtorVideochamada.PROFISSIONAL_EXTERNO, boolean>) => {
      if (!autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO])
        alert('danger', 'O profissional precisa autorizar a teleinterconsulta.')

      await entrarEmVideochamada(
        values.nomeParticipante,
        values.cpf,
        autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO]
      )

      analytics.logEvent('videochamadas_acesso_externo')
    },
    [entrarEmVideochamada, analytics, alert]
  )

  const renderForm = ({ values, handleSubmit }: FormRenderProps<SalaEsperaFormModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      <Box>
        <TermoModal
          onAccept={(autorizacoes) => handleEntrar(values, autorizacoes)}
          open={isTermoResponsabilidadeModalOpen}
          onClose={() => setIsTermoResponsabilidadeModalOpen(false)}
          termos={termosVideochamadaProfissionalVisitante}
          confirmText='Iniciar chamada'
        />
        <Grid gap={1} alignItems='center' justifyContent='space-between'>
          {session ? (
            <Fragment>
              <Cell size={8}>
                <Text>Você está realizando essa chamada como </Text>
                <Text fontWeight='bold'>
                  {session.profissional.nome}
                  {cboProfissionalExterno ? ` | ${cboProfissionalExterno.capitalize()}` : ''}
                </Text>
              </Cell>
              <Cell size={4} style={styles.buttonContainer} onClick={handleEntrarButtonClick}>
                <Button type='submit' kind='primary'>
                  Entrar na chamada
                </Button>
              </Cell>
            </Fragment>
          ) : (
            <Fragment>
              <Cell size={6} alignSelf='flex-start'>
                <TextField
                  label='Nome completo'
                  name={meta.nomeParticipante}
                  placeholder='Digite o nome do participante'
                  maxLength={70}
                  required
                />
              </Cell>
              <Cell size={6} alignSelf='flex-start'>
                <CpfField label='CPF' name={meta.cpf} required />
              </Cell>
              <Cell size={12} style={styles.buttonContainer}>
                <Button type='submit' kind='primary'>
                  Entrar na chamada
                </Button>
              </Cell>
            </Fragment>
          )}
        </Grid>
      </Box>
    </form>
  )

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContainer>
        <Grid gap={6} gapVertical={1}>
          <Cell size={12}>
            <Heading level={1} style={styles.heading}>
              Teleinterconsulta e-SUS APS
            </Heading>
          </Cell>
          <Cell xs={12} sm={7}>
            <Grid>
              <Cell size={12}>
                <Heading level={2} style={styles.headingName}>
                  Chamada com {nomeProfissional}
                  {cboProfissional ? ` | ${cboProfissional}` : ''}
                </Heading>
              </Cell>
              <Cell size={12}>
                <Form<SalaEsperaFormModel>
                  render={renderForm}
                  onSubmit={handleEntrarButtonClick}
                  validate={validator}
                  suppressNotificationError
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell xs={12} sm={5}>
            <CameraPreview
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
              setAudioEnabled={setAudioEnabled}
              setVideoEnabled={setVideoEnabled}
            />
          </Cell>
        </Grid>
      </PageContainer>
    </Fragment>
  )
}

const styles = {
  heading: css`
    margin-top: 2rem;
  `,
  headingName: css`
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
}
