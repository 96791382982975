import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings } from '../common/KeyMapping'
import { Absenteismo } from './Absenteismo'

export const absenteismoKeyMapping: PivotTableProps<Absenteismo>['keyMapping'] = new Map<keyof Absenteismo, KeyMapping>(
  [
    ['categoriaProfissional', keyMappings.categoriaProfissional],
    ['nomeEquipe', keyMappings.equipe],
    ['nomeMunicipio', keyMappings.municipio],
    ['nomeProfissional', keyMappings.nomeProfissional],
    ['nomeSituacaoAgendamento', keyMappings.nomeSituacaoAgendamento],
    ['nomeUnidadeSaude', keyMappings.unidadeSaude],
    ['turno', keyMappings.turno],
    ['intervaloAgendamento', keyMappings.intervaloAgendamento],
    ['faixaEtaria', keyMappings.faixaEtaria],
    ['nomeCbo', keyMappings.cbo],
  ]
)
