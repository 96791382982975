/** @jsx jsx */
import { css } from '@emotion/core'
import { blue, gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { format } from 'date-fns'
import {
  MarcadorConsumoAlimentar,
  OrigemAtendimento,
  PerguntasQuestionarioEnum,
  TipoAtendimentoHistoricoClinico,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { turnoRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { EvolucaoProblema } from '../../soap/aside/types/ProblemaModel'
import { OpcoesMarcadoresConsumoAlimentarRecord } from '../../soap/objetivo/marcadores-consumo-alimentar/model'
import {
  HistoricoAtendimentoModel,
  InformacoesAdministrativas,
  MEDICOES_ATENDIMENTO_PEC,
  MEDICOES_PRE_NATAL_PEC,
} from '../model/model-historico'

export const isHistoricoPrintEnable = (historico: HistoricoAtendimentoModel) => {
  const { hasObservacao, origemAtendimento, isAtendObsFinalizado } = historico

  const isFicha = isFichaAtendimento(origemAtendimento)

  if (hasObservacao && (!isAtendObsFinalizado || isFicha)) return false

  const isAtendimentoADAndNotElegibilidade =
    origemAtendimento === OrigemAtendimento.AD &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE

  return (
    (origemAtendimento === OrigemAtendimento.PEC || isAtendimentoADAndNotElegibilidade) &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.ESCUTA_INICIAL &&
    historico.tipoApresentacao !== TipoAtendimentoHistoricoClinico.ESCUTA_INICIAL &&
    !historico.isCancelado
  )
}

export const hasSomeSubmodulesPlano = (form: HistoricoAtendimentoModel) =>
  form.hasAlergia ||
  form.hasPrescricaoMedicamento ||
  form.hasOrientacao ||
  form.hasAtestado ||
  form.hasEncaminhamento ||
  form.hasEncaminhamentoEspecializado ||
  form.hasSolicitacaoExame ||
  form.hasResultadoExame ||
  form.hasProcedimentoClinico ||
  form.hasMarcadorConsumoAlimentar

/**
 * Check that all attributes of an object are null
 * except "__typename" and "id"
 * @param object
 * @returns a boolean
 */
export const hasAllAttrsNull = (object: any) => {
  return Object.keys(object)
    .filter((key) => key !== '__typename' && key !== 'id')
    .every((key) => !object[key])
}

export const hasMedicoes = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes?.some((medicao) => MEDICOES_ATENDIMENTO_PEC.some((key) => !!medicao[key]))

export const hasMedicoesPreNatal = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes.some((medicao) => MEDICOES_PRE_NATAL_PEC.some((key) => !!medicao[key]))

export const convertToMedicaoModel = (medicoes) => medicoes?.map((medicao) => medicao as HistoricoMedicaoModel)

export const convertToEvolucaoProblema = (problemas) => problemas?.map((problema) => problema as EvolucaoProblema)

export const ClassificacaoRiscoColor = {
  PEC_ALTA: red.c40,
  PEC_INTERMEDIARIA: yellow.c60,
  PEC_BAIXA: green.c40,
  PEC_NAO_AGUDA: blue.c40,
  NAO_CLASSIFICADO: gray.c40,
}

export const horaOrTurnoByOrigem = (historicoAtendimento: HistoricoAtendimentoModel) =>
  historicoAtendimento.origemAtendimento === OrigemAtendimento.PEC ||
  historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.CUIDADO_COMPARTILHADO
    ? format(historicoAtendimento.dataAtendimento, 'HH:mm')
    : turnoRecord[historicoAtendimento.turno]

export const naoRegistradoNesseAtendimento = 'Não registrado nesse atendimento'

export const shadowIcon = (color: string) =>
  css`
    filter: drop-shadow(0 0.08rem 0.1rem ${color});
  `

export const isTipoAtendimentoIndividual = (
  tipoAtendimento: TipoAtendimentoHistoricoClinico | TipoAtendimentoProfissional
) =>
  tipoAtendimento === TipoAtendimentoHistoricoClinico.CONSULTA ||
  tipoAtendimento === TipoAtendimentoHistoricoClinico.PRE_NATAL ||
  tipoAtendimento === TipoAtendimentoHistoricoClinico.PUERICULTURA ||
  tipoAtendimento === TipoAtendimentoHistoricoClinico.PUERPERIO

export const mountInfosAdministrativas = (data): InformacoesAdministrativas => {
  return {
    nomeProfissionalPrincipal: data.dimensaoProfissional?.nome,
    cboProfissionalPrincipal: data.dimensaoCbo?.nome,
    nomeUnidadeSaudePrincipal: data.dimensaoUnidadeSaude?.nome,
    cnesUnidadeSaudePrincipal: data.dimensaoUnidadeSaude?.cnes,
    nomeEquipePrincipal: data.dimensaoEquipe?.nome,
    ineEquipePrincipal: data.dimensaoEquipe?.ine,
    codigoInep: data.dimensaoInep?.nuIdentificador,
    nomeInep: data.dimensaoInep?.noEstabelecimento,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
  }
}
export const mountInfosAdministrativasAtendimentoCompartilhado = (data): InformacoesAdministrativas => {
  return {
    nomeProfissionalPrincipal: data.dimensaoProfissional1?.nome,
    cboProfissionalPrincipal: data.dimensaoCbo1?.nome,
    nomeUnidadeSaudePrincipal: data.dimensaoUnidadeSaude1?.nome,
    cnesUnidadeSaudePrincipal: data.dimensaoUnidadeSaude1?.cnes,
    nomeEquipePrincipal: data.dimensaoEquipe1?.nome,
    ineEquipePrincipal: data.dimensaoEquipe1?.ine,

    nomeProfissionalCompartilhado: data.dimensaoProfissional2?.nome,
    cboProfissionalCompartilhado: data.dimensaoCbo2?.nome,
    nomeUnidadeSaudeCompartilhado: data.dimensaoUnidadeSaude2?.nome,
    cnesUnidadeSaudeCompartilhado: data.dimensaoUnidadeSaude2?.cnes,
    nomeEquipeCompartilhado: data.dimensaoEquipe2?.nome,
    ineEquipeCompartilhado: data.dimensaoEquipe2?.ine,

    cpfCnsCuidador: data.cpfCuidador ?? data.cnsCuidador,
    tipoCuidador: data.dimensaoCuidador?.descricao,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
    stAtendimentoCompartilhado: !!data.dimensaoProfissional2?.id,
  }
}

export const idadeMarcoAlcancadoStringMount = (anos: number, meses: number) => {
  const anosText = 'ano'.pluralizeAndConcatValue(anos)
  const mesesText = `${meses} ${meses > 1 ? 'meses' : 'mês'}`
  const conectivoAnoMes = anos > 0 ? ' e ' : ''

  return ` (alcançado com ${anos > 0 ? anosText : ''}${meses > 0 ? `${conectivoAnoMes}${mesesText}` : ''})`
}

export const mountInfosAdministrativasAtendimentosRecentes = (data): InformacoesAdministrativas => {
  return {
    nomeProfissionalPrincipal: data.lotacao.profissional?.nome,
    cboProfissionalPrincipal: data.lotacao.cbo?.nome,
    nomeUnidadeSaudePrincipal: data.lotacao.unidadeSaude?.nome,
    cnesUnidadeSaudePrincipal: data.lotacao.unidadeSaude?.cnes,
    nomeEquipePrincipal: data.lotacao.equipe?.nome,
    ineEquipePrincipal: data.lotacao.equipe?.ine,

    nomeEstagiario: data.estagio?.profissional?.nome,
    cboEstagiario: data.estagio?.cbo?.nome,
    nomeEquipeEstagiario: data.estagio?.equipe?.nome,
    ineEquipeEstagiario: data.estagio?.equipe?.ine,

    stAtendimentoCompartilhado: !!data.lotacaoAtendimentoCompartilhado?.profissional?.id,
    nomeProfissionalCompartilhado: data.lotacaoAtendimentoCompartilhado?.profissional?.nome,
    cboProfissionalCompartilhado: data.lotacaoAtendimentoCompartilhado?.cbo?.nome,
    nomeUnidadeSaudeCompartilhado: data.lotacaoAtendimentoCompartilhado?.unidadeSaude?.nome,
    cnesUnidadeSaudeCompartilhado: data.lotacaoAtendimentoCompartilhado?.unidadeSaude?.cnes,
    nomeEquipeCompartilhado: data.lotacaoAtendimentoCompartilhado?.equipe?.nome,
    ineEquipeCompartilhado: data.lotacaoAtendimentoCompartilhado?.equipe?.ine,

    codigoInep: data.lotacao.inep?.nuIdentificador,
    nomeInep: data.lotacao.inep?.noEstabelecimento,
    localAtendimento: data.atendimento?.localAtendimento?.localAtendimentoExibicao,
    stRegistroTardio: data.atendimento?.registroTardio,
    dataCriacaoRegistro: data.atendimento?.dataCriacaoRegistro,
  }
}

export const isFichaAtendimento = (origemAtendimento: OrigemAtendimento) =>
  origemAtendimento === OrigemAtendimento.CDS ||
  origemAtendimento === OrigemAtendimento.SISTEMA_TERCEIRO ||
  origemAtendimento === OrigemAtendimento.RAS_PEC

export const vacinadoExterior = (aplicadoExterior: boolean) =>
  isUndefinedOrNull(aplicadoExterior) ? '-' : aplicadoExterior ? 'Sim' : 'Não'

export const convertOpcaoMCAEnumToString = (marcadorConsumoAlimentar: MarcadorConsumoAlimentar) => {
  const respostasMap = new Map<PerguntasQuestionarioEnum, string>()

  marcadorConsumoAlimentar.respostas.forEach((resposta) => {
    const respostaMCA = resposta.opcoesSelecionadas
      ?.map((opcao) => OpcoesMarcadoresConsumoAlimentarRecord[opcao])
      .join(', ')

    respostasMap.set(resposta.pergunta, respostaMCA)
  })

  return respostasMap
}
