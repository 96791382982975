/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoVacinacaoDwQuery } from 'graphql/hooks.generated'
import { HistoricoVacinacaoDwQuery } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import { mountInfosAdministrativas } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import CondicoesVacinacao from '../CondicoesVacinacao'
import HistoricoVacinacaoDwTableItem from './HistoricoVacinacaoDwTableItem'

export interface HistoricoVacinacaoProcessadosProps extends HistoricoDwPanelProps {}

export type HistoricoVacinacaoDwModel = HistoricoVacinacaoDwQuery['historicoVacinacaoDw']['fatosVacinacaoVacina'][0]

export default function HistoricoVacinacaoDw(props: HistoricoVacinacaoProcessadosProps) {
  const { uuidAtend, cpfOuCnsCidadao, idadeOrigemAtendimento } = props
  const {
    data: { historicoVacinacaoDw: historico },
    loading,
  } = useHistoricoVacinacaoDwQuery({
    variables: {
      input: {
        uuidAtend,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const { recentes, registroAnterior } = filterByRegistroAnterior<HistoricoVacinacaoDwModel>(
    historico.fatosVacinacaoVacina,
    'isRegistroAnterior'
  )

  const renderItem = (item: HistoricoVacinacaoDwModel) => HistoricoVacinacaoDwTableItem(item)

  return (
    <Fragment>
      {historico && (
        <HistoricoGapPanel
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          informacoesAdministrativas={mountInfosAdministrativas(historico)}
        >
          <CondicoesVacinacao
            gestante={historico.gestante}
            puerpera={historico.puerpera}
            viajante={historico.viajante}
            comunicanteHanseniase={historico.comunicanteHanseniase}
          />
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Vacinação</Heading>
            {!isEmpty(recentes) && (
              <DataTable<HistoricoVacinacaoDwModel>
                rows={recentes}
                columns={[
                  {
                    name: 'registro de aplicação',
                    header: 'Registro de aplicação',
                    render: renderItem,
                    style: css`
                      width: 40%;
                    `,
                  },
                ]}
              />
            )}
            {!isEmpty(registroAnterior) && (
              <DataTable<HistoricoVacinacaoDwModel>
                rows={registroAnterior}
                columns={[
                  {
                    name: 'transcrição de caderneta',
                    header: 'Transcrição de caderneta',
                    render: renderItem,
                    style: css`
                      width: 40%;
                    `,
                  },
                ]}
              />
            )}
          </VFlow>
        </HistoricoGapPanel>
      )}
    </Fragment>
  )
}
