/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import api from 'api'
import { Alert, Button, HFlow, Icon, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { HLabel } from 'components/HLabel'
import { formatISO } from 'date-fns'
import { useCidadaoInformacoesContatoQuery } from 'graphql/hooks.generated'
import { SexoEnum, TipoReceitaEnum } from 'graphql/types.generated'
import { useForceRender } from 'hooks/useForceRender'
import { useServerTime } from 'hooks/useServerTime'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { metaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { MedicamentoFormModel, PrescricaoDigitalFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { convertMedicamentoToPrintDto, processTextoMedicamentos } from '../../utils/prescricaoConverter'
import { isOnPrescricaoDigital } from '../../utils/prescricaoUtils'
import { EncaminharPrescricaoMedicamentoItem } from '../components/EncaminharPrescricaoMedicamentoItem'
import { ImpressaoMedicamentosInput } from '../impressao/ImpressaoMedicamentosInput'
import { EmailUpdateForm } from './EmailUpdateField'
import {
  PrescricaoDigitalIntegracaoMessage,
  PrescricaoDigitalIntegracaoMessageProps,
} from './PrescricaoDigitalIntegracaoMessage'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

type PrescricaoDigitalIntegracaoModel = Pick<
  PrescricaoDigitalIntegracaoMessageProps,
  'success' | 'message' | 'errorCode'
>

export interface PrescricaoDigitalViewProps {
  medicamentos: MedicamentoFormModel[]
  prescricaoDigital: PrescricaoDigitalFormModel
  updatePrescricaoDigital: (prescricao: PrescricaoDigitalFormModel) => void
  atendimentoId: ID
  municipioId: ID
  cidadaoId: ID
  cidadaoSexo: SexoEnum
  cidadaoDataNascimento: string
  onModalClose: () => void
}

export const PrescricaoDigitalGerarView = (props: PrescricaoDigitalViewProps) => {
  // TODO: (@Legacy) #16563 - Component muito complexo; Refatorar
  const {
    medicamentos,
    prescricaoDigital,
    updatePrescricaoDigital,
    atendimentoId,
    municipioId,
    cidadaoId,
    cidadaoSexo,
    cidadaoDataNascimento,
    onModalClose,
  } = props

  const {
    input: { value: medicamentosField, onChange: updateMedicamentosField },
  } = useField<MedicamentoFormModel[]>(meta.plano.prescricaoMedicamento.medicamentos.absolutePath(), {
    subscription: { value: true },
  })

  const [medicamentosSelected, setMedicamentosSelected] = useState<MedicamentoFormModel[]>(() =>
    medicamentos.filter(
      (medicamento) =>
        (medicamento.registroManual
          ? medicamento.tipoReceita.tipoReceitaEnum
          : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita) === TipoReceitaEnum.COMUM &&
        !isOnPrescricaoDigital(medicamento)
    )
  )

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const email = infoCidadao?.contatoCidadao?.email

  const { analytics } = useFirebase()
  const forceRender = useForceRender()
  const alertUpdateEmailSucceedRef = useRef(false)
  const [isGerandoPrescricao, setIsGerandoPrescricao] = useState(false)
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [statusMessage, setStatusMessage] = useState<PrescricaoDigitalIntegracaoModel>(null)
  const [externalPopup, setExternalPopup] = useState<Window>(null)
  const { getServerTimeNow } = useServerTime()

  const handleEmitirPrescricaoDigitalSuccess = useCallback(
    (codigoPrescricao: string) => {
      const medicamentosToPush = medicamentosSelected
        .filter((medicamento) => !isOnPrescricaoDigital(medicamento))
        .map((medicamento) => medicamento.id)
      if (medicamentosToPush.isNotEmpty()) {
        updateMedicamentosField([
          ...medicamentosField.map((medicamento) => {
            if (medicamentosToPush.includes(medicamento.id)) {
              medicamento.codigoPrescricaoDigital = codigoPrescricao
            }
            return medicamento
          }),
        ])
        updatePrescricaoDigital({
          [codigoPrescricao]: {
            prescricaoInvalidada: false,
            emailEnvio: email,
            dataAssinatura: formatISO(getServerTimeNow()),
            textoMedicamentos: processTextoMedicamentos(
              medicamentosField.filter((medicamento) => medicamentosToPush.includes(medicamento.id)),
              codigoPrescricao
            ),
          },
          ...prescricaoDigital,
        })
      }

      setMedicamentosSelected([])
    },
    [
      medicamentosSelected,
      updateMedicamentosField,
      medicamentosField,
      prescricaoDigital,
      updatePrescricaoDigital,
      email,
      getServerTimeNow,
    ]
  )

  const handleStatusChangeGeracaoPrescricao = ({ success, message, errorCode }: PrescricaoDigitalIntegracaoModel) => {
    setStatusMessage({
      success,
      message,
      errorCode,
    })

    setIsGerandoPrescricao(false)
  }

  // TODO (@Calistro): Criar uma hook para este comportamento
  useEffect(() => {
    if (!externalPopup || externalPopup.closed) {
      return
    }

    const timer = setInterval(async () => {
      if (!externalPopup || externalPopup.closed) {
        timer && clearInterval(timer)
        if (!statusMessage) {
          handleStatusChangeGeracaoPrescricao({ success: false, message: 'Geração de prescrição digital cancelada.' })
        }
        return
      }

      let currentUrl: string
      try {
        currentUrl = externalPopup.location.href
      } catch {
        return
      }

      if (!currentUrl) {
        return
      }

      const searchParams = new URL(currentUrl).searchParams
      const codigoPrescricao = searchParams.get('prescricaoId')
      const error = searchParams.get('error')

      if (error) {
        externalPopup.close()
        timer && clearInterval(timer)
        handleStatusChangeGeracaoPrescricao({ success: false, errorCode: error })
      } else if (codigoPrescricao) {
        externalPopup.close()
        timer && clearInterval(timer)

        try {
          const response = await api.prescricaoDigital.emitirPrescricaoDigital({
            atendimentoId,
            cidadaoId,
            codigoPrescricao,
            emailCidadao: email,
          })

          if (response) {
            handleStatusChangeGeracaoPrescricao({ success: true, message: 'Prescrição digital gerada com sucesso.' })
            handleEmitirPrescricaoDigitalSuccess(codigoPrescricao)
          }
        } catch {
          handleStatusChangeGeracaoPrescricao({
            success: false,
            message: 'Não foi possível gerar a prescrição digital no momento. Tente novamente mais tarde.',
          })
        }
      }

      return () => {
        timer && clearInterval(timer)
      }
    }, 500)
  }, [
    externalPopup,
    setStatusMessage,
    setIsGerandoPrescricao,
    handleEmitirPrescricaoDigitalSuccess,
    statusMessage,
    atendimentoId,
    medicamentos,
    email,
    cidadaoId,
  ])

  const handleEmailChange = (newEmail: string) => {
    analytics.logEvent('click_atualizar_email_prescricao_digital')

    if (newEmail !== email) {
      alertUpdateEmailSucceedRef.current = true
    }

    setIsEditingEmail(false)
  }

  const handleAlertEmailClose = () => {
    alertUpdateEmailSucceedRef.current = false
    forceRender()
  }

  const handleAlertStatusClose = () => {
    setStatusMessage(null)
  }

  const handleOnEditarEmailClick = () => {
    analytics.logEvent('click_editar_email_prescricao_digital')

    setIsEditingEmail(true)
  }

  const handleOnChangeMedicamentosSelected = (checked: boolean, medicamento: MedicamentoFormModel) => {
    checked
      ? setMedicamentosSelected([...medicamentosSelected, medicamento])
      : setMedicamentosSelected(medicamentosSelected.filter((item) => item.id !== medicamento.id))
  }

  const statusGerarButton = getStatusGerarButon({
    hasEmail: !!email,
    isEditingEmail,
    medicamentosSelectedLength: medicamentosSelected.length,
  })

  const renderFieldArray = () => {
    const handleGerarClicked = async () => {
      analytics.logEvent('click_gerar_prescricao_digital')

      setIsGerandoPrescricao(true)

      const input: ImpressaoMedicamentosInput = {
        atendimentoId: atendimentoId,
        cidadaoDataNascimento: Date.parse(cidadaoDataNascimento),
        cidadaoSexo: cidadaoSexo,
        localidadeId: municipioId,
        medicamentos: medicamentosSelected.map((item) => convertMedicamentoToPrintDto(item)),
      }

      try {
        const response = await api.prescricaoDigital.gerarPrescricaoDigital(input)

        const windowAssinatura = window.open(
          response.data,
          'prescricao-digital',
          `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}`
        )

        setExternalPopup(windowAssinatura)
      } catch {
        handleStatusChangeGeracaoPrescricao({
          success: false,
          message: 'Erro ao gerar prescrição digital, tente novamente mais tarde.',
        })
      }
    }

    return (
      <React.Fragment>
        <div
          css={css`
            margin-top: -1.5rem;
            padding: 0 2.25rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            {!!statusMessage && (
              <PrescricaoDigitalIntegracaoMessage {...statusMessage} handleAlertClose={handleAlertStatusClose} />
            )}
            {!!alertUpdateEmailSucceedRef.current && (
              <Alert inline type='success' onCloseClick={handleAlertEmailClose}>
                E-mail atualizado com sucesso.
              </Alert>
            )}
            <Alert inline type='info'>
              A prescrição será assinada eletronicamente e será enviada ao cidadão através do e-mail cadastrado. A
              responsabilidade dos dados enviados a este e-mail é do prescritor. Verifique atentamente os dados da
              prescrição antes de assinar e enviar.
            </Alert>
            {isEditingEmail ? (
              <EmailUpdateForm cidadaoId={cidadaoId} onSubmitSuccess={handleEmailChange} initialValues={{ email }} />
            ) : (
              <HFlow hSpacing={0.25} alignItems='center'>
                <HLabel title='E-mail:'>{email}</HLabel>
                <Tooltip text='Editar'>
                  <Button size='small' skin='ghost' onClick={handleOnEditarEmailClick}>
                    <Icon icon='penOutline' />
                  </Button>
                </Tooltip>
              </HFlow>
            )}
            <Text>Selecione os medicamentos que deseja incluir na prescrição digital</Text>
            <EncaminharPrescricaoMedicamentoItem
              medicamentosToPrint={medicamentos}
              medicamentoIdsSelecionado={medicamentosSelected.map((item) => item.id)}
              onChange={handleOnChangeMedicamentosSelected}
              disableReceitaControlada
              isPrescricaoDigital
            />
          </VFlow>
        </div>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onModalClose} style={styles.footerButton} data-cy='CancelarPrescricaoDigitalButton'>
              Cancelar
            </Button>
            <Tooltip text={statusGerarButton?.tooltip}>
              <Button
                kind='primary'
                onClick={handleGerarClicked}
                loading={isGerandoPrescricao}
                style={styles.footerButton}
                disabled={statusGerarButton?.disabled}
                data-cy='GerarPrescricaoButton'
              >
                Gerar
              </Button>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </React.Fragment>
    )
  }

  return <FieldArray name={path.medicamentos.absolutePath()} render={renderFieldArray} />
}

const styles = {
  footerButton: css`
    width: 10rem;
    height: 3rem;
  `,
}

const getStatusGerarButon = ({
  medicamentosSelectedLength,
  hasEmail,
  isEditingEmail,
}: {
  medicamentosSelectedLength: number
  hasEmail: boolean
  isEditingEmail: boolean
}): {
  disabled: boolean
  tooltip: string
} => {
  if (medicamentosSelectedLength === 0) {
    return {
      disabled: true,
      tooltip: 'Nenhum medicamento selecionado',
    }
  }
  if (!hasEmail) {
    return {
      disabled: true,
      tooltip: 'E-mail não cadastrado',
    }
  }
  if (isEditingEmail) {
    return {
      disabled: true,
      tooltip: 'E-mail não atualizado',
    }
  }
}
