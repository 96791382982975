import { Button, ButtonGroup, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField } from 'components/form'
import { SexoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'

interface SexoIdentidadeGeneroButtonGroup {
  onChange?: (name: string, value?: any) => void
}

export default function SexoIdentidadeGeneroButtonGroup(props: SexoIdentidadeGeneroButtonGroup) {
  const [option, setOption] = useState<boolean>(false)

  const handleChangeButtonGroup = (itemGroup) => {
    return () => {
      setOption(itemGroup)
      itemGroup ? props.onChange('sexo', undefined) : props.onChange('identidadeGenero', undefined)
    }
  }

  return (
    <VFlow vSpacing={0.5}>
      <ButtonGroup>
        <Button
          kind={!option ? 'primary' : 'normal'}
          size='small'
          skin='default'
          onClick={handleChangeButtonGroup(false)}
        >
          Sexo
        </Button>
        <Button
          kind={option ? 'primary' : 'normal'}
          size='small'
          skin='default'
          onClick={handleChangeButtonGroup(true)}
        >
          Identidade de gênero
        </Button>
      </ButtonGroup>
      <Box>
        {!option ? (
          <>
            <CheckboxField name='sexo' value={SexoEnum.FEMININO} label='Feminino' style={{ marginRight: '1rem' }} />
            <CheckboxField name='sexo' value={SexoEnum.MASCULINO} label='Masculino' />
          </>
        ) : (
          <>
            <CheckboxField
              name='identidadeGenero'
              label='Mulher transsexual'
              value='MULHER_TRANS'
              style={{ marginRight: '1rem' }}
            />
            <CheckboxField
              name='identidadeGenero'
              label='Homem transsexual'
              value='HOMEM_TRANS'
              style={{ marginRight: '1rem' }}
            />
            <CheckboxField name='identidadeGenero' label='Travesti' value='TRAVESTI' style={{ marginRight: '1rem' }} />
            <CheckboxField name='identidadeGenero' label='Outros' value='OUTRO_IDENTIDADE_GENERO' />
          </>
        )}
      </Box>
    </VFlow>
  )
}
