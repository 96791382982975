import { useLayoutEffect, useState } from 'react'

export function useCalculaCabecalhoSideMenuNews() {
  const [isMinimizado, setIsMinimizado] = useState(false)

  useLayoutEffect(() => {
    const listener = () => {
      const cabecalhoMinimizado = window.scrollY >= 125 && window.document.body.offsetHeight - window.innerHeight >= 340

      if (cabecalhoMinimizado && !isMinimizado) {
        setIsMinimizado(true)
      } else if (!cabecalhoMinimizado && isMinimizado) {
        setIsMinimizado(false)
      }
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [isMinimizado])
  return isMinimizado
}
