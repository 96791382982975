import { CidadaoAtendimentoSelectFieldModel } from 'components/form'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { AgendamentoConsultaModel } from '../model'

const meta = metaPath<AgendamentoConsultaModel>()

const agendamentoConsultaCalculations = [
  {
    field: meta.cidadao.absolutePath(),
    updates: {
      [meta.email.absolutePath()]: (cidadaoValue: CidadaoAtendimentoSelectFieldModel) => cidadaoValue?.contato.email,
      [meta.telefoneCelular.absolutePath()]: (cidadaoValue: CidadaoAtendimentoSelectFieldModel) =>
        cidadaoValue?.contato.telefoneCelular,
    },
  },
]

export const agendamentoConsultaDecorator = [createDecorator(...agendamentoConsultaCalculations)]
