import { Heading, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { InfoIcon } from 'components/InfoIcon'
import { useLoadHorarioGeracaoLotesProcessamentoFichasQuery } from 'graphql/hooks.generated'
import React from 'react'

import { TransmissaoHorarioGeracaoLotesForm } from './TransmissaoHorarioGeracaoLotesForm'

export interface TransmissaoHorarioGeracaoLotesProps {}
export const TransmissaoHorarioGeracaoLotesBox = (props: TransmissaoHorarioGeracaoLotesProps) => {
  const {
    data: { loadHorarioGeracaoLotesProcessamentoFichas: horario },
  } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery()

  return (
    <VFlow>
      <Heading level={2}>
        <HFlow hSpacing={0.5} alignItems='center'>
          Horário de geração de lotes e processamento de fichas
          <InfoIcon
            icon='infoCircleFilled'
            text='Geração de lotes e processamento de fichas serão realizados apenas no horário definido.'
          />
        </HFlow>
      </Heading>
      <Box>{horario && <TransmissaoHorarioGeracaoLotesForm horario={horario} />}</Box>
    </VFlow>
  )
}
