/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, InfoLabel, isEmpty, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoAtendimentoOdontoPecQuery } from 'graphql/hooks.generated'
import {
  AtendimentoProfissionalOdonto,
  EncaminhamentoExternoEspecializado,
  ReceitaMedicamento,
  RegistroVacinacao,
  SolicitacaoExame,
} from 'graphql/types.generated'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import {
  HistoricoPecPanelProps,
  historicoSubsectionStyles,
  vigilanciaEmSaudeBucalRecord,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertToEvolucaoProblema,
  convertToMedicaoModel,
  mountInfosAdministrativasAtendimentosRecentes,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoAntecedenteSection from '../../atendimento-individual/consulta/HistoricoAntecedenteSection'
import HistoricoAvaliacaoPecPanel from '../../atendimento-individual/consulta/HistoricoAvaliacaoPecPanel'
import HistoricoObjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoObjetivoPecPanel'
import HistoricoPlanoPecPanel from '../../atendimento-individual/consulta/HistoricoPlanoPecPanel'
import HistoricoSubjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoSubjetivoPecPanel'
import HistoricoDesfechoPanel from '../../HistoricoDesfechoPecPanel'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import HistoricoRegistrosAnterioresPanel from '../../HistoricoRegistrosAnterioresPanel'

interface HistoricoAtendimentoOdontoPecProps extends HistoricoPecPanelProps {}

function HistoricoAtendimentoOdontoPec(props: HistoricoAtendimentoOdontoPecProps) {
  const { idAtend, idadeOrigemAtendimento, dataNascimento } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoAtendimentoOdontoPecQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico)}
    >
      {historico.evolucaoObjetivo.necessidadesEspeciais && (
        <Alert type='info' inline>
          <Text color='primary' fontWeight='bold'>
            Possui necessidades especiais
          </Text>
        </Alert>
      )}
      <HistoricoSection section='S'>
        <HistoricoSubjetivoPecPanel evolucaoSubjetivo={historico.evolucaoSubjetivo} />
      </HistoricoSection>
      <HistoricoSection section='O'>
        <HistoricoObjetivoPecPanel
          evolucaoObjetivo={historico.evolucaoObjetivo}
          medicoes={convertToMedicaoModel(historico.medicoes)}
          examesResultados={historico.examesResultados}
          marcadorConsumoAlimentar={historico.marcadorConsumoAlimentar}
        />
      </HistoricoSection>
      <HistoricoSection section='A'>
        <HistoricoAvaliacaoPecPanel
          evolucaoAvaliacao={historico.evolucaoAvaliacao}
          evolucaoAlergia={historico.alergiaEvolucao}
          evolucaoProblema={convertToEvolucaoProblema(historico.evolucaoProblema)}
        />
        <VFlow vSpacing={0.5}>
          {!isEmpty(historico.evolucaoAvaliacao.tiposVigilanciaBucal) && (
            <InfoLabel title='Vigilância em saúde bucal' titleStyles={historicoSubsectionStyles.avaliacao}>
              {stringGrouper(
                ...historico.evolucaoAvaliacao.tiposVigilanciaBucal.map(
                  (vigilancia) => vigilanciaEmSaudeBucalRecord[vigilancia]
                )
              )}
            </InfoLabel>
          )}
          <InfoLabel title='Necessidade de prótese dentária' titleStyles={historicoSubsectionStyles.avaliacao}>
            {historico.evolucaoAvaliacao.necessidadeDeProtese === false
              ? 'Não'
              : historico.evolucaoAvaliacao.necessidadeDeProtese
              ? 'Sim'
              : 'Não avaliado'}
          </InfoLabel>
        </VFlow>
      </HistoricoSection>
      <HistoricoSection section='P'>
        <HistoricoPlanoPecPanel
          evolucaoPlano={historico.evolucaoPlano}
          atendProfOdonto={historico.atendimentoProfissionalOdonto as AtendimentoProfissionalOdonto}
          quantidadeAtestados={historico.quantidadeAtestados}
          quantidadeOrientacoes={historico.quantidadeOrientacoes}
          encaminhamentos={historico.encaminhamentos}
          encaminhamentosEspecializados={
            historico.encaminhamentosEspecializados as EncaminhamentoExternoEspecializado[]
          }
          prescricaoMedicamentos={historico.receitaMedicamento as ReceitaMedicamento[]}
          examesSolicitados={historico.requisicoesExames as SolicitacaoExame[]}
        />
      </HistoricoSection>
      <HistoricoAntecedenteSection
        antecedentes={historico.antecedentes}
        cirurgiasInternacoes={historico.cirurgiasInternacoes}
        dataNascimento={dataNascimento}
      />
      <HistoricoSection section='RA'>
        <HistoricoRegistrosAnterioresPanel
          medicoes={convertToMedicaoModel(historico.medicoes)}
          registrosVacinacao={historico.vacinacao?.registrosVacinacao as RegistroVacinacao[]}
          dataNascimentoCidadao={idadeOrigemAtendimento.dataNascimentoCidadao}
        />
      </HistoricoSection>
      <HistoricoSection section='D'>
        <HistoricoDesfechoPanel
          condutas={historico.condutas}
          condutasOdonto={historico.atendimentoProfissionalOdonto.tipoConduta}
          procedimentosAdministrativos={historico.procedimentosAtendimento?.map((proced) => proced.procedimento)}
          notificacoesCasoSuspeito={historico.notificacoesCasoSuspeito}
          racionalidadeEmSaude={historico.racionalidadeSaude}
          fornecimentos={historico.atendimentoProfissionalOdonto.cdsTipoFornecimOdonto}
        />
      </HistoricoSection>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoOdontoPec)
