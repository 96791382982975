import { AggregatorFunction } from './AggregatorFunction'

export type Aggregator = {
  id: string
  label: string
  value: AggregatorFunction | undefined
  keyDependent: boolean
  chain?: Aggregator[]
  suffix?: string
}

// Aggregators:

const contagem: Aggregator = { id: 'contagem', label: 'Contagem', value: undefined, keyDependent: false }

export const aggregators: Aggregator[] = [
  contagem,
  {
    id: 'percentual',
    label: 'Percentual',
    value: (values: number[], total: number): number => (values.reduce((prev, curr) => prev + curr, 0) * 100) / total,
    keyDependent: false,
    chain: [contagem],
    suffix: '%',
  },
  {
    id: 'media',
    label: 'Média',
    value: (values: number[]): number => values.reduce((prev, curr) => prev + curr, 0) / values.length,
    keyDependent: true,
  },
  {
    id: 'maximo',
    label: 'Máximo',
    value: (values: number[]): number => values.reduce((prev, curr) => (prev > curr ? prev : curr)),
    keyDependent: true,
  },
  {
    id: 'minimo',
    label: 'Mínimo',
    value: (values: number[]): number => values.reduce((prev, curr) => (prev < curr ? prev : curr)),
    keyDependent: true,
  },
]
