import { Checkbox, Highlight, Spinner, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useRecursosQuery } from 'graphql/hooks.generated'
import { TipoAcesso } from 'graphql/types.generated'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createTree, RecursoNode } from 'view/perfil/components/RecursoNode'

import {
  RECURSO_REGISTRAR_ATENDIMENTO_URI,
  RECURSO_VISUALIZAR_ATEND_URI,
  RECURSO_VISUALIZAR_PRONTUARIO_URI,
} from './model'
import { RecursoTree } from './RecursoTree'

interface RecursoTreeFieldProps {
  value: string[]
  tipoPerfil: TipoAcesso

  onChange(value: string[]): any
}

export const RecursoTreeField = (props: RecursoTreeFieldProps) => {
  const {
    data: { recursos },
    loading,
  } = useRecursosQuery({
    fetchPolicy: 'cache-first',
    variables: { tipoAcesso: props.tipoPerfil },
  })

  const root = useMemo(() => createTree(recursos ?? []), [recursos])

  useEffect(() => {
    root.setTreeValue(props.value)
  }, [props.value, root])

  const alert = useAlert()
  const nodeVisualizarProntuario = useMemo(
    () => root.children.find((item) => item.state.uri === RECURSO_VISUALIZAR_PRONTUARIO_URI),
    [root]
  )
  const nodeRegistrarAtendimento = useMemo(
    () =>
      root.children
        .find((item) => item.state.uri === RECURSO_VISUALIZAR_ATEND_URI)
        ?.children.find((item) => item.state.uri === RECURSO_REGISTRAR_ATENDIMENTO_URI),
    [root]
  )
  const [visualizarProntDisabled, setVisualizarProntDisabled] = useState<boolean>(false)
  const recursoVisualizarProntAtivadoAuto = useRef<boolean>(false)

  const handleChange = (node: RecursoNode) => (e) => {
    node.changeValue(e.target.checked)

    const nodeArrayParentOrChildren = visualizarProntDisabled
      ? [nodeRegistrarAtendimento?.parent]
      : nodeRegistrarAtendimento?.children
    const isNodeRegistrarAtendimentoClicked = compareUriOfNodes(node, [
      nodeRegistrarAtendimento,
      ...(nodeArrayParentOrChildren || []),
    ])

    if (isNodeRegistrarAtendimentoClicked) {
      if (node.state.checked) {
        if (!nodeVisualizarProntuario.state.checked) {
          nodeVisualizarProntuario.changeValue(e.target.checked)
          recursoVisualizarProntAtivadoAuto.current = true
          alert('info', 'Visualizar prontuário foi adicionado automaticamente')
        }
        setVisualizarProntDisabled(true)
      } else {
        if (recursoVisualizarProntAtivadoAuto.current) {
          nodeVisualizarProntuario.changeValue(e.target.checked)
          recursoVisualizarProntAtivadoAuto.current = false
          alert('info', 'Visualizar prontuário foi removido automaticamente')
        }
        setVisualizarProntDisabled(false)
      }
    } else if (
      compareUriOfNodes(node, [nodeVisualizarProntuario]) &&
      nodeVisualizarProntuario?.state.checked &&
      nodeRegistrarAtendimento?.state.checked
    ) {
      node.changeValue(e.target.checked)
      recursoVisualizarProntAtivadoAuto.current = true
      setVisualizarProntDisabled(true)
    }

    const newValue = node.getTreeValue()
    props.onChange(newValue)
  }

  useEffect(() => {
    setVisualizarProntDisabled(nodeRegistrarAtendimento?.state.checked && nodeVisualizarProntuario?.state.checked)
  }, [nodeRegistrarAtendimento, nodeVisualizarProntuario])

  const renderNode = (node: RecursoNode, search: string) => {
    const renderNodeVisualizarProntuario = compareUriOfNodes(node, [nodeVisualizarProntuario])

    return (
      <Tooltip
        text={
          renderNodeVisualizarProntuario &&
          visualizarProntDisabled &&
          'O recurso registrar atendimento depende de visualizar prontuário'
        }
      >
        <div>
          <Checkbox
            value={node.state.uri}
            checked={node.state.checked}
            indeterminate={node.state.checked && node.checkCount() !== node.totalCount()}
            label={<Highlight text={node.state.label} words={[search]} />}
            onChange={handleChange(node)}
            disabled={renderNodeVisualizarProntuario && visualizarProntDisabled}
          />
        </div>
      </Tooltip>
    )
  }

  if (!root) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return <RecursoTree root={root} renderNode={renderNode} />
}

const compareUriOfNodes = (nodeClick: RecursoNode, nodeToBeCompared: RecursoNode[]) => {
  return nodeToBeCompared.some((node) =>
    node?.state.uri && nodeClick?.state.uri ? node.state.uri === nodeClick.state.uri : false
  )
}
