import api from 'api'
import { AxiosRequestConfig } from 'axios'
import { HeadingSection, HFlow, Icon, Paper, Spinner, Text, Tooltip, useStyles, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import { useHasMunicipioAtivoQuery, useTransmissaoQuery } from 'graphql/hooks.generated'
import { ImportacaoArquivo, StatusImportacaoArquivo } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import moment from 'moment'
import React, { useState } from 'react'

interface TransmissaoRecebimentoImportFileBoxProps {
  refetchQuery(): Promise<any>
}

interface StatusImportacaoProps {
  importacaoArquivo: ImportacaoArquivo
  falhaImportacao: boolean
}

type ImportacaoLediModel = {
  importacaoArquivo: {
    dataEvento?: any
    status: StatusImportacaoArquivo
    message?: string
  }
}

export function TransmissaoRecebimentoImportFileBox(props: TransmissaoRecebimentoImportFileBoxProps) {
  const { refetchQuery } = props
  const alert = useAlert()
  const [throwError, setThrowError] = useState(false)
  const [transmissaoState, setTransmissaoState] = useState<ImportacaoLediModel>()
  const [uploading, setUploading] = useState(false)

  const { classes } = useStyles((theme) => ({
    legenda: {
      padding: '0 0.5rem',
      border: `1px solid ${theme.pallete.divider}`,
      borderTop: 0,
      borderRadius: `${theme.radius.paper}px`,
    },
  }))

  useAtmosphere<ImportacaoLediModel>({
    topic: 'importacao-ledi',
    onMessage: (result) => {
      setThrowError(false)
      setTransmissaoState(result)
      if (result.importacaoArquivo.status === 'CONCLUIDO') {
        refetchQuery()
        alert('success', result.importacaoArquivo.message || 'Importação de arquivo concluída com sucesso.')
      } else if (result.importacaoArquivo.status === 'PROCESSAMENTO_DUPLICADO_CONCLUIDO') {
        refetchQuery()
        alert('success', result.importacaoArquivo.message || 'Verificação de fichas repetidas concluída com sucesso.')
      } else if (result.importacaoArquivo.status === 'ERRO') {
        alert('danger', result.importacaoArquivo.message || 'Falha na importação do arquivo.')
      }
    },
  })

  const {
    data: { hasMunicipioAtivo },
  } = useHasMunicipioAtivoQuery({ context: { newRequest: true } })
  useTransmissaoQuery({
    fetchPolicy: 'cache-first',
    context: { newRequest: true },
    onCompleted: (data) => setTransmissaoState({ ...data.transmissao }),
  })

  if (!transmissaoState) {
    return null
  }

  const upload = (formData: FormData, config?: AxiosRequestConfig) => {
    setUploading(true)
    return api.transmissao
      .importarFichas(formData, config)
      .then((data) => {
        setUploading(false)
        return data
      })
      .catch((error) => {
        setUploading(false)
        setThrowError(true)
        alert('danger', error.response.data || 'Falha na importação do arquivo.')
        throw error
      })
  }

  return (
    <HeadingSection title='Importar arquivo' level={2}>
      <VFlow>
        <Tooltip text={!hasMunicipioAtivo && 'Somente instalação com município habilitado pode importar arquivos.'}>
          <VFlow vSpacing={0}>
            <FileUploaderComponent
              disabled={transmissaoState.importacaoArquivo.status === 'IMPORTANDO' || !hasMunicipioAtivo || uploading}
              uploadRequest={upload}
              text='Clique para importar ou arraste os arquivos'
            />
            <HFlow style={classes.legenda} alignItems='center'>
              <Text>Apenas arquivos no formato ZIP são permitidos</Text>
            </HFlow>
          </VFlow>
        </Tooltip>
        <StatusImportacao importacaoArquivo={transmissaoState.importacaoArquivo} falhaImportacao={throwError} />
      </VFlow>
    </HeadingSection>
  )
}

const StatusImportacao = (props: StatusImportacaoProps) => {
  const { classes } = useStyles((theme) => ({
    fileDetailsContainer: {
      alignItems: 'center',
      display: 'flex',
      padding: '1rem',
      background: theme.pallete.surface.main,
    },
    fileDetails: {
      flexGrow: 1,
      marginLeft: '1rem',
    },
    successIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.success.main,
    },
    loadingIcon: {
      fontSize: '1rem',
      color: theme.pallete.primary.main,
    },
    errorIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  const {
    importacaoArquivo: { dataEvento, status },
    falhaImportacao,
  } = props

  if (falhaImportacao) {
    return (
      <Paper>
        <div className={classes.fileDetailsContainer}>
          <div className={classes.fileDetails}>
            <HFlow hSpacing={0.2}>
              <Icon style={classes.errorIcon} icon='banOutline' />
              <Text color='danger'>
                Falha na importação do arquivo em <DateTime value={moment.now()} />.
              </Text>
            </HFlow>
          </div>
        </div>
      </Paper>
    )
  }

  if (status === 'SEM_RESULTADO') {
    return <></>
  }

  return (
    <Paper>
      <div className={classes.fileDetailsContainer}>
        <div className={classes.fileDetails}>
          {status === 'IMPORTANDO' && (
            <HFlow hSpacing={0.2}>
              <Spinner style={classes.loadingIcon} />
              <Text color='primary'>Importando arquivo</Text>
            </HFlow>
          )}
          {status === 'CONCLUIDO' && (
            <HFlow hSpacing={0.2}>
              <Icon style={classes.successIcon} icon='checkCircleOutline' />
              <Text color='success'>
                Importação de arquivo concluída com sucesso em <DateTime value={dataEvento} />.
              </Text>
            </HFlow>
          )}
          {status === 'ERRO' && (
            <HFlow hSpacing={0.2}>
              <Icon style={classes.errorIcon} icon='banOutline' />
              <Text color='danger'>
                Falha na importação do arquivo em <DateTime value={dataEvento} />.
              </Text>
            </HFlow>
          )}
        </div>
      </div>
    </Paper>
  )
}
