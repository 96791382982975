import { Cell, Grid, HFlow, Icon, InfoLabel, Tag, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { DateTime } from 'components/date'
import { AccordionField } from 'components/form/field/AccordionField'
import { css } from 'emotion'
import { OrigemAtendimento } from 'graphql/types.generated'
import { noop } from 'lodash'
import React, { CSSProperties } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { origemDadosVacinacao } from '../model'
import { SubModalVacinacaoOptions } from '../VacinacaoModal'
import { VacinacaoVisualizacaoButtons } from './VacinacaoVisualizacaoButtons'

export interface RegistroAnteriorDoseProps {
  dataRegistro: string
  dataAplicacao?: string
  nomeProfissional: string
  cbo: string
  lote: string
  nomeFabricante: string
  observacoes: string
  unidadeSaude: string
  origemDados: OrigemAtendimento
  registradoAtendimento: boolean
  idUnicoRegistro: ID
  estrategiaVacinacao: string
  isAplicadoExterior: boolean
  isCovid: boolean
  handleOnDelete?(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
  handleOnEdit?(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
  readOnly?: boolean
}

export const RegistroAnteriorDose = (props: RegistroAnteriorDoseProps) => {
  const {
    dataRegistro,
    dataAplicacao,
    nomeProfissional,
    cbo,
    lote,
    nomeFabricante,
    observacoes,
    unidadeSaude,
    origemDados,
    registradoAtendimento,
    idUnicoRegistro,
    estrategiaVacinacao,
    isAplicadoExterior,
    isCovid,
    handleOnDelete = noop,
    handleOnEdit = noop,
    readOnly = false,
  } = props

  const { classes } = useStyles(createStyles)

  const renderRegistroAnteriorDoseContent = () => (
    <RegistroAnteriorDoseContent
      nomeFabricante={nomeFabricante}
      lote={lote}
      observacoes={observacoes}
      nomeProfissional={nomeProfissional}
      cbo={cbo}
      unidadeSaude={unidadeSaude}
      dataRegistro={dataRegistro}
      estrategiaVacinacao={estrategiaVacinacao}
      isAplicadoExterior={isAplicadoExterior}
      isCovid={isCovid}
    />
  )

  if (readOnly) {
    return renderRegistroAnteriorDoseContent()
  }

  return (
    <VFlow vSpacing={0}>
      <AccordionField name='Registro' allowZeroExpanded>
        <AccordionItem>
          <AccordionButton style={classes.accordionButton}>
            <HFlow
              alignItems='center'
              justifyContent='space-between'
              style={css`
                padding: 0.5rem;
                width: 35rem;
              `}
            >
              <HFlow>
                <Icon icon='angleDown' size={1.5} />
                <HFlow hSpacing={0.3} alignItems='center'>
                  <Icon color={green.c40} icon='needleFilled' size={1.1} />
                  <Text fontWeight='bold'>Dose aplicada em </Text>
                  <DateTime
                    value={dataAplicacao}
                    style={css`
                      font-weight: bold;
                    `}
                    format='DD/MM/YYYY'
                  />
                </HFlow>
              </HFlow>
              <HFlow hSpacing={0} alignItems='center'>
                <Tooltip text={origemDadosVacinacao[origemDados]?.hint}>
                  <Tag type='info'>{origemDadosVacinacao[origemDados]?.label}</Tag>
                </Tooltip>
                {registradoAtendimento && (
                  <VacinacaoVisualizacaoButtons
                    idUnicoRegistro={idUnicoRegistro}
                    tipoModal='ANTERIOR'
                    handleOnDelete={handleOnDelete}
                    handleOnEdit={handleOnEdit}
                  />
                )}
              </HFlow>
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel className={classes.panel}>{renderRegistroAnteriorDoseContent()}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </VFlow>
  )
}

interface RegistroAnteriorDoseContentProps {
  dataRegistro: string
  nomeProfissional: string
  cbo: string
  lote: string
  nomeFabricante: string
  observacoes: string
  unidadeSaude: string
  estrategiaVacinacao: string
  isAplicadoExterior: boolean
  isCovid: boolean
}

const RegistroAnteriorDoseContent = (props: RegistroAnteriorDoseContentProps) => {
  const {
    nomeFabricante,
    lote,
    observacoes,
    nomeProfissional,
    cbo,
    unidadeSaude,
    dataRegistro,
    estrategiaVacinacao,
    isAplicadoExterior,
    isCovid,
  } = props

  return (
    <Grid
      gap={0}
      gapVertical={1}
      style={css`
        padding: 1rem;
        width: 36rem;
        word-wrap: break-word;
      `}
    >
      {isCovid && (
        <Cell size={5}>
          <InfoLabel title='Estratégia' placeholder='-'>
            {estrategiaVacinacao?.capitalize()}
          </InfoLabel>
        </Cell>
      )}
      <Cell size={7}>
        <InfoLabel title='Lote/Fabricante'>
          {`${lote?.capitalize() || ' - '}/${nomeFabricante?.capitalize() || ' - '}`}
        </InfoLabel>
      </Cell>
      {isCovid && (
        <Cell size={12}>
          <InfoLabel title='Aplicado no exterior' placeholder='-'>
            {isAplicadoExterior ? 'Sim' : 'Não'}
          </InfoLabel>
        </Cell>
      )}
      <Cell size={12}>
        <InfoLabel title='Observações' placeholder='-'>
          {observacoes}
        </InfoLabel>
      </Cell>
      <Cell size={12}>
        <InfoLabel title='Registrado por'>{`${nomeProfissional} (${cbo})`}</InfoLabel>
        {unidadeSaude}
      </Cell>
      <Cell size={8}>
        <Text component='b'>
          Aplicação registrada em
          <DateTime
            value={dataRegistro}
            style={css`
              font-weight: bold;
            `}
            format=' DD/MM/YYYY [às] HH:mm'
          />
        </Text>
      </Cell>
      <Cell size={4}>
        <Tag type='normal' icon='undo'>
          Transcrição de caderneta
        </Tag>
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  panel: {
    margin: '0 0.3rem',
    borderWidth: '0 0.063rem 0.063rem',
    borderStyle: 'solid',
    borderColor: theme.pallete.gray.c60,
    position: 'relative',
    top: '-0.6rem',
  } as CSSProperties,
  accordionButton: {
    display: 'flex',
    padding: '0.5rem',
    border: `0.063rem solid ${theme.pallete.gray.c60}`,
    borderBlockEnd: `0.063rem solid ${theme.pallete.gray.c80}`,
  } as CSSProperties,
})
