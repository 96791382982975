import { VFlow } from 'bold-ui'
import React from 'react'

import { IdadeOrigemAtendimento, InformacoesAdministrativas } from '../../../model/model-historico'
import { HistoricoAtendimentoObservacaoAlert } from './atendimento-observacao/HistoricoAtendimentoObservacaoAlert'
import HistoricoIdadeOrigemAtendimento from './HistoricoIdadeOrigemAtendimento'
import HistoricoInfoAdminPanel from './HistoricoInfoAdminPanel'

interface HistoricoGapPanelProps {
  idadeOrigemAtendimento?: IdadeOrigemAtendimento
  informacoesAdministrativas: InformacoesAdministrativas
  children: React.ReactNode
  isAtendObservacao?: boolean
  dataInicioCompartilhamento?: LocalDate
}

export default function HistoricoGapPanel({
  idadeOrigemAtendimento,
  children,
  informacoesAdministrativas,
  isAtendObservacao = false,
  dataInicioCompartilhamento,
}: HistoricoGapPanelProps) {
  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        {isAtendObservacao && <HistoricoAtendimentoObservacaoAlert />}
        {idadeOrigemAtendimento && (
          <HistoricoIdadeOrigemAtendimento
            isAtendObservacao={isAtendObservacao}
            idadeOrigemAtendimento={idadeOrigemAtendimento}
            isCompartilhamentoCuidado={informacoesAdministrativas.stCuidadoCompartilhado}
            dataInicioCompartilhamento={dataInicioCompartilhamento}
          />
        )}
      </VFlow>
      {children}
      <HistoricoInfoAdminPanel content={informacoesAdministrativas} />
    </VFlow>
  )
}
