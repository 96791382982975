import { Button, ButtonProps, ExternalStyles, Portal, Theme, useStyles } from 'bold-ui'
import { useClickOutside } from 'bold-ui/lib/hooks'
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { PopperProps, usePopper } from 'react-popper'

export interface PopperButtonProps extends ButtonProps {
  placement?: PopperProps<any>['placement']
  positioningStrategy?: PopperProps<any>['strategy']
  renderPopper: (controls: PopperControls) => React.ReactNode
  zIndex?: number
  onClose?(): void
  styleCallback?(isOpen: boolean): ExternalStyles
  container?: Element
  onOpen?(): void
  closeOnBackdropClick?: boolean
}

export interface PopperControls {
  open(): void
  close(): void
  toggle(): void
}

export function PopperButton(props: PopperButtonProps) {
  const {
    placement,
    positioningStrategy,
    renderPopper,
    zIndex,
    onClose,
    onOpen,
    style,
    styleCallback,
    container,
    closeOnBackdropClick = true,
    ...buttonProps
  } = props

  const rootRef = useRef<HTMLDivElement>()
  const [buttonRef, setButtonRef] = useState<HTMLElement>()
  const [popperRef, setPopperRef] = useState<HTMLDivElement>()

  const [isOpen, setOpen] = useState(false)

  const open = useCallback(() => setOpen(true), [])

  const close = useCallback(() => {
    onClose?.()
    setOpen(false)
  }, [onClose])

  const toggle = useCallback(() => {
    if (!isOpen) {
      onOpen?.()
    }

    setOpen((state) => !state)
  }, [isOpen, onOpen])

  const { css, classes } = useStyles(createStyles, { isOpen, zIndex })

  useClickOutside(rootRef, () => {
    isOpen && closeOnBackdropClick && close()
  })

  useEffect(() => {
    // Attach "Escape" to close popper
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close()
        buttonRef?.focus()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isOpen, close, buttonRef])

  useEffect(() => {
    if (isOpen && popperRef) {
      setTimeout(() => {
        const e: HTMLElement = popperRef.querySelector(
          'a:not([disabled]), button:not([disabled]), input:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
        e && e.focus()
      })
    }
  }, [isOpen, popperRef])

  const {
    styles: { popper: popperStyle },
  } = usePopper(buttonRef, popperRef, { placement, strategy: positioningStrategy })

  return (
    <>
      <Button
        innerRef={setButtonRef}
        onClick={toggle}
        {...buttonProps}
        style={styleCallback ? styleCallback(isOpen) : style}
      />

      <Portal container={container}>
        <div ref={rootRef}>
          {isOpen && (
            <div
              ref={setPopperRef}
              className={css(classes.popper, popperStyle as any)}
              data-placement={placement}
              data-visible={isOpen}
            >
              {renderPopper({ open, close, toggle })}
            </div>
          )}
        </div>
      </Portal>
    </>
  )
}

PopperButton.defaultProps = {
  placement: 'bottom',
} as PopperButtonProps

const createStyles = (theme: Theme, { isOpen, zIndex }) => ({
  popper: {
    zIndex: zIndex ?? theme.zIndex.popper,
    visibility: isOpen ? 'visible' : 'hidden',
    transition: 'opacity .2s ease',
    opacity: isOpen ? 1 : 0,
  } as CSSProperties,
  button: {} as CSSProperties,
})
