import { Button, Cell, Grid, Icon, Tooltip, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { ProcedimentoSelectField, ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { getFieldError } from 'components/form/final-form/util'
import { GrupoProcedimentoEnum, TipoExameEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaArray } from 'util/metaPath'
import metaPath from 'util/metaPath/metaPath'
import { arrayMaxLength, createValidator, required } from 'util/validation'
import { EditableList, EditableRow, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'

export interface ProcedimentoGrupoExameFieldModel {
  _id: ID
  procedimento: ProcedimentoSelectModel
}

export interface ProcedimentoGrupoExameFieldProps<T> {
  name: MetaArray<ProcedimentoGrupoExameFieldModel>
}

const metaPath1 = metaPath<ProcedimentoGrupoExameFieldModel>()

const validator = createValidator<ProcedimentoGrupoExameFieldModel>({
  procedimento: [required, arrayMaxLength(16)],
})

export function ProcedimentoGrupoExameField<T = any>(props: ProcedimentoGrupoExameFieldProps<T>) {
  const { name } = props

  const itemIsEqual = (procedimento: ProcedimentoSelectModel) => {
    return (value || []).find((o2) => procedimento.id === o2.procedimento.id)
  }
  const { getServerTimeNow } = useServerTime()

  const {
    handleSubmit,
    removeItem,
    input: { value },
    meta,
  } = useEditableListField({
    name,
  })

  const errors = getFieldError(meta)

  const renderForm = (props: FormRenderProps<ProcedimentoGrupoExameFieldModel>) => {
    const handleChange = (newValue: ProcedimentoSelectModel) => {
      newValue && !itemIsEqual(newValue) && props.form.submit()
    }

    return (
      <>
        <Tooltip text={value?.length >= 16 ? 'Número máximo de itens atingido.' : ''} placement='auto'>
          <ProcedimentoSelectField
            name={metaPath1.procedimento}
            label='Exames'
            tipoExame={TipoExameEnum.COMUM}
            gruposProcedimento={[GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA]}
            filtroPadrao={true}
            itemIsEqual={itemIsEqual}
            onChange={handleChange}
            disabled={value?.length >= 16}
            required
            error={errors}
            dataAtendimento={dateAsYyyyMmDd(getServerTimeNow())}
          />
        </Tooltip>
      </>
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={8}>
        <VFlow vSpacing={0.35}>
          <Form<ProcedimentoGrupoExameFieldModel>
            render={renderForm}
            onSubmit={handleSubmit}
            validate={validator}
            {...props}
          />
        </VFlow>
      </Cell>

      {value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <ProcedimentoRow key={item._id} model={item} onRemove={removeItem} />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

interface ProcedimentoRowProps {
  model: ProcedimentoGrupoExameFieldModel
  onRemove(itemToRemove: ProcedimentoGrupoExameFieldModel): void
}

function ProcedimentoRow(props: ProcedimentoRowProps) {
  const { model, onRemove } = props
  const handleRemove = () => onRemove(model)

  return (
    <EditableRow editing={false}>
      <Grid style={{ margin: 0 }}>
        <Cell size={10} alignSelf='center'>{`${model.procedimento.descricao} - ${showCodigoProcedimento(
          model.procedimento.codigo
        )}`}</Cell>
        <Cell size={2} alignSelf='center' style={{ padding: '0.25rem 0', textAlign: 'right' }}>
          <Tooltip text='Excluir'>
            <Button size='small' skin='ghost' onClick={handleRemove}>
              <Icon icon='trashOutline' />
            </Button>
          </Tooltip>
        </Cell>
      </Grid>
    </EditableRow>
  )
}
