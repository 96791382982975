import { round } from 'lodash'

/**
 * Arredonda o valor para o número de casas decimais desejadas e formata para texto
 * @param value valor a ser formatado
 * @param precision número de casas decimais desejadas, padrão = 2
 */
export function formatNumber(value: number | string, precision: number = 2) {
  const formatter = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: precision })
  const numberValue = typeof value === 'number' ? value : parseNumber(value)
  return isNaN(numberValue) ? (value as string) : formatter.format(round(numberValue, precision))
}

/**
 * formatNumber removendo pontos ("."). Usar para preencher initialValue de DecimalField e exibir
 * números decimais na mensagem de validação, enquanto nosso <DecimalField /> não suporta pontos
 */
export function formatNumberRemovingDots(value: number | string, precision: number = 2): string {
  return formatNumber(value, precision).replace(/\./g, '')
}

export function parseNumber(value: string) {
  if (value) {
    const unlocalizedValue = value.replace('.', '').replace(',', '.')
    if (unlocalizedValue.includes('.')) {
      return parseFloat(unlocalizedValue)
    } else {
      return parseInt(unlocalizedValue)
    }
  }
  return undefined
}

/**
 * Usado para pegar a precisão de um numero
 */
export const getNumberPrecision = (value: number) => {
  const stringfied = value.toString()
  const dotPos = stringfied.indexOf('.')

  return dotPos > -1 ? stringfied.slice(dotPos + 1, stringfied.length).length : 0
}
