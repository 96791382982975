import ReactPDF, { StyleSheet, Text, View } from '@react-pdf/renderer'
import { convertFromHTML } from 'draft-convert'
import { convertToRaw, EditorState } from 'draft-js'
import React from 'react'
import redraft from 'redraft'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintRichTextProps {
  align?: 'center' | 'left' | 'right' | 'justify'
  wrapperStyles?: ReactPDF.Style
  textStyles?: ReactPDF.Style
  content: string
}

const RichText = (props: PrintRichTextProps) => {
  const { content, align, textStyles } = props
  const { config } = usePrinterStyles()

  const styles = StyleSheet.create({
    text: { ...config.main, lineHeight: 1.25, textAlign: align ?? 'justify' },
    bold: { fontFamily: 'Lato Bold' },
    italic: { fontFamily: 'Lato Italic' },
    underline: { textDecoration: 'underline' },
    strikethrough: { textDecoration: 'line-through' },
    blockquote: {
      ...config.secondary,
      borderLeft: 2,
      borderLeftColor: config.secondary.color,
      padding: 5,
      margin: 3,
      fontFamily: 'Lato Italic',
    },
    list: {
      marginLeft: 10,
      marginBottom: 5,
    },
  })

  const renderers = {
    inline: {
      BOLD: (children, { key }) => {
        return (
          <Text key={`bold-${key}`} style={styles.bold}>
            {children}
          </Text>
        )
      },
      ITALIC: (children, { key }) => {
        return (
          <Text key={`italic-${key}`} style={styles.italic}>
            {children}
          </Text>
        )
      },
      UNDERLINE: (children, { key }) => {
        return (
          <Text wrap key={`underline-${key}`} style={styles.underline}>
            {children}
          </Text>
        )
      },
      STRIKETHROUGH: (children, { key }) => {
        return (
          <Text wrap key={`strikethrough-${key}`} style={styles.strikethrough}>
            {children}
          </Text>
        )
      },
    },

    blocks: {
      unstyled: (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <View key={keys[index]}>
              <Text style={[styles.text, textStyles]}>{child}</Text>
            </View>
          )
        })
      },
      blockquote: (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <View key={keys[index]}>
              <Text key={`blockquote-${index}`} style={[styles.blockquote, textStyles]}>
                {child}
              </Text>
            </View>
          )
        })
      },
      'unordered-list-item': (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <View key={keys[index]}>
              <Text key={`ul-${index}`} style={[styles.text, styles.list]}>
                &#8226; {child}
              </Text>
            </View>
          )
        })
      },
      'ordered-list-item': (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <View key={keys[index]}>
              <Text key={`ol-${index}`} style={[styles.text, styles.list]}>
                {index + 1}. {child}
              </Text>
            </View>
          )
        })
      },
    },
  }

  const editorState = EditorState.createWithContent(convertFromHTML(content))
  const rawContent = convertToRaw(editorState.getCurrentContent())

  return redraft(rawContent, renderers, { blockFallback: 'unstyled' })
}

export const PrintRichText = ({ wrapperStyles, ...props }: PrintRichTextProps) => {
  const classes = StyleSheet.create({
    wrapper: { marginHorizontal: 15 },
  })

  return (
    <>
      <View wrap style={[classes.wrapper, wrapperStyles]}>
        <RichText {...props} />
      </View>
    </>
  )
}
