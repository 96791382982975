import { noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { ConfiguracoesVideochamadaModel } from '../model'

interface UseUserMediaOptions {
  video: boolean
  audio: boolean
  videoDeviceId?: string
  audioDeviceId?: string
}

export function useUserMedia(options: UseUserMediaOptions) {
  const { audio, audioDeviceId, video, videoDeviceId } = options
  const [stream, setStream] = useState<MediaStream>()
  const [audioDeviceAvailable, setAudioDeviceAvailable] = useState(false)
  const [videoDeviceAvailable, setVideoDeviceAvailable] = useState(false)
  const [mediaDevices, setMediaDevices] = useState<ConfiguracoesVideochamadaModel>()

  //É necessário solicitar acesso aos dispositivos separadamente para verificar se estão disponíveis
  const checkDevicesStatusAndGetStreams = useCallback(
    ({ audio, audioDeviceId, video, videoDeviceId }: UseUserMediaOptions) => {
      const audioConstraint = audioDeviceId ? { deviceId: { ideal: audioDeviceId } } : true
      const videoConstraint = videoDeviceId ? { deviceId: { ideal: videoDeviceId } } : true

      Promise.all([
        navigator.mediaDevices
          ?.getUserMedia({ audio: audioConstraint })
          .then((audioStream) => {
            const audioTracks = audioStream.getAudioTracks()
            setAudioDeviceAvailable(!!audioTracks.length)

            if (audio) return audioTracks

            audioTracks.forEach((track) => track.stop())
            return []
          })
          .catch(() => {
            setAudioDeviceAvailable(false)
            return []
          }),
        navigator.mediaDevices
          ?.getUserMedia({ video: videoConstraint })
          .then((videoStream) => {
            const videoTracks = videoStream.getVideoTracks()
            setVideoDeviceAvailable(!!videoTracks.length)

            if (video) return videoTracks

            videoTracks.forEach((track) => track.stop())
            return []
          })
          .catch(() => {
            setVideoDeviceAvailable(false)
            return []
          }),
      ])
        .then(([audioTracks, videoTracks]) => {
          setStream(new MediaStream([...(audioTracks ?? []), ...(videoTracks ?? [])]))

          const audioTrack = audioTracks[0]
          const videoTrack = videoTracks[0]

          const audioDeviceId = audioTrack?.getSettings()?.deviceId
          const audioDeviceLabel = audioTrack?.label

          const videoDeviceId = videoTrack?.getSettings()?.deviceId
          const videoDeviceLabel = videoTrack?.label

          setMediaDevices({
            audioInput: { id: audioDeviceId ?? '', nome: audioDeviceLabel ?? '' },
            audioOutput: { id: 'default', nome: 'Padrão' },
            videoInput: { id: videoDeviceId ?? '', nome: videoDeviceLabel ?? '' },
          })
        })
        .catch(noop) // Os erros serão lançados quando não houver acesso aos dispositivos, mas isso é esperado
    },
    [setAudioDeviceAvailable, setVideoDeviceAvailable]
  )

  useEffect(() => {
    checkDevicesStatusAndGetStreams({ audio, audioDeviceId, video, videoDeviceId })
    const handleDeviceChange = () => checkDevicesStatusAndGetStreams({ audio, audioDeviceId, video, videoDeviceId })
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange)
  }, [checkDevicesStatusAndGetStreams, audio, audioDeviceId, video, videoDeviceId])

  //Para de usar os dispositivos de mídia ao destruir o componente
  useEffect(() => () => stream?.getTracks().forEach((track) => track.stop()), [stream])

  return { stream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices }
}
