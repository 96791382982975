import useAtmosphere, { AtmosphereVariables } from 'hooks/useAtmosphere'
import { useCallback } from 'react'
import { SignalData } from 'simple-peer'

import { LocalVideocallParticipant } from '../model'

export interface WebRtcSignal {
  sender: LocalVideocallParticipant
  receiverId: ID
  data: SignalData
}

export type SendSignalCallback = (receiverId: ID, signal: SignalData) => void

interface WebRtcSignalingServerOptions extends Omit<AtmosphereVariables<WebRtcSignal>, 'topic' | 'onMessage'> {
  selfData: LocalVideocallParticipant
  roomId: ID
  onSignal(signal: WebRtcSignal, sendSignal: SendSignalCallback): void
}

type PushCallback = (message: WebRtcSignal) => void
const pushSignalProxy = (push: PushCallback, sender: LocalVideocallParticipant) => (
  receiverId: ID,
  signal: SignalData
) => {
  push({ receiverId, sender: sender, data: signal })
}

export function useWebRtcSignalingServer({ selfData, roomId, onSignal, ...rest }: WebRtcSignalingServerOptions) {
  const handleMessage = useCallback(
    (message: WebRtcSignal, _, push: PushCallback) => {
      //TODO: @RNG Otimizar para não enviar todas as mensagens para todos sempre (#14543)
      if (selfData && selfData.id === message.receiverId && onSignal) onSignal(message, pushSignalProxy(push, selfData))
    },
    [onSignal, selfData]
  )

  const { push } = useAtmosphere<WebRtcSignal>({
    topic: `public/webRtcSignal/${roomId}`,
    onMessage: handleMessage,
    ...rest,
  })

  const sendSignal = useCallback<SendSignalCallback>(
    (receiverId, signal) => push({ receiverId, sender: selfData, data: signal }),
    [push, selfData]
  )

  return {
    sendSignal,
  }
}
