import { colors } from 'bold-ui'
import { usePagination } from 'components/table/usePagination'
import { useLembretesCardQuery } from 'graphql/hooks.generated'
import React, { useEffect, useMemo, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { MetaPath } from 'util/metaPath'
import { LembreteFormModel } from 'view/atendimentos/detail/components/modals/lembretes/components/LembreteForm'
import { mergeSortLembretes } from 'view/atendimentos/detail/components/modals/lembretes/mergeSortLembretes'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { LembretesModal } from '../../../components/modals/lembretes/LembretesModal'
import { ProntuarioCard } from '../ProntuarioCard'
import { LembreteModel } from '../types/LembreteModel'
import { LembretesCard } from './LembretesCard'

interface LembretesSectionProps {
  prontuarioId: ID
  isAtendimentoObservacao: boolean
  lembretesCache?: LembreteFormModel[]
  lotacao?: LotacaoAtendimento
  name?: MetaPath<LembreteFormModel>
}

export function LembretesSection({
  prontuarioId,
  isAtendimentoObservacao,
  lembretesCache,
  name,
  lotacao,
}: LembretesSectionProps) {
  const history = useHistory()
  const match = useRouteMatch()
  const modalUrl = `${match.path}/lembretes`
  const editable = !!name

  const [canRender, setCanRender] = useState(false)

  const handleClick = () => history.push(modalUrl)

  const { data, loading } = useLembretesCardQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lembretesQueryInput: {
        prontuarioId,
      },
    },
  })

  const lembretesCacheDatabase = useMemo(() => mergeSortLembretes(data?.lembretes ?? [], lembretesCache ?? []), [
    data,
    lembretesCache,
  ])

  const lembretesFiltered = useMemo(() => lembretesCacheDatabase.filter((lembrete) => lembrete.ultimoLembrete?.ativo), [
    lembretesCacheDatabase,
  ])

  const { paginatedItems } = usePagination<LembreteModel>({
    items: lembretesFiltered,
  })

  const hasLembretes: boolean = useMemo(() => paginatedItems && paginatedItems.length !== 0, [paginatedItems])

  useEffect(() => {
    setCanRender(hasLembretes)
  }, [hasLembretes])

  return (
    <>
      <Route
        path={modalUrl}
        render={(renderProps) => (
          <LembretesModal
            {...renderProps}
            prontuarioId={prontuarioId}
            lembretesCache={lembretesCache}
            name={name}
            lotacao={lotacao}
            isAtendimentoObservacao={isAtendimentoObservacao}
          />
        )}
      />
      <ProntuarioCard
        title='Lembretes'
        tooltip={
          canRender ? (!editable ? 'Ver Lembretes' : 'Ver ou Adicionar Lembretes') : 'Nenhum lembrete registrado'
        }
        onClick={handleClick}
        titleBackgroundColor={colors.yellow.c90}
        style={{ maxHeight: 'auto', overflow: 'hidden' }}
      >
        <LembretesCard
          loading={loading}
          paginatedItems={paginatedItems}
          hasLembretes={hasLembretes}
          canRender={canRender}
          mostrarAutor={!editable}
        />
      </ProntuarioCard>
    </>
  )
}
