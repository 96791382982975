import useSession from 'components/auth/useSession'
import { useLocalStorage } from 'hooks/useStorage'

import { ConfiguracoesVideochamadaModel, DispositivoMidiaSelectModel } from '../model'

export function useConfiguracoesVideochamada(): [
  ConfiguracoesVideochamadaModel,
  (value: ConfiguracoesVideochamadaModel) => void
] {
  const { data: session } = useSession()

  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useLocalStorage<ConfiguracoesVideochamadaModel>(
    `${session?.profissional?.id ?? ''}/configuracoes-videochamada`
  )

  return [configuracoesVideochamada, setConfiguracoesVideochamada]
}

export function useStoredAudioDevice(): [
  DispositivoMidiaSelectModel,
  (audioDevice: DispositivoMidiaSelectModel) => void
] {
  const { data: session } = useSession()

  const [storedAudioDevice, setStoredAudioDevice] = useLocalStorage<DispositivoMidiaSelectModel>(
    `${session?.profissional?.id ?? ''}/dispositivo-audio-videochamada`
  )

  return [storedAudioDevice, setStoredAudioDevice]
}

export function useStoredVideoDevice(): [
  DispositivoMidiaSelectModel,
  (videoDevice: DispositivoMidiaSelectModel) => void
] {
  const { data: session } = useSession()

  const [storedVideoDevice, setStoredVideoDevice] = useLocalStorage<DispositivoMidiaSelectModel>(
    `${session?.profissional?.id ?? ''}/dispositivo-video-videochamada`
  )

  return [storedVideoDevice, setStoredVideoDevice]
}
