import { Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { FooterButton } from 'components/footer-button'
import { FormPrompt } from 'components/form'
import React, { Fragment } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'

import { CuidadoCompartilhadoPlanoModel, EDITAR_CUIDADO_COMPARTILHADO_URL } from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoForm } from './CuidadoCompartilhadoForm'
import { CuidadoCompartilhadoFormFields } from './CuidadoCompartilhadoFormFields'

interface EditarCuidadoCompartilhadoModalFormProps {
  cuidadosCompartilhado: CuidadoCompartilhadoPlanoModel[]
  onSubmit: (values: CuidadoCompartilhadoPlanoModel) => void
}

export const EditarCuidadoCompartilhadoModalForm = (props: EditarCuidadoCompartilhadoModalFormProps) => {
  const { cuidadosCompartilhado, onSubmit } = props

  const { id } = useParams<{ id: string }>()
  const match = useRouteMatch()
  const history = useHistory()
  const alert = useAlert()
  const { analytics } = useFirebase()

  const itemToEdit = (cuidadosCompartilhado || emptyArray).find((cuidado) => cuidado._id === id)

  const handleClose = () => {
    history.push(match.url.split(EDITAR_CUIDADO_COMPARTILHADO_URL)[0])
  }

  const onSubmitSucceeded = () => {
    alert('success', 'Cuidado compartilhado alterado com sucesso.')
    analytics.logEvent('CC_editado')
    handleClose()
  }

  if (isUndefinedOrNull(itemToEdit)) {
    handleClose()
  }

  const renderForm = (renderProps: FormRenderProps<CuidadoCompartilhadoPlanoModel>) => {
    return (
      <Fragment>
        <FormPrompt />
        <Modal open onClose={handleClose}>
          <ModalBody>
            <VFlow>
              <Heading level={1}>Editar compartilhamento de cuidado</Heading>

              <CuidadoCompartilhadoFormFields />
            </VFlow>
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <FooterButton kind='normal' onClick={handleClose}>
                Cancelar
              </FooterButton>
              <FooterButton kind='primary' onClick={renderProps.handleSubmit}>
                Salvar
              </FooterButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }

  return (
    <CuidadoCompartilhadoForm
      initialValues={itemToEdit}
      render={renderForm}
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
    />
  )
}
