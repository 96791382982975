/** @jsx jsx */
import { QueryLazyOptions } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import { colors, HFlow, Icon, Text } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import theme from 'config/theme'
import { RegistrosDoseVacinacaoQueryVariables } from 'graphql/types.generated'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import {
  ImunobiologicoRow,
  ImunobiologicoRowProps,
} from 'view/atendimentos/detail/components/vacinacao/ImunobiologicoRow'
import { InfoCardModalVacinacao } from 'view/atendimentos/detail/vacinacao/VacinacaoCalendarioView'

interface CovidAccordionImunobiologicoRowProps {
  imunobiologicosCovid: ImunobiologicoRowProps[]
  isAcompanhamentoVacinal: boolean
  mostrarCalendarioCompleto: boolean
  readOnly?: boolean
  handleOnDoseClicked: (info: InfoCardModalVacinacao) => void
  registrosDoseLazyQuery: (options?: QueryLazyOptions<RegistrosDoseVacinacaoQueryVariables>) => void
}

export default function CovidAccordionImunobiologicoRow(props: CovidAccordionImunobiologicoRowProps) {
  const {
    mostrarCalendarioCompleto,
    imunobiologicosCovid,
    handleOnDoseClicked,
    registrosDoseLazyQuery,
    isAcompanhamentoVacinal,
    readOnly,
  } = props

  return (
    <Accordion allowZeroExpanded>
      <AccordionItem uuid={`covidAccordion`}>
        <AccordionButton style={styles.accordionButton}>
          <HFlow>
            <Icon icon='angleDown' size={1.5} />
            <Text fontWeight='bold'>Doses/imunobiológicos/vacinas para COVID - 19 | COV19</Text>
          </HFlow>
        </AccordionButton>

        <AccordionItemPanel css={styles.itemPanel}>
          {imunobiologicosCovid.map((value) => (
            <ImunobiologicoRow
              key={value.model.imunobiologicoId}
              mostrarCalendarioCompleto={mostrarCalendarioCompleto}
              handleOnDoseClicked={handleOnDoseClicked}
              registrosDoseLazyQuery={registrosDoseLazyQuery}
              isAcompanhamentoVacinal={isAcompanhamentoVacinal}
              readOnly={readOnly}
              {...value}
            />
          ))}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

const styles = {
  itemPanel: css`
    & > * {
      margin-bottom: 0.25rem;
    }
    ,
    & > :first-child {
      margin-top: -0.5rem;
    }
  `,
  accordionButton: css`
    display: flex;
    padding: 1.5rem;
    border: 0.063rem solid ${theme.pallete.gray.c60};
    background-color: ${colors.orange.c90};
  `,
}
