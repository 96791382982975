import { Link, VFlow } from 'bold-ui'
import { useHasEquipesVinculadasQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { EquipeModel } from 'view/profissional/detail/model'
import { EQUIPES_VINCULADAS_MODAL_URL } from 'view/vinculacao-equipes/EquipesVinculadasModal'
import EquipesVinculadasRootView from 'view/vinculacao-equipes/EquipesVinculadasRootView'

import { EquipeLabel } from './EquipeLabel'

export interface LinkVerEquipesVinculadasViewProps {
  equipe: EquipeModel
}

export function LinkVerEquipesVinculadasView(props: LinkVerEquipesVinculadasViewProps) {
  const { equipe } = props
  const { url } = useRouteMatch()
  const history = useHistory()

  const {
    data: { hasEquipesVinculadas },
  } = useHasEquipesVinculadasQuery({
    variables: { equipePrincipalId: equipe?.id },
    skip: !equipe?.id,
  })

  const handleClick = () => history.push(`${url}${EQUIPES_VINCULADAS_MODAL_URL}`)

  return (
    <>
      {hasEquipesVinculadas && (
        <VFlow vSpacing={0.25}>
          <EquipeLabel nome={equipe.nome} ine={equipe.ine} />
          <Link onClick={handleClick}>Ver equipes vinculadas</Link>
          <EquipesVinculadasRootView equipePrincipal={equipe} />
        </VFlow>
      )}
    </>
  )
}
