/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DataTable, Heading, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { parseISO } from 'date-fns'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { downloadFile } from 'util/downloadFile'

import { MedicamentoFormModel, PrescricaoDigitalFormModel } from '../model'
import { prescricaoDigitalTableConverter } from '../utils/prescricaoConverter'
import { downloadPrescricaoDigital } from './downloadPrescricaoDigital'
import { invalidarPrescricaoDigital } from './invalidarPrescricaoDigital'

interface PrescricaoDigitalTableProps {
  atendimentoId: ID
  medicamentos: MedicamentoFormModel[]
  updateMedicamentos: (medicamentos: MedicamentoFormModel[]) => void
  prescricaoDigital: PrescricaoDigitalFormModel
  updatePrescricaoDigital: (prescricao: PrescricaoDigitalFormModel) => void
  nomeCidadao: string
}

export interface PrescricaoDigitalTableModel {
  codigoPrescricaoDigital: string
  textoMedicamentos: string
  prescricaoInvalidada: boolean
  emailEnvio: string
  dataAssinatura: string
}

export default function PrescricaoDigitalTable(props: PrescricaoDigitalTableProps) {
  const {
    atendimentoId,
    medicamentos,
    updateMedicamentos,
    nomeCidadao,
    prescricaoDigital,
    updatePrescricaoDigital,
  } = props

  const alert = useAlert()
  const { analytics } = useFirebase()
  const rows = prescricaoDigitalTableConverter(prescricaoDigital)

  const handleInvalidar = async (codigoPrescricaoDigital: string) => {
    const confirmed = await confirm({
      title: 'Deseja invalidar a prescrição digital gerada?',
      body:
        'Ao invalidar uma prescrição, ela não poderá mais ser visualizada pelo cidadão ou pela farmácia no serviço de prescrição digital.',
      confirmLabel: 'Invalidar',
    })()

    if (confirmed) {
      try {
        await invalidarPrescricaoDigital({
          atendimentoId: atendimentoId,
          codigoPrescricao: codigoPrescricaoDigital,
        })

        analytics.logEvent('invalidar_prescricao_digital')
        alert('success', 'Prescrição invalidada com sucesso ')

        updatePrescricaoDigital({
          ...prescricaoDigital,
          [codigoPrescricaoDigital]: {
            ...prescricaoDigital[codigoPrescricaoDigital],
            prescricaoInvalidada: true,
          },
        })

        updateMedicamentos(
          medicamentos.map((medicamento) =>
            medicamento.codigoPrescricaoDigital === codigoPrescricaoDigital
              ? { ...medicamento, codigoPrescricaoDigital: null }
              : medicamento
          )
        )
      } catch {
        alert('danger', 'Não foi possível invalidar a prescrição. Tente novamente mais tarde.')
      }
    }
  }

  const handleDownload = (codigoPrescricaoDigital: string, nomeCidadao: string, dataAssinaturaPrescricao: string) => {
    const nomeArquivo = `prescricao_${nomeCidadao.replaceAll(' ', '_')}_${dateAsDdMmYyyy(
      parseISO(dataAssinaturaPrescricao)
    ).replaceAll('/', '-')}.pdf`
    downloadPrescricaoDigital(codigoPrescricaoDigital)
      .then((response) => {
        downloadFile(new Blob([response.data]), nomeArquivo)
        alert('success', 'Download do documento realizado com sucesso.')
        return response
      })
      .catch(() => alert('danger', 'Não foi possível baixar o documento da prescrição. Tente novamente mais tarde.'))
    analytics.logEvent('download_prescricao_digital')
  }

  return (
    <VFlow>
      <Heading level={5}>Prescrições enviadas digitalmente para o cidadão</Heading>
      <DataTable<PrescricaoDigitalTableModel>
        rows={rows}
        columns={[
          {
            name: 'prescricao',
            header: 'Prescrição',
            render: (row) => (
              <VFlow vSpacing={0}>
                {row.textoMedicamentos}
                {row.prescricaoInvalidada && (
                  <HFlow
                    hSpacing={0.2}
                    alignItems='center'
                    style={css`
                      color: ${theme.pallete.status.danger.c40};
                    `}
                  >
                    <Icon
                      icon='banOutline'
                      size={1}
                      style={css`
                        display: block;
                      `}
                    />
                    Prescrição invalidada
                  </HFlow>
                )}
              </VFlow>
            ),
          },
          {
            name: 'enviadoPara',
            header: 'Enviado para',
            render: (row) => row.emailEnvio,
          },
          {
            name: 'actions',
            render: (row) => (
              <HFlow justifyContent='flex-end'>
                <Tooltip text='Invalidar prescrição digital'>
                  <Button
                    size='small'
                    skin='ghost'
                    disabled={row.prescricaoInvalidada}
                    onClick={() => handleInvalidar(row.codigoPrescricaoDigital)}
                  >
                    <Icon icon='timesDefault' />
                  </Button>
                </Tooltip>
                <Tooltip text='Baixar prescrição digital'>
                  <Button
                    size='small'
                    skin='ghost'
                    disabled={row.prescricaoInvalidada}
                    onClick={() => handleDownload(row.codigoPrescricaoDigital, nomeCidadao, row.dataAssinatura)}
                  >
                    <Icon icon='download' />
                  </Button>
                </Tooltip>
              </HFlow>
            ),
          },
        ]}
      />
    </VFlow>
  )
}
