import { ButtonProps, IconProps, modal } from 'bold-ui'
import { ModalDepthLevel } from 'bold-ui/lib/components/Modal/Modal'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { ModalBody } from 'components/modals/ModalBody'
import React from 'react'

export interface ConfirmConfig {
  title: React.ReactNode
  body?: React.ReactNode
  confirmLabel?: string
  cancelLabel?: string
  type?: ButtonProps['kind']
  icon?: IconProps['icon']
  onCancel?(e: React.SyntheticEvent): any
  onClose?(): any
  onConfirm?(e: React.SyntheticEvent): any
  depthLevel?: ModalDepthLevel
  manageOverflow?: boolean
}

const defaultConfig: ConfirmConfig = {
  body: null,
  title: 'Confirmação',
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  type: 'primary',
  onCancel: () => null,
  onConfirm: () => null,
}

const icons: { [key in ButtonProps['kind']]: IconProps['icon'] } = {
  normal: 'questionMarkOutline',
  primary: 'questionMarkOutline',
  danger: 'exclamationTriangleOutline',
}

export const confirm = (config: ConfirmConfig) => {
  const {
    title,
    body,
    onCancel,
    onConfirm,
    confirmLabel,
    cancelLabel,
    type,
    icon = icons[type],
    onClose,
    depthLevel,
    manageOverflow,
  } = {
    ...defaultConfig,
    ...config,
  }

  return () =>
    new Promise<boolean>((resolve) => {
      const handleResolve = (confirm: boolean) => () => resolve(confirm)

      modal({
        size: 'small',
        onClose: composeHandlers(onClose, handleResolve(false)),
        render: () => (
          <ModalBody title={title} icon={icon} type={type}>
            {body}
          </ModalBody>
        ),
        depthLevel,
        manageOverflow,
        actions: [
          { label: cancelLabel, onClick: composeHandlers(onCancel, handleResolve(false)) },
          { label: confirmLabel, kind: type, onClick: composeHandlers(onConfirm, handleResolve(true)) },
        ],
      })()
    })
}
