/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Progress, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'

interface IndiceItemProps {
  descricaoIndice: string
  textoToolTip: string
  quantidade: number
  quantidadeTotal: number
}

export function IndiceItem(props: IndiceItemProps) {
  const { descricaoIndice, textoToolTip, quantidade, quantidadeTotal } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>
          {descricaoIndice} {`${Math.trunc((quantidade / quantidadeTotal) * 100)}%`}
        </Text>
        ({quantidade} de {quantidadeTotal})
      </HFlow>
      <Tooltip
        placement='bottom'
        style={css`
          margin-top: 0.5rem;
        `}
        text={textoToolTip}
      >
        <Progress value={quantidade} max={quantidadeTotal} style={styles.progressBar} />
      </Tooltip>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  progressBar: css`
    width: 15rem;
    height: 0.8rem;
    border: 1.5px solid ${theme.pallete.gray.c40};
    border-radius: 5px;
  `,
})
