import { focusBoxShadow, hexToRGB, Text, Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'
import { NavHashLink, NavHashLinkProps } from 'react-router-hash-link'

interface SideMenuNewsSublinkProps extends NavHashLinkProps {
  title: string
}

export const SideMenuNewsSublink = (props: SideMenuNewsSublinkProps) => {
  const { classes } = useStyles(createStyles, true)
  const { title, to, ...rest } = props

  return (
    <li>
      <NavHashLink smooth className={classes.sublink} to={to} {...rest}>
        <Text color='inherit'>{title}</Text>
      </NavHashLink>
    </li>
  )
}

export const createStyles = (theme: Theme) => ({
  sublink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.pallete.text.main,
    outline: 'none',
    padding: '0.5rem 0.5rem 0.5rem 2rem',
    fontWeight: 'normal',

    '&:hover': {
      background: hexToRGB(theme.pallete.gray.c20, 0.1),
    },

    '&:focus': {
      borderRadius: 4,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },

    '&.active': {
      fontWeight: 'bold',
    },
  } as CSSProperties,
})
