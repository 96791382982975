import {
  ListaEsperaFilter,
  ListaEsperaItemCreateInput,
  ListaEsperaItemUpdateInput,
  UnidadesSaudeByGarantiaAcessoCidadaoInput,
} from 'graphql/types.generated'

import { ListaEsperaEdicaoModel, ListaEsperaFilterModel, ListaEsperaFormModel } from './model'

export const convertListaEsperaFormModelToInput = (model: ListaEsperaFormModel): ListaEsperaItemCreateInput => ({
  cidadaoId: model.cidadao.id,
  equipeId: model.equipe?.id,
  tipoAtendimento: model.tipoAtendimento?.value,
  tipoServico: model.tipoServico?.id,
  motivo: model.motivo,
})

export const convertListaEsperaFilterModelToInput = (filter: ListaEsperaFilterModel): ListaEsperaFilter => ({
  pageParams: filter.pageParams,
  nomeCpfCns: filter.nomeCpfCns,
  equipes: filter.equipes.map((item) => item.id),
  tiposAtendimento: filter.tiposAtendimento.map((item) => item.value),
  tiposServicoIds: filter.tiposServico.map((item) => item.id),
})

export const convertFormModelToUbsByGarantiaAcessoCidadaoInput = (
  values: ListaEsperaFormModel
): UnidadesSaudeByGarantiaAcessoCidadaoInput => ({
  cidadaoId: values.cidadao.id,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
})

export const convertListaEsperaEdicaoModelToInput = (values: ListaEsperaEdicaoModel): ListaEsperaItemUpdateInput => ({
  listaEsperaId: values.listaEsperaId,
  equipeId: values.equipe?.id,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
  motivo: values.motivo,
})

export const convertEdicaoModelToUbsByGarantiaAcessoCidadaoInput = (
  values: ListaEsperaEdicaoModel,
  cidadaoId: ID
): UnidadesSaudeByGarantiaAcessoCidadaoInput => ({
  cidadaoId: cidadaoId,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
})
