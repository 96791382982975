export enum RelatoriosGerenciaisUnidadeMedidaTempoEnum {
  DIA = 'DIA',
  SEMANA = 'SEMANA',
  MES = 'MES',
}

export enum RelatoriosGerenciaisOpcoesPeriodoEnum {
  PRIMEIRA = '1',
  SEGUNDA = '2',
  TERCEIRA = '3',
  OUTRO = '4',
}

type RelatoriosGerenciaisOpcoesPeriodoMapping = Map<
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisPeriodoOption
>

interface RelatoriosGerenciaisPeriodoOption {
  label: string
  quantityToSubtract: number
}

export const RELATORIOS_GERENCIAIS_DIA_OPCOES_MAPPING = new Map<
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisPeriodoOption
>([
  [RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA, { label: 'Últimos 30 dias', quantityToSubtract: 30 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA, { label: 'Últimos 90 dias', quantityToSubtract: 90 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA, { label: 'Últimos 180 dias', quantityToSubtract: 180 }],
])

export const RELATORIOS_GERENCIAIS_SEMANA_OPCOES_MAPPING = new Map<
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisPeriodoOption
>([
  [RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA, { label: 'Última semana', quantityToSubtract: 1 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA, { label: 'Últimas 2 semanas', quantityToSubtract: 2 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA, { label: 'Últimas 4 semanas', quantityToSubtract: 4 }],
])

export const RELATORIOS_GERENCIAIS_MES_OPCOES_MAPPING = new Map<
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisPeriodoOption
>([
  [RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA, { label: 'Último mês', quantityToSubtract: 1 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.SEGUNDA, { label: 'Últimos 3 meses', quantityToSubtract: 3 }],
  [RelatoriosGerenciaisOpcoesPeriodoEnum.TERCEIRA, { label: 'Últimos 6 meses', quantityToSubtract: 6 }],
])

export const RELATORIOS_GERENCIAIS_PERIODO_MAPPING = new Map<
  RelatoriosGerenciaisUnidadeMedidaTempoEnum,
  RelatoriosGerenciaisOpcoesPeriodoMapping
>([
  [RelatoriosGerenciaisUnidadeMedidaTempoEnum.DIA, RELATORIOS_GERENCIAIS_DIA_OPCOES_MAPPING],
  [RelatoriosGerenciaisUnidadeMedidaTempoEnum.SEMANA, RELATORIOS_GERENCIAIS_SEMANA_OPCOES_MAPPING],
  [RelatoriosGerenciaisUnidadeMedidaTempoEnum.MES, RELATORIOS_GERENCIAIS_MES_OPCOES_MAPPING],
])

export const RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS = 52
export const RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS = 1
export const RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO = 'relatorios_bi_selecionou_relatório'
